import React from "react";
import "./text.scss";

import Parser from "Class/Parser";
import Eatery from "Class/Eatery";
import {UcFirst} from "Functions";
import Button from "Components/UI/Button";
import EateryNavigation from "Components/UI/EateryNavigation";
import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class ModuleText extends React.Component
{
    render()
    {
        const {
            backgroundColor,
            buttons,
            buttonsDirection,
            index,
            showEateryMenu,
            text
        } = this.props;
        const Buttons = [];
        const CA = ["SegmentButtons", "Buttons", `Buttons${UcFirst(buttonsDirection) || "Column"}`];
        buttons.forEach((button, index) =>
        {
            const {action, color, hollow, label, page, type, url} = button;

            switch (type)
            {
                case "action":
                    Buttons.push(
                        <Button
                            color={color}
                            hollow={hollow}
                            key={index}
                            label={label}
                            onClick={() => Eatery.Action(action)}
                        />
                    );
                    break;

                case "link":
                    Buttons.push(
                        <Button
                            color={color}
                            hollow={hollow}
                            href={url}
                            key={index}
                            label={label}
                        />
                    );
                    break;

                default:
                    Buttons.push(
                        <Button
                            color={color}
                            hollow={hollow}
                            key={index}
                            label={label || Eatery.GetTitle(page)}
                            to={page}
                        />
                    );
            }
        });

        return (
            <Segment backgroundColor={backgroundColor} className="Module ModuleText" top={!index}>
                <ThreeColumns left={showEateryMenu ? <EateryNavigation /> : ""}>
                    {Parser.Parse(text, "div", null, {className: "SegmentText"})}
                    {Buttons.length ? <div className={CA.join(" ")}>{Buttons}</div> : ""}
                </ThreeColumns>
            </Segment>
        );
    }
}

ModuleText.defaultProps =
{
    backgroundColor: "white",
    buttons: [],
    index: 0,
    showEateryMenu: false,
    text: ""
};

export default ModuleText;