
/*!
 *  Date form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop object|array options - Field options.
 *  @prop string placeholder - Placeholder when unselected.
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string|number value - Field value.
 */

import React from "react";
import "./datefield.scss";

import {DateParse, DateToTime, ObjectCompare, UcFirst} from "Functions";

import Calendar from "Components/UI/Calendar";
import Icon from "Components/Layout/Icon";

class DateField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Calendar = false;
        this.Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        this.Input = false;
        this.Months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
        this.Weekdays = ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"];

        this.state =
        {
            expand: false,
            focus: false,
            value: -1
        };
    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        const Value = this.ParseValue(value);
        this.setState({value: Value});

        if (!ObjectCompare(value, Value))
        {
            this.OnSelect(null, Value);
        }
    }

    /**
     * Update value.
     * 
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1, limitLower: ll1, limitUpper: lu1} = this.props;
        const {value: v2, limitLower: ll2, limitUpper: lu2} = prevProps;
        const {value: v3} = this.state;

        if ((v1 !== v2 && v1 !== v3) || ll1 !== ll2 || lu1 !== lu2)
        {
            const Value = this.ParseValue(v1, ll1, lu1);
            this.setState({value: Value})

            if (!ObjectCompare(v1, Value))
            {
                this.OnSelect(null, Value);
            }
        }
    }

    /**
     * Set focus on this field.
     * 
     * @return void
     */

    Focus = (e) =>
    {
        if (this.Input)
        {
            this.Input.focus();
        }
    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) =>
    {
        const {id, onBlur} = this.props;
        const {value} = this.state;
        onBlur(e, value, id);
        this.setState({expand: false, focus: false});
    }

    /**
     * Toggle expand/collapse when the input is clicked.
     * 
     * @return void
     */

    OnClick = (e) =>
    {
        const {expand} = this.state;
        this.setState({expand: !expand});
    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const {value} = this.state;

        onFocus(e, value, id);
        this.setState({focus: true});
    }

    /**
     * Callback for when the user selects a new date.
     * 
     * @param object e - The event object.
     * @param string date - New date.
     * 
     * @return void
     */

    OnSelect = (e, date) =>
    {
        const SetDate = DateParse(date);
        const {disabled, id, onChange} = this.props;

        if (disabled)
        {
            return;
        }

        const Value = this.ParseValue(SetDate);
        onChange(e, Value, id);
        this.setState({value: Value});
    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) =>
    {
        if (this.Calendar && e.which > 36 && e.which < 41)
        {
            this.Calendar.OnKeyDown(e);
        }
        else
        {
            const {expand, value} = this.state;
            let Step = 0;

            switch (e.which)
            {
                case 13:
                    this.setState({expand: false});
                    break;

                case 32:
                    this.setState({expand: !expand});
                    break;

                case 37:
                case 38:
                    Step = -1;
                    break;

                case 39:
                case 40:
                    Step = 1;
                    break;
                
                default:
                    return;
            }

            e.stopPropagation();
            e.preventDefault();

            if (Step)
            {
                const [D, M, Y] = value;
                const Days = (M === 1 && Y % 4 === 0) ? 29 : this.Days[M];
                const DaysPrevious = (M === 2 && Y % 4 === 0) ? 29 : this.Days[M ? M - 1 : 11];
                let SetDate;
                let SetMonth = M;
                let SetYear = Y;

                if (Step < 0 && D <= 1 && !M)
                {
                    SetDate = DaysPrevious + Step + D;
                    SetMonth = 11;
                    SetYear = Y - 1;
                }
                else if (Step < 0 && D <= 1)
                {
                    SetDate = DaysPrevious + Step + D;
                    SetMonth = M - 1;
                }
                else if (Step > 0 && D > Days - Step && M === 11)
                {
                    SetDate = Step - (Days - D);
                    SetMonth = 0;
                    SetYear = Y + 1;
                }
                else if (Step > 0 && D > Days - Step)
                {
                    SetDate = Step - (Days - D);
                    SetMonth = M + 1;
                }
                else
                {
                    SetDate = D + Step;
                }

                this.OnSelect(e, [SetDate, SetMonth, SetYear]);
            }
        }
    }

    /**
     * Parse date.
     * 
     * @param mixed date - Input date.
     * 
     * @return string - Output date.
     */

    ParseValue = (date, lower, upper) =>
    {
        const {limitLower, limitUpper} = this.props;
        const LimitLower = lower || limitLower;
        const LimitUpper = upper || limitUpper;
        const D1 = DateParse(date);
        const D2 = LimitLower ? DateParse(LimitLower) : false;
        const D3 = LimitUpper ? DateParse(LimitUpper) : false;
        const T1 = DateToTime(D1);
        const T2 = DateToTime(D2);
        const T3 = DateToTime(D3);

        if (T2 && T1 < T2)
        {
            return D2;
        }

        if (T3 && T1 > T3)
        {
            return D3;
        }

        return D1;
    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () =>
    {
        const Value = this.ParseValue(this.props.value);
        this.setState({value: Value});
    }

    /**
     * Get the field value.
     * 
     * @return string - The field value.
     */

    Value = () =>
    {
        return this.state.value;
    }

    render()
    {
        const {
            alignRight,
            className,
            color,
            disabled,
            disabledDates,
            disableRedLetters,
            disableWeekends,
            error,
            hollow,
            label,
            limitLower,
            limitUpper,
            minimal,
            redLetters
        } = this.props;
        const {
            expand,
            focus,
            value
        } = this.state;
        const CA = ["Field", "DateField"];
        const Color = UcFirst(color);
        const [D, M, Y] = typeof value === "object" ? value : DateParse(value);
        const SelectedDate = new Date(Y, M, D, 0, 0, 0, 0);
        const Day = SelectedDate.getDay();
        const Selected = (
            <div className="DateFieldValue">
                <div className="DateFieldValueDay">{this.Weekdays[Day]}</div>
                <div className="DateFieldValueDate">{D} {this.Months[M]}</div>
                <div className="DateFieldValueYear">{Y}</div>
            </div>
        );

        if (alignRight)
        {
            CA.push("AlignRight");
        }
        else
        {
            CA.push("AlignLeft");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (expand)
        {
            CA.push("Expand");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (minimal)
        {
            CA.push("Minimal");
        }
        if (Selected)
        {
            CA.push("HasValue");
        }
        if (className)
        {
            CA.push(className);
        }

        const Expand = expand ? (
            <div
                className="DateFieldCalendarWrapper"
                onClick={this.OnClick}
            >
                <div className="DateFieldCalendarContainer" onClick={e => e.stopPropagation()}>
                    <Calendar
                        allowFocus={false}
                        disabled={disabled}
                        disabledDates={disabledDates}
                        disableRedLetters={disableRedLetters}
                        disableWeekends={disableWeekends}
                        limitLower={limitLower}
                        limitUpper={limitUpper}
                        onChange={this.OnSelect}
                        onClick={this.OnClick}
                        redLetters={redLetters}
                        ref={calendar => this.Calendar = calendar}
                        selected={value}
                    />
                </div>
            </div>
        ) : "";

        return minimal ? (
            <div
                className={CA.join(" ")}
                onBlur={this.OnBlur}
                onClick={this.OnClick}
                onFocus={this.OnFocus}
                onKeyDown={this.OnKeyDown}
                ref={input => this.Input = input}
                tabIndex="0"
            >
                <div className="MinimalLabel">{label}</div>
                {Expand}
            </div>
        ) : (
            <div
                className={CA.join(" ")}
                onBlur={this.OnBlur}
                onFocus={this.OnFocus}
                onKeyDown={this.OnKeyDown}
                ref={input => this.Input = input}
                tabIndex="0"
            >
                {label ? <label onClick={this.Focus}>{label}</label> : ""}
                <div
                    className={hollow ? "Input Hollow" : `Input Color${Color}`}
                    onClick={this.OnClick}
                >
                    {Selected}
                    <Icon className="FieldIcon" src="ChevronDownSmall"/>
                </div>
                {Expand}
            </div>
        );
    }
}

DateField.defaultProps =
{
    alignRight: false,
    className: "",
    color: "black",
    disabled: false,
    disabledDates: [],
    disableRedLetters: true,
    disableWeekends: false,
    error: false,
    hollow: false,
    icon: "",
    id: "",
    label: "",
    limitLower: "",
    limitUpper: "",
    minimal: false,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    redLetters: [],
    return: "text",
    token: "",
    type: "text",
    value: ""
};

export default DateField;