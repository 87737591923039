import React from "react";
import "./links.scss";

import Links from "Components/UI/Links";
import Segment from "Components/Layout/Segment";

class ModuleLinks extends React.Component
{
    render()
    {
        const {
            backgroundColor,
            hideDesktop,
            hideNarrow,
            index,
            links,
            linksColor,
            linksDouble
        } = this.props;
        const CA = ["Module", "ModuleLinks"];

        if (hideDesktop)
        {
            CA.push("HideDesktop");
        }
        if (hideNarrow)
        {
            CA.push("HideNarrow");
        }
        
        return (
            <Segment backgroundColor={backgroundColor} className={CA.join(" ")} top={!index}>
                <Links color={linksColor} doubleColumn={linksDouble} items={links}/>
            </Segment>
        );
    }
}

ModuleLinks.defaultProps =
{
    backgroundColor: "white",
    hideDesktop: false,
    hideNarrow: false,
    index: 0,
    links: [],
    linksColor: "black",
    linksDouble: false
};

export default ModuleLinks;