
import Globals from "Class/Globals";
import Eatery from "Class/Eatery";
import {RandomToken} from "Functions";

class Booking
{
    constructor()
    {
        this.Key = "eateryBookings";
        this.KeyActive = "eateryBookingsActive";
        this.KeyInfo = "eateryBookingsSavedInfo";
        this.Active = Globals.StorageJson(this.KeyActive, {});
        this.Bookings = {};
        this.Saved = {};

        const Saved = Globals.StorageJson(this.Key, {});

        for (let id in Saved)
        {
            this.Bookings[id] = this.CreateBooking(Saved[id], id);
            this.Saved[id] = Saved[id];
        }
    }

    Clear = (id, type) =>
    {
        const Booking = this.GetBookingById(id);

        if (!Booking)
        {
            return;
        }

        delete this.Saved[id];
        Globals.Storage(this.Key, JSON.stringify(this.Saved));

        if (this.Active[type] === id)
        {
            delete this.Active[type];
            Globals.Storage(this.KeyActive, JSON.stringify(this.Active));
        }
    }

    CreateBooking = (params = {}, id) =>
    {
        const Saved = Globals.StorageJson(this.KeyInfo, {});

        for (let key in Saved)
        {
            if (!params[key])
            {
                params[key] = Saved[key];
            }
        }

        return new BookingRequest(this, params, id);
    }

    GetBookingById = (id) =>
    {
        return this.Bookings[id];
    }

    GetBookingByType = (type, params = false) =>
    {
        const Id = this.Active[type];
        const Active = this.GetBookingById(Id);

        if (Active)
        {
            return Active;
        }
        if (!params)
        {
            return false;
        }

        return this.SetActive(type, this.CreateBooking(params));
    }

    Save = (booking) =>
    {
        const {Id, Params} = booking;
        this.Bookings[Id] = booking;
        this.Saved[Id] = Params;
        Globals.Storage(this.Key, JSON.stringify(this.Saved));

        return booking;
    }

    SaveInfo = (id) =>
    {
        const Booking = this.GetBookingById(id);

        if (!Booking)
        {
            return;
        }
        if (!Booking.GetParameter("save"))
        {
            Globals.StorageRemove(this.KeyInfo);
            return;
        }

        const Keys = ["name", "company", "email", "phone", "reference"];
        const Save = {save: true};
        Keys.forEach(key => Save[key] = Booking.GetParameter(key));
        Globals.Storage(this.KeyInfo, JSON.stringify(Save));
    }

    Send = (id, type, callback) =>
    {
        const Booking = this.GetBookingById(id);

        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        if (!Booking)
        {
            callback(false);
            return;
        }

        Eatery.Request(`booking/${type}`, {data: Booking.Params, id}, callback);
    }

    SetActive = (type, booking) =>
    {
        const {Id} = booking;
        this.Active[type] = Id;
        Globals.Storage(this.KeyActive, JSON.stringify(this.Active));

        return booking;
    }
}

class BookingRequest
{
    constructor(parent, params, id)
    {
        this.Id = id || RandomToken();
        this.Params = params || {};
        this.Parent = parent;
        this.Parent.Save(this);
        Globals.Trigger("booking-parameters", this.Params, this.Id);
    }

    GetParameter = (key, defaultValue = "") =>
    {
        return this.Params[key] || defaultValue;
    }

    SetParameter = (key, value) =>
    {
        this.Params[key] = value;
        this.Parent.Save(this);
        Globals.Trigger("booking-parameter", key, value, this.Id);
        Globals.Trigger("booking-parameters", this.Params, this.Id);
    }
}

export default new Booking();