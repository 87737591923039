import Cision from 'Class/Cision';
import Globals from 'Class/Globals';
import Parser from 'Class/Parser';
import Full from 'Components/Layout/Full';
import LoadImage from 'Components/Layout/LoadImage';
import Segment from 'Components/Layout/Segment';

import Button from 'Components/UI/Button';
import Gallery from 'Components/UI/Gallery';
import ItemPress from 'Components/UI/ItemPress';
import Loading from 'Components/View/Loading';
import { DateStamp } from 'Functions';
import React from 'react';
import './pressrelease.scss';

class TemplatePressRelease extends React.Component {
    constructor(props) {
        super(props);

        this.Mounted = false;

        this.state =
            {
                content: false,
                error: false,
                loading: true,
                related: [],
            };
    }

    componentDidMount() {
        this.Mounted = true;
        this.Load();
        this.LoadRelated();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.Load();
        }
    }

    componentWillUnmount() {
        this.Mounted = false;
    }

    Load = () => {
        const {id} = this.props;

        if (!id) {
            this.setState({error: true});
            return;
        }

        this.setState({
            error: false,
            loading: true,
        });

        Cision.GetRelease(id, response => {
            if (!this.Mounted) {
                return;
            }

            const {Release} = response || {};

            if (!Release ||
                Release.SourceName !==
                Globals.Setting('CisionSource')) {
                this.setState({
                    error: true,
                    loading: false,
                });
            } else {
                this.setState({
                    content: Release,
                    loading: false,
                });
            }
        });
    };

    LoadRelated = () => {
        Cision.GetNews(response => {
            if (!this.Mounted) {
                return;
            }

            const {Releases} = response || {};

            if (!Releases) {
                return;
            }

            this.setState({related: Releases});
        }, false, 5);
    };

    render() {
        const {content, error, loading, related} = this.state;
        const {HtmlBody, HtmlIntro, Id, Images, PublishDate, Title} = content ||
        {};

        if (loading) {
            return (
                <div className="TemplatePressRelease">
                    <Full className="TemplatePressReleaseLoading" top={true}>
                        <Loading />
                    </Full>
                </div>
            );
        } else if (error) {
            return (
                <div className="TemplatePressRelease">
                    <Full className="TemplatePressReleaseError" top={true}>
                        <h2>Det gick inte att ladda pressreleasen</h2>
                        <Button
                            color="Black"
                            label="Prova igen"
                            onClick={this.Load}
                        />
                    </Full>
                </div>
            );
        }

        const GalleryItems = [];
        const RelatedItems = [];

        if (Array.isArray(Images)) {
            Images.forEach(image => {
                const {DownloadUrl, FileName} = image;
                GalleryItems.push(<LoadImage
                    key={FileName}
                    src={DownloadUrl}
                />);
            });
        }

        related.forEach(release => {
            const {Id: RelatedId} = release;

            if (Id === RelatedId || RelatedItems.length > 3) {
                return;
            }

            RelatedItems.push(
                <ItemPress
                    className="TemplatePressRelatedItem"
                    key={RelatedId}
                    release={release}
                />,
            );
        });

        function onShare() {
            navigator.share({
                url: document.location.href,
                title: Title,
            });
        }

        return (
            <div className="TemplatePressRelease">
                <Segment backgroundColor="White" top={true}>
                    <div className="TemplatePressReleaseTop">
                        <h3>Pressrelease</h3>
                        <h4>{DateStamp(new Date(PublishDate))}</h4>
                        <h1>{Title}</h1>
                    </div>
                    <div className="TemplatePressReleaseContent">
                        {Parser.Parse(
                            HtmlIntro,
                            'div',
                            null,
                            {className: 'TemplatePressReleaseIntro'},
                            'intro',
                        )}
                        {Parser.Parse(
                            HtmlBody,
                            'div',
                            null,
                            {className: 'TemplatePressReleaseBody'},
                            'body',
                        )}
                        {navigator.share &&
                            <button
                                className="share Link Button ColorBlack Big"
                                onClick={onShare}
                            >Dela</button>}
                        {GalleryItems.length
                            ? <Gallery>{GalleryItems}</Gallery>
                            : ''}
                    </div>
                </Segment>
                {RelatedItems.length ? (
                    <Segment backgroundColor="Beige">
                        <div className="TemplatePressRelated">
                            <h3>Fler pressreleaser</h3>
                            <div className="TemplatePressRelatedItems">
                                {RelatedItems}
                            </div>
                            <Button
                                color="Black"
                                label="Alla pressreleaser"
                                to="/press"
                            />
                        </div>
                    </Segment>
                ) : ''}
            </div>
        );
    }
}

TemplatePressRelease.defaultProps =
    {
        id: 0,
    };

export default TemplatePressRelease;
