import React from "react";
import "./menugarden.scss";
import Parser from "Class/Parser";
import Button from "Components/UI/Button";
import LoadImage from "Components/Layout/LoadImage";
import MainLogoGarden from "Components/UI/MainLogoGarden";
import Segment from "Components/Layout/Segment";
import ImgBackground from "./background.png";

class TemplateMenuGarden extends React.Component
{
    render()
    {
        const {content, title} = this.props;
        const {content: menuContent, pdf, title: menuTitle} = content || {};
        const CA = ["TemplateMenuGarden"];
        const Title = menuTitle || title;
        if (pdf)
        {
            return (
                <div className="TemplateMenuGarden">
                    <Segment
                        backgroundColor="Beige"
                        top={true}
                    >
                        <h1 className="TemplateMenuGardenTitle">{Title}</h1>
                        <div className="TemplateMenuGardenTray">
                            <Button
                                color="Black"
                                href={pdf}
                                label="Ladda ner som PDF"
                            />
                        </div>
                    </Segment>
                </div>
            );
        }
        return (

            <div className={CA.join(" ")}>
                <Segment backgroundColor="White" className="TemplateMenuGardenPageContainer" top={true}>
                    <div className="TemplateMenuGardenPage">
                        <LoadImage className="TemplateMenuGardenBackground" src={ImgBackground}/>
                        <div className="TemplateMenuGardenPageContentTop">
                            <MainLogoGarden/>
                            <div className="TemplateMenuGardenPageContentTopInfo">
                                <h2>{Title}</h2>
                            </div>
                        </div>
                        <div className="TemplateMenuGardenPageContent">
                            {Parser.Parse(menuContent, "div", null, {className: "TemplateMenuGardenPageContentText"})}
                        </div>
                    </div>
                </Segment>
            </div>
        );
    }
}

TemplateMenuGarden.defaultProps =
{
    content: {}
};

export default TemplateMenuGarden;