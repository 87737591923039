import React from "react";
import "./leftside.scss";

import Globals from "Class/Globals";
import IconButton from "Components/UI/IconButton";
import MainNavigation from "Components/UI/MainNavigation";

class LeftSide extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            show: false
        };
    }

    componentDidMount()
    {
        Globals.Listen("navigation", this.OnClose);
        Globals.Listen("var-side", this.OnToggle);
        this.setState({show: Globals.Var("side")});
    }

    componentWillUnmount()
    {
        Globals.Remove("navigation", this.OnClose);
        Globals.Remove("var-side", this.OnToggle);
    }

    OnClose = () =>
    {
        Globals.Var("side", false);
    }

    OnToggle = (show) =>
    {
        this.setState({show});
    }

    render()
    {
        const {show} = this.state;

        if (!show)
        {
            return "";
        }

        return (
            <div className="LeftSide">
                <div className="LeftSideOverlay"/>
                <div className="LeftSideNavigationContainer">
                    <IconButton
                        className="LeftSideClose"
                        onClick={this.OnClose}
                        src="X"
                    />
                    <MainNavigation vertical={true}/>
                </div>
            </div>
        );
    }
}

export default LeftSide;