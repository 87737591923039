/*!
 *  Calendar view.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the calendar should be disabled.
 *  @prop array disableDates - Array of dates that should be disabled.
 *  @prop boolean disableWeekends - Whether weekend dates should be disabled.
 *  @prop string|date limitLower - Disable all dates before (and including) this date.
 *  @prop string|date limitUpper - Disable all dates after (and including) this date.
 *  @prop function onChange - Callback when a date is clicked.
 *  @prop array redLetters - Array of dates that are red letter days.
 *  @prop string|date selected - Selected date.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./calendar.scss";

import {DateParse, DateStamp, ObjectCompare, ObjectExtend, UcFirst} from "Functions";
import IconButton from "Components/UI/IconButton";

class Calendar extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        this.Months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
        this.Today = DateStamp();

        const Now = new Date();

        this.state = {
            disabled: [],
            limitLower: false,
            limitUpper: false,
            redLetters: [],
            selectedDate: Now.getDate(),
            selectedMonth: Now.getMonth(),
            selectedYear: Now.getFullYear()
        };
    }

    /**
     * Setup calendar on mount.
     * 
     * @return void
     */

    componentDidMount()
    {
        const {disabledDates, limitLower, limitUpper, redLetters, selected} = this.props;
        const State = this.ParseSelected(selected);

        this.DisabledDates = disabledDates;
        this.LimitLower = limitLower;
        this.LimitUpper = limitUpper;
        this.RedLetters = redLetters;
        this.Selected = selected;

        State.disabled = DateParse(disabledDates, true);
        State.redLetters = DateParse(redLetters, true);
        State.limitLower = limitLower ? DateParse(limitLower) : false;
        State.limitUpper = limitUpper ? DateParse(limitUpper) : false;

        this.setState(State);
    }

    /**
     * Reset the calendar when new props are received.
     * 
     * @return void
     */

    componentDidUpdate()
    {
        const {disabledDates, limitLower, limitUpper, redLetters, selected} = this.props;
        const State = {};
        let Update = false;

        if (selected !== this.Selected)
        {
            Update = true;
            this.Selected = selected;
            const Selected = this.ParseSelected(selected);
            ObjectExtend(State, Selected);
        }
        if (!ObjectCompare(disabledDates, this.DisabledDates))
        {
            Update = true;
            this.DisabledDates = disabledDates;
            State.disabled = DateParse(disabledDates, true);
        }
        if (!ObjectCompare(redLetters, this.RedLetters))
        {
            Update = true;
            this.RedLetters = redLetters;
            State.redLetters = DateParse(redLetters, true);
        }
        if (limitLower !== this.LimitLower)
        {
            Update = true;
            this.LimitLower = limitLower;
            State.limitLower = limitLower ? DateParse(limitLower) : false;
        }
        if (limitUpper !== this.LimitUpper)
        {
            Update = true;
            this.LimitUpper = limitUpper;
            State.limitUpper = limitUpper ? DateParse(limitUpper) : false;
        }

        if (Update)
        {
            this.setState(State);
        }
    }

    Block = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Output a date in the calendar.
     * 
     * @param integer date - The date (1-31).
     * @param integer month - The month (0-11).
     * @param integer year - The year.
     * @param integer day - The weekday (0-6).
     * 
     * @return JSX - The date item.
     */

    Item = (date, month, year, day) =>
    {
        const {
            selectedDate,
            selectedMonth,
            selectedYear,
            redLetters
        } = this.state;

        const CA = ["CalendarDate"];
        const Formatted = DateStamp([date, month, year], true);
        const Disabled = this.ItemDisabled(day, Formatted);

        if (Disabled)
        {
            CA.push("Disabled");
        }
        if (selectedMonth !== month)
        {
            CA.push("Outside");
        }
        if (redLetters.indexOf(Formatted) >= 0)
        {
            CA.push("RedLetter");
        }
        if (selectedDate === date && selectedMonth === month && selectedYear === year)
        {
            CA.push("Selected");
        }
        if (Formatted === this.Today)
        {
            CA.push("Today");
        }

        return (
            <div
                className={CA.join(" ")}
                key={`${year}-${month}-${date}`}
                onClick={Disabled ? null : e => this.OnDate(e, date, month, year)}
                title={Formatted}
            >
                <span>{date}</span>
            </div>
        );
    }

    /**
     * Check if a date should be disabled.
     * 
     * @param integer day - The weekday (0-6).
     * @param string formatted - The formatted date (YYYY-MM-DD).
     * 
     * @return boolean - Whether the date should be disabled.
     */

    ItemDisabled = (day, formatted) =>
    {
        const {disabled, limitLower, limitUpper, redLetters} = this.state;
        const {disableRedLetters, disableWeekends} = this.props;

        // Disable weekend?
        if (disableWeekends && (!day || day > 5))
        {
            return true;
        }
        // Has this date been specifically disabled?
        if (disabled.indexOf(formatted) >= 0)
        {
            return true;
        }
        // Disable red letter days?
        if (disableRedLetters && redLetters.indexOf(formatted) >= 0)
        {
            return true;
        }
        // Check lower limit.
        if (limitLower && formatted < DateStamp(limitLower, true))
        {
            return true;
        }
        // Check upper limit.
        if (limitUpper && formatted > DateStamp(limitUpper, true))
        {
            return true;
        }

        return false;
    }

    /**
     * Change the selected date.
     * 
     * @param integer date - The date (1-31).
     * @param integer month - The month (0-11).
     * @param integer year - The year.
     * 
     * @return object - The seleted date as JS date object.
     */

    OnChange = (date, month, year) =>
    {
        const {onChange} = this.props;
        const {selectedDate, selectedMonth, selectedYear} = this.state;
        const D = new Date(
            year === undefined ? selectedYear : year,
            month === undefined ? selectedMonth : month,
            date === undefined ? selectedDate : date,
            0, 0, 0, 0);

        onChange(null, D);

        return D;
    }

    /**
     * Callback when a date is clicked in the calendar.
     * 
     * @param object e - The click event.
     * @param integer selectedDate - The selected date (1-31).
     * @param integer selectedMonth - The selected month (0-11).
     * @param integer selectedYear - The selected year.
     * 
     * @return void
     */

    OnDate = (e, selectedDate, selectedMonth, selectedYear) =>
    {
        const {disabled, onClick} = this.props;

        if (disabled)
        {
            return;
        }

        this.setState({
            selectedDate,
            selectedMonth,
            selectedYear
        });

        const D = this.OnChange(selectedDate, selectedMonth, selectedYear);
        onClick(null, D);
    }

    /**
     * Callback when a key is pressed.
     * 
     * @return void
     */

    OnKeyDown = (e) =>
    {
        const {disabled} = this.props;
        let Step;

        if (disabled)
        {
            return;
        }

        switch (e.which)
        {
            case 37:
                Step = -1;
                break;

            case 38:
                Step = -7;
                break;

            case 39:
                Step = 1;
                break;

            case 40:
                Step = 7;
                break;

            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
        const {selectedDate, selectedMonth, selectedYear} = this.state;
        const AbsStep = Math.abs(Step);
        const Days = (selectedMonth === 1 && selectedYear % 4 === 0) ? 29 : this.Days[selectedMonth];
        const DaysPrevious = (selectedMonth === 2 && selectedYear % 4 === 0) ? 29 : this.Days[selectedMonth ? selectedMonth - 1 : 11];
        let SetDate;
        let SetMonth = selectedMonth;
        let SetYear = selectedYear;
        
        if (Step < 0 && selectedDate <= AbsStep && !selectedMonth)
        {
            SetDate = DaysPrevious + Step + selectedDate;
            SetMonth = 11;
            SetYear = selectedYear - 1;
        }
        else if (Step < 0 && selectedDate <= AbsStep)
        {
            SetDate = DaysPrevious + Step + selectedDate;
            SetMonth = selectedMonth - 1;
        }
        else if (Step > 0 && selectedDate > Days - Step && selectedMonth === 11)
        {
            SetDate = Step - (Days - selectedDate);
            SetMonth = 0;
            SetYear = selectedYear + 1;
        }
        else if (Step > 0 && selectedDate > Days - Step)
        {
            SetDate = Step - (Days - selectedDate);
            SetMonth = selectedMonth + 1;
        }
        else
        {
            SetDate = selectedDate + Step;
        }

        this.setState({
            selectedDate: SetDate,
            selectedMonth: SetMonth,
            selectedYear: SetYear
        });

        this.OnChange(SetDate, SetMonth, SetYear);
    }

    /**
     * Callback when the forward button is clicked.
     * 
     * @return void
     */

    OnNext = () =>
    {
        const {disabled} = this.props;

        if (disabled)
        {
            return;
        }

        const {selectedMonth, selectedYear} = this.state;
        let Month = selectedMonth + 1;
        let Year = selectedYear;
        let Day = 0;
        let D, F;

        if (Month > 11)
        {
            Month = 0;
            Year += 1;
        }

        do
        {
            Day++;
            D = new Date(Year, Month, Day);
            F = DateStamp([Day, Month, Year], true);
        } while (Day < this.Days[Month] && this.ItemDisabled(D.getDay(), F));

        this.setState({
            selectedDate: Day,
            selectedMonth: Month,
            selectedYear: Year
        });

        this.OnChange(Day, Month, Year);
    }

    /**
     * Callback when the back button is clicked.
     * 
     * @return void
     */

    OnPrevious = () =>
    {
        const {disabled} = this.props;

        if (disabled)
        {
            return;
        }

        const {selectedMonth, selectedYear} = this.state;
        let Month = selectedMonth - 1;
        let Year = selectedYear;
        let Day = 0;
        let D, F;

        if (Month < 0)
        {
            Month = 11;
            Year -= 1;
        }

        do
        {
            Day++;
            D = new Date(Year, Month, Day);
            F = DateStamp([Day, Month, Year], true);

        } while (Day < this.Days[Month] && this.ItemDisabled(D.getDay(), F));

        this.setState({
            selectedDate: Day,
            selectedMonth: Month,
            selectedYear: Year
        });

        this.OnChange(Day, Month, Year);
    }

    /**
     * Format a raw date.
     * 
     * @param mixed date - The unparsed date.
     * 
     * @return object - The formatted date.
     */

    ParseSelected = (date) =>
    {
        const [selectedDate, selectedMonth, selectedYear] = DateParse(date);

        return {
            selectedDate,
            selectedMonth,
            selectedYear
        };

    }

    render()
    {
        const {allowFocus, className, color, disabled} = this.props;
        const {limitLower, limitUpper, selectedMonth, selectedYear} = this.state;
        const Color = UcFirst(color);
        const CA = ["Calendar", `Color${Color}`];
        
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (className)
        {
            CA.push(className);
        }

        const Month = this.Months[selectedMonth];
        const Days = Array.from(this.Days);
        const First = new Date(selectedYear, selectedMonth, 1, 0, 0, 0, 0);
        const PreviousMonth = selectedMonth ? selectedMonth - 1 : 11;
        const PreviousYear = PreviousMonth > selectedMonth ? selectedYear - 1 : selectedYear;
        const NextMonth = (selectedMonth + 1) % 12;
        const NextYear = NextMonth < selectedMonth ? selectedYear + 1 : selectedYear;
        let FirstDay = First.getDay() - 1;

        if (FirstDay < 0)
        {
            FirstDay = 6;
        }

        if (selectedYear % 4 === 0)
        {
            Days[1] = 29;
        }

        const Dates = [];
        let Total = 0;

        for (let i = 0; i < FirstDay; i++)
        {
            let D = Days[PreviousMonth] - FirstDay + i + 1;
            Dates.push(this.Item(D, PreviousMonth, PreviousYear, (Total + 1) % 7));
            Total++;
        }

        for (let i = 0; i < Days[selectedMonth]; i++)
        {
            let D = i + 1;
            Dates.push(this.Item(D, selectedMonth, selectedYear, (Total + 1) % 7));
            Total++;
        }

        for (let i = Total; i < 42; i++)
        {
            let D = i - Total + 1;
            Dates.push(this.Item(D, NextMonth, NextYear, (i + 1) % 7));
        }

        return (
            <div
                className={CA.join(" ")}
                onClick={this.Block}
                onKeyDown={this.OnKeyDown}
                onMouseDown={this.Block}
                onMouseUp={this.Block}
                onScroll={this.Block}
                tabIndex={allowFocus ? "0" : "false"}
            >
                <div className="CalendarHeading">
                    <IconButton
                        allowFocus={allowFocus}
                        className="CalendarPrevious"
                        disabled={disabled || (limitLower && selectedMonth <= limitLower[1] && selectedYear <= limitLower[2]) || (limitLower && selectedYear < limitLower[2])}
                        src="ChevronLeftSmall"
                        onClick={this.OnPrevious}
                    />
                    <IconButton
                        allowFocus={allowFocus}
                        className="CalendarNext"
                        disabled={disabled || (limitUpper && selectedMonth >= limitUpper[1] && selectedYear >= limitUpper[2]) || (limitUpper && selectedYear > limitUpper[2])}
                        src="ChevronRightSmall"
                        onClick={this.OnNext}
                    />
                    <div className="CalendarMonth">{Month} {selectedYear}</div>
                </div>
                <div className="CalendarDays">
                    <div className="CalendarDay">Mån</div>
                    <div className="CalendarDay">Tis</div>
                    <div className="CalendarDay">Ons</div>
                    <div className="CalendarDay">Tor</div>
                    <div className="CalendarDay">Fre</div>
                    <div className="CalendarDay">Lör</div>
                    <div className="CalendarDay">Sön</div>
                </div>
                <div className="CalendarDates">{Dates}</div>
            </div>
        );
    }
}

Calendar.defaultProps =
{
    allowFocus: true,
    className: "",
    color: "black",
    disabled: false,
    disabledDates: [],
    disableRedLetters: true,
    disableWeekends: false,
    limitLower: "",
    limitUpper: "",
    onChange: () => {},
    onClick: () => {},
    redLetters: [],
    selected: ""
};

export default Calendar;