import React from "react";
import "./propertyowners.scss";

import Parser from "Class/Parser";

import Icon from "Components/Layout/Icon";
import ItemStaff from "Components/UI/ItemStaff";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplatePropertyOwners extends React.Component
{
    Consultant = () =>
    {
        const {content} = this.props;
        const {aboutConsultant, consultant} = content || {};

        return (
            <div className="TemplatePropertyOwnersConsultant ColorBeige">
                {Parser.Parse(aboutConsultant, "div", null, {className: "TemplatePropertyOwnersConsultantAbout"})}
                <ItemStaff id={consultant}/>
            </div>
        );
    }

    render()
    {
        const {content, title} = this.props;
        const {
            company,
            contents,
            iconList,
            listTitle,
            text
        } = content || {};
        const ListItems = [];

        if (Array.isArray(iconList))
        {
            iconList.forEach((item, index) =>
            {
                const {icon, text} = item;
                ListItems.push(
                    <div className="TemplatePropertyOwnersListItem" key={index}>
                        <Icon src={icon}/>
                        <span>{text}</span>
                    </div>
                );
            });
        }

        return (
            <div className="TemplatePropertyOwners">
                <Segment top={true}>
                    <ThreeColumns right={this.Consultant()}>
                        <h5 className="TemplatePropertyOwnersTitle">{title}</h5>
                        {Parser.Parse(text, "div", null, {className: "TemplatePropertyOwnersAbout"})}
                        {ListItems.length ? (
                            <div className="TemplatePropertyOwnersListWrapper ColorBeige">
                                {listTitle ? <h3>{listTitle}</h3> : ""}
                                <div className="TemplatePropertyOwnersListItems">
                                    {ListItems}
                                </div>
                            </div>
                        ) : ""}
                        {Parser.Parse(company, "div", null, {className: "TemplatePropertyOwnersAbout"})}
                    </ThreeColumns>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
            </div>
        );
    }
}

TemplatePropertyOwners.defaultProps =
{
    content: {}
};

export default TemplatePropertyOwners;