import React from 'react';
import Eatery from '../../../../Class/Eatery';
import { DateStamp } from '../../../../Functions';
import Segment from '../../../Layout/Segment';
import './news.scss';
import Button from '../../../UI/Button';

class TemplateNews extends React.Component {
    constructor(props) {
        super(props);

        this.Mounted = false;

        this.state = {
            page: 1,
            all: false,
            error: false,
            releases: [],
            loading: true,
        };
    }

    render() {
        const {title} = this.props;

        const news = Eatery.News();

        return (
            <div className="TemplateNews">
                <Segment backgroundColor="Beige" top={true}>
                    <div className="TemplatePressTop">
                        <h1>{title}</h1>
                    </div>
                    <div className="TemplateNewsItemsWrapper">
                        <div className="TemplateNewsItems">
                            {news.map(item => (
                                <div
                                    className="ItemRoom ItemPress TemplateNewsItem"
                                    key={item.id}
                                >
                                    <div className="ItemRoomWrapper">
                                        <div className="ItemRoomImage">
                                            <img
                                                src={item.content.thumbnail}
                                                alt={item.title}
                                            />
                                        </div>
                                        <div className="ItemRoomContent">
                                            <div className="ItemPressContent">
                                                <h3>{item.title}</h3>
                                                <h4>{DateStamp(new Date(item.date))}</h4>
                                                <p>{item.content.excerpt}</p>
                                                <div className="ItemRoomButtons">
                                                    <Button
                                                        color="Black"
                                                        small={true}
                                                        title={item.title}
                                                        to={item.uri}
                                                    >
                                                        Läs mer
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Segment>
            </div>
        );
    }
}

export default TemplateNews;
