import Eatery from 'Class/Eatery';
import Globals from 'Class/Globals';
import Parser from 'Class/Parser';
import LoadImage from 'Components/Layout/LoadImage';
import Segment from 'Components/Layout/Segment';

import Link from 'Components/UI/Link';
import React from 'react';
import './news.scss';

class ModuleNews extends React.Component {
    constructor(props) {
        super(props);
        this.Container = false;
        this.Items = [];
        this.Mounted = false;
        this.ResizeTimeout = false;
    }

    componentDidMount() {
        this.Mounted = true;
        this.SetPositions();
        window.addEventListener('resize', this.OnResize);
    }

    componentDidUpdate() {
        this.SetPositions();
    }

    componentWillUnmount() {
        this.Mounted = false;
        window.removeEventListener('resize', this.OnResize);
    }

    Item = (post, index) => {
        const {content, id, title, uri} = post;
        const {
            altFont,
            altTitle,
            preTitle,
            height,
            hideTitle,
            image,
            imagePositionH,
            imagePositionV,
            logo,
            textColor,
        } = content || {};
        const Height = parseInt(height, 10) || 1;
        const CA = ['ModuleNewsItem', `Height${Height}`];
        const Content = [];
        if (altFont) {
            CA.push('AltFont');
        }
        if (image) {
            CA.push('HasBackground');
            CA.push(textColor === 'black'
                ? 'ColorTextBlack'
                : 'ColorTextWhite');
            Content.push(
                <LoadImage
                    className="ModuleNewsItemBackgroundImage"
                    key="image"
                    src={image}
                    style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}
                />,
            );
        }
        if (preTitle && Height > 1) {
            Content.push(<h5 key="pretitle">{preTitle}</h5>);
        }
        if (logo) {
            Content.push(
                <LoadImage
                    className="ModuleNewsItemContentLogo"
                    key="logo"
                    src={logo}
                />,
            );
        } else if (!hideTitle) {
            Content.push(Parser.Parse(
                altTitle || title,
                'h3',
                null,
                {key: 'title'},
            ));
        }
        return (
            <Link
                className={CA.join(' ')}
                id={id}
                key={id}
                //onClick={this.OnClick}
                ref={item => this.Items[index] = item}
                to={uri}
            >
                <div className="ModuleNewsItemWrapper">
                    <div className="ModuleNewsItemContent">
                        {Content}
                        <div className="ModuleNewsItemButton ColorBlack">Läs
                            mer
                        </div>
                    </div>
                </div>
            </Link>
        );
    };

    OnClick = (e, id) => {
        Globals.DialogPost(id);
    };

    OnResize = () => {
        clearTimeout(this.ResizeTimeout);
        this.ResizeTimeout = setTimeout(() => {
            if (!this.Mounted) {
                return;
            }

            this.SetPositions();
        }, 100);
    };

    SetPositions = () => {
        const First = this.Items[0];
        if (!First || !First.Node || !this.Container) {
            return;
        }
        const Columns = Math.round(this.Container.offsetWidth /
            First.Node.offsetWidth) || 1;
        let Height = 0;
        this.Items.forEach((item, index) => {
            let Offset = 0;
            let Index = index - Columns;
            let Prev = item;
            while (this.Items[Index]) {
                Offset -=
                    Prev.Node.offsetTop -
                    this.Items[Index].Node.offsetTop -
                    this.Items[Index].Node.offsetHeight;
                Prev = this.Items[Index];
                Index -= Columns;
            }
            item.Node.children[0].style.transform = `translateY(${Offset}px)`;
            Height =
                Math.max(
                    Height,
                    item.Node.offsetTop + item.Node.offsetHeight + Offset,
                );
        });
        this.Container.style.height = Height + 'px';
    };

    render() {
        const {backgroundColor, eatery, index, postsLimit} = this.props;
        const Posts = Eatery.Posts(eatery, postsLimit);
        if (!Posts.length) {
            return '';
        }
        const Items = [];
        Posts.forEach((post, index) => Items.push(this.Item(post, index)));
        return (
            <Segment
                backgroundColor={backgroundColor}
                className="Module ModuleNews"
                top={!index}
            >
                <div
                    className="ModuleNewsItems"
                    ref={container => this.Container = container}
                >
                    {Items}
                </div>
            </Segment>
        );
    }
}

ModuleNews.defaultProps =
    {
        backgroundColor: 'white',
        eatery: '',
        index: 0,
        postsLimit: 8,
    };

export default ModuleNews;
