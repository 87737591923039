import React from "react";
import "./full.scss";

import Globals from "Class/Globals";
import {UcFirst} from "Functions";
import LoadImage from "Components/Layout/LoadImage";
import LoadVideo from "Components/Layout/LoadVideo";

class Full extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            paddingTop: 0
        };
    }

    componentDidMount()
    {
        if (this.props.top)
        {
            this.AddPadding();
        }
    }

    componentDidUpdate(prevProps)
    {
        const {top: t1} = this.props;
        const {top: t2} = prevProps;
        if (t1 && !t2)
        {
            this.AddPadding();
        }
        else if (!t1 && t2)
        {
            this.RemovePadding();
        }
    }

    componentWillUnmount()
    {
        if (this.props.top)
        {
            this.RemovePadding();
        }
    }

    AddPadding()
    {
        Globals.Listen("announcement", this.OnPadding);
        window.addEventListener("resize", this.OnPadding);
        this.OnPadding();
    }

    OnPadding = () =>
    {
        clearTimeout(this.PaddingTimeout);
        this.PaddingTimeout = setTimeout(() =>
        {
            const Header = document.getElementById("header");
            if (!Header)
            {
                return;
            }
            this.setState({paddingTop: Header.offsetHeight});
        }, 10);
    }

    RemovePadding()
    {
        clearTimeout(this.PaddingTimeout);
        Globals.Remove("announcement", this.OnPadding);
        window.removeEventListener("resize", this.OnPadding);
        this.setState({paddingTop: 0});
    }

    render()
    {
        const {
            backgroundColor,
            backgroundImage,
            backgroundPositionH,
            backgroundPositionV,
            backgroundVideo,
            children,
            className,
            textColor,
            top
        } = this.props;
        const {paddingTop} = this.state;
        const CA = ["Full"];
        const Style = {};
        let Background;

        if (top)
        {
            CA.push("Top");
            Style.minHeight = `calc(75vh - ${paddingTop}px)`;
            Style.paddingTop = paddingTop;
        }

        if (backgroundImage || backgroundVideo)
        {
            CA.push("HasBackground");
            CA.push(textColor === "black" ? "ColorTextBlack" : "ColorTextWhite");
        }
        else
        {
            CA.push("Color" + UcFirst(backgroundColor));
        }

        if (className)
        {
            CA.push(className);
        }

        if (backgroundVideo)
        {
            Background = (
                <LoadVideo
                    autoplay={true}
                    className="SegmentBackgroundVideo"
                    loop={true}
                    poster={backgroundImage}
                    src={backgroundVideo}
                />
            );
        }
        else if (backgroundImage)
        {
            Background = (
                <LoadImage
                    className="SegmentBackgroundImage"
                    src={backgroundImage}
                    style={{backgroundPosition: `${backgroundPositionH}% ${backgroundPositionV}%`}}
                />
            );
        }

        return (
            <div className={CA.join(" ")}>
                {Background}
                <div className="FullTable" style={Style}>
                    <div className="FullCell">
                        <div className="FullContent">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Full.defaultProps =
{
    backgroundColor: "white",
    backgroundImage: "",
    backgroundPositionH: 50,
    backgroundPositionV: 50,
    backgroundVideo: "",
    className: "",
    textColor: "white",
    top: false
};

export default Full;