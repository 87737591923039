import React from "react";
import "./meetingroom.scss";
import Booking from "Class/Booking";
import Eatery from "Class/Eatery";
import Globals from "Class/Globals";
import Parser from "Class/Parser";
import BookingStart from "Components/View/BookingStart";
import Button from "Components/UI/Button";
import Gallery from "Components/UI/Gallery";
import Icon from "Components/Layout/Icon";
import ItemRoom from "Components/UI/ItemRoom";
import ItemStaff from "Components/UI/ItemStaff";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import LoadVideo from "Components/Layout/LoadVideo";
import Map from "Components/UI/Map";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import Tag from "Components/UI/Tag";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplateMeetingRoom extends React.Component
{
    ColumnLeft = () =>
    {
        const {content} = this.props;
        const {eatery} = content || {};
        const Location = Eatery.Eatery(eatery);
        if (!Location)
        {
            return "";
        }
        const {address, location} = Location.content;
        const {lat, lng} = location || {}
        const Address = address.split("\n");
        const AddressItems = [];
        Address.forEach((row, index) =>
        {
            if (!location || index)
            {
                AddressItems.push(<div key={index}>{row}</div>);
            }
            else
            {
                AddressItems.push(<Link className="EateryNavigationContactLink" key={index} href={`https://www.google.com/maps/place/?q=place_id:${location.id}`}>{row}</Link>);
            }
        });
        return (
            <div className="TemplateMeetingRoomLeft">
                {(lat && lng) ? (
                    <div className="TemplateMeetingRoomMap">
                        <Map address={address} lat={lat} lng={lng}/>
                    </div>
                ) : ""}
                <div className="TemplateMeetingRoomAddress">
                    {AddressItems}
                </div>
            </div>
        );
    }

    ColumnRight = () =>
    {
        const {id} = this.props;
        return (
            <div className="TemplateMeetingRoomRight">
                <BookingStart room={id} type="konferens-fest"/>
            </div>
        );
    }

    ItemFeature = (key, label) =>
    {
        return (
            <div className="TemplateMeetingRoomFeature" key={key}>
                <Icon src={key}/>
                <div className="TemplateMeetingRoomFeatureLabel">
                    {label}
                </div>
            </div>
        );
    }

    OnPriceList = (id) =>
    {
        Globals.DialogPriceList(id);
    }

    OnRelated = (e, id) =>
    {
        const Active = Booking.GetBookingByType("konferens-fest");
        const Room = Eatery.MeetingRoom(id);
        const Location = Room ? Room.content.eatery : "";
        if (Active)
        {
            Active.SetParameter("eatery", Location);
            Active.SetParameter("meetingRoom", [Room.uri]);
        }
        else
        {
            Globals.Var("booking-konferens-fest",
            {
                meetingRoom: [Room.uri],
                eatery: Location
            });
        }
        Globals.Trigger("redirect", "/bokning/konferens-fest");
    }

    render()
    {
        const {content, id, tags, title} = this.props;
        const {
            about,
            contents,
            eatery,
            features,
            gallery,
            peopleMin,
            peopleMax
        } = content || {};
        const {content: locationContent, title: locationName, uri: locationUri} = Eatery.Eatery(eatery) || {};
        const {eventAbout, eventCloses, eventOpens, eventPriceList, eventPriceListNoVat, external, menues} = locationContent || {};
        const Staff = Eatery.Option("conferenceStaff", []);
        const MeetingRooms = Eatery.MeetingRooms();
        const Menues = Eatery.Menues(locationUri);
        const Features = [];
        const GalleryItems = [];
        const PriceList = eventPriceListNoVat || eventPriceList;
        const StaffItems = [];
        const Tags = [];
        const RelatedItems = [];
        const Header = (
            <div className="TemplateMeetingRoomHeader">
                <h3>Konferens & fest</h3>
                <h1>{title}</h1>
                <Link
                    className="TemplateMeetingRoomLocationLink"
                    href={external}
                    to={!external ? `/anlaggningar${locationUri}` : ""}
                >{locationName}</Link>
                <div className="TemplateMeetingRoomTags">{Tags}</div>
            </div>
        );
        if (features)
        {
            for (let key in features)
            {
                Features.push(this.ItemFeature(key, features[key]));
            };
        }
        if (gallery)
        {
            const Autoplay = gallery.length < 2;

            gallery.forEach((media, index) =>
            {
                const {image, imagePositionH, imagePositionV, video} = media;
                if (video)
                {
                    GalleryItems.push(<LoadVideo autoplay={Autoplay} key={index} loop={Autoplay} src={video}/>);
                }
                else
                {
                    GalleryItems.push(<LoadImage key={index} src={image} style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}/>);
                }
            });
        }
        const MenuButtons = [];
        if (menues)
        {
            menues.forEach(({menu, label}) =>
            {
                if (Menues[menu] === undefined)
                {
                    console.error(menu, Menues);
                    return;
                }  
                MenuButtons.push(
                    <Button
                        color="Beige"
                        key={menu}
                        label={label || Menues[menu].title}
                        to={`${locationUri}/${menu}`}
                    />
                );
            });
        }
        tags.forEach((label, index) =>
        {
            Tags.push(<Tag key={index} label={label}/>);
        });
        let OpeningHours = "Kontakta oss";
        if (eventOpens && eventCloses)
        {
            OpeningHours = `Kl ${eventOpens}–${eventCloses}`; 
        }
        else if (eventOpens)
        {
            OpeningHours = `Öppnar ${eventOpens}`;
        }
        else if (eventCloses)
        {
            OpeningHours = `Stänger ${eventCloses}`;
        }
        if (Staff)
        {
            Staff.forEach(id =>
            {
                StaffItems.push(<ItemStaff id={id} key={id}/>);
            });
        }
        for (let rId in MeetingRooms)
        {
            const {
                content: rContent,
                tags: rTags,
                title: rTitle,
                uri
            } = MeetingRooms[rId];
            const {
                eatery: rEatery,
                image,
                imagePositionH,
                imagePositionV,
                peopleMin: rPeopleMin,
                peopleMax: rPeopleMax
            } = rContent;
            const {title: rLocationName} = Eatery.Eatery(rEatery) || {};
            if (rId === id || eatery !== rEatery)
            {
                continue;
            }
            RelatedItems.push(
                <ItemRoom
                    buttons={[
                        {hollow: true, label: "Läs mer", to: uri},
                        {color: "Black", id: rId, label: "Bokningsförfrågan", onClick: this.OnRelated}
                    ]}
                    className="TemplateConferenceItem"
                    image={image}
                    imagePositionH={imagePositionH}
                    imagePositionV={imagePositionV}
                    key={rId}
                    subTitle={rLocationName}
                    tags={rTags}
                    title={rTitle}
                    top={[
                        <Icon key="icon" src="People"/>,
                        peopleMin ? `${rPeopleMin} – ${rPeopleMax}` : `Max ${rPeopleMax}`
                    ]}
                />
            );

            if (RelatedItems.length > 3)
            {
                break;
            }
        }

        return (
            <div className="TemplateMeetingRoom">
                <Segment top={true}>
                    <div className="TemplateMeetingRoomHeaderNarrow">
                        {Header}
                    </div>
                    <ThreeColumns
                        left={this.ColumnLeft()}
                        reverseNarrow={true}
                        right={this.ColumnRight()}
                    >
                        <div className="TemplateMeetingRoomContent">
                            <div className="TemplateMeetingRoomHeaderDesktop">
                                {Header}
                            </div>
                            {Parser.Parse(about, "div", null, {className: "TemplateMeetingRoomAbout"})}
                            <div className="TemplateMeetingRoomFeatures ColorBeige">
                                {this.ItemFeature("people", peopleMin ? `${peopleMin} – ${peopleMax} personer` : `Max ${peopleMax} personer`)}
                                {this.ItemFeature("clock", OpeningHours)}
                                {Features}
                            </div>
                        </div>
                        <Gallery className="TemplateMeetingRoomGallery">{GalleryItems}</Gallery>
                        {PriceList ? <Button color="beige" label="Prislista" onClick={e => this.OnPriceList(PriceList)}/> : ""}
                        {MenuButtons}
                    </ThreeColumns>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
                {locationName ? <Segment backgroundColor="Beige" className="TemplateMeetingRoomLocation">
                    <h3>{`Om ${locationName}`}</h3>
                    {Parser.Parse(eventAbout, "div", null, {className: "TemplateMeetingRoomAboutLocation"})}
                    <Button
                        hollow={true}
                        href={external}
                        label="Läs mer"
                        to={!external ? `/anlaggningar${locationUri}` : ""}
                    />
                </Segment> : ""}
                {StaffItems.length ? (
                    <Segment>
                        <div className="TemplateMeetingRoomStaffItemsWrapper">
                            <div className="TemplateMeetingRoomStaffItems">
                                {StaffItems}
                            </div>
                        </div>
                    </Segment>
                ) : ""}
                {RelatedItems.length ? (
                    <Segment backgroundColor="Beige">
                        <div className="TemplateMeetingRoomRelatedItemsWrapper">
                            <h2>Fler spännande lokaler</h2>
                            <div className="TemplateMeetingRoomRelatedItems">
                                {RelatedItems}
                            </div>
                            <Gallery
                                className="TemplateMeetingRoomRelatedItemsGallery"
                                showArrows={false}
                                switchInterval={0}
                            >
                                {RelatedItems}
                            </Gallery>
                        </div>
                    </Segment>
                ) : ""}
            </div>
        );
    }
}

TemplateMeetingRoom.defaultProps =
{
    content: {},
    id: 0,
    title: "",
    uri: ""
};

export default TemplateMeetingRoom;