
/*!
 *  Useful functions.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

/**
 * Clone an array or object. Called recursively.
 * 
 * @param array|object arr - The array or object to be cloned.
 * @param integer level - The current level of recursion.
 * @param integer maxLevel - The maximum level of recursion. To avoid leaks.
 * 
 * @return array|object - The clone.
 */

export const ArrayClone = (arr, level = 0, maxLevel = 10) =>
{
    if (!arr || level >= maxLevel)
    {
        return arr;
    }

    const IsArray = typeof arr === "object" && typeof arr.map === "function";
    const IsObject = !IsArray && typeof arr === "object";

    if (!IsArray && !IsObject)
    {
        return arr;
    }

    const Clone = IsArray ? [] : {};

    for (var key in arr)
    {
        Clone[key] = ArrayClone(arr[ key ], level + 1, maxLevel);
    }

    return Clone;
}

/**
 * Move an array item from one index to another.
 * 
 * @param array arr - The array.
 * @param integer from - The current index.
 * @param integer to - The new index.
 * @param boolean noClone - Whether to manipulate the array directly.
 * 
 * @return array - The new array.
 */

export const ArrayMove = (arr, from, to, noClone) =>
{
    const Arr = noClone ? arr : ArrayClone(arr);
    const Item = Arr.splice(from, 1)[0];
    const To = to > from ? to - 1 : to;

    Arr.splice(Math.min(Arr.length, To), 0, Item);

    return Arr;
}

/*
 * Get the language of the client browser.
 * 
 * @return string|boolean - Locale on success. Boolean false when failed.
 */

export const BrowserLanguage = () =>
{
    const Lang = window.navigator.userLanguage || window.navigator.language;
    const Match = typeof Lang === "string" ? Lang.match(/^([a-z]{2}).([A-Z]{2})$/) : false;

    if (!Match || !Match.shift())
    {
        return false;
    }

    const [L1, L2] = Match;
    const Locale = `${L1}_${L2}`;

    if ( Locale.match( /^ar/ ) ) return "ar_AR";
    if ( Locale.match( /^de/ ) ) return "de_DE";
    if ( Locale.match( /^en/ ) ) return "en_US";
    if ( Locale.match( /^es/ ) ) return "es_ES";
    if ( Locale.match( /^fr/ ) ) return "fr_FR";
    if ( Locale.match( /^it/ ) ) return "it_IT";
    
    return Locale;
}

/**
 * Cap a number between two values.
 * 
 * @param number number - The number.
 * @param number min - The minimum. Default to '0'.
 * @param number maximum - The minimum. Default to '1'.
 * 
 * @return number - The capped number.
 */

export const CapFloat = (number, min = 0, max = 1) =>
{
    if (typeof number !== "number" || number < min)
    {
        return min;
    }

    if (number > max)
    {
        return max;
    }

    return number;
}

/**
 * Capitalize a string.
 * 
 * @param string str - The string.
 * @param boolean firstWord - Whether to only capitalize the first word.
 * 
 * @return string - The parsed string.
 */

export const Capitalize = (str, firstWord = false) =>
{
    if (typeof str !== "string")
    {
        return str;
    }

    const RegExp = firstWord ? /(^)(.)/ : /(^|[\s -])(.)/g;

    return str.replace(RegExp, (match, before, char) =>
    {
        return before + char.toUpperCase();
    });
}

export const DaysBetweenDates = (date1, date2, list = false, format = 0) =>
{
    const Date1 = Time(DateParse(date1, true));
    const Date2 = Math.max(Date1, Time(DateParse(date2, true)));
    const Count = (Date2 - Date1) / 86400000 + 1;

    if (!list)
    {
        return Count;
    }

    const List = [];

    for (let t = Date1; t <= Date2; t += 86400000)
    {
        List.push(DateParse(t, format));
    }

    return List;
}

/**
 * Parse a date.
 * 
 * @param mixed date - The unparsed date.
 * @param boolean|integer format - Whether to return a formatted date.
 * 
 * @return array - [date, month, year] or formatted date.
 */

export const DateParse = (date, format) =>
{
    let D;

    if (date)
    {
        if (typeof date === "object" && typeof date.getDate === "function")
        {
            D = [date.getDate(), date.getMonth(), date.getFullYear()];

            return format !== undefined ? DateStamp(date, true, format) : D;
        }
        else if (typeof date === "object" && date.constructor === Array)
        {
            if (date.length === 3 && typeof date[0] === "number")
            {
                return format !== undefined ? DateStamp(date, true, format) : date;
            }

            const Dates = [];

            date.forEach(d => Dates.push(DateParse(d, format)));

            return Dates;
        }
        else if (typeof date === "string" && date.match(/^\d{4}.\d{2}.\d{2}$/))
        {
            D = [
                parseInt(date.substr(8, 2), 10),
                parseInt(date.substr(5, 2), 10) - 1,
                parseInt(date.substr(0, 4), 10)
            ];

            return format !== undefined ? DateStamp(D, true, format) : D;
        }
    }

    const Now = (date && !isNaN(date)) ? new Date(date) : new Date();

    D = [
        Now.getDate(),
        Now.getMonth(),
        Now.getFullYear()
    ];

    return format !== undefined ? DateStamp(D, true, format) : D;
}

/**
 * Parse a date into a formatted date stamp.
 * 
 * @param object date - The unparsed date.
 * @param boolean noObj - Whether this is not a JS date object.
 * 
 * @return string - The formatted date.
 */

export const DateStamp = (date, noObj, format = 0) =>
{
    const Dt = date || new Date();
    const Y = noObj ? date[2] : Dt.getFullYear();
    const M = PadNumber(noObj ? date[1] + 1 : Dt.getMonth() + 1);
    const D = PadNumber(noObj ? date[0] : Dt.getDate());
    //const Months = ["dec", "jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
    const Months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
    const Days = ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"];
    switch (format)
    {
        case 1:
        case 2:
            const SelectedDate = noObj ? new Date(Y, M - 1, D, 0, 0, 0, 0) : Dt;
            return format === 1 ? `${Days[SelectedDate.getDay()]} ${D} ${Months[SelectedDate.getMonth()]}` : `${D} ${Months[SelectedDate.getMonth()]}`;

        default:
            return `${Y}-${M}-${D}`;
    }
}

export const DateToTime = (date) =>
{
    if (!date || typeof date !== "object" || date.length !== 3)
    {
        return 0;
    }
    return parseInt(date[2] + PadNumber(date[1]) + PadNumber(date[0]), 10);
}

/**
 * Format a number.
 * 
 * @param number num - The number.
 * 
 * @return string - The formatted number.
 */

export const NiceNumber = (num) =>
{
    const Rounded = Math.round(num);

    return Rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

/**
 * Prevent orphans in a text.
 * 
 * @param string str - The text.
 * @param integer wrap - The minimum length of the last row.
 * 
 * @return string - The parsed text.
 */

export const NoOrphans = (str, wrap = 15) =>
{
    if (typeof str !== "string"  || window.innerWidth < 400)
    {
        return str;
    }

    const Words = str.split(" ");
    let Word, Last = ""

    Words.forEach(word =>
    {
        if (Last.length > wrap)
        {
            return;
        }

        Word = Words.pop();
        Last = Last ? Word + "\u00A0" + Last : Word;
    });

    Words.push(Last);

    return Words.join(" ");
}

/**
 * Copy properties from one object to another. Same as Object.assign.
 * 
 * @param array|object target - The target object.
 * @param array|object source - The source object.
 * 
 * @return object - The target object.
 */

export const ObjectAssign = (target, source) =>
{
    if (typeof target !== "object" || typeof source !== "object")
    {
        return target;
    }

    for (let key in source)
    {
        target[key] = source[key];
    }

    return target;
}

/**
 * Check if two object are identical.
 * 
 * @param array|object obj1 - The first object.
 * @param array|object obj2 - The second object.
 * 
 * @return boolean - Whether the object are identical.
 */

export const ObjectCompare = (obj1, obj2) =>
{
    if (typeof obj1 !== "object" || typeof obj2 !== "object")
    {
        return obj1 === obj2;
    }

    const Json1 = JSON.stringify(obj1);
    const Json2 = JSON.stringify(obj2);

    return Json1 === Json2;
}

/**
 * Extend a object with new properties.
 * 
 * @param object obj1 - The object.
 * @param object obj2 - New properties.
 * @param boolean newObject - Whether to create a new object.
 * @param boolean overwrite - Whether to overwrite exisiting props.
 * 
 * @return obj - The extended object.
 */

export const ObjectExtend = (obj1, obj2, newObject, overwrite) =>
{
    if (newObject)
    {
        const Obj = {};

        ObjectExtend(Obj, obj1);
        ObjectExtend(Obj, obj2);

        return Obj
    }

    for (let key in obj2)
    {
        if (obj1[key] === undefined || overwrite)
        {
            obj1[key] = obj2[key];
        }
    }

    return obj1;
}

/**
 * Add leading zeroes to a number.
 * 
 * @param number number - The number.
 * @param integer length - Add zeroes until this length is reached.
 * 
 * @return string - The padded number.
 */

export const PadNumber = (number, length = 2) =>
{
    let Padded = number.toString();

    while (Padded.length < length)
    {
        Padded = "0" + Padded;
    }

    return Padded;
}

/**
 * Get the absolute position of a node in the DOM.
 * 
 * @param object node - The node.
 * 
 * @return array - [xPos, yPos]
 */

export const Position = (node) =>
{
    let X = 0;
    let Y = 0;

    if (typeof node !== "object" || node.offsetLeft === undefined)
    {
        return [X, Y];
    }

    while (node)
    {
        X += node.offsetLeft;
        Y += node.offsetTop;
        node = node.offsetParent;
    }

    return [X, Y];
}

/**
 * Format a number into power 10 notation.
 * 
 * @param number num - The number.
 * @param integer threshold - The minimum number of digits before the number is formatted.
 * @param integer precision - The decimal precision of the formatted number.
 * @param boolean JSX - Whether to format into JSX (using <sup> instead of special chars).
 * 
 * @return string|number - The parsed number.
 */

export const Power10 = (num, threshold = 6, precision = 2, jsx = true) =>
{
    let Power = String(num).length - 1;

    if (Power < threshold)
    {
        return num;
    }

    const Mult1 = Math.pow(10, Power);
    const Mult2 = Math.pow(10, precision);
    const Factor = String( Math.round(num / Mult1 * Mult2) / Mult2);
    const Super = [ "\u2070", "\u00B9", "\u00B2", "\u00B3", "\u2074", "\u2075", "\u2076", "\u2077", "\u2078", "\u2079" ]; 

    Power = String(Power);

    if (jsx)
    {
        return `${Factor} * 10<sup>${Power}</sup>`;
    }

    let PowerStr = "";

    for (let i = 0; i < Power.length; i++)
    {
        PowerStr += Super[parseInt(Power.substr(i, 1), 10)];
    };

    return `${Factor} * 10${PowerStr}`;
}

/**
 * Generate a random token.
 * 
 * @param number length - Token length.
 * 
 * @return string - The token.
 */

export const RandomToken = (length = 16) =>
{
    const Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvxyz012345678901234567890123456789";
    let Token = "";

    for (var i = 0; i < length; i++)
    {
        Token += Chars.charAt(Math.round(Math.random() * (Chars.length - 1)));
    }

    return Token;
}

/**
 * Shuffle an array.
 * 
 * @param array arr - The array
 * 
 * @return array - The array.
 */

export const ShuffleArray = (arr) =>
{
    arr.forEach((value, key) =>
    {
        const move = Math.floor(Math.random() * (key + 1));

        [arr[ key ], arr[ move ]] = [arr[ move ], arr[ key ]];
    });

    return arr;
}

/**
 * Parse a string into a slug.
 * 
 * @param string str - The string.
 * 
 * @return string - The slug.
 */

export const Slug = (str) =>
{
    let Parsed = str.toLowerCase();

    Parsed = Parsed.replace(/[åäáàâÅÄÁÀÄ]/g, "a");
    Parsed = Parsed.replace(/[ëéèêËÉÈÊ]/g, "e");
    Parsed = Parsed.replace(/[ïíìîÏÍÌÎ]/g, "i");
    Parsed = Parsed.replace(/[öóòôÖÓÒÔ]/g, "o");
    Parsed = Parsed.replace(/[üúùûÜÚÙÛ]/g, "u");
    Parsed = Parsed.replace(/[ .-]{1,}/g, "-");
    Parsed = Parsed.replace(/[^a-z0-9.]/g, "");

    return Parsed;
}

/**
 * Parse a string into a slug with a maximum length.
 * 
 * @param string str - The string.
 * @param integer maxLength - The maximum length.
 * 
 * @return string - The slug.
 */

export const SlugShort = (str, maxLength = 20) =>
{
    return Slug(str).substr(0, maxLength);
}

/**
 * Inject vars into a string.
 * 
 * @param string str - The string.
 * @param object vars - The vars.
 * 
 * @return string - The parsed string.
 */

export const Sprintf = (str, vars) =>
{
    if (typeof str !== "string" || typeof vars !== "object")
    {
        return str;
    }

    let Inject = 0;

    return str.replace(/%([a-z])/g, (matches) =>
    {
        let Key = Inject++;

        return vars[matches[1]] || vars[Key] || "";
    });
}

/**
 * Stip tabs and excessive line breaks from a string.
 * 
 * @param string str - The string.
 * 
 * @return string - The parsed string.
 */

export const StringClean = (str) =>
{
    let Parsed = str;

    Parsed = Parsed.replace(/^\s+|\t/gi, "");
    Parsed = Parsed.replace(/\n +/gi, "\n");
    Parsed = Parsed.replace(/\n{3,}/, "\n\n");

    return Parsed;
}

export const Time = (str) =>
{
    return (new Date(str)).getTime();
}

/**
 * Get the current timestamp.
 * 
 * @param boolean seconds - Whether to include seconds.
 * 
 * @return string - The timestamp.
 */

export const TimeStamp = (seconds = false) =>
{
    const Now = new Date();
    const H = Now.getHours();
    const M = Now.getMinutes();

    if (!seconds)
    {
        return H + ":" + PadNumber(M);
    }

    const S = Now.getSeconds();

    return PadNumber(H) + ":" + PadNumber(M) + ":" + PadNumber(S);
}

/**
 * Stip non-apphabetic chars from the start and end of a string.
 * 
 * @param string str - The string.
 * 
 * @return string - The parsed string.
 */

export const Trim = (str) =>
{
    return str.replace(/^\s+|\s+$/, "");
}

/**
 * Make the first char of a string uppercase.
 * 
 * @param string str - The string.
 * 
 * @return string - The parsed string.
 */

export const UcFirst = (str) =>
{
    if (typeof str !== "string")
    {
        return str;
    }

    const First = str.substr(0, 1);
    const Rest = str.substr(1);

    return First.toUpperCase() + Rest;
}