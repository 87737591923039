import React from "react";
import "./confirm.scss";

import {UcFirst} from "Functions";
import Icon from "Components/Layout/Icon";

class Confirm extends React.Component
{
    render()
    {
        const {
            color,
            children,
            className,
            icon,
            title
        } = this.props;
        const CA = ["Confirm"];
        const Color = UcFirst(color);

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {title ? <h2>{title}</h2> : ""}
                <Icon
                    className={`ConfirmIcon Color${Color}`}
                    src={icon}
                />
                <div className="ConfirmContent">
                    {children}
                </div>
            </div>
        );
    }
}

Confirm.defaultProps = 
{
    color: "green",
    className: "",
    icon: "CheckBig",
    title: ""
};

export default Confirm;