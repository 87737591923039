import Eatery from 'Class/Eatery';
import Parser from 'Class/Parser';

import Full from 'Components/Layout/Full';
import LocalLogo from 'Components/Layout/LocalLogo';
import Segment from 'Components/Layout/Segment';
import ItemLarge from 'Components/UI/ItemLarge';
import ItemStaff from 'Components/UI/ItemStaff';
import Modules from 'Components/View/Modules';
import React from 'react';
import './party.scss';
import Icon from '../../../Layout/Icon';

class TemplateLocations extends React.Component {
    render() {
        const {content, title} = this.props;
        const {
            about,
            backgroundColor,
            backgroundImage,
            backgroundPositionH,
            backgroundPositionV,
            contents,
        } = content || {};

        const Eateries = Eatery.Eateries();
        const Staff = Eatery.Option('conferenceStaff', []);
        let Locations = [];
        const StaffItems = [];

        for (let slug in Eateries) {
            const eatery = Eateries[slug];

            const {
                content: locationContent,
                title: locationName,
            } = eatery;

            const tags = [];
            eatery.rooms.forEach(room => {
                room.tags.forEach(tag => {
                    if (tags.indexOf(tag) === -1) {
                        tags.push(tag);
                    }
                });
            });

            if (tags.indexOf('Fest') === -1 || tags.indexOf('Event') === -1) {
                continue;
            }

            const {
                eventAbout,
                eventImage,
                eventImagePositionH,
                eventImagePositionV,
                eventPriority,
                partyAbout,
                partyImage,
                partyMax,
                city,
            } = locationContent || {};

            Locations.push({
                name: locationName,
                about: partyAbout || eventAbout,
                max: partyMax,
                image: partyImage || eventImage,
                imagePositionH: eventImagePositionH,
                imagePositionV: eventImagePositionV,
                city: city || 'Stockholm',
                priority: eventPriority || 0,
                tags: tags || [],
                uri: slug + '#event',
            });
        }

        Locations.sort((a, b) => {
            return a.priority > b.priority
                ? -1
                : a.priority < b.priority
                    ? 1
                    : a.name.localeCompare(b.name);
        });

        if (Staff && Staff.length) {
            Staff.forEach(id => {
                StaffItems.push(<ItemStaff id={id} key={id} />);
            });
        }

        return (
            <div className="TemplateLocations">
                <Full
                    backgroundColor={backgroundColor}
                    backgroundImage={backgroundImage}
                    backgroundPositionH={backgroundPositionH}
                    backgroundPositionV={backgroundPositionV}
                    className="TemplateLocationsTopNew"
                    top={true}
                >
                    <LocalLogo
                        mainLogo={false}
                        name={title}
                        rotate={true}
                    />
                </Full>
                <Segment backgroundColor="Beige">
                    <div className="TemplateLocationsTop">
                        {Parser.Parse(
                            about,
                            'div',
                            null,
                            {className: 'TemplateLocationsAbout'},
                        )}
                    </div>
                    <div className="TemplateLocationsItemsWrapper">
                        <div className="TemplateLocationsItems">
                            {Locations.map(location => {
                                return (
                                    <ItemLarge
                                        buttons={[
                                            {
                                                hollow: true,
                                                label: 'Läs mer',
                                                to: `anlaggningar${location.uri}`,
                                            },
                                        ]}
                                        className="TemplateLocationsItem"
                                        key={location.name}
                                        image={location.image}
                                        imagePositionH={location.imagePositionH}
                                        imagePositionV={location.imagePositionV}
                                    >
                                        {location.max ? (
                                            <div className="ItemRoomTop">
                                                <Icon key="icon" src="People" />
                                                Max {location.max}
                                            </div>
                                        ) : ''}
                                        <h3 className="notranslate">{location.name}</h3>
                                        <h5>{location.city}</h5>
                                        {Parser.Parse(
                                            location.about.replace(
                                                /<h.*>[^<]*<\/h.*>/gi,
                                                '',
                                            ),
                                            'div',
                                            null,
                                            {className: 'TemplateLocationsItemAbout'},
                                        )}
                                    </ItemLarge>
                                );
                            })}
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <div className="TemplateConferenceStaffItemsWrapper">
                        <div className="TemplateConferenceStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={1} />
            </div>
        );
    }
}

TemplateLocations.defaultProps = {
    content: {},
};

export default TemplateLocations;
