import React from "react";
import "./gallery.scss";

import Gallery from "Components/UI/Gallery";
import LoadImage from "Components/Layout/LoadImage";
import LoadVideo from "Components/Layout/LoadVideo";
import Segment from "Components/Layout/Segment";

class ModuleGallery extends React.Component
{
    render()
    {
        const {
            backgroundColor,
            gallery,
            hideDesktop,
            hideNarrow,
            index,
            title
        } = this.props;
        const CA = ["Module", "ModuleGallery"];
        const Autoplay = gallery.length < 2;
        const GalleryItems = [];

        if (!gallery)
        {
            return "";
        }
        if (hideDesktop)
        {
            CA.push("HideDesktop");
        }
        if (hideNarrow)
        {
            CA.push("HideNarrow");
        }

        gallery.forEach((media, index) =>
        {
            const {image, imagePositionH, imagePositionV, video} = media;

            if (video)
            {
                GalleryItems.push(<LoadVideo autoplay={Autoplay} key={index} loop={Autoplay} src={video}/>);
            }
            else
            {
                GalleryItems.push(<LoadImage key={index} src={image} style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}/>);
            }
        });
        
        return (
            <Segment backgroundColor={backgroundColor} className={CA.join(" ")} top={!index}>
                <div className="ModuleGalleryContent">
                    {title ? <h2>{title}</h2> : ""}
                    <Gallery>{GalleryItems}</Gallery>
                </div>
            </Segment>
        );
    }
}

ModuleGallery.defaultProps =
{
    backgroundColor: "white",
    hideDesktop: false,
    hideNarrow: false,
    index: 0,
    links: [],
    linksColor: "black",
    linksDouble: false
};

export default ModuleGallery;