import React from "react";
import "./eateries.scss";

import Eatery from "Class/Eatery";
import Button from "Components/UI/Button";
import CheckboxField from "Components/UI/Field/CheckboxField";
import Link from "Components/UI/Link";
import LocalLogo from "Components/Layout/LocalLogo";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";

class TemplateEateries extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Priority =
        {
            Göteborg: 80,
            Lund: 40,
            Malmö: 60,
            Mölndal: 80,
            Stockholm: 100,
            Åre: 0
        };
        this.state =
        {
            exclude: []
        };
    }

    Item = (eatery) =>
    {
        const {content, title, uri} = eatery;
        const {address, email, phone} = content || {};
        const Address = address.split("\n");
        const AddressItems = [];
        Address.forEach((row, index) =>
        {
            AddressItems.push(<div key={index}>{row}</div>);
        });
        return (
            <Link className="TemplateEateriesItem" key={uri} to={uri}>
                <div className="TemplateEateriesItemContent">
                    <div className="TemplateEateriesItemLogoWrapper">
                        <LocalLogo
                            className="TemplateEateriesItemContent"
                            name={title}
                            uri={uri}
                        />
                    </div>
                    <div className="TemplateEateriesItemContact">
                        <div className="TemplateEateriesItemContactAddress">
                            {AddressItems}
                        </div>
                        <div>{email}</div>
                        <div>{phone}</div>
                    </div>
                    <div className="TemplateEateriesItemTray">
                        <Button
                            hollow={true}
                            label="Läs mer"
                            small={true}
                        />
                    </div>
                </div>
            </Link>
        );
    }

    OnFilter = (e, include, city) =>
    {
        const {exclude} = this.state;
        const Index = exclude.indexOf(city);
        if (include && Index >= 0)
        {
            exclude.splice(Index, 1);
        }
        else if (!include && Index < 0)
        {
            exclude.push(city);
        }
        this.setState({exclude});
    }

    render()
    {
        const {content, title} = this.props;
        const {exclude} = this.state;
        const {contents} = content || {};
        const Checkboxes = [];
        const Cities = [];
        const Eateries = Eatery.EateriesSorted();
        const Filter = [];
        const Items = [];
        let NumTotal = 0;
        Eateries.forEach(eatery =>
        {
            const City = eatery.content.city;
            if (Cities.indexOf(City) < 0)
            {
                Cities.push(City);
            }
            if (exclude.indexOf(City) < 0)
            {
                Items.push(this.Item(eatery));
            }
            NumTotal++;
        });
        Cities.sort((a, b) =>
        {
            const P1 = this.Priority[a] || 0;
            const P2 = this.Priority[b] || 0;
            if (P1 < P2)
            {
                return 1;
            }
            if (P1 > P2)
            {
                return -1;
            }
            return 0;
        });
        Cities.forEach(city =>
        {
            Checkboxes.push(
                <CheckboxField
                    checked={exclude.indexOf(city) < 0}
                    id={city}
                    key={city}
                    onChange={this.OnFilter}
                    label={city}
                />
            );
        });
        for (let i = 0; i < Checkboxes.length; i += 2)
        {
            Filter.push(
                <div className="TemplateEateriesFilterColumn" key={i}>
                    {Checkboxes[i]}
                    {Checkboxes[i + 1] || ""}
                </div>
            );
        }
        return (
            <div className="TemplateEateries">
                <Segment backgroundColor="Beige" className="TemplateEateriesWrapper">
                    <div className="TemplateEateriesContainer">
                        <h1>{title}</h1>
                        <div className="TemplateEateriesFilter">
                            {Filter}
                        </div>
                        <div className="TemplateEateriesOnDisplay">
                            Visar {Items.length} av {NumTotal}
                        </div>
                        <div className="TemplateEateriesItems">
                            {Items}
                        </div>
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
            </div>
        );
    }
}

TemplateEateries.defaultProps =
{
    content: {}
};

export default TemplateEateries;