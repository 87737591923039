/**!
 *  Render a Google map.
 *  https://tomchentw.github.io/react-google-maps
 *  @param float lat - Center latitude.
 *  @param float lng - Center longitude.
 *  @param integer zoom - Zoom level.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./map.scss"
import {renderToString} from "react-dom/server";
import {GoogleMap, Marker, withScriptjs, withGoogleMap} from "react-google-maps";
import MapStyle from "Import/JSON/mapstyle.json"
import MarkerIcon from "./marker.js";

class MapObject extends React.Component
{
    constructor(props)
    {
        super(props);
        this.CustomMarker = true;
        this.Map = window.google.maps;
        this.MapObject = false;
        this.MapOptions =
        {
            draggable: true,
            fullscreenControl: false,
            mapTypeControl: false,
            maxZoom: 18,
            minZoom: 5,
            mousewheel: false,
            panControl: false,
            streetViewControl: false,
            styles: MapStyle,
            zoomControl: false
        };
    }

    componentDidMount()
    {
        const {locations} = this.props;
        if (!locations || !locations.length)
        {
            return;
        }
        const Bounds = new this.Map.LatLngBounds();
        locations.forEach(({lat, lng}) =>
        {
            Bounds.extend(new this.Map.LatLng(lat, lng));
        });
        this.MapObject.fitBounds(Bounds);
    }

    OnClick = (e, index) =>
    {
        this.props.onClick(e, index);
    }

    OnMouseOut = (e, index) =>
    {
        this.props.onMouseOut(e, index);
    }

    OnMouseOver = (e, index) =>
    {
        this.props.onMouseOver(e, index);
    }

    render()
    {
        const {active, hover, lat, lng, locations, zoom} = this.props;
        const W = 24.21;
        const H = 30.87;
        const Markers = [];
        if (locations && locations.length)
        {
            locations.forEach(({lat: lt, lng: ln, uri}, index) =>
            {
                const Icon = renderToString(<MarkerIcon
                    bgColor={index === hover ? "#333333" : "#101010"}
                    fgColor={index === active ? "#b98952" : (index === hover ? "#e3e3e3" : "#ffffff")}
                    uri={uri}
                />);
                Markers.push(
                    <Marker
                        icon={this.CustomMarker ? {
                            anchor: new this.Map.Point(W / 2, H / 1.5),
                            size: new this.Map.Size(W, H),
                            url: "data:image/svg+xml;utf-8," + encodeURIComponent(Icon)
                        } : null}
                        key={index}
                        onClick={e => this.OnClick(e, index)}
                        onMouseOver={e => this.OnMouseOver(e, index)}
                        onMouseOut={e => this.OnMouseOut(e, index)}
                        position={new this.Map.LatLng(lt, ln)}
                    />
                );
            });
        }
        else
        {
            const Icon = renderToString(<MarkerIcon/>);
            Markers.push(
                <Marker
                    icon={this.CustomMarker ? {
                        anchor: new this.Map.Point(W / 2, H / 1.5),
                        size: new this.Map.Size(W, H),
                        url: "data:image/svg+xml;utf-8," + encodeURIComponent(Icon)
                    } : null}
                    key="center"
                    position={new this.Map.LatLng(lat, lng)}
                />
            );
        }
        return (
            <GoogleMap
                defaultCenter={{lat, lng}}
                defaultZoom={zoom}
                options={this.MapOptions}
                ref={mapObject => this.MapObject = mapObject}
            >
                {Markers}
            </GoogleMap>
        );
    }
}

MapObject.propTypes =
{
    active: PropTypes.number,
    hover: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    locations: PropTypes.array,
    onClick: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    zoom: PropTypes.number
};

MapObject.defaultProps =
{
    active: -1,
    hover: -1,
    lat: 0,
    lng: 0,
    locations: [],
    onClick: () => {},
    onMouseOut: () => {},
    onMouseOver: () => {},
    zoom: 12
};

export default withScriptjs(withGoogleMap(MapObject));