import React from "react";
import "./openinghours.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

class OpeningHours extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Maj",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec"
        ];
        this.Weekday = {
            mon: "måndag",
            tue: "tisdag",
            wed: "onsdag",
            thu: "torsdag",
            fri: "fredag",
            sat: "lördag",
            sun: "söndag"
        };
    }

    DeviationItem = (label, startDate, endDate, opens, closes, closed, index) =>
    {
        let Label;
        if (label)
        {
            Label = label;
        }
        else if (endDate)
        {
            Label = `${this.FormatDate(startDate)} — ${this.FormatDate(endDate)}`;
        }
        else
        {
            Label = this.FormatDate(startDate);
        }
        return (
            <div className="OpeningHoursItem Deviation" key={`deviation-${index}`}>
                <div className="OpeningHoursItemDays">
                    {Label}
                </div>
                {" "}
                <div className="OpeningHoursItemHours">
                    {closed ? "Stängt" : `${opens}–${closes}`}
                </div>
            </div>
        );
    }

    FormatDate = (date) =>
    {
        const Extract = typeof date == "string" ? date.match(/\d{4}-(\d{2})-(\d{2})/) : false;
        if (!Extract)
        {
            return date;
        }
        const Date = parseInt(Extract[2], 10);
        const Month = parseInt(Extract[1], 10) - 1;
        return Date + " " + this.Months[Month];
    }

    Item = ({day, opens, closes, closed, notice}, index) =>
    {
        return (
            <div className="OpeningHoursItem" key={`item-${index}`}>
                <div className="OpeningHoursItemDays">
                    {day.length > 1 ? `${day[0]}–${day[1]}` : day[0]}
                    {notice ? Parser.Parse(notice, "div", null, {className: "OpeningHoursNotice"}) : ""}
                </div>
                <div className="OpeningHoursItemHours">
                    {closed ? "Stängt" : `${opens}–${closes}`}
                </div>
            </div>
        );
    }

    render()
    {
        const {className, days, deviations} = this.props;
        const CA = ["OpeningHours"];
        if (className)
        {
            CA.push(className);
        }
        const Items = [];
        const [DateNow] = Eatery.Now().split(" ");
        let Index = 0;
        let Item;
        if (days)
        {
            days.forEach(({closed, closes, day, opens, notice}) =>
            {
                if (Item && ((Item.opens === opens && Item.closes === closes) || (Item.closed && closed)) && Item.notice === notice)
                {
                    Item.day[1] = this.Weekday[day];
                }
                else
                {
                    if (Item)
                    {
                        Items.push(this.Item(Item, Index++));
                    }

                    Item =
                    {
                        day: [this.Weekday[day]],
                        opens, closes, closed, notice
                    };
                }
            });
        }
        if (Item)
        {
            Items.push(this.Item(Item, Index++));
        }
        if (deviations)
        {
            deviations.forEach(({closed, closes, date, endDate, name, opens}, index) =>
            {
                if (DateNow > date && (!endDate || DateNow > endDate))
                {
                    return;
                }

                Items.push(this.DeviationItem(name, date, endDate, opens, closes, closed, index));
            });
        }
        return (
            <div className={CA.join(" ")}>
                {Items}
            </div>
        );
    }
}

OpeningHours.defaultProps =
{
    className: "",
    days: [],
    deviations: []
};

export default OpeningHours;