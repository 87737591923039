import Cision from 'Class/Cision';
import Spinner from 'Components/Feedback/Spinner';
import Segment from 'Components/Layout/Segment';

import Item from 'Components/UI/Item';
import ItemPress from 'Components/UI/ItemPress';
import Loading from 'Components/View/Loading';
import Modules from 'Components/View/Modules';
import React from 'react';
import './press.scss';

class TemplatePress extends React.Component {
    constructor(props) {
        super(props);

        this.Mounted = false;

        this.state = {
            page: 1,
            all: false,
            error: false,
            releases: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.Mounted = true;
        this.GetNews();
    }

    componentWillUnmount() {
        this.Mounted = false;
    }

    GetNews = () => {
        const {releases, page} = this.state;

        this.setState({
            error: false,
            loading: true,
        });

        Cision.GetNews(response => {
            if (!this.Mounted) {
                return;
            }

            const {Releases} = response || {};

            if (!Releases) {
                this.setState({
                    error: true,
                    loading: false,
                });
            } else {
                releases.push(...Releases);
                this.setState({
                    page: page + 1,
                    all: Releases.length !== 8,
                    loading: false,
                    releases,
                });
            }
        }, true, 8, page);
    };

    render() {
        const {content, title} = this.props;
        const {
            all,
            loading,
            releases,
        } = this.state;
        const {contents} = content || {};
        const Items = [];

        if (releases.length) {
            releases.forEach((release, index) => {
                Items.push(
                    <ItemPress
                        className="TemplatePressItem"
                        key={index}
                        release={release}
                    />,
                );
            });
        } else if (loading) {
            Items.push(<Loading key="loading" />);
        }

        return (
            <div className="TemplatePress">
                <Segment backgroundColor="Beige" top={true}>
                    <div className="TemplatePressTop">
                        <h1>{title}</h1>
                    </div>
                    <div className="TemplatePressItemsWrapper">
                        <div className="TemplatePressItems">
                            {Items}
                        </div>
                    </div>
                    {releases.length > 0 && !all && (
                        <Item
                            className="TemplatePressFetch"
                            disabled={loading}
                            onClick={this.GetNews}
                        >
                            <span>
                                {loading ? <Spinner /> : 'Ladda fler'}
                            </span>
                        </Item>
                    )}
                </Segment>
                <Modules modules={contents} startIndex={1} />
            </div>
        );
    }
}

export default TemplatePress;
