import React from "react";
import "./academy.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import Link from "Components/UI/Link";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplateAcademy extends React.Component
{
    RightColumn = () =>
    {
        return (
            <div className="TemplateAcademyBadge"></div>
        );
    }

    render()
    {
        const {content} = this.props;
        const {about, contacts, contents, title} = content || {};
        const ContactItems = [];

        if (contacts && contacts.length)
        {
            contacts.forEach(id =>
            {
                const Staff = Eatery.Staff(id);
                const {content, title} = Staff || {};
                const {email, phone, role} = content || {};

                if (!title)
                {
                    return;
                }

                ContactItems.push(
                    <div className="TemplateAcademyContactItem" key={id}>
                        <h3>{title}</h3>
                        <h6>{role}</h6>
                        {email ? <Link href={`mailto:${email}`}>{email}</Link> : ""}
                        {phone ? <Link href={`tel:${phone}`}>{phone}</Link> : ""}
                    </div>
                );
            });
        }

        return (
            <div className="TemplateAcademy">
                <Segment top={true}>
                    <ThreeColumns
                        className="TemplateAcademyTop"
                        right={this.RightColumn()}
                    >
                        <h5>Teser Academy</h5>
                        <h1>{title}</h1>
                        <div className="TemplateAcademyButtons">
                            <Button color="Black" label="Alla kurser" href="https://eatery.learnster.com/eatery/sessions"/>
                        </div>
                        {Parser.Parse(about, "div", null, {className: "TemplateAcademyAbout"})}
                        <div className="TemplateAcademyContact">
                            <h2>Kontaktpersoner</h2>
                            <div className="TemplateAcademyContactItems">
                                {ContactItems}
                            </div>
                        </div>
                    </ThreeColumns>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
            </div>
        );
    }
}

TemplateAcademy.defaultProps =
{
    content: {}
};

export default TemplateAcademy;