import React from "react";
import "./spinner.scss";

import Icon from "Components/Layout/Icon";

class Spinner extends React.Component
{
    render()
    {
        const {className} = this.props;
        const CA = ["Spinner"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                <Icon src="E"/>
            </div>
        );
    }
}

Spinner.defaultProps =
{
    className: ""
};

export default Spinner;