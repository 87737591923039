/*!
 *  Internal/external link.
 *
 *  @param string className - Append a class name.
 *  @param string href - External URL.
 *  @param string label - Link label.
 *  @param function onClick - Callback for when the item is clicked.
 *  @param object style - Style attributes.
 *  @param string target - Optional. Link target window when a href has been specified.
 *  @param string title - The item title tag.
 *  @param string to - URI path when used for internal navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./link.scss";

//import {NavLink as RouterLink, withRouter} from "react-router-dom";
import {NavLink as RouterLink} from "react-router-dom";

class Link extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Node = false;
    }

    render()
    {
        let {
            children,
            className,
            href,
            label,
            onBlur,
            onClick,
            onFocus,
            style,
            target,
            title,
            to
        } = this.props;
        const CA = ["Link"];

        if (href.match(/^\s*javascript/i))
        {
            href = target = "";
        }
        else if (href.match(/^\s*mailto/i))
        {
            target = "";
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");

        if (href)
        {
            return (
                <a
                    rel="noopener noreferrer"
                    className={CS}
                    href={href}
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    ref={n => this.Node = n}
                    style={style}
                    tabIndex="0"
                    target={target}
                    title={title}
                >{label || children}</a>
            );
        }
        else if (to)
        {
            return (
                <RouterLink
                    activeClassName="ActiveLink"
                    className={CS}
                    exact={true }
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    ref={n => this.Node = n}
                    style={style}
                    tabIndex="0"
                    title={title}
                    to={to}
                >{label || children}</RouterLink>
            );
        }
        else
        {
            return (
                <div
                    className={CS}
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    ref={n => this.Node = n}
                    style={style}
                    tabIndex="0"
                    title={title}
                >{label || children}</div>
            );
        }
    }
}

Link.defaultProps =
{
    className: "",
    href: "",
    label: "",
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    style: {},
    target: "_blank",
    title: "",
    to: ""
};

//export default withRouter(Link);
export default Link;