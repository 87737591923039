import Item from 'Components/UI/Item';
import Link from 'Components/UI/Link';

import { UcFirst } from 'Functions';
import React from 'react';
import './button.scss';

class Button extends React.Component {
    OnClick = (e) => {
        const {disabled, id, onClick} = this.props;
        if (disabled || typeof onClick !== 'function') {
            return;
        }
        onClick(e, id);

    };

    render() {
        const {
            children,
            className,
            color,
            disabled,
            hollow,
            href,
            label,
            small,
            target,
            title,
            to,
            subLabel,
        } = this.props;
        const CA = ['Button'];
        const Content = [label || children];
        if (disabled) {
            CA.push('Disabled');
        }
        if (hollow) {
            CA.push('Hollow');
        } else {
            const Color = UcFirst(color);
            CA.push(`Color${Color}`);
        }
        if (small) {
            CA.push('Small');
        } else {
            CA.push('Big');
        }
        if (subLabel) {
            Content.push(<span
                className="SubLabel"
                key="sub"
            >{subLabel}</span>);
            CA.push('HasSubLabel');
        }
        if (className) {
            CA.push(className);
        }
        if (href || to) {
            return (
                <Link
                    className={CA.join(' ')}
                    href={href}
                    target={target}
                    title={title}
                    to={to}
                >{Content}</Link>
            );
        }
        return (
            <Item
                allowFocus={!disabled}
                className={CA.join(' ')}
                disabled={disabled}
                onClick={this.OnClick}
                onKeyDown={this.OnKeyDown}
                title={title}
            >{Content}</Item>
        );
    }
}

Button.defaultProps =
    {
        className: '',
        color: 'White',
        disabled: false,
        hollow: false,
        href: '',
        id: '',
        label: '',
        onClick: false,
        target: '_blank',
        title: '',
        to: '',
        subLabel: '',
    };

export default Button;
