import React from "react";
import "./foodselectfield.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";
import {ObjectCompare} from "Functions";

import Icon from "Components/Layout/Icon";
import TextField from "Components/UI/Field/TextField";
import SelectField from "Components/UI/Field/SelectField";

class FoodSelectField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.BlurCallback = false;
        this.BlurTimeout = false;

        this.state =
        {
            selectValue: [],
            value: []
        }
    }

    componentDidMount()
    {
        const Value = this.ParseValue(this.props.value);
        const SelectValue = this.ExtractSelectValue(Value);

        this.setState({
            selectValue: SelectValue,
            value: Value
        })
    }

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        const {selectValue} = this.state;
        const V1 = this.ParseValue(v1);
        const V2 = this.ParseValue(v2);
        const SelectValue = this.ExtractSelectValue(V1);

        if (!ObjectCompare(V1, V2) && !ObjectCompare(SelectValue, selectValue))
        {
            this.setState({
                selectValue: SelectValue,
                value: V1
            })
        }
    }

    ExtractSelectValue = (value) =>
    {
        const SelectValue = [];
        value.forEach(([id]) => SelectValue.push(id));
        return SelectValue;
    }

    GetPeople = (id) =>
    {
        const {defaultPeople} = this.props;
        const {selectValue, value} = this.state;
        const Index = selectValue.indexOf(id);
        
        if (value[Index] === undefined)
        {
            return defaultPeople;
        }

        return value[Index][1] || defaultPeople;
    }

    OnBlur = (callback) =>
    {
        clearTimeout(this.BlurTimeout);
        this.BlurCallback = callback;
        this.BlurTimeout = setTimeout(() =>
        {
            callback();
            this.BlurCallback = false;
        }, 100);
    }

    OnChange = (e, value, id) =>
    {
        const {onChange} = this.props;
        const Value = [];

        value.forEach(id => Value.push([id, this.GetPeople(id)]));
        this.setState({
            selectValue: value,
            value: Value
        });
        onChange(e, Value, id);
    }

    OnKeyDownItem = (e) =>
    {
        switch (e.which)
        {
            case 13:
            case 32:
                break;

            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
        const {categories, eatery, id, onChange} = this.props;
        const {selectValue} = this.state;
        const Id = e.currentTarget.id;
        const Index = selectValue.indexOf(Id);
        const Value = [];

        if (Eatery.Food(Id, eatery, categories))
        {
            return;
        }
        if (Index < 0)
        {
            selectValue.push(Id);
        }
        else
        {
            selectValue.splice(Index, 1);
        }

        selectValue.forEach(id => Value.push([id, this.GetPeople(id)]));

        this.setState({
            selectValue,
            value: Value
        });
        onChange(e, Value, id);
    }

    OnPeople = (e, people, id) =>
    {
        const {id: fieldId, onChange} = this.props;
        const {selectValue, value} = this.state;
        const Index = selectValue.indexOf(id);

        if (Index < 0)
        {
            return;
        }

        value[Index][1] = people;
        this.setState({value});
        onChange(e, value, fieldId);
    }

    OnSubBlur = () =>
    {
        this.OnBlur(this.BlurCallback);
    }

    OnSubFocus = () =>
    {
        clearTimeout(this.BlurTimeout);
    }

    Options = (value, selectValue) =>
    {
        const {eatery, categories} = this.props;
        const List = Eatery.FoodList(eatery, categories);
        const Options = {};

        for (let category in List)
        {
            List[category].forEach((item, index) =>
            {
                const {content, id, title} = item;
                const {description, price} = content;
                const CA = ["FoodSelectFieldItemWrapper"]
                const Selected = (selectValue && selectValue.indexOf(id) >= 0);

                if (Selected)
                {
                    CA.push("Selected");
                }

                Options[id] = (
                    <div className={CA.join(" ")}>
                        {index ? "" : (
                            <div className="FoodSelectFieldCategory">{category}</div>
                        )}
                        <div
                            id={id}
                            className="FoodSelectFieldItem"
                            onBlur={this.OnSubBlur}
                            onFocus={this.OnSubFocus}
                            onKeyDown={this.OnKeyDownItem}
                            tabIndex="0"
                        >
                            <Icon className="FoodSelectFieldItemIcon" src={Selected ? "Checked" : "Square"}/>
                            <div className="FoodSelectFieldItemContent">
                                <div className="FoodSelectFieldItemTitle">{title}</div>
                                <div className="FoodSelectFieldItemPrice">
                                    {price ? `${price}kr/person` : "Pris på förfrågan"}
                                </div>
                                {description ? Parser.Parse(description, "div", null, {className: "FoodSelectFieldItemDescription"}) : ""}
                                <div className="FoodSelectFieldItemRight" onClick={e => e.stopPropagation()}>
                                    <Icon src="People"/>
                                    <TextField
                                        disabled={!Selected}
                                        hollow={true}
                                        id={id}
                                        onBlur={this.OnSubBlur}
                                        onChange={this.OnPeople}
                                        onFocus={this.OnSubFocus}
                                        onInput={this.OnPeople}
                                        type="number"
                                        value={this.GetPeople(id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return Options;
    }

    ParseValue = (value) =>
    {
        const {categories, defaultPeople, eatery} = this.props;
        const Parsed = [];

        if (!Array.isArray(value))
        {
            return Parsed;
        }

        value.forEach(item =>
        {
            const Id = Array.isArray(item) ? item[0] : item;
            const People = Array.isArray(item) ? item[1] || defaultPeople : defaultPeople;

            if (Eatery.Food(Id, eatery, categories))
            {
                Parsed.push([Id, People]);
            }
        })

        return Parsed;
    }

    SelectedValue = (id) =>
    {
        const Item = Eatery.Food(id);
        const Title = Item ? Item.title : id;
        const People = this.GetPeople(id);

        return `${Title} (x${People})`;
    }

    render()
    {
        const {className, placeholder} = this.props;
        const {selectValue, value} = this.state;
        const CA = ["FoodSelectField"];

        if (className)
        {
            CA.push(className);
        }

        return <SelectField
            {...this.props}
            className={CA.join(" ")}
            onBlurCollapse={this.OnBlur}
            onChange={this.OnChange}
            onRenderValue={this.SelectedValue}
            options={this.Options(value, selectValue)}
            placeholder={placeholder || "Inget alternativ valt"}
            multiple={true}
            value={selectValue}
        />
    }
}

FoodSelectField.defaultProps =
{
    categories: [],
    className: "",
    defaultPeople: 0,
    eatery: "",
    event: "",
    id: "",
    onChange: () => {},
    placeholder: "",
    value: []
};

export default FoodSelectField;