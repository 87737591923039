import Eatery from 'Class/Eatery';

import Activity from 'Components/View/Activity';
import Post from 'Components/View/Post';
import PriceList from 'Components/View/PriceList';
import { BrowserLanguage, RandomToken } from 'Functions';
import Settings from 'Import/JSON/settings.json';
import { default as JsCookie } from 'js-cookie';
import React from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

class Globals {
    constructor() {
        this.AddedTracking = false;
        this.CookieKey = '_cookies';
        this.CookiePreferenceKey = '_cookiePreferences';
        this.Duration = 0.8;
        this.DurationPerItem = 0.05;
        this.Listeners = {};
        this.Settings = Settings || {};
        this.StorageIsEnabled = -1;
        this.StorageWarningSent = false;
        this.Vars = {};

        // Trigger an event when fonts load to update section masks etc.
        if (document.fonts) {
            document.fonts.onloadingdone = this.OnLoadFont;
        }

        this.Var('location', window.location.pathname || '/');
        // Add tracking (if it's been allowed).
        this.AddTracking();
    }

    /**
     * Add tracking if the IDs have been set and the user has allowed cookies.
     *
     * @param bool confirmed - Whether cookies have been allowed.
     *
     * @return void
     */

    AddTracking = (confirmed) => {
        const {statistics} = this.StorageJson(this.CookiePreferenceKey, {});
        const Allowed = statistics === undefined ? true : statistics;
        const Confirmed = confirmed || JsCookie.get(this.CookieKey);
        const GA = this.Setting('GoogleAnalyticsId');
        const GTM = this.Setting('GoogleTagManagerId');
        this.Trigger('cookies', Confirmed);

        if (!Confirmed || !Allowed || this.AddedTracking) {
            return;
        }
        // Add Google Analytics if an ID has been set.
        if (GA) {
            ReactGA.initialize(GA);
            ReactGA.pageview('/');

            this.Listen('navigation', location => {
                ReactGA.pageview(location.pathname);
            });
        }
        // Add Google Tag Manager if an ID has been set.
        if (GTM) {
            TagManager.initialize({gtmId: GTM});
        }

        this.Var('tracking', true);
        this.Trigger('add-tracking');
    };

    /**
     * Set cookies allowed.
     *
     * @return void
     */

    AllowCookies = () => {
        JsCookie.set(this.Key, '1', {expires: 14});
        this.AddTracking(true);
    };

    /**
     * Save or Load a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     *
     * @return mixed - The setting value.
     */

    Client = (key, value = null) => {
        if (this.StorageEnabled()) {
            return this.Session(key, value) || this.Storage(key, value);
        } else {
            if (!this.StorageWarningSent) {
                console.warn('Storage is not available in this client.');
                this.StorageWarningSent = true;
            }

            return this.Var(key, value);
        }
    };

    /**
     * Remove a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     *
     * @param string key - The value key.
     *
     * @return void
     */

    ClientRemove = (key) => {
        if (this.StorageEnabled()) {
            this.SessionRemove(key);
            this.StorageRemove(key);
        }
    };

    /**
     * Create a dialog.
     *
     * @param object params - Dialog params.
     *
     * @return void
     */

    Dialog = (params) => {
        if (typeof params !== 'object') {
            return;
        }

        if (!params.id) {
            params.id = RandomToken();
        }

        this.Trigger('dialog', params);
    };

    /**
     * Create a activity dialog.
     *
     * @param string id - Activity id.
     *
     * @return void
     */

    DialogActivity = (id) => {
        return this.Dialog({
            content: <Activity id={id} />, fillNarrow: true, id,
        });
    };

    /**
     * Destroy a dialog.
     *
     * @param string id - Dialog id.
     *
     * @return void
     */

    DialogDestroy = (id) => {
        this.Trigger('dialog-destroy', id);
    };

    /**
     * Create a post dialog.
     *
     * @param string id - Post id.
     *
     * @return void
     */

    DialogPost = (id) => {
        return this.Dialog({
            content: <Post id={id} />, fillNarrow: true, id,
        });
    };

    /**
     * Create a price list dialog.
     *
     * @param string id - Price list id.
     *
     * @return void
     */

    DialogPriceList = (id) => {
        const PrintHref = Eatery.Url(`skriv-ut/?id=${id}`);
        const PriceListObject = Eatery.PriceList(id);
        if (!PriceListObject) {
            return;
        }
        return this.Dialog({
            buttons: this.Setting('HidePrint') ? [] : [
                {
                    href: PrintHref, label: 'Ladda ner som PDF',
                },
            ],
            content: <PriceList id={id} />,
            fillNarrow: true,
            header: PriceListObject.content.title,
            id,
        });
    };

    /**
     * Get all event listeners for an event.
     *
     * @param string event - The event name
     *
     * @return array - An array of listeners.
     */

    Get = (event) => {
        if (this.Listeners[event] === undefined) {
            this.Listeners[event] = [];
        }

        return this.Listeners[event];
    };

    /**
     * Increase and return the value of a global variable.
     *
     * @param string key - The value key.
     *
     * @return mixed - The value.
     */

    Increase = (key) => {
        let Value = this.Vars[key];

        if (Value === undefined) {
            return this.Vars[key] = 0;
        }

        if (typeof Value !== 'number') {
            return Value;
        }

        return this.Vars[key] = Value + 1;
    };

    /**
     * Add an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     *
     * @return void
     */

    Listen = (event, callback) => {
        if (typeof callback !== 'function') {
            return;
        }

        const Listeners = this.Get(event);
        const Index = Listeners.indexOf(callback);

        if (Index < 0) {
            Listeners.push(callback);
        }
    };

    /**
     * Get the client locale.
     *
     * @param string locale - Optional. Set a initial locale.
     *
     * @return string - The locale
     */

    LocaleGet = (locale) => {
        if (locale) {
            return this.LocaleSet(locale);
        }

        let Locale = this.Var('locale');

        if (Locale) {
            return Locale;
        }

        Locale = this.Storage('locale');

        if (typeof Locale === 'string' && Locale.match(/^[a-z]{2}_[A-Z]{2}$/)) {
            return this.Var('locale', Locale);
        }

        return this.Var(
            'locale',
            BrowserLanguage() || this.Setting('Locale', 'en_US'),
        );
    };

    /**
     * Set the client locale.
     *
     * @param string locale - The new locale.
     *
     * @return string - The new locale.
     */

    LocaleSet = (locale) => {
        this.Storage('locale', locale);
        this.Var('locale', locale);
        return locale;
    };

    /**
     * Create a notification
     *
     * @param object params - Notification params.
     *
     * @return void
     */

    Notification = (params) => {
        if (typeof params !== 'object') {
            return;
        }

        if (!params.id) {
            params.id = RandomToken();
        }

        this.Trigger('notification', params);
    };

    /**
     * Callback when a font loads.
     *
     * @return void
     */

    OnLoadFont = (e) => {
        this.Trigger('font');
    };

    /**
     * Remove an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     *
     * @return void
     */

    Remove = (event, callback) => {
        const Listeners = this.Get(event);
        const Index = Listeners.indexOf(callback);

        if (Index < 0) {
            return;
        }

        Listeners.splice(Index, 1);
    };

    /**
     * Save or Load a value from the clients session storage.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     *
     * @return mixed - The setting value.
     */

    Session = (key, value = null) => {
        if (!this.StorageEnabled()) {
            if (!this.StorageWarningSent) {
                console.warn('Storage is not available in this client.');
                this.StorageWarningSent = true;
            }

            return this.Var(key, value);
        }

        if (value !== null) {
            sessionStorage.setItem(key, value);
        } else {
            value = sessionStorage.getItem(key);
        }

        switch (value) {
            case 'false':
                return false;
            case 'true':
                return true;
            case null:
                return undefined;
            default:
                return value;
        }
    };

    /**
     * Remove a value from the clients session storage.
     *
     * @param string key - The value key.
     *
     * @return void
     */

    SessionRemove = (key) => {
        if (!this.StorageEnabled()) {
            return undefined;
        }

        sessionStorage.removeItem(key);
    };

    /**
     * Save a cookie preference
     *
     * @param string key - Cookie preference name.
     * @param mixed value - Cookie preference value.
     *
     * @return void
     */

    SetCookiePreference = (key, value) => {
        const Preferences = Globals.StorageJson(this.CookiePreferenceKey, {});
        Preferences[key] = value;
        Globals.Storage(this.Key, JSON.stringify(Preferences));
        this.Trigger('cookie-preference', key, value);
    };

    /***
     * Get a setting value.
     *
     * @param string name - The setting name.
     * @param string defaultValue - The default value when not set.
     *
     * @return mixed - The setting value.
     */

    Setting = (name, defaultValue) => {
        if (name === 'ApiUrl') {
            return process.env.REACT_APP_API_URL;
        }

        return this.Settings[name] !== undefined
            ? this.Settings[name]
            : defaultValue;
    };

    /**
     * Set the document title.
     *
     * @param string title - The new title
     *
     * @return void
     */
    SetTitle = (title) => {
        document.title = title ? `Eatery - ${title}` : 'Eatery';
    };

    /**
     * Save or Load a value from the clients local storage.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     *
     * @return mixed - The setting value.
     */

    Storage = (key, value = null) => {
        if (!this.StorageEnabled()) {
            if (!this.StorageWarningSent) {
                console.warn('Storage is not available in this client.');
                this.StorageWarningSent = true;
            }

            return this.Var(key, value);
        }

        if (value !== null) {
            localStorage.setItem(key, value);
        } else {
            value = localStorage.getItem(key);
        }

        switch (value) {
            case 'false':
                return false;
            case 'true':
                return true;
            case null:
                return undefined;
            default:
                return value;
        }
    };

    /**
     * Check if local/ession storage is available.
     *
     * @return boolean - Whether local/session storage is available.
     */

    StorageEnabled = () => {
        if (this.StorageIsEnabled !== -1) {
            return this.StorageIsEnabled;
        }

        try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');
            sessionStorage.setItem('test', 'test');
            sessionStorage.removeItem('test');

            return this.StorageIsEnabled = true;
        } catch (e) {
            return this.StorageIsEnabled = false;
        }
    };

    /**
     * Load a value from the clients local storage and parse it as JSON.
     *
     * @param string key - The value key.
     * @param object defaultObject - Default object when missing/failed.
     *
     * @return object - Parsed or default object.
     */

    StorageJson = (key, defaultObject) => {
        const Raw = this.Storage(key);
        const Default = defaultObject !== undefined ? defaultObject : [];

        if (!Raw || typeof Raw !== 'string') {
            return Default;
        }

        let Decoded;

        try {
            Decoded = JSON.parse(Raw);
        } catch (e) {
            return Default;
        }

        return typeof Decoded === 'object' ? Decoded : Default;
    };

    /**
     * Remove a value from the clients local storage.
     *
     * @param string key - The value key.
     *
     * @return void
     */

    StorageRemove = (key) => {
        if (!this.StorageEnabled()) {
            return undefined;
        }

        localStorage.removeItem(key);
    };

    /**
     * Toggle a global variable between true/false.
     *
     * @param string key - The value key.
     *
     * @return mixed - The value.
     */

    Toggle = (key) => {
        const Value = !this.Vars[key];

        return this.Var(key, Value);
    };

    /**
     * Trigger an event
     *
     * @param string event - The event name
     * @param mixed data1 - Optional. Data to send to the event listeners.
     * @param mixed data2 - Optional. Data to send to the event listeners.
     * @param mixed data3 - Optional. Data to send to the event listeners.
     *
     * @return void
     */

    Trigger = (event, data1, data2, data3) => {
        const Listeners = this.Get(event);

        Listeners.forEach(callback => {
            callback(data1, data2, data3);
        });
    };

    /**
     * Set or get the value of a global variable.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     *
     * @return mixed - The value.
     */

    Var = (key, value) => {
        if (value === undefined || value === this.Vars[key]) {
            return this.Vars[key];
        }

        this.Trigger('var-' + key, value, this.Vars[key]);

        return this.Vars[key] = value;
    };
}

export default new Globals();
