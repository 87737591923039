import React, { useEffect } from "react";
import "./googletranslate.scss";
import IconButton from '../IconButton';
const GoogleTranslate = () => {

    const ToggleTranslation = () =>
    {
        var element = document.getElementById("lang");
        element.classList.toggle("active");
    }
    const ClearTranslation = () =>
    {
        var iframe = document.getElementsByClassName("skiptranslate")[1];
        var container = iframe.contentDocument || iframe.contentWindow.document;
        if (container) {
            var restoreButton = container.querySelector('#\\:1\\.restore');
            if (restoreButton) {
                restoreButton.click();
                ToggleTranslation();
            }
        }
    }

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "sv",
                includedLanguages: 'sv,en,da,no,fi,fr,de,es',
                autoDisplay: false
            },
            "google_translate_element"
        );
    };
    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);
    return (
        <>
            <IconButton
                className="lang-toggle"
                onClick={ToggleTranslation}
                src="Lang"
            />
            <div id="lang">
                <IconButton
                    className="lang-toggle"
                    onClick={ToggleTranslation}
                    src="X"
                />
                <div className="lang">
                    <div id="google_translate_element"></div>
                    <button
                        onClick={ClearTranslation}
                        className="clear-translate"
                    >Visa orginalspråk
                    </button>
                </div>
            </div>
        </>
    );
};
export default GoogleTranslate;