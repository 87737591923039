import React from "react";
import "./footer.scss";

import Parser from "Class/Parser";

import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class ModuleFooter extends React.Component
{
    render()
    {
        const {index, text} = this.props;

        return (
            <Segment backgroundColor="Black" className="Module ModuleFooter" top={!index}>
                <ThreeColumns>
                    {Parser.Parse(text, "div", null, {className: "SegmentText"})}
                </ThreeColumns>
            </Segment>
        );
    }
}

ModuleFooter.defaultProps =
{
    index: 0,
    text: ""
};

export default ModuleFooter;