import React from "react";
import "./locallogo.scss";

import MainLogo from "Components/UI/MainLogo";
import * as Logos from "Components/Layout/Logos";

class LocalLogo extends React.Component
{
    render()
    {
        const {className, heading, mainLogo, name, rotate, uri} = this.props;
        const CA = ["LocalLogo"];
        const Key = uri.replace(/[^a-z]/g, "");
        const Logo = Logos[Key];

        if (Logo)
        {
            CA.push("CustomLogo");
        }
        else if (rotate)
        {
            CA.push("Rotate");
        }
        if (className)
        {
            CA.push(className);
        }

        if (Logo)
        {
            return (
                <div className={CA.join(" ")}>
                    {React.createElement(Logo)}
                </div>
            );
        }

        const Heading = React.createElement(`h${heading}`, {}, name);

        return (
            <div className={CA.join(" ")}>
                {mainLogo ? <MainLogo link={false}/> : ""}
                {Heading}
            </div>
        );
    }
}

LocalLogo.defaultProps =
{
    className: "notranslate",
    heading: 3,
    mainLogo: true,
    name: "",
    rotate: false,
    uri: ""
};

export default LocalLogo;