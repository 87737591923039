
import Eatery from "Class/Eatery";
import Globals from "Class/Globals";

class Cision
{
    constructor()
    {
        this.ApiUrl = "https://publish.ne.cision.com/papi";
        this.News = false;
        this.Reports = false;
        this.Ticker = false;
    }

    GetNews = (callback, update, limit = 10, index = 1, filter = 0) =>
    {
        const Key = Globals.Setting("CisionNewsKey");

        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        if (typeof Key !== "string" || !Key)
        {
            console.error("Cision access key is missing.");
            callback(false);
        }
        else if (typeof this.News === "object" && !update)
        {
            callback(this.News);
        }
        else
        {
            const Params =
            {
                pageIndex: index,
                pageSize: limit
            };

            switch (parseInt(filter, 10))
            {
                case 1:
                    Params.regulatory = "true";
                    break;

                case 2:
                    Params.regulatory = "false";
                    break;

                default:
            }

            this.Request(["NewsFeed", Key, "Releases"], Params, response =>
            {
                callback(update ? response : this.News = response);
            });
        }
    }

    GetRelease = (id, callback, filter = 0) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }

        this.Request(["Release", id, "Detail"], {}, callback);
    }

    GetReports = (key, callback, update, limit = 10, index = 1) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }

        if (typeof key !== "string" || !key)
        {
            console.error("Cision access key is missing.");
            callback(false);
        }
        else if (typeof this.Reports === "object" && !update)
        {
            callback(this.Reports);
        }
        else
        {
            Eatery.Request("cision/reports", {key, limit, page: index}, response =>
            {
                callback(update ? response : this.Reports = response.data);
            });
        }
    }

    GetTicker = (key, callback, update) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }

        if (typeof key !== "string" || !key)
        {
            console.error( "Cision access key is missing." );
            callback(false);
        }
        else if (typeof this.Ticker === "object" && !update)
        {
            callback(this.Ticker);
        }
        else
        {

            this.Request(["Ticker", key, "Performance"], {}, response =>
            {
                callback(this.Ticker = response);
            });
        }
    }

    Request = (uri, params, callback) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }

        const ParamsArray = [];

        for (var param in params)
        {
            ParamsArray.push(`${param}=${params[param]}`);
        }

        const UriString = uri.join("/");
        const ParamsString = ParamsArray.length ? "?" + ParamsArray.join( "&" ) : "";
        const Url = [this.ApiUrl, UriString].join("/") + ParamsString;
        const Xhr = new XMLHttpRequest();

        Xhr.open("GET", Url, true);
        // On complete.
        Xhr.addEventListener("load", e =>
        {
            // Check for 200.
            if (Xhr.readyState !== XMLHttpRequest.DONE)
            {
                return;
            }
            // Check that the response is a proper JSON.
            if (Xhr.response.match(/^[{[]/))
            {
                callback(JSON.parse(Xhr.response));
            }
            else
            {
                console.error("Cision API response error.");
                callback(false);
            }
        }, false);
        // On fail.
        Xhr.addEventListener("error", e =>
        {
            console.error("Cision API error.");
            callback(false);
        }, false);

        Xhr.send();
    };
}

export default new Cision();