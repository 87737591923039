import React from "react";
import "./cardlogo.scss";

class CardLogo extends React.Component
{
    Svg = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="191.162" height="113.76" viewBox="0 0 191.162 113.76">
                <g transform="translate(-859.787 -323.74)">
                    <text transform="translate(965.949 426.5)" fontSize="36" fill="currentColor"><tspan x="0" y="0">kortet</tspan></text>
                    <g transform="translate(859.787 402.5)">
                        <path d="M1.1,2.8V7.2H3.228V21.724H1.1v4.451H21.284V18.617H15.517v3.107H10.142V16.265H16.5V11.814H10.142V7.2h5.375v3h5.767V2.8Z" transform="translate(-1.1 -2.8)" fill="currentColor"/>
                        <g transform="translate(21.752 1.568)">
                            <path d="M89.046,35.978v-1.26a2.1,2.1,0,0,0-1.988-.98c-1.344,0-1.932.728-1.932,1.764a1.634,1.634,0,0,0,1.764,1.568,2.5,2.5,0,0,0,2.156-1.092M79.5,25.48a15.006,15.006,0,0,1,7.307-2.38c4,0,9.07,1.512,9.07,6.887v6.523h2.016v4.283H90.726l-.812-1.512a5.666,5.666,0,0,1-4.507,2.156c-3.191,0-6.607-1.708-6.607-5.6,0-3.779,3.191-6.159,6.971-6.159a4.7,4.7,0,0,1,3.3,1.176V29.679c0-1.512-1.372-1.876-3.191-1.876a10.378,10.378,0,0,0-4.983,1.54Z" transform="translate(-78.8 -18.985)" fill="currentColor"/>
                            <path d="M144.528,13.467h1.82V10.388L152.507,8.4v5.067H155.7v4.339h-3.191v5.963c0,1.148-.224,2.687,1.064,2.687a7.853,7.853,0,0,0,2.1-.56V30.18a16.021,16.021,0,0,1-4.059.644c-5.011,0-5.179-2.743-5.291-7.027V17.834H144.5V13.467Z" transform="translate(-126.108 -8.4)" fill="currentColor"/>
                            <path d="M193.507,32.095h5.067c-.028-1.26-.336-2.687-2.464-2.687a2.656,2.656,0,0,0-2.6,2.687m10.862,7.7a12.113,12.113,0,0,1-8.622,2.911c-4.143,0-8.846-2.631-8.846-9.07,0-5.487,3.947-8.734,9.014-8.734,3.975,0,7.027,2.044,8.006,4.843a9.822,9.822,0,0,1,.056,5.655H193.423a3.091,3.091,0,0,0,3.443,2.743,6.851,6.851,0,0,0,4.675-1.6Z" transform="translate(-156.638 -20.281)" fill="currentColor"/>
                            <path d="M262.062,41.153H252.6V36.785h1.484V28.779h-1.428V24.412h6.3l1.008,2.156c.868-1.6,1.736-2.967,5.179-2.967v6.019c-3.023,0-4.731.784-4.731,3.723v3.443h1.68v4.367Z" transform="translate(-203.945 -19.345)" fill="currentColor"/>
                            <path d="M314.074,30.755h-1.54V26.5h8.511v4.143h-1.6l-5.795,14.165c-.98,2.072-2.379,4.759-7.251,4.731-1.736-.112-3.051-.812-4.927-2.3L304,43.3c1.428,1.12,2.1,1.624,2.8,1.764,1.036.2,1.82-.756,2.156-1.764l-.056-.112-6.523-12.374H301V26.5h10.3v4.255h-1.568l2.184,5.207Z" transform="translate(-238.796 -21.433)" fill="currentColor"/>
                        </g>
                    </g>
                    <g transform="matrix(0.966, -0.259, 0.259, 0.966, 921, 340.305)">
                        <path d="M45.057,6.9H10.843A6.786,6.786,0,0,0,4,13.743H4V36.284a6.786,6.786,0,0,0,6.843,6.843H45.258A6.786,6.786,0,0,0,52.1,36.284V13.743A6.959,6.959,0,0,0,45.057,6.9ZM10.843,9.919H45.258a3.959,3.959,0,0,1,3.824,3.824v2.214H7.019V13.743A3.821,3.821,0,0,1,10.843,9.919ZM45.057,40.108H10.843a3.959,3.959,0,0,1-3.824-3.824V21.994H49.082V36.284A4.144,4.144,0,0,1,45.057,40.108Z" transform="translate(4.05 6.987)"/>
                    </g>
                </g>
            </svg>
        );
    }

    render()
    {
        return (
            <div className="CardLogo">
                {this.Svg()}
            </div>
        );
    }
}

export default CardLogo;