import React from "react";
import "./delivery.scss";

import Eatery from "Class/Eatery";
import Links from "Components/UI/Links"

class Delivery extends React.Component
{
    render()
    {
        const Items = Eatery.Option("deliveryLinks", []);

        Items.forEach(link =>
        {
            link.type = "link";
        });

        return (
            <div className="Delivery">
                <h3>{Eatery.Option("deliveryTitle")}</h3>
                <Links columns={2} items={Items}/>
            </div>
        );
    }
}

export default Delivery;