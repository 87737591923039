import React from "react";
import "./contact.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import ItemStaff from "Components/UI/ItemStaff";
import Link from "Components/UI/Link";
import LocalLogo from "Components/Layout/LocalLogo";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplateContact extends React.Component
{
    render()
    {
        const {content, title} = this.props;
        const {about, contents} = content || {};
        const Eateries = Eatery.Eateries();
        const EateryItems = [];
        const Staff = Eatery.Staff();
        const StaffItems = [];

        for (let uri in Eateries)
        {
            const {content, title} = Eateries[uri];
            const {address, email, phone} = content || {};
            const Address = address.split("\n");
            const AddressItems = [];

            Address.forEach((row, index) => AddressItems.push(index ? <br key={index}/> : "", row))

            EateryItems.push(
                <div className="TemplateContactEateryItemWrapper" key={uri}>
                    <div className="TemplateContactEateryItem ColorBlack">
                        <LocalLogo
                            name={title}
                            uri={uri}
                        />
                        <div className="TemplateContactEateryItemRows">
                            {email ? <Link href={`mailto:${email}`}>{email}</Link> : ""}
                            {phone ? <Link href={`tel:${phone}`}>{phone}</Link> : ""}
                        </div>
                        <div className="TemplateContactEateryItemRows">
                            {AddressItems}
                        </div>
                    </div>
                </div>
            );
        };

        const StaffList = Object.values(Staff);
        StaffList.sort((a, b) =>
        {
            const AP = a.content.priority || 0;
            const BP = b.content.priority || 0;
            if ((AP === undefined && BP !== undefined) || AP < BP) return 1;
            if ((BP === undefined && AP !== undefined) || AP > BP) return -1;
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
        });

        /*for (let id in Staff)
        {
            StaffItems.push(<ItemStaff id={id} key={id}/>);
        }*/
        StaffList.forEach(member =>
        {
            StaffItems.push(<ItemStaff id={member.id} key={member.id}/>);
        });

        return (
            <div className="TemplateContact">
                <Segment top={true}>
                    <ThreeColumns>
                        <div className="TemplateContactTop">
                            <h5>{title}</h5>
                            {Parser.Parse(about, "div", null, {className: "TemplateContactText"})}
                        </div>
                    </ThreeColumns>
                </Segment>
                <Segment backgroundColor="beige">
                    <div className="TemplateContactStaff">
                        <h2>Några av oss som jobbar på Eatery</h2>
                        <div className="TemplateContactStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <div className="TemplateContactEateries">
                        <h2>Våra Eaterys</h2>
                        <div className="TemplateContactEateryItems">
                            {EateryItems}
                        </div>
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={3}/>
            </div>
        );
    }
}

TemplateContact.defaultProps =
{
    content: {}
};

export default TemplateContact;