import Eatery from 'Class/Eatery';
import Globals from 'Class/Globals';

import Collapsable from 'Components/Layout/Collapsable';
import Icon from 'Components/Layout/Icon';
import Item from 'Components/UI/Item';
import Link from 'Components/UI/Link';
import React from 'react';

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.AddMapPage = false;
        this.BlurOnUp = false;
        this.Container = false;
        this.Expanded = false;
        this.SplitEateries = 10;
        this.state = {
            expand: -1,
        };
    }

    componentDidMount() {
        Globals.Listen('navigation', this.OnNavigation);
    }

    componentWillUnmount() {
        Globals.Remove('navigation', this.OnNavigation);
    }

    CheckClick = () => {
        if (this.props.vertical) {
            return;
        }
        this.Collapse();
    };

    CheckFocus = (e) => {
        const Target = e.target;
        if (!this.Container ||
            !Target ||
            !Target.nodeName ||
            !this.Container.contains(Target)) {
            this.Collapse();
        }
    };

    Collapse = () => {
        if (!this.Expanded) {
            return;
        }
        this.Expanded = false;
        window.removeEventListener('click', this.CheckClick);
        window.removeEventListener('focusin', this.CheckFocus);
        this.setState({expand: -1});
    };

    Eateries = (levelInfo = {}) => {
        const Eateries = Eatery.EateriesSorted();
        const Items = [];
        if (!Eateries.length) {
            return Items;
        }
        const MapPage = Eatery.Option('mapPage');
        if (MapPage && this.AddMapPage) {
            const CA = ['NavigationItemLink'];
            const Label = Eatery.Option(
                'mapPageLabel',
                Eatery.GetTitle(MapPage),
            );
            if (Globals.Var('location') === MapPage) {
                CA.push('ActiveAncestor');
                levelInfo.activeAncestor = true;
            }
            Items.push(
                <div className="NavigationItem" key={MapPage}>
                    <Link className={CA.join(' ')} to={MapPage}>{Label}</Link>
                </div>,
            );
        } else if (MapPage && Globals.Var('location') === MapPage) {
            levelInfo.activeAncestor = true;
        }
        Eateries.forEach(eatery => {
            const {content, title, uri} = eatery;
            const {external} = content || {};
            const CA = ['NavigationItemLink'];
            if (Globals.Var('location') === uri) {
                CA.push('ActiveAncestor');
                levelInfo.activeAncestor = true;
            }
            Items.push(
                <div className="NavigationItem EateryItem notranslate" key={uri}>
                    {external ? (
                        <Link
                            className={CA.join(' ')}
                            href={external}
                        >{title}</Link>
                    ) : (
                        <Link className={CA.join(' ')} to={uri}>{title}</Link>
                    )}
                </div>,
            );
        });
        if (this.SplitEateries && Items.length > this.SplitEateries) {
            const NumColumns = Math.ceil(Items.length / this.SplitEateries);
            const PerColumn = Math.floor(Items.length / NumColumns);
            const Rest = Items.length - PerColumn * NumColumns;
            const Columns = [];
            let Index = 0;
            for (let i = 0; i < NumColumns; i++) {
                const Column = [];
                const Limit = i < Rest ? PerColumn + 1 : PerColumn;
                for (let j = 0; j < Limit; j++) {
                    Column.push(Items[Index++]);
                }
                Columns.push(<div
                    className="NavigationColumn"
                    key={i}
                >{Column}</div>);
            }
            return Columns;
        }
        return Items;
    };

    Expand = (expand) => {
        if (!this.Expanded) {
            this.Expanded = true;
            window.addEventListener('click', this.CheckClick);
            window.addEventListener('focusin', this.CheckFocus);
        }
        this.setState({expand});
    };

    OnFocus = (e, index) => {
        this.Expand(index);
    };

    OnFocusLink = (e, index, levelIndex) => {
        if (!levelIndex) {
            this.Collapse();
        }
    };

    OnMouseDown = (e, index) => {
        this.BlurOnUp = index === this.state.expand;
    };

    OnMouseUp = () => {
        if (this.BlurOnUp && document.activeElement) {
            document.activeElement.blur();
            this.Collapse();
        }
    };

    OnNavigation = () => {
        this.Collapse();
        this.forceUpdate();
    };

    ParseNavigationItem = (item, ref, levelIndex, levelInfo = {}) => {
        const {
            collapsables,
            hideCollapsed,
            iconCollapse,
            iconExpand,
        } = this.props;
        const {expand} = this.state;
        const {action, label, page, subPages, type, url} = item;
        const CA1 = ['NavigationItem'];
        const CA2 = ['NavigationItemLink'];
        const Label = label || Eatery.GetTitle(page);
        const Content = [
            <div
                className="NavigationItemLinkLabel"
                key="label"
            >{Label}</div>,
        ];

        const Index = ref.index;
        const Expand = Index === expand;
        const Location = Globals.Var('location');
        const SubLevelInfo = {};
        const Template = Eatery.GetTemplate(page);
        let Children, ItemContent, SubMenu = '';
        if (Expand) {
            CA1.push('Expand');
        }

        switch (type) {
            case 'eateries':
                Children = this.Eateries(SubLevelInfo);
                break;
            case 'tab':
                Children =
                    this.ParseNavigationLevel(
                        subPages,
                        ref,
                        levelIndex + 1,
                        SubLevelInfo,
                    );
                break;

            default:
        }

        if (
            Location === page ||
            SubLevelInfo.activeAncestor ||
            (
                Template === 'conference' &&
                Location.match(/^\/motesrum/)
            )
        ) {
            CA2.push('ActiveAncestor');
            levelInfo.activeAncestor = true;
        } else if (levelInfo.activeAncestor === undefined) {
            levelInfo.activeAncestor = false;
        }

        if (Children && Children.length) {
            CA1.push('HasChildren');

            const Style1 = {
                animationDuration: Globals.Duration /
                    2 +
                    Children.length *
                    Globals.DurationPerItem +
                    's',
            };

            const Style2 = {
                transitionDuration: Globals.Duration /
                    2 +
                    Children.length *
                    Globals.DurationPerItem +
                    's',
            };

            Content.push(<Icon
                key="icon"
                src={Expand ? iconCollapse : iconExpand}
                style={Style2}
            />);

            SubMenu = (collapsables || Expand || !hideCollapsed) && (
                collapsables ? (
                    <Collapsable
                        className="NavigationChildrenWrapper"
                        collapsed={!Expand}
                    >
                        <div className="NavigationChildren" style={Style1}>
                            <div
                                className="NavigationChildrenItems"
                                style={Style1}
                            >
                                {Children}
                            </div>
                        </div>
                    </Collapsable>
                ) : (
                    <div className="NavigationChildrenWrapper">
                        <div className="NavigationChildren" style={Style1}>
                            <div
                                className="NavigationChildrenItems"
                                style={Style1}
                            >
                                {Children}
                            </div>
                        </div>
                    </div>
                )
            );
        }

        switch (type) {
            case 'action':
                ItemContent = (
                    <Item
                        className={CA2.join(' ')}
                        onClick={() => Eatery.Action(action)}
                        onFocus={e => this.OnFocus(e, Index)}
                    >{Content}</Item>
                );
                break;
            case 'link':
                ItemContent = (
                    <Link
                        className={CA2.join(' ')}
                        onFocus={e => this.OnFocusLink(e, Index, levelIndex)}
                        href={url}
                    >{Content}</Link>
                );
                break;
            case 'page':
                ItemContent = (
                    <Link
                        className={CA2.join(' ')}
                        onFocus={e => this.OnFocusLink(e, Index, levelIndex)}
                        to={page}
                    >{Content}</Link>
                );
                break;
            default:
                ItemContent = (
                    <Item
                        className={CA2.join(' ')}
                        onFocus={e => this.OnFocus(e, Index)}
                        onMouseDown={e => this.OnMouseDown(e, Index)}
                        onMouseUp={e => this.OnMouseUp(e, Index)}
                    >{Content}</Item>
                );
        }
        return (
            <div
                className={CA1.join(' ')}
                key={Index}
                onClick={e => e.stopPropagation()}
            >
                {ItemContent}
                {SubMenu}
            </div>
        );
    };

    ParseNavigationLevel = (level, ref, levelIndex = 0, levelInfo) => {
        const Items = [];

        if (!level || !Array.isArray(level)) {
            return Items;
        }

        if (!ref) {
            ref = {index: 0};
        }

        level.forEach(item => {
            ref.index++;
            Items.push(this.ParseNavigationItem(
                item,
                ref,
                levelIndex,
                levelInfo,
            ));
        });

        return Items;
    };

    render() {
        const {className, items} = this.props;

        const CA = ['Navigation'];
        if (className) {
            CA.push(className);
        }

        const Items = this.ParseNavigationLevel(items);

        return (
            <div
                className={CA.join(' ')}
                ref={container => this.Container = container}
            >
                <div className="NavigationItems">
                    {Items}
                </div>
            </div>
        );
    }
}

Navigation.defaultProps = {
    className: '',
    collapsables: false,
    hideCollapsed: true,
    iconCollapse: 'Minus',
    iconExpand: 'Plus',
    items: [],
};

export default Navigation;
