import React from "react";
import ModuleAltTop from "./AltTop";
import ModuleGallery from "./Gallery";
import ModuleInstagram from "./Instagram";
import ModuleFooter from "./Footer";
import ModuleLinks from "./Links";
import ModuleNews from "./News";
import ModuleText from "./Text";
import ModuleTop from "./Top";

class Modules extends React.Component
{
    render()
    {
        const {modules, startIndex} = this.props;
        const Modules = [];

        if (modules)
        {
            modules.forEach((module, index) =>
            {
                const Index = startIndex + index;

                switch (module.moduleType)
                {
                    case "gallery":
                        Modules.push(<ModuleGallery {...module} index={Index} key={Index}/>);
                        break;

                    case "instagram":
                        Modules.push(<ModuleInstagram {...module} index={Index} key={Index}/>);
                        break;

                    case "footerless":
                        Modules.push(<ModuleFooter {...module} index={Index} key={Index}/>);
                        break;

                    case "headless":
                        Modules.push(<ModuleAltTop {...module} index={Index} key={Index}/>);
                        break;

                    case "links":
                        Modules.push(<ModuleLinks {...module} index={Index} key={Index}/>);
                        break;

                    case "news":
                        Modules.push(<ModuleNews {...module} index={Index} key={Index}/>);
                        break;

                    case "top":
                        Modules.push(<ModuleTop {...module} index={Index} key={Index}/>);
                        break;

                    default:
                        Modules.push(<ModuleText {...module} index={Index} key={Index}/>);
                }
            });
        }

        return Modules;
    }
}

Modules.defaultProps =
{
    modules: [],
    startIndex: 0
}

export default Modules;