import React from "react";
import "./post.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import LoadImage from "Components/Layout/LoadImage";
import Modules from "Components/View/Modules";
import ModuleTop from "Components/View/Modules/Top";
import Segment from "Components/Layout/Segment";

class TemplatePost extends React.Component
{
    render()
    {
        const {content, title} = this.props;
        const {
            altTitle,
            content: postContent,
            contents,
            image,
            imagePositionH,
            imagePositionV,
            postButtons,
            postButtonsPosition,
            preTitle,
            topImage,
            topImageHeading,
            topImagePositionH,
            topImagePositionV
        } = content || {};
        const Buttons = [];
        if (Array.isArray(postButtons))
        {
            postButtons.forEach((button, index) =>
            {
                const {action, color, hollow, label, page, type, url} = button;
                switch (type)
                {
                    case "action":
                        Buttons.push(
                            <Button
                                color={color}
                                hollow={hollow}
                                key={index}
                                label={label}
                                onClick={() => Eatery.Action(action)}
                            />
                        );
                        break;
                    case "link":
                        Buttons.push(
                            <Button
                                color={color}
                                hollow={hollow}
                                href={url}
                                key={index}
                                label={label}
                            />
                        );
                        break;
                    default:
                        Buttons.push(
                            <Button
                                color={color}
                                hollow={hollow}
                                key={index}
                                label={label || Eatery.GetTitle(page)}
                                to={page}
                            />
                        );
                }
            });
        }
        return (
            <div className="TemplatePost">
                {topImage ? <ModuleTop
                    backgroundImage={topImage}
                    backgroundPositionH={topImagePositionH}
                    backgroundPositionV={topImagePositionV}
                    title={topImageHeading}
                /> : ""}
                <Segment backgroundColor="Beige" top={!topImage}>
                    <div className={image ? "TemplatePostWrapperTop" : "TemplatePostWrapper"}>
                        {(postButtonsPosition === "before" && Buttons.length) ? <div className="SegmentButtons Buttons ButtonsBefore">{Buttons}</div> : ""}
                        {preTitle ? <h2>{preTitle}</h2> : ""}
                        {Parser.Parse(altTitle || title, "h1", null, {}, "h1")}
                        {Parser.Parse(postContent, "div", null, {className: "TemplatePostContent"})}
                        {(postButtonsPosition !== "before" && Buttons.length) ? <div className="SegmentButtons Buttons ButtonsAfter">{Buttons}</div> : ""}
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
                {image ? (
                    <Segment backgroundColor="Beige">
                        <div className="TemplatePostWrapperBottom">
                            <div className="TemplatePostImageWrapper">
                                    <div className="TemplatePostImageContainer">
                                    <LoadImage
                                        className="TemplatePostImage"
                                        src={image}
                                        style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}
                                    />
                                </div>
                            </div>
                        </div>
                    </Segment>
                ) : ""}
            </div>
        );
    }
}

TemplatePost.defaultProps =
{
    content: {}
};

export default TemplatePost;