import React from "react";
import "./openstatus.scss";

import Eatery from "Class/Eatery";
import Icon from "Components/Layout/Icon";

class OpenStatus extends React.Component
{
    constructor(props)
    {
        super(props);

        this.UpdateInterval = false;
        this.WeekDays = {
            mon: 0,
            tue: 1,
            wed: 2,
            thu: 3,
            fri: 4,
            sat: 5,
            sun: 6
        };
    }

    componentDidMount()
    {
        this.UpdateInterval = setInterval(() =>
        {
            this.forceUpdate();
        }, 15000);
    }

    componentWillUnmount()
    {
        clearInterval(this.UpdateInterval);
    }

    render()
    {
        const {className, days, deviations, hideClosed} = this.props;
        const CA = ["OpenStatus"];
        if (className)
        {
            CA.push(className);
        }
        const Now = Eatery.Now();
        const [DateNow, TimeNow] = Now.split(" ");
        let Day;
        if (deviations)
        {
            deviations.forEach(day =>
            {
                if (Day)
                {
                    return;
                }
                const {date, endDate} = day;
                if (date === DateNow || (endDate && DateNow > date && DateNow <= endDate))
                {
                    Day = day;
                }
            });
        }
        if (!Day)
        {
            const WeekDayNow = (new Date(Now.replace(" ", "T"))).getDay() - 1;
            days.forEach(day =>
            {
                if (Day)
                {
                    return;
                }
                const {day: weekDay} = day;
                const WeekDay = this.WeekDays[weekDay];
                if (WeekDay === WeekDayNow)
                {
                    Day = day;
                }
            });
        }
        if (!Day)
        {
            return "";
        }
        const {closed, closes, opens} = Day;
        let Status = 0;
        let Label = "Stängt";
        if (!closed)
        {
            const [H1, M1] = TimeNow.split(":");
            const [H2, M2] = opens.split(":");
            const [H3, M3] = closes.split(":");
            const T1 = parseInt(H1, 10) * 60 + parseInt(M1, 10);
            const T2 = parseInt(H2, 10) * 60 + parseInt(M2, 10);
            const T3 = parseInt(H3, 10) * 60 + parseInt(M3, 10);
            const T4 = T2 - T1;
            if (T1 >= T2 && (T3 - T1 > 60))
            {
                Status = 3;
                Label = "Öppet nu";
            }
            else if (T1 >= T2 && T1 < T3)
            {
                Status = 2;
                Label = "Stänger snart";
            }
            else if (T4 > 0 && T4 < 60)
            {
                Status = 1;
                Label = "Öppnar snart";
            }
        }
        if (Status === 0 && hideClosed)
        {
            return "";
        }
        CA.push(`Status${Status}`);
        return (
            <div className={CA.join(" ")}>
                <Icon src="Clock"/>
                <span>{Label}</span>
            </div>
        );
    }
}

OpenStatus.defaultProps =
{
    className: "",
    days: [],
    deviations: [],
    hideClosed: true
};

export default OpenStatus;