
/*!
 *  Select form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop object|array options - Field options.
 *  @prop string placeholder - Placeholder when unselected.
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string|number value - Field value.
 */

import React from "react";
import "./selectfield.scss";

import Globals from "Class/Globals";
import {ObjectCompare, UcFirst} from "Functions";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";

class SelectField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Input = false;

        this.state =
        {
            expand: false,
            focus: false,
            value: -1
        };
    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        const Value = this.ParseValue(value);
        this.setState({value: Value});
    }

    /**
     * Update value.
     * 
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        const {value: v3} = this.state;

        if (!ObjectCompare(v1, v2) && !ObjectCompare(v1, v3))
        {
            const Value = this.ParseValue(v1);
            this.setState({value: Value})
        }
    }

    Block = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Get the first option.
     * 
     * @return mixed - First option.
     */

    FirstOption = () =>
    {
        const {options} = this.props;
        const Key = this.FirstKey();

        if (typeof options === "number")
        {
            return Key;
        }

        return Key !== false ? options[Key] : "";
    }

    /**
     * Get the first key
     * 
     * @return mixed - First key.
     */

    FirstKey = () =>
    {
        const {options} = this.props;
        
        if (typeof options === "object")
        {
            const Keys = Object.keys(options);
            return Keys[0] || false;
        }
        if (typeof options === "number")
        {
            return 0;
        }

        return false;
    }

    /**
     * Set focus on this field.
     * 
     * @return void
     */

    Focus = (e) =>
    {
        if (this.Input)
        {
            this.Input.focus();
        }
    }

    /**
     * Render a option item.
     * 
     * @param mixed option - Option label.
     * @param mixed key - Option key.
     * 
     * @return JSX - Rendered item.
     */

    Item = (option, key, style) =>
    {
        const {multiple, onRenderItem} = this.props;
        const {value} = this.state;
        const CA = ["SelectFieldOption"]
        const Selected = multiple ? (Array.isArray(value) && value.indexOf(key) >= 0) : key === value;

        if (Selected)
        {
            CA.push("Selected");
        }
        
        return (
            <div
                className={CA.join(" ")}
                key={key}
                onClick={e => this.OnSelect(e, key)}
                style={style}
            >{onRenderItem(option, key, Selected) || option}</div>
        );
    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) =>
    {
        const {onBlurCollapse} = this.props;

        // Allow collapse to be delayed or halted via a callback.
        if (typeof onBlurCollapse === "function")
        {
            onBlurCollapse(this.OnBlurCollapse);
        }
        else
        {
            this.OnBlurCollapse();
        }
    }

    OnBlurCollapse = () =>
    {
        const {id, onBlur} = this.props;
        const {value} = this.state;
        onBlur(null, value, id);
        this.setState({expand: false, focus: false});
    }

    /**
     * Toggle expand/collapse when the input is clicked.
     * 
     * @return void
     */

    OnClick = (e) =>
    {
        const {expand} = this.state;

        if (!expand)
        {
            this.setState({expand: true});
        }
        else
        {
            this.OnBlurCollapse();
        }
    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const {value} = this.state;

        onFocus(e, value, id);
        this.setState({focus: true});
    }

    /**
     * Callback for when the user selects a new option.
     * 
     * @param object e - The event object.
     * @param mixed key - Option key.
     * 
     * @return void
     */

    OnSelect = (e, key) =>
    {
        const {
            disabled,
            closeOnSelect,
            id,
            multiple,
            onChange,
            options
        } = this.props;
        const {value: currentValue} = this.state;
        const Keys = typeof options === "object" ? Object.keys(options) : false;

        if (disabled)
        {
            return;
        }

        if (multiple)
        {
            let Value = currentValue;

            if (!Array.isArray(Value))
            {
                Value = (Value && Value !== -1) ? [Value] : [];
            }

            const Index = Value.indexOf(key);

            if (Index < 0)
            {
                Value.push(key);
                Value.sort((a, b) =>
                {
                    const I1 = Keys ? Keys.indexOf(a) : a;
                    const I2 = Keys ? Keys.indexOf(b) : b;

                    if (I1 > I2) return 1;
                    if (I1 < I2) return -1;
                    return 0;
                });
            }
            else
            {
                Value.splice(Index, 1);
            }

            onChange(e, Value, id);
            this.setState({value: Value});
        }
        else
        {
            const Value = this.ParseValue(key);
            onChange(e, Value, id);
            this.setState({
                expand: !closeOnSelect,
                value: Value
            });
        }
    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) =>
    {
        const {id, multiple, onChange, options} = this.props;
        const {expand, value} = this.state;
        let Step = 0;

        switch (e.which)
        {
            case 13:
                this.setState({expand: false});
                break;

            case 32:
                this.setState({expand: !expand});
                break;

            case 37:
            case 38:
                Step = -1;
                break;

            case 39:
            case 40:
                Step = 1;
                break;
            
            default:
                return;
        }

        e.stopPropagation();
        e.preventDefault();

        if (Step && multiple)
        {

        }
        else
        {
            let Value = value;
            
            if (typeof options === "object")
            {
                const Keys = Object.keys(options);
                const Index = Math.max(Math.min(Keys.indexOf(value) + Step, Keys.length - 1), -1);
                Value = Keys[Index] || false;
            }
            else if (typeof options === "number")
            {
                Value = Math.max(Math.min(options - 1, value + Step), 0);
            }
            if (Value !== value)
            {
                onChange(e, Value, id);
                this.setState({value: Value});
            }
        }
    }

    /**
     * Check if the option keys is valid.
     * 
     * @param mixed value - Value/Input key.
     * 
     * @return mixed - Output key.
     */

    ParseValue = (value) =>
    {
        const {multiple, options, placeholder} = this.props;

        if (multiple)
        {
            if (!Array.isArray(value))
            {
                const Set = [];

                if (typeof options === "number" || (typeof options === "object" && options[value] !== undefined))
                {
                    Set.push(value)
                }

                return Set;
            }

            return value;
        }

        if  (typeof options === "number")
        {
            return value
        }
        if (typeof options !== "object")
        {
            return false;
        }
        if (options[value] !== undefined)
        {
            return value;
        }

        return placeholder ? false : this.FirstKey();
    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () =>
    {
        const Value = this.ParseValue(this.props.value);
        this.setState({value: Value});
    }

    /**
     * Remove a selected item.
     * 
     * @param object e - Event object
     * @param string key - Input key.
     * 
     * @return void
     */

    UnSelect = (e, key) =>
    {
        e.stopPropagation();
        e.preventDefault();

        const {id, onChange} = this.props;
        const {value} = this.state;
        const Index = value.indexOf(key);

        if (Index >= 0)
        {
            value.splice(Index, 1);
        }

        this.setState({value});
        onChange(e, value, id);
    }

    /**
     * Get the field value.
     * 
     * @return string - The field value.
     */

    Value = () =>
    {
        return this.ParseValue(this.state.value);
    }

    Selected = () =>
    {
        const {
            multiple,
            onRenderValue,
            options,
            placeholder
        } = this.props;
        const {value} = this.state;

        if (multiple)
        {
            if (!Array.isArray(value) || !value.length)
            {
                return placeholder || "Lägg till";
            }

            const Items = [];

            value.forEach(key =>
            {
                const Label = onRenderValue(key) || options[key] || key;
                Items.push(
                    <div className="SelectFieldSelectedItem" key={key}>
                        {Label}
                        <IconButton id={key} onClick={this.UnSelect} src="X"/>
                    </div>
                );
            })

            return (
                <div className="SelectFieldSelectedItems">
                    {Items}
                </div>
            );
        }
        
        return onRenderValue(value) || (value === false ? placeholder : options[value]) || this.FirstOption() || placeholder;
    }

    render()
    {
        const {
            className,
            color,
            disabled,
            error,
            hollow,
            label,
            minimal,
            multiple,
            options,
            placeholder,
            placeholderOption
        } = this.props;
        const {
            expand,
            focus
        } = this.state;
        const CA = ["Field", "SelectField"];
        const Color = UcFirst(color);
        const Selected = this.Selected();
        const NumItems = typeof options === "object" ? Object.keys(options).length : options || 0;
        const Style1 = {animationDuration: Globals.Duration / 2 + NumItems * Globals.DurationPerItem + "s"};
        const Style2 = {transitionDuration: Globals.Duration / 2 + NumItems * Globals.DurationPerItem + "s"};
        const Disabled = disabled || !NumItems;
        let Options = "";

        if (Disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (expand && typeof options === "object")
        {
            const Items = [];

            if (!multiple && placeholder && placeholderOption)
            {
                Items.push(this.Item(placeholder, false, Style1));
            }
            for (let key in options)
            {
                Items.push(this.Item(options[key], key, Style1));
            }

            Options = (
                <div className="SelectFieldOptionsWrapper" onClick={this.OnClick}>
                    <div className="SelectFieldOptions" style={Style1}>
                        {Items}
                    </div>
                </div>
            );
            
            CA.push("Expand");
        }
        else if (expand && typeof options === "number")
        {
            const Items = [];

            if (!multiple && placeholder && placeholderOption)
            {
                Items.push(this.Item(placeholder, false, Style1));
            }
            for (let i = 0; i < options; i++)
            {
                Items.push(this.Item(i, i, Style1));
            }

            Options = (
                <div className="SelectFieldOptionsWrapper" onClick={this.OnClick}>
                    <div
                        className="SelectFieldOptions"
                        onClick={this.Block}
                        onMouseDown={this.Block}
                        onMouseUp={this.Block}
                        onScroll={this.Block}
                        style={Style1}
                    >
                        {Items}
                    </div>
                </div>
            );
            
            CA.push("Expand");
        }

        if (focus)
        {
            CA.push("Focus");
        }
        if (minimal)
        {
            CA.push("Minimal");
        }
        if (multiple)
        {
            CA.push("Mutiple");
        }
        if (Selected)
        {
            CA.push("HasValue");
        }
        if (className)
        {
            CA.push(className);
        }

        return minimal ? (
            <div
                className={CA.join(" ")}
                onBlur={this.OnBlur}
                onClick={this.OnClick}
                onFocus={this.OnFocus}
                onKeyDown={this.OnKeyDown}
                ref={input => this.Input = input}
                tabIndex={Disabled ? "false" : "0"}
            >
                <div className="MinimalLabel">{label}</div>
                {Options}
            </div>
        ) : (
            <div
                className={CA.join(" ")}
                onBlur={this.OnBlur}
                onFocus={this.OnFocus}
                onKeyDown={this.OnKeyDown}
                ref={input => this.Input = input}
                tabIndex={Disabled ? "false" : "0"}
            >
                {label ? <label onClick={this.Focus}>{label}</label> : ""}
                <div
                    className={hollow ? "Input Hollow" : `Input Color${Color}`}
                    onClick={this.OnClick}
                >
                    {Selected}
                    <Icon className="FieldIcon" src="ChevronDownSmall" style={Style2}/>
                </div>
                {Options}
            </div>
        );
    }
}

SelectField.defaultProps =
{
    className: "",
    closeOnSelect: true,
    color: "black",
    disabled: false,
    error: false,
    hollow: false,
    icon: "",
    id: "",
    label: "",
    minimal: false,
    multiple: false,
    onAdjust: () => {},
    onBlur: () => {},
    onBlurCollapse: false,
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    onRenderItem: () => {},
    onRenderValue: () => {},
    placeholder: "",
    placeholderOption: true,
    return: "text",
    token: "",
    type: "text",
    value: -1
};

export default SelectField;