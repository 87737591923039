import React from "react";
import "./repeatingfields.scss";

import {ObjectCompare} from "Functions";
import DotNavigation from "Components/UI/DotNavigation";
import Form from "Components/UI/Form";

class RepeatingFields extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            focus: -1,
            offset: 0,
            value: []
        }
    }

    componentDidMount()
    {
        const {numRepeats, value} = this.props;
        const Value = this.ParseValue(value, numRepeats);
        this.setState({value: Value});
        this.SyncValue(value, Value);
    }

    componentDidUpdate(prevProps)
    {
        const {numRepeats: r1, value: v1} = this.props;
        const {numRepeats: r2, value: v2} = prevProps;
        const {value: v3} = this.state;

        if ((!ObjectCompare(v1, v2) && !ObjectCompare(v1, v3)) || r1 !== r2)
        {
            const Value = this.ParseValue(v1, r1);

            this.setState({offset: 0, value: Value});
            this.SyncValue(v1, Value);
        }
    }

    Item = (index) =>
    {
        const {
            disabled,
            fields,
            id,
            label,
            numRepeats,
            onRenderTag,
            onValue
        } = this.props;
        const {focus, value} = this.state;
        const CA = ["RepeatingFieldsItem"];
        const Tag = onRenderTag(index, id);

        if (index === focus)
        {
            CA.push("Focus");
        }

        return (
            <div className={CA.join(" ")} key={index} style={{zIndex: numRepeats - index}}>
                {label ? (
                    <label>
                        {label}
                        {Tag ? <div className="LabelTag">{Tag}</div> : ""}
                    </label>
                ) : ""}
                <Form
                    disabled={disabled}
                    id={index}
                    className="RepeatingFieldsItemForm"
                    fields={fields}
                    onBlur={this.OnBlur}
                    onChange={this.OnChange}
                    onFocus={this.OnFocus}
                    onValue={key => onValue(key, id)}
                    values={value[index]}
                />
            </div>
        );
    }

    OnBlur = () =>
    {
        
    }

    OnChange = (e, value, key, index) =>
    {
        const {id, onChange} = this.props;
        const {value: currentValue} = this.state;
        const NewValue = Object.assign([], currentValue);

        if (!NewValue[index] || typeof NewValue[index] !== "object")
        {
            NewValue[index] = {};
        }

        NewValue[index][key] = value;
        onChange(e, NewValue, id);
    }

    OnFocus = (e, id) =>
    {
        this.setState({focus: id});
    }

    OnNavigation = (e, index) =>
    {
        this.setState({offset: index * this.props.perPage})
    }

    ParseValue = (value, numRepeats) =>
    {
        const {fields} = this.props;
        const Value = [];

        for (let i = 0; i < numRepeats; i++)
        {
            if (value && value[i] !== undefined)
            {
                Value[i] = value[i];
            }
            else
            {
                Value[i] = {};

                for (let key in fields)
                {
                    Value[i][key] = fields[key].value || "";
                }
            } 
        }

        return Value;
    }

    SyncValue = (unparsed, parsed) =>
    {
        const {id, onChange} = this.props;

        if (ObjectCompare(unparsed, parsed))
        {
            return;
        }

        onChange(null, parsed, id);
    }

    render()
    {
        const {className, disabled, maxDots, numRepeats, perPage} = this.props;
        const {offset} = this.state;
        const CA = ["Field", "RepeatingFields"];
        const Items = [];
        const Pages = Math.ceil(numRepeats / perPage);
        const Page = offset / perPage;
        const Navigation = Pages > 1 ? <DotNavigation
            active={Page}
            disabled={disabled === true}
            dots={Pages}
            onClick={this.OnNavigation}
            showArrows={true}
            showDots={Pages <= maxDots}
        /> : "";

        if (className)
        {
            CA.push(className);
        }

        for (let n = 0, i = offset; n < perPage && i < numRepeats; n++, i++)
        {
            Items.push(this.Item(i));
        }

        return (
            <div className={CA.join(" ")}>
                <div className="RepeatingFieldsItems">
                    {Items}
                </div>
                {Navigation}
            </div>
        );
    }
}

RepeatingFields.defaultProps =
{
    className: "",
    disabled: false,
    fields: {},
    id: "",
    label: "",
    maxDots: 7,
    numRepeats: 0,
    onChange: () => {},
    onRenderTag: () => {},
    onValue: () => {},
    perPage: 5,
    value: []
};

export default RepeatingFields;