import React from "react";
import "./activities.scss";

import Eatery from "Class/Eatery";
import Globals from "Class/Globals";
import Parser from "Class/Parser";

import Modules from "Components/View/Modules";
import ProductItem from "Components/UI/ProductItem";
import Segment from "Components/Layout/Segment";

class TemplateActivities extends React.Component
{
    OnActivityInfo = (e, id) =>
    {
        Globals.DialogActivity(id);
    }

    render()
    {
        const {content, title} = this.props;
        const {about, contents} = content || {};
        const Activities = Eatery.ActivityList();
        const Items = [];

        Activities.forEach(activity =>
        {
            const {content, id, title} = activity;
            const {about: aboutItem, price} = content;

            Items.push(<ProductItem
                {...content}
                buttons={aboutItem ? [
                    {
                        hollow: true,
                        id,
                        label: "Läs mer",
                        onClick: this.OnActivityInfo
                    }
                ] : []}
                className="TemplateActivitiesItem"
                key={id}
            >
                <h4>{title}</h4>
                <div className="TemplateBookingExtraItemPrice">
                    {price ? `Från ${price} kr` : "Pris på förfrågan"}
                </div>
            </ProductItem>);
        });

        return (
            <div className="TemplateActivities">
                <Segment backgroundColor="Beige" top={true}>
                    <div className="TemplateActivitiesTop">
                        <h3>Konferens & fest</h3>
                        <h1>{title}</h1>
                        {Parser.Parse(about, "div", null, {className: "TemplateActivitiesAbout"})}
                    </div>
                    <div className="TemplateActivitiesWrapper">
                        <div className="TemplateActivitiesItems">
                            {Items}
                        </div>
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
            </div>
        );
    }
}

TemplateActivities.defaultProps =
{
    content: {}
};

export default TemplateActivities;