import React from "react";
import "./loadvideo.scss";

class LoadVideo extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Player = false;
        this.state =
        {
            playing: false
        }
    }

    OnPause = (e) =>
    {
        const {onPause, src} = this.props;
        this.setState({playing: false});
        onPause(e, src);
    }

    OnPlay = (e) =>
    {
        const {onPlay, src} = this.props;
        this.setState({playing: true});
        onPlay(e, src);
    }

    render()
    {
        const {
            autoplay,
            className,
            loop,
            muted,
            poster,
            src,
            type
        } = this.props;
        const {playing} = this.state;
        const CA = ["LoadVideo"];

        if (playing)
        {
            CA.push("Playing");
        }

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                <video
                    autoPlay={autoplay}
                    className="LoadVideoElement"
                    inline="inline"
                    loop={loop}
                    muted={muted}
                    onPause={this.OnPause}
                    onPlay={this.OnPlay}
                    playsInline="playsInline"
                    poster={poster}
                    ref={player => this.Player = player}
                    webkit-playsinline="true"
                >
                    <source src={src} type={type}/>
                </video>
            </div>
        );
    }
}

LoadVideo.defaultProps =
{
    autoplay: false,
    className: "",
    loop: false,
    muted: true,
    onPause: () => {},
    onPlay: () => {},
    poster: "",
    src: "",
    type: "video/mp4"
};

export default LoadVideo;