import React from "react";
import "./itemstaff.scss";

import Eatery from "Class/Eatery";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";

class ItemStaff extends React.Component
{
    render()
    {
        const {className, id} = this.props;
        const Person = Eatery.Staff(id);
        if (!Person)
        {
            return "";
        }
        const CA = ["ItemStaff"];
        const {content, title} = Person;
        const {
            email,
            image,
            imagePositionH,
            imagePositionV,
            phone,
            role
        } = content || {};
        if (className)
        {
            CA.push(className);
        }
        const PhoneFormatted = phone.replace("+", "00").replace(/[^\d]/g, "");
        return (
            <div className={CA.join(" ")}>
                <div className="ItemStaffWrapper">
                    {0 ? <div className="ItemStaffImage">
                        <LoadImage
                            src={image}
                            style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}
                        />
                    </div> : ""}
                    <div className="ItemStaffContent">
                        <h3>{title}</h3>
                        <h5>{role}</h5>
                        {phone ? <Link href={`tel:${PhoneFormatted}`}>{phone}</Link> : ""}
                        {email ? <Link href={`mailto:${email}`}>{email}</Link> : ""}
                    </div>
                </div>
            </div>
        );
    }
}

ItemStaff.defaultProps =
{
    className: "",
    id: 0
};

export default ItemStaff;