import Eatery from 'Class/Eatery';

import Icon from 'Components/Layout/Icon';
import Item from 'Components/UI/Item';
import Link from 'Components/UI/Link';
import { UcFirst } from 'Functions';
import React from 'react';
import './links.scss';

class Links extends React.Component {
    Item = (item, index) => {
        const {color} = this.props;

        const {action, label, message, page, to, type, url} = item;
        const CA = ['LinksItemContent', `Color${UcFirst(color)}`];
        const Label = label || Eatery.GetTitle(page);

        if (message) {
            CA.push('Invert');
        }

        const Content = (
            <div className={CA.join(' ')}>
                <div className="LinksItemLabel">{Label}</div>
                {message ? <div className="LinksItemMessage">{message}</div> :
                    <Icon src="Arrow" />}
            </div>
        );

        switch (type) {
            case 'action':
                return (
                    <Item
                        className="LinksItem"
                        key={index}
                        onClick={() => Eatery.Action(action)}
                    >{Content}</Item>
                );
            case 'link':
                return (
                    <Link
                        className="LinksItem"
                        href={url}
                        key={index}
                    >{Content}</Link>
                );
            default:
                return (
                    <Link
                        className="LinksItem"
                        key={index}
                        to={page || to}
                    >{Content}</Link>
                );
        }
    };

    render() {
        const {className, doubleColumn, items, vertical} = this.props;

        const CA = ['Links'];

        if (doubleColumn) {
            CA.push('DoubleColumn');
        } else {
            CA.push('SingleColumn');
        }

        if (vertical) {
            CA.push('Vertical');
        } else {
            CA.push('Horizontal');
        }

        if (className) {
            CA.push(className);
        }

        const Items = [];
        items.forEach((item, index) => Items.push(this.Item(item, index)));

        return (
            <div className={CA.join(' ')}>
                <div className="LinksItems">
                    {Items}
                </div>
            </div>
        );
    }
}

Links.defaultProps =
    {
        className: '',
        color: 'Black',
        doubleColumn: false,
        items: [],
        vertical: false,
    };

export default Links;
