import React from "react";
import "./studio.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import BookingStart from "Components/View/BookingStart";
import Gallery from "Components/UI/Gallery";
import Icon from "Components/Layout/Icon";
import ItemStaff from "Components/UI/ItemStaff";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import LoadVideo from "Components/Layout/LoadVideo";
import Map from "Components/UI/Map";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import Tag from "Components/UI/Tag";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplateStudio extends React.Component
{
    ColumnLeft = () =>
    {
        const {content} = this.props;
        const {eatery} = content || {};
        const Location = Eatery.Eatery(eatery);

        if (!Location)
        {
            return "";
        }

        const {address, location} = Location.content;
        const {lat, lng} = location || {}
        const Address = address.split("\n");
        const AddressItems = [];

        Address.forEach((row, index) =>
        {
            if (!location || index)
            {
                AddressItems.push(<div key={index}>{row}</div>);
            }
            else
            {
                AddressItems.push(<Link className="EateryNavigationContactLink" key={index} href={`https://www.google.com/maps/place/?q=place_id:${location.id}`}>{row}</Link>);
            }
        });

        return (
            <div className="TemplateStudioLeft">
                {(lat && lng) ? (
                    <div className="TemplateStudioMap">
                        <Map address={address} lat={lat} lng={lng}/>
                    </div>
                ) : ""}
                <div className="TemplateStudioAddress">
                    {AddressItems}
                </div>
            </div>
        );
    }

    ColumnRight = () =>
    {
        const {id} = this.props;

        return (
            <div className="TemplateStudioRight">
                <BookingStart
                    setParameters={{studio: id}}
                    type="studio"
                />
            </div>
        );
    }

    ItemFeature = (key, label) =>
    {
        return (
            <div className="TemplateStudioFeature" key={key}>
                <Icon src={key}/>
                <div className="TemplateStudioFeatureLabel">
                    {label}
                </div>
            </div>
        );
    }

    render()
    {
        const {content, tags, title} = this.props;
        const {
            about,
            contents,
            eatery,
            features,
            peopleMin,
            peopleMax
        } = content || {};
        const {content: locationContent} = Eatery.Eatery(eatery) || {};
        const {city, studioCloses, studioGallery, studioOpens} = locationContent || {};
        const Staff = Eatery.Option("studiosStaff", []);
        const StaffItems = [];
        const Features = [];
        const GalleryItems = [];
        const Tags = [];
        const Header = (
            <div className="TemplateStudioHeader">
                <h3>Digitala möten</h3>
                <h1>{title}</h1>
                <div className="TemplateStudioLocationCity">{city}</div>
                <div className="TemplateStudioTags">{Tags}</div>
            </div>
        );

        if (features)
        {
            for (let key in features)
            {
                Features.push(this.ItemFeature(key, features[key]));
            };
        }

        if (studioGallery)
        {
            const Autoplay = studioGallery.length < 2;

            studioGallery.forEach((media, index) =>
            {
                const {image, imagePositionH, imagePositionV, video} = media;

                if (video)
                {
                    GalleryItems.push(<LoadVideo autoplay={Autoplay} key={index} loop={Autoplay} src={video}/>);
                }
                else
                {
                    GalleryItems.push(<LoadImage key={index} src={image} style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}/>);
                }
            });
        }

        tags.forEach((label, index) =>
        {
            Tags.push(<Tag key={index} label={label}/>);
        });

        let OpeningHours = "Kontakta oss";

        if (studioOpens && studioCloses)
        {
            OpeningHours = `Kl ${studioOpens}–${studioCloses}`; 
        }
        else if (studioOpens)
        {
            OpeningHours = `Öppnar ${studioOpens}`;
        }
        else if (studioCloses)
        {
            OpeningHours = `Stänger ${studioCloses}`;
        }

        Staff.forEach(id =>
        {
            StaffItems.push(<ItemStaff id={id} key={id}/>);
        });

        return (
            <div className="TemplateStudio">
                <Segment top={true}>
                    <div className="TemplateStudioHeaderNarrow">
                        {Header}
                    </div>
                    <ThreeColumns
                        left={this.ColumnLeft()}
                        reverseNarrow={true}
                        right={this.ColumnRight()}
                    >
                        <div className="TemplateStudioContent">
                            <div className="TemplateStudioHeaderDesktop">
                                {Header}
                            </div>
                            {Parser.Parse(about, "div", null, {className: "TemplateStudioAbout"})}
                            <div className="TemplateStudioFeatures ColorBeige">
                                {this.ItemFeature("people", peopleMin ? `${peopleMin} – ${peopleMax} personer` : `Max ${peopleMax} personer`)}
                                {this.ItemFeature("clock", OpeningHours)}
                                {Features}
                            </div>
                        </div>
                        <Gallery>{GalleryItems}</Gallery>
                    </ThreeColumns>
                </Segment>
                <Modules modules={contents} startIndex={1}/>
                {StaffItems.length ? (
                    <Segment>
                        <div className="TemplateStudioStaffItemsWrapper">
                            <div className="TemplateStudioStaffItems">
                                {StaffItems}
                            </div>
                        </div>
                    </Segment>
                ) : ""}
            </div>
        );
    }
}

TemplateStudio.defaultProps =
{
    content: {},
    id: 0,
    title: "",
    uri: ""
};

export default TemplateStudio;