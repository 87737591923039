import React from "react";
import "./reservation.scss";

import Eatery from "Class/Eatery";
import Links from "Components/UI/Links";
import LocalLogo from "Components/Layout/LocalLogo";

class Reservation extends React.Component
{
    render()
    {
        const Eateries = Eatery.Eateries();
        const Items = [];

        for (let uri in Eateries)
        {
            const {content, title} = Eateries[uri];
            const {bookingUrl} = content || {};

            if (!bookingUrl)
            {
                continue;
            }

            Items.push({
                label: <LocalLogo name={title} uri={uri}/>,
                type: "link",
                url: bookingUrl
            });
        }

        return (
            <div className="Reservation">
                <h3>{Eatery.Option("reservationTitle")}</h3>
                <Links columns={2} items={Items}/>
            </div>
        );
    }
}

export default Reservation;