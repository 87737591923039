import React from "react";
import "./threecolumns.scss";

class ThreeColumns extends React.Component
{
    render()
    {
        const {
            center,
            children,
            className,
            left,
            reverseNarrow,
            right
        } = this.props;
        const CA = ["ThreeColumns"];

        if (reverseNarrow)
        {
            CA.push("ReverseNarrow");
        }

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                <div className="ThreeColumnsColumn ThreeColumnsLeft">
                    <div className="ThreeColumnsContent">{left}</div>
                </div>
                <div className="ThreeColumnsColumn ThreeColumnsCenter">
                    <div className="ThreeColumnsContent">{center || children}</div>
                </div>
                <div className="ThreeColumnsColumn ThreeColumnsRight">
                    <div className="ThreeColumnsContent">{right}</div>
                </div>
            </div>
        );
    }
}

ThreeColumns.defaultProps =
{
    center: "",
    className: "",
    left: "",
    reverseNarrow: false,
    right: ""
};

export default ThreeColumns;