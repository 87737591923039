import React from "react";
import "./default.scss";

import Modules from "Components/View/Modules";

class TemplateDefault extends React.Component
{
    render()
    {
        const {content} = this.props;
        const {contents} = content || {};

        return (
            <div className="TemplateDefault">
                <Modules modules={contents}/>
            </div>
        );
    }
}

TemplateDefault.defaultProps =
{
    content: {}
};

export default TemplateDefault;