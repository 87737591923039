import React from "react";
import "./itempress.scss";

import {DateStamp} from "Functions";
import ItemRoom from "Components/UI/ItemRoom";

class ItemPress extends React.Component
{
    render()
    {
        const {className, release} = this.props;
        const {EncryptedId, Id, Images, Intro, PublishDate, Title} = release || {};
        const Image = Images.length ? Images[0].UrlTo800x800ArResized : "";

        if (!Id)
        {
            return "";
        }

        const CA = ["ItemPress"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <ItemRoom
                buttons={[{color: "Black", id: Id, label: "Läs mer", to: `/press/${EncryptedId}`}]}
                className={CA.join(" ")}
                image={Image}
            >
                <div className="ItemPressContent">
                    <h3>{Title}</h3>
                    <h4>{DateStamp(new Date(PublishDate))}</h4>
                    <p>{Intro}</p>
                </div>
            </ItemRoom>
        );
    }
}

ItemPress.defaultProps =
{
    className: "",
    release: {}
};

export default ItemPress;