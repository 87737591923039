import React from "react";
import "./post.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

class Post extends React.Component
{
    render()
    {
        const {className, id} = this.props;
        const Post = Eatery.Post(id);

        if (!Post)
        {
            return "";
        }

        const {content: postContent, date, title} = Post;
        const {altTitle, content} = postContent;
        const CA = ["Post"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                <div className="PostHeader">
                    {Parser.Parse(altTitle || title, "h2")}
                </div>
                {Parser.Parse(content, "div", null, {className: "PostContent"})}
                <div className="PostFooter">
                    <div className="PostFooterColumn">
                        {date.replace(/ .*/, "")}
                    </div>
                </div>
            </div>
        );
    }
}

Post.defaultProps =
{
    className: "",
    id: 0
};

export default Post;