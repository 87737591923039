import React from "react";
import PropTypes from "prop-types";

const MarkerIcon = (props) =>
{
    const {bgColor, fgColor, uri} = props;

    switch (uri)
    {
        case "/lumafabriken":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24.206" height="30.873" viewBox="0 0 24.2 30.9">
                    <circle cx="12.1" cy="12.1" r="12.1" fill={fgColor}/>
		            <path d="M24.2,12.1C24.2,5.4,18.8,0,12.1,0S0,5.4,0,12.1c0,6.4,5,11.7,11.4,12.1v6.7h1.5v-6.7C19.2,23.8,24.2,18.5,24.2,12.1zM12.1,22.7C6.2,22.7,1.5,18,1.5,12.1S6.2,1.5,12.1,1.5s10.6,4.7,10.6,10.6l0,0C22.7,18,18,22.7,12.1,22.7z" fill={bgColor}/>
                    <path d="M11.5,5.8v10.5h3.6v1.7H9.6V5.8H11.5z" fill={bgColor}/>
                </svg>
            );

        case "/van-der-nootska-palatset":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24.206" height="30.873" viewBox="0 0 24.2 30.9">
                    <circle cx="12.1" cy="12.1" r="12.1" fill={fgColor}/>
		            <path d="M24.2,12.1C24.2,5.4,18.8,0,12.1,0S0,5.4,0,12.1c0,6.4,5,11.7,11.4,12.1v6.7h1.5v-6.7C19.2,23.8,24.2,18.5,24.2,12.1zM12.1,22.7C6.2,22.7,1.5,18,1.5,12.1S6.2,1.5,12.1,1.5s10.6,4.7,10.6,10.6l0,0C22.7,18,18,22.7,12.1,22.7z" fill={bgColor}/>
                    <path d="M4.8,6.4c1,0.1,2.1,0.1,3.1,0.1c0.5,0,1.1,0,1.6,0c0.5,0,1.1,0,1.6-0.1v0.4c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.2C9.8,7.2,9.7,7.3,9.6,7.5C9.5,7.6,9.4,7.8,9.4,8c0,0.2,0.1,0.5,0.3,1c0.2,0.5,0.4,1.1,0.7,1.8c0.3,0.7,0.6,1.5,1,2.4c0.4,0.9,0.8,1.8,1.1,2.8c0.4-1,0.8-2,1.2-2.9c0.4-0.9,0.7-1.7,1-2.4c0.3-0.7,0.5-1.3,0.7-1.8c0.2-0.5,0.2-0.8,0.2-1.1s-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.3-0.4-0.3C14.9,7,14.7,7,14.5,6.9c-0.2,0-0.4-0.1-0.5-0.1V6.4c0.4,0,0.8,0,1.2,0.1c0.4,0,0.8,0,1.2,0c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1-0.1v0.4C18.4,6.9,18.2,7,18,7c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-1,2-1.9,3.9-2.6,5.6c-0.8,1.7-1.4,3.4-2.1,5.1h-1c-0.4-0.8-0.7-1.6-1.1-2.5c-0.4-0.9-0.7-1.8-1.1-2.7c-0.4-0.9-0.8-1.8-1.2-2.8C7.4,9.9,7,9,6.6,8.1C6.4,7.8,6.2,7.5,5.9,7.3C5.6,7.1,5.3,6.9,4.8,6.9V6.4z" fill={bgColor}/>
                </svg>
            );

        default:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24.206" height="30.873" viewBox="0 0 24.206 30.873">
                    <circle cx="12.103" cy="12.103" r="12.103" fill={fgColor}/>
                    <path d="M24.206,12.1A12.1,12.1,0,1,0,11.352,24.168v6.691h1.5V24.168A12.093,12.093,0,0,0,24.206,12.1Zm-12.1,10.6a10.6,10.6,0,1,1,10.6-10.6A10.6,10.6,0,0,1,12.1,22.706Z" fill={bgColor}/>
                    <path d="M6.762,8.323H7.853v7.708H6.758v2.337H17.443V14.386H14.392v1.627H11.559v-2.9H14.9V10.764H11.556V8.328H14.4v1.55h3.049V5.989H6.762Z" fill={bgColor}/>
                </svg>
            );
    }
};

MarkerIcon.propTypes =
{
    bgColor: PropTypes.string,
    fgColor: PropTypes.string
};

MarkerIcon.defaultProps =
{
    bgColor: "#101010",
    fgColor: "#ffffff"
};

export default MarkerIcon;