import React from "react";
import "./collapsableview.scss";

import Collapsable from "Components/Layout/Collapsable";
import Icon from "Components/Layout/Icon";
import Item from "Components/UI/Item";


class CollapsableView extends React.Component
{
    constructor(props)
    {
        super(props);

        this.BlurOnUp = false;
        this.state =
        {
            collapsed: true
        };
    }

    componentDidMount()
    {
        const {collapsed} = this.props;
        this.setState({collapsed});
    }

    componentDidUpdate(prevProps)
    {
        const {collapsed: c1} = this.props;
        const {collapsed: c2} = prevProps;

        if (c1 !== c2)
        {
            this.setState({collapsed: c1});
        }
    }

    Expand = () =>
    {
        this.setState({collapsed: false});
    }

    OnMouseDown = () =>
    {
        this.BlurOnUp = !this.state.collapsed;
    }

    OnMouseUp = () =>
    {
        if (this.BlurOnUp && document.activeElement)
        {
            document.activeElement.blur();
            this.setState({collapsed: true});
        }
    }

    render()
    {
        const {children, className, label} = this.props;
        const {collapsed} = this.state;
        const CA = ["CollapsableView"];

        if (collapsed)
        {
            CA.push("Collapsed");
        }
        else
        {
            CA.push("Expanded");
        }

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                <Item
                    className="CollapsableViewLabel"
                    onFocus={this.Expand}
                    onMouseDown={this.OnMouseDown}
                    onMouseUp={this.OnMouseUp}
                >
                    {label}
                    <Icon className="CollapsableViewLabelIcon" src="Plus"/>
                </Item>
                <Collapsable collapsed={collapsed}>
                    {children}
                </Collapsable>
            </div>
        );
    }
}

CollapsableView.defaultProps =
{
    collapsed: true,
    className: "",
    label: ""
};

export default CollapsableView;