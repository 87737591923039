import React from "react";

class VanDerNootskaPalatset extends React.Component
{
    Svg = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 329.8 230.5">
                <path d="M121.3,38.3c3.7,0,7.3,0,10.9,0c3.7-0.1,7.3,0,10.8-0.1c1.8,0,3.5,0.1,5.1-0.4c1.6-0.4,3.1-0.7,4.7-1.1
                c0.8-0.2,1.7-0.3,2.5-0.5c0.8-0.2,1.5-0.5,2.3-0.6c2.8-0.3,5.6-0.3,8.5-0.4c3.1-0.1,6,0.4,8.7,0.7c0.7,0.1,1.6,0.4,2.5,0.6
                c2.4,0.5,5.3,1,7.3,1.8c0.4,0.2,1.4-0.1,1.9,0c4.2,0.1,8.7,0,13,0c2.5,0,4.9,0.1,7.3,0.1c2.4-0.1,4.8,0,7.1,0c1.3,0,2.6,0,3.9,0
                c4.1-0.1,8.1,0,12-0.1c1.2,0,2.5-0.1,3.8,0c2.6,0.2,5.1,0.4,7.9,0.3c0.9,0,1.9-0.2,2.7-0.2c0.3,0,0.7-0.1,0.9-0.1
                c0.3,0,0.7,0.4,1.1,0.7c0.4,0.3,0.7,0.6,1,0.8c1.9,1.7,3.9,3.2,5.8,4.8c1.2,1,2.6,2,3.9,3.2c0.5,0.5,1.2,1.1,1.9,1.6
                c0.6,0.5,1.7,1.1,1.7,1.7c0,0.5-0.3,0.5-0.8,0.8c-0.3,0.2-0.5,0.6-0.8,0.8c-0.3,0.2-0.6,0.4-0.8,0.8c-0.3,0.4-0.6,0.5-0.7,0.9
                c-0.1,0.6,0.2,1,0.3,1.5c0.1,0.6-0.2,1.5-0.2,2.2c0,3.8,0.2,7.6,0.2,11c0,1.5,0,3,0,4.5c0.1,3,0.1,6,0.1,9c0,2.9,0.1,5.8,0,8.8
                c-0.2,2.9,0.1,6,0,9c0,0.8,0.1,1.5,0.1,2.3c-0.2,4.9,0.2,10.2,0.2,15.6c0,1.5,0,3,0.1,4.4c0.1,1.5,0,3,0,4.5c0,4.9,0,10,0.1,14.9
                c-1.4,0.3-3.1,0.2-4.8,0.2c-0.8,0-1.5,0-2.3,0c-2.4,0-4.6-0.2-6.9-0.1c-6.4,0.4-12.9,0.3-19.3,0.2c-0.8,0-1.6,0-2.4,0
                c-2.1,0-4.7,0.2-6.9-0.1c-1.5-0.2-3-0.2-4.5-0.1c-2.3,0.1-4.7-0.2-7-0.1c-3.6,0.2-7.9-0.2-11.6,0c-1.5,0.1-3.1,0.1-4.6,0.1
                c-0.8,0-1.6,0-2.3,0c-0.7,0-1.5-0.2-2.2-0.2c-0.4,0-0.7,0.2-1.1,0.2c-1,0-2.2,0-3.4,0.1c-1.5,0-3.1-0.2-4.6-0.1
                c-0.2,0-0.5,0.1-0.7,0.2c-4.1,0.4-8.3-0.1-12.3,0c-4.2,0.2-8.4-0.2-12.7-0.1c-2.1,0.1-4.2,0.2-6.4,0.2c-2.1,0-4.2,0.1-6.3,0.1
                c-4.3,0-8.6-0.2-12.9-0.2c-2.1,0-4.1,0-6.3,0c-2.2,0-4.4,0.1-6.6,0c-4.4-0.3-8.7,0-13.1-0.2c-4.4-0.2-8.7,0.2-13.1,0.1
                c-2.2,0-4.4,0.1-6.6,0.1c-2.1,0.1-4.2,0.3-6.3,0.2c-0.3-0.5-0.1-1.1-0.1-1.6c0-0.9-0.2-1.8-0.2-2.8c0-2.6-0.1-5.3-0.1-8.1
                c0-0.8,0.2-1.7,0.2-2.6c0.2-9.7,0-20.2-0.1-30.2c-0.1-6.4-0.3-13-0.2-19.4c0-4.3,0.1-8.8,0.3-13.5c0.1-1.7-0.1-3.7,0-5.4
                c0-0.7,0.1-1.7,0-2.7c-0.2-1.8,0.2-3.1-1-3.7c-0.1-0.1-0.3-0.1-0.5-0.3c-0.4-0.4-0.5-0.9-0.8-1.1c-0.2-0.1-0.9-0.1-1-0.3
                c-0.2-0.5,1.6-1.6,1.9-1.8c0.8-0.6,1.4-1.1,2.1-1.7c1.5-1.2,3-2.1,4.3-3.2c2.4-1.9,4.9-3.7,7.4-5.6c0.4-0.3,0.7-0.8,1-0.8
                c0.5-0.2,1.4-0.1,2.2-0.1c2.3-0.1,4.5,0,6.8,0c3,0,6.1-0.2,8.9,0c3.1,0.2,6,0.2,8.8,0.1c0.7,0,1.4-0.1,2.2,0
                c1.4,0.2,2.9,0.1,4.3,0.2c0.8,0,1.5-0.1,2.2,0L121.3,38.3z"/>
                <path className="st0" d="M175.3,38.7c-1.3-0.1-2.6-0.4-3.9-0.5c-0.9,0-1.7-0.2-2.6-0.2c-0.5,0-1,0.1-1.4,0.1c-0.6,0-1.2-0.2-1.8-0.2
                c-1.3-0.1-2.5,0-3.8-0.1c-1.1,0-2.4,0-3.4,0.2c-1.4,0.3-2.7,0.7-4.1,1c-1.3,0.2-2.6,0.2-3.8,0.6c-0.4,0.1-0.8,0.4-1.2,0.5
                c-1.1,0.4-2.3,0.5-3.5,0.9c-2,0.6-3.8,1.7-5.6,2.7c-0.6,0.3-1.1,0.8-1.7,1.1c-0.6,0.4-1.2,0.6-1.8,1.1c-0.5,0.4-1.1,0.8-1.6,1.2
                c-0.6,0.4-1.2,0.8-1.7,1.2c-0.2,0.1-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.1-0.4,0.2c-0.5,0.4-0.9,0.8-1.3,1.3c0.7,0.2,1.5-0.1,2.2,0
                c0.2,0,0.4,0.2,0.6,0.2c0.7,0,1.4-0.1,2.1-0.1c2,0,4,0.3,6,0.2c2.7-0.2,5.4,0.3,8.1,0.2l4.9-0.1c1.6,0.1,3.5,0,5.3,0.1
                c0.7,0,1.4-0.1,2.1-0.1c1.4,0,2.8,0,4.2-0.1c3.4,0,7.2,0.1,10.8,0c0.8,0,5.3,0.1,5.3,0.1c1.2,0,2.4,0,3.5,0c1.8,0,3.5,0.2,5.2,0.2
                c0.6,0,1.1,0,1.7,0c2.3,0,4.4,0.1,6.3-0.1c0-0.3-0.3-0.4-0.5-0.6c-0.1-0.1-0.1-0.3-0.2-0.4c-0.9-1.1-2-1.8-3.1-2.7
                c-1.1-0.9-2.2-1.5-3.5-2.2c-0.6-0.4-1.2-0.6-1.9-0.9c-1.8-0.9-3.6-1.8-5.5-2.6c-0.5-0.2-1-0.2-1.4-0.4c-0.6-0.2-1-0.5-1.6-0.6
                c-1.8-0.3-3.6-0.9-5.5-1.2L175.3,38.7z"/>
                <path className="st0" d="M259.5,51.2c-1.4,0.2-2.7,0-4.3,0.1c-0.2-1.1-0.1-2.5-0.2-3.8c0.1,0,0.1,0,0.2,0c0.8,0.6,1.4,1.3,2.2,1.9
                C258.1,50,259,50.5,259.5,51.2z"/>
                <path className="st0" d="M258.6,52.3c-0.6,0.5-1.5,0.2-2.2,0.2c-0.7,0-1.3,0.2-1.9,0.2c-1.2,0.1-2.6,0-3.9,0c-1.3,0-2.5-0.1-3.8-0.1
                c-1.2,0-2.5-0.2-3.8-0.3c-0.9-0.1-1.8,0-2.7,0c-2.6-0.1-5.1,0-7.7-0.2c-5.1-0.3-10.5,0-15.4-0.2c-1.8-0.1-3.5,0.1-5.2,0.1
                c-3.5-0.1-7.1-0.4-10.5-0.1c-0.8,0.1-1.7,0.2-2.5,0.2c-0.9,0-1.7,0-2.6,0.1c-0.4,0-0.9,0.2-1.3,0.2c-1.1,0-2.2-0.2-3.3-0.2
                c-3.4-0.2-6.7-0.1-9.9-0.3c-3.2-0.2-6.8,0.4-9.7,0.3c-4.2-0.1-8.8-0.3-13.3-0.2c-3.4,0.1-6.7,0.5-10,0.3c-5-0.3-10-0.2-14.8-0.3
                c-1.6,0-3.3,0.3-4.8,0.2c-0.3,0-0.5-0.1-0.8-0.2c-0.5,0-1.1,0.2-1.6,0.2c-2.3,0-4.7-0.2-7.2-0.2c-2.5,0-5-0.2-7.5,0
                c-2.4,0.2-4.8,0.2-7.2,0.3c-1.2,0.1-2.4,0.1-3.7,0.1c-1.2,0-2.4-0.2-3.6-0.1c-4.7,0.2-9.4-0.1-14.2-0.1c-2.4,0-4.9,0-7.3,0
                c-1.7,0-3.4,0.3-5.3,0.2c-0.4,0-1.1-0.1-1.2-0.7c1.9-0.3,3.8-0.2,5.7-0.1c2,0.1,4,0,6,0c6.3,0.2,12.8-0.1,19.2,0
                c1.2,0,2.4,0,3.6,0c1.2-0.1,2.3-0.2,3.5-0.2c0.8,0,1.7,0.2,2.5,0.1c3.8-0.3,8.5-0.2,12.5-0.3c0.7,0,1.6-0.1,2.4-0.1
                c0.7,0,1.9,0.2,2.4,0.1c0.2,0,0.2-0.1,0.4-0.3c0.3-0.3,0.8-0.8,1-1c0.6-0.5,1-1,1.4-1.4c0.9-0.9,2.2-1.8,3.3-2.4
                c2.3-1.4,4.7-3.1,7.1-4.3c2.5-1.2,5.2-2.3,8.3-3.1c1.5-0.4,3-0.8,4.6-1.1c1.6-0.3,3.1-0.8,4.7-1c3-0.3,6.2,0,8.8-0.3
                c0.6-0.1,1.6,0.1,2.4,0.2c0.8,0,1.6,0.1,2.3,0.2c1.7,0.3,3.2,0.5,4.9,0.9c1.5,0.3,2.9,0.4,4.3,0.9c0.7,0.2,1.4,0.4,2.1,0.6
                c1.3,0.4,2.6,1,3.8,1.4c1.3,0.4,2.6,0.9,3.8,1.4c0.7,0.3,1.3,0.7,1.9,1.1c0.6,0.4,1.3,0.7,1.9,1.1c1.2,0.8,2.3,1.7,3.4,2.6
                c0.2,0.1,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.3,0.6,0.6c0.4,0.4,0.6,0.8,1.1,1.3c0.3,0.3,0.8,0.8,1.1,1.2c0.3,0.4,0.6,1.1,1,1.3
                c0.5,0.2,1.3,0,2.2,0c0.8,0,1.6,0,2.2,0.1c1.4,0,2.7-0.1,4.2-0.2c4.6-0.2,8.5,0.1,12.7,0c2.7-0.1,5.5,0.3,8.3,0.3
                c1.4,0,2.7,0.1,4.1,0.1c0.4,0,0.7-0.2,1.1-0.2c1.4-0.1,3,0.2,4.3,0.2c0.4,0,0.7,0,1.1,0.2c3.1-0.5,6.5,0,9.7,0.2
                C254.3,52.2,256.5,51.9,258.6,52.3z M201,51.3c0.4-0.6-0.7-1.5-1-1.8c-0.5-0.6-1-1.1-1.5-1.6c-1.1-0.9-2-2.1-3.3-2.8
                c-0.5-0.3-1-0.5-1.5-0.8c-2.4-1.4-5.1-2.6-7.7-3.8c-1.1-0.5-2.3-0.7-3.4-1.2c-1,0.1-1.9-0.4-2.6-0.6c-0.6-0.1-1.1-0.2-1.8-0.3
                c-3-0.7-6.4-0.9-9.7-1.1c-3.2-0.2-6.6-0.4-9.8-0.1c-1.4,0.1-3.2,0.7-4.4,0.9c-0.5,0.1-0.9,0.1-1.3,0.2c-0.9,0.2-1.9,0.2-2.7,0.4
                c-0.6,0.2-1.4,0.6-2.2,0.9c-0.8,0.3-1.7,0.3-2.5,0.6c-0.3,0.1-0.6,0.4-0.9,0.5c-0.4,0.1-0.7,0.1-1.1,0.2c-0.3,0.1-0.6,0.4-0.9,0.6
                c-1.7,0.8-3.2,1.8-4.8,2.7c-0.7,0.4-1.2,0.9-1.8,1.3c-0.3,0.2-0.6,0.4-0.9,0.6c-0.9,0.6-1.8,1.2-2.6,1.9c-0.5,0.4-1,0.9-1.7,1.3
                c-0.5,0.4-1.7,1-1.6,1.4c0.1,0.4,1.2,0.2,1.6,0.2c0.8,0,1.3,0,1.8,0c2.9,0,4.2,0.3,7,0.2c1.2,0,2.5-0.2,3.6-0.2
                c1.8,0,3.6,0.3,5.4,0.3c7.4,0,14-0.1,21.5,0c2.3,0,4.6-0.1,6.9-0.1c4.3,0,8.4,0.2,13.1,0.2c3.1,0,6.1-0.1,9.1,0
                C200,51.4,200.7,51.6,201,51.3z"/>
                <path className="st0" d="M257.5,129.8c0.2,1.1,0,2.5,0,3.7c-2.8,0.4-5.5,0-8.4,0.2c-0.1-1.4-0.2-3,0.2-4.1c-0.6-0.5-1.8-0.4-2.7-0.3
                c-1.3,0.1-2.9,0.3-4.2,0.3c-0.5,0.9,0.1,2.9-0.3,4.1c-0.7,0-1.5,0.1-2.2,0.2c-0.8,0-1.7-0.2-2.4,0.1c0,0.7,1,0.5,1.5,0.5
                c0.6,0,1.3,0,1.9-0.1c0.8,0,1.7,0.2,2.4,0.2c0.6,0,1.4-0.2,2.1,0.1c1-0.3,2.1,0,3.2,0c1,0,2.3-0.3,3.5-0.2c1.8,0.1,3.6,0,5.4,0.2
                c0,0.7,0.2,1.7,0,2.5c-1.4,0.3-3,0.2-4.6,0.2c-1.8,0-3.7,0-5.3,0.3c-1.5-0.3-4.1-0.1-5.6-0.2c-1.9-0.1-3.9,0.1-5.8,0.1
                c-1.7,0-3.4,0.1-5.1,0c-0.3,0-1.7-0.1-1.9,0.2c0,0-0.3,0.4,0.5,0.5c0.8,0.2,1.7,0.1,2.8,0c4.2,0,9.3-0.2,13.1-0.2
                c0.7,0,1.5,0.2,1.9-0.2c0.5,0.4,1.2,0.2,1.9,0.1c2.6-0.1,5.7,0,8.1,0c0.1,1.4,0.1,2.5-0.1,3.8c-3.9,0.2-8.3-0.1-11.9-0.1
                c-2.9,0-5.8,0.2-8.8,0.3c-3.5,0-7.2,0-10.8,0c-3,0-5.6,0-8.6-0.2c-1,0-2-0.1-3-0.1c-2.5,0-5.2-0.1-7.8,0h-1.4
                c-4.6,0.1-9.1-0.1-13.6-0.1c-1,0-2.1,0.2-3.2,0.2c-1.7,0-3.4-0.3-5-0.2c-0.3,0-0.6,0.2-0.8,0.2c-2.5,0.2-5.3-0.1-7.9-0.1
                c-0.1-1.2-0.3-2.3-0.1-3.5c6.2-0.2,12.1,0.1,18.2,0.2c1.2,0,1.7,0,2.9,0c1.2,0,1.3,0,1.6,0c2.4,0.2,4.5-0.1,6.9-0.1
                c0.9,0,1.9-0.1,2.9,0c2.1,0,4.6,0.3,6.7,0.1c0.9-0.1,1.8-0.2,2.6-0.3c1,0,2.2,0.1,3.1,0.2c2.5,0.1,5.4-0.1,8.2,0
                c0.7,0,1.3,0.1,1.4-0.2c0.3-0.6-1.6-0.6-2-0.6c-0.2,0-0.5,0.1-0.7,0.1c-1.1,0-2.1-0.2-3.2-0.2c-0.9,0-1.8,0-2.7,0
                c-1.3,0-2.8-0.1-4.1,0c-1.5,0.1-3,0.3-4.5,0.3c-1.1,0-2.2-0.1-3.3-0.1c-1.8,0-3.5-0.2-5.6-0.1c-1.6,0.1-3.2,0.2-4.8,0.2
                c-0.5,0-0.9-0.1-1.6,0c-1,0-2.9,0-4.4-0.1c-1.7,0-3.6,0-5.4,0c-1.9,0-3.9-0.2-5.8-0.2c-2.1,0-4.2,0.3-6.3,0.1c0-1-0.1-1.9-0.1-3
                c3.4,0.2,6.9-0.1,10.4-0.1c2.5,0,5.2,0.1,7.9,0.1c1.3,0,2.6-0.2,3.9-0.1c1.2,0,2.5,0.1,3.7,0.1c1.7,0,3.2,0,4.8,0
                c0.2,0.1,0.5,0,0.7,0.1c1.2,0.2,2.8,0.1,4.1,0c3.2-0.2,6.3-0.1,9.8-0.3c0.6,0,1.6,0,1.7-0.3c0.2-0.7-1-0.4-1.7-0.4
                c-0.8,0-1.6,0-2.2,0.1c-1.7,0.1-3.7,0.3-5.3,0.1c-0.1-1.1-0.1-2.4,0.1-3.5c0.9-0.3,2.4,0,3.2-0.4c0.9,0.5,2.7,0.1,4,0.2
                c1.2,0,2.3,0,3.4,0.1c0.2,1.1,0.1,2.5,0.2,3.6c-0.3,0.1-1.5-0.2-1.4,0.4c0,0.5,2.9,0.4,3.4,0.4c1.4,0,2.5-0.1,3.7,0
                c0.4,0,0.5,0.2,0.7,0.2c0.8-0.6,2.7-0.4,4.3-0.4c0.7,0,1.3,0,1.9,0c0.5,0,1.2,0.2,1.5-0.3c-0.2-0.6-1-0.4-1.5-0.4
                c-1.7,0-4.2,0.1-5.8-0.1c0-1.1,0-2.4,0.1-3.4c2.6-0.6,6.5-0.3,9.3-0.3c0.6,0,2.5,0.1,2.6-0.4c0.1-0.7-2-0.4-2.5-0.4
                c-1.6,0.1-2.4-0.1-3.4-0.1c-1.5-0.1-3.2,0.2-4.9,0.2c-5.2,0.2-10.6-0.2-15.3,0.3c-0.7-0.6-2.7-0.3-3.8-0.2
                c-0.9,0.1-1.7-0.1-2.5-0.1c-1,0-2.2,0.1-3.3,0.1c-0.7,0-1.5-0.2-2.5-0.2c-2.5,0.1-5.6-0.1-8.1,0.1c-1.3,0.1-2.6,0-3.7,0.1
                c-0.2,0-0.5,0-0.7,0.3c-0.3-0.6-1.5-0.4-2.5-0.4c-1.4,0-2.9,0-4.2,0.1c-0.1,1.1,0.2,2.8-0.1,4.1c-3.4,0.2-6.5,0.4-9.9,0
                c-0.1-1.2-0.1-2.4-0.2-3.5c2.4,0,5.1,0.1,7.5,0.1c0.8,0,1.8,0.2,2.3-0.4c-0.4-0.6-1.4-0.4-2.1-0.4c-0.8,0-1.7,0-2.5,0
                c-1.8,0-3.6-0.3-5.2,0.1c-0.1-1.1,0-2.4,0-3.8c7.3,0,15.1,0,22.3,0.1c3,0,6,0.1,9-0.1c2.4-0.1,5.3,0.2,8,0.1c1.4,0,2.8,0,4.1,0
                c3.8,0,7.8-0.2,11.4-0.1c1.8,0,3.5,0.1,5.4,0c2.1-0.1,4.4,0.1,6.3-0.1c1.9-0.2,3.5,0.1,5.2,0.1c1,0,2-0.2,2.9-0.2
                c2.9,0,5.6-0.4,8.4,0.2c0.1,1.1,0.1,1.9,0.1,3.1c-1.8,0.1-4.1,0.3-6.2,0.3c-0.5,0-1.8-0.1-1.8,0.4c0,0.3,0.4,0.4,0.8,0.4
                C252.6,130.1,255.2,129.5,257.5,129.8z"/>
                <path className="st0" d="M257.4,123.7c0,0.4,0,0.7,0,1.1c-2.8,0-5.6,0-8.4,0.1c-1,0-2.1,0.2-3.1,0.2c-2.1,0-4.2,0.1-6.1,0
                c-3.1-0.1-6.2,0.2-9.3,0.1c-2,0-3.9-0.2-5.9-0.2c-1.1,0-2.2,0.3-3.3,0.3c-0.9,0-1.7-0.1-2.6-0.1c-0.9,0-1.7,0.1-2.6,0.1
                c-1.4,0-2.8-0.2-4.2-0.2c-3.3,0.1-6.4-0.1-9.5-0.1c-0.1-0.3-0.1-0.6-0.1-1c4.1-0.2,8.3-0.3,12.4-0.2c4.2,0.1,8.3,0.1,12.4,0
                c3.1,0,6.2,0.1,9.2,0.1c3.8,0,7.6,0,11.5-0.1C251,123.6,254.1,123.5,257.4,123.7z"/>
                <path className="st0" d="M257,108.2c0.1,2.7,0.3,5.6,0.2,8.2c-1.3,0.3-2.9,0-4.3,0c-0.2-1.6-0.3-3.4-0.3-5.1c0-2.6-0.2-5.3-0.2-7.8
                c0-1,0.1-1.9,0.1-2.9c-0.1-1.2,0-2.5,0-3.7c0.1-1.5-0.1-3-0.1-4.5c0-0.4,0.1-0.8,0.1-1.2c0-2.5-0.1-4.9,0-7.4
                c0.2-5.2,0-10.2-0.1-15c0-1.3,0-2.5,0.1-3.8c0.1-1.9,0.1-3.8,0.1-6c0-0.8-0.3-1.9-0.2-2.5c0-0.1,0.1-0.1,0.2-0.2
                c1.6,0,2.7,0,4,0.2c0.4,3.8,0.2,7.6,0.2,11.4c0,1.3,0.2,2.5,0.2,3.8c0,3.3,0.2,6.6,0.2,10c0,2.1,0.1,4.1,0.1,6.2s-0.1,4-0.2,6.1
                c-0.1,3.3-0.1,6.5,0,10C257.1,105.5,257,106.8,257,108.2z"/>
                <path className="st0" d="M256.7,53.4c-0.1,0.8,0.1,1.5-0.1,2.2c-5,0.1-10,0.1-14.9,0.2c-2.5,0-4.9-0.1-7.4-0.2c-0.8,0-1.7,0.1-2.5,0
                c-1.3-0.1-2.7,0.1-4,0.1c-1.1,0-2.2-0.2-3.3-0.2c-0.6,0-1.2,0.2-1.8,0.2c-0.8,0-1.5-0.1-2.3-0.1c-0.9,0-1.8,0.1-2.7,0.1
                c-3.3,0-6.4-0.1-9.3-0.1c-1.7,0-3.3,0.1-4.9,0.2c-0.9,0-1.9,0.1-2.7,0.1c-1.4,0-2.7-0.2-4.2-0.2c-9.6-0.1-19.5-0.4-29.3,0
                c-1.4,0.1-2.8,0-4.2,0c-1.7,0-3.4,0.1-4.9,0.2c-1.4,0-2.9,0-4.4,0c-0.6,0-1.1,0-1.7,0c-0.6-0.1-1.3,0.1-1.9-0.2
                c0.2-0.5,0.9,0,1.5,0c0.4-0.1,0.5-0.3,0.8-0.6c0.1-0.4,0-0.5,0.2-0.8c0.2,0.1,0.6,0,0.7,0.1c0.1,0.2,0.1,0.4,0.1,0.6
                c-0.3,0.1-0.8,0-1,0.2c-0.2,0.9,1.4,0.6,1.9,0.4c0.2-0.4-0.2-0.6-0.3-1.1c0-0.3,0.2-0.7,0-0.9c-0.3-0.5-1.2-0.2-1.7,0.1
                c-0.2-0.2-0.6-0.3-0.7-0.7c7.6-0.5,14.1-0.4,21.6-0.2c0,0.8-0.2,2,0.5,2.5c0.8,0,2.1,0.2,1.9-0.6c1,0.8,2.5,0.4,2.5-0.8
                c0-0.5-0.4-0.9-0.6-1.2c2.9-0.4,6.2,0,8.8,0.1c-0.2,1-1.4-0.3-1.9,0.3c0,0.6,0.8,0.4,1,0.9c-0.2,0.5-1.1,0.1-1.1,0.7
                c0,0.9,1.4,0.1,1.6,0c0.7,0,1.9,0.7,2.1,0c0.1-0.6-0.6-0.6-1.2-0.8c0.4-0.1,1.5,0.2,1.3-0.6c-0.1-0.6-0.9,0-1.1-0.6
                c2.7,0,5.5,0.1,8.1,0.3c-0.2,0.4-0.7,0.9-0.6,1.7c0.4,0.4,2.2,1.3,2.6,0.3c0.4-0.8-0.2-1.7-0.7-2.1c2.7,0,5.4-0.2,8-0.4
                c1.1-0.1,2.1,0.2,3.2,0.2c1.6,0,3.2,0,4.8,0c5-0.1,9.9,0.1,14.8,0c5.8,0,12,0.2,18.1,0.4c1.7,0,3.4,0.3,5,0.3
                C252.5,53.3,254.7,53.6,256.7,53.4z M200,55.5c0.2-0.6-0.2-1.4-0.2-2.2c-0.6-0.1-1.7-0.2-2.2,0.2c-0.1,0.7,0.9,0.4,1.4,0.6
                c0,0.2,0.1,0.5,0,0.7c-0.4,0-0.8-0.1-1.1,0.1C197.9,55.8,199.4,55.5,200,55.5z M194.8,53.9c0-0.2,0.1-0.3,0-0.5
                c-0.5-0.3-1.6-0.4-2.1,0c0,0.6-0.2,1.5,0,1.9c0.6,0.1,2.1,0.3,2.1-0.4c-0.1-0.5-0.9-0.2-1.3-0.4c0-0.1,0-0.2,0-0.3
                C193.7,53.9,194.6,54.2,194.8,53.9z M191.1,55.3c0.6-0.2,1.1-1.1,0.8-1.7c-0.2-0.4-2.3-1-2.2-0.1c0.1,0.6,1.2,0.1,1.5,0.7
                c-0.2,0.5-1,0.3-1.3,0.7C189.8,55.6,190.8,55.5,191.1,55.3z M183.6,54.8c0-0.7-1.5-0.2-1.6-0.9c0.2-0.7,1.4,0,1.3-0.9
                c0,0-0.1,0-0.1-0.1c-0.8-0.2-1.8-0.1-2.1,0.7c-0.1,0.5,0.2,1.1,0.5,1.3C182.2,55.2,183.6,55.3,183.6,54.8z M180.5,55.1
                c0.2-0.6,0-1.6,0-2.2c-0.5-0.1-1.6-0.2-1.9,0.2c-0.1,0.7,0.7,0.5,1.1,0.7c0,0.3,0,0.6-0.1,0.7c-0.3,0-0.6,0-0.8,0.1
                C178.4,55.3,179.9,55.3,180.5,55.1z M172.2,54.4c0.4-0.6,0-0.8-0.3-1.2c-0.3-0.2-1-0.2-1.7-0.2c-0.4,0-1.1-0.2-1,0.4
                c0.3,0.5,1.8-0.1,2.1,0.5c-0.4,0.5-2-0.2-1.8,0.6C169.6,55.2,171.7,55.1,172.2,54.4z M164.4,53.8c0.8-0.1,2.7,0,1.9-1.1
                c-0.7,0.3-1.6,0-2.1,0.3C164.1,53.4,164.2,53.7,164.4,53.8z M165.6,55.4c0.5,0,0.9-0.2,0.8-0.5c-0.1-0.4-1.2-0.5-1.3-0.8
                c-0.9-0.3-1.4,0.5-0.7,0.8c0.3,0,0.6,0,0.8,0C165.4,55.1,165.4,55.3,165.6,55.4z M163.4,54.7c0.6-0.1,0.6-1.1,0.3-1.5
                c-0.4-0.5-1.2-0.7-1.7-0.2c-0.3-0.1-0.6-0.2-0.8,0.1c0,0.6,0.5,0.7,1,0.8c-0.2,0.4-1,0.4-1,0.8c-0.1,0.9,1.4-0.1,1.5-0.1
                C162.9,54.6,163.2,54.8,163.4,54.7z M158.2,54.8c0-0.8-1.4-0.1-1.5-0.8c0.3-0.4,1.6,0,1.5-0.7c-0.1-0.6-1.7-0.3-2.1,0
                C155.1,54.3,157.1,55.9,158.2,54.8z"/>
                <path className="st0" d="M254.1,46.6c0.1,1.7,0.1,3.1,0,4.7c-1.4,0-3.2-0.2-4.7-0.3c0.1-2.5-0.8-6.2,0.9-7.4c0.3,0.3,0.2,1,0.2,1.6
                c0,1.3,0,2.2,0,3.6c0,0.6-0.1,1.4,0.3,1.5c0.7,0.2,0.5-1.1,0.5-1.5c0.1-1.4,0-2.8,0.1-4.1C252.3,45,253.2,46,254.1,46.6z"/>
                <path className="st0" d="M251.8,87.7c0.1,1.7-0.1,3.3-0.1,5c0.1,3.3,0.1,6.5,0.1,9.8c0,0.9-0.2,1.8-0.2,2.7c0,2.6-0.1,5.2,0.1,7.8
                c0.1,1.2,0.3,2.4,0,3.6c-2,0.2-4.1,0.3-6.3,0.3c-2.2,0-4.3,0.1-6.3-0.1c-0.3-2.7-0.1-5.5-0.3-8.4c0-0.9,0.1-1.8,0-2.6
                c0-0.3-0.1-0.6-0.2-0.9c-0.1-1,0.1-1.8,0.1-2.8c0.1-1.9-0.2-4-0.2-5.9c0-1,0.1-2.2,0.1-3.4c0-0.7-0.2-1.4-0.2-2.1
                c-0.1-3-0.1-6,0-9.1c0-1.4,0-2.9,0-4.3c0-2.2,0.3-4.3,0.2-6.5c-0.1-2.1,0-4.3-0.1-6.4c-0.1-1.7,0.2-3.4,0-5.1c0-0.9-0.2-1.9,0-2.8
                c4.5-0.2,8.3,0,12.8-0.1c0.3,1.6,0,3.3,0.1,5c0,2,0,4.1,0,6.1c0,1.7,0.1,3.3,0.2,5C251.9,77.6,251.6,82.6,251.8,87.7z M251,59.5
                c0.1-0.5,0.4-0.7,0.2-1.2c-1-0.4-2.1,0.1-3.2,0.1c-0.6,0-1.4-0.2-2.1-0.2c-1.7,0-3.1,0.1-4.8,0.1c-0.4,0-1.2-0.2-1.6-0.1
                c-0.7,0.2-0.4,2.2-0.4,3.3c0,1.2-0.1,2.3-0.1,3.3c0,3.7,0.2,7.2,0.2,10.8c0,1.1-0.2,2.3,0.2,2.9c1.7,0.2,3.9-0.1,5.8-0.2
                c0.9,0,2.1,0.1,3,0.1c0.7,0,2.4,0.1,2.7-0.3c0.3-0.4,0-1.7,0-2.2c-0.2-2.8,0.2-6.6,0.1-10C250.9,63.7,250.6,61.5,251,59.5z
                M250.8,93.5c0-0.6,0.3-1,0.2-1.5c-0.3-0.4-0.8-0.1-1.2-0.1c-1.3,0-2.9,0.2-4.4,0.2c-0.8,0-1.6-0.1-2.4-0.2
                c-1.2,0-2.5,0.2-3.7,0.1c-0.7,7.1,0.5,14.1,0.1,21c0,0.6-0.3,1-0.1,1.4c3.5,0.3,7.6-0.1,11.3,0.1c0.4-0.6,0.2-1.3,0.2-1.9
                c0-2.3,0-4.6-0.2-6.9C250.6,101.5,250.9,97.4,250.8,93.5z"/>
                <path className="st0" d="M250.1,106.3c0,2.4,0,4.9,0,7.3c-3.1,0-6.9,0.3-9.8,0.1c-0.2-1.4-0.1-2.9-0.1-4.3c0-5.4-0.6-11.3-0.2-16.5
                c3.2-0.3,7,0,9.9-0.2c0.3,3.7,0,7.4-0.1,11.2C250,104.7,250.1,105.5,250.1,106.3z M249.6,112.5c0.1-0.4-0.1-0.5-0.2-0.8
                c-0.2-0.8,0-1.7,0-2.7c0-2.3-0.1-4.3-0.1-6.5c0-1.3-0.1-2.7,0-4c0-1.8,0.3-3.6-0.1-5.2c-0.3-0.2-0.8-0.1-1-0.1
                c-2.4,0.3-4.9,0.4-7.5,0.5c-0.1,6.4,0.4,12.8,0.2,19c0.5,0.4,1.3,0.2,1.9,0.2c0.7,0,1.5,0,2.3,0c0.4,0,0.8-0.2,1.2-0.2
                C247.5,112.8,248.8,113.2,249.6,112.5z"/>
                <path className="st0" d="M250.1,59.1c0.1,5.9,0.2,13,0,18.4c-1.5,0.2-3.3,0-5.1,0.1c-1.7,0.1-3.4,0.3-4.8,0.1
                c-0.2-2.8-0.2-5.7-0.2-8.6c0-3.4-0.1-6.7,0.1-10.1C243.7,59,246.8,58.9,250.1,59.1z M249.4,62.7c0-0.8,0.2-2.5-0.1-2.8
                c-0.3-0.3-1.7-0.2-2.3-0.2c-0.9,0-1.7,0-2.5,0c-1.4-0.1-2.6-0.2-3.8,0.2c-0.2,0.3,0,0.7,0,1.1c0,5.1,0,11.3,0,15.8
                c0.4,0.2,0.9,0,1.3,0c2.4,0,4.9,0.3,7.2-0.1c-0.1-1-0.1-2.3-0.1-3.5C249.3,70.1,249.4,66,249.4,62.7z"/>
                <path className="st0" d="M248.5,62.5c0.2,1,0.2,2.5,0.1,3.5c-0.8-0.1-1.6,0.1-2.4,0c-0.1-0.4-0.1-0.7,0-1.1c0.6-0.4,2.4,0.4,2.1-0.8
                c-0.4-0.4-1.5,0-2-0.2c-0.2-0.3-0.2-1,0-1.3C246.8,62.4,247.8,62.7,248.5,62.5z"/>
                <path className="st0" d="M248.5,66.7c0.3,1.2,0,2.8,0,4.2c-0.7,0-1.7,0.4-2.6,0.2c0-0.5,0-1.3,0.2-1.8c0.8-0.2,2.2,0.3,2.3-0.4
                c0.1-0.9-1.7-0.1-2.3-0.6c0-0.4-0.1-1,0.1-1.3C246.9,66.8,247.8,66.8,248.5,66.7z"/>
                <path className="st0" d="M248.5,94.2c0.2,0.4,0.2,1,0.1,1.4c-0.6,0.3-1.7,0.2-2.4,0c0-0.5,0-1,0.1-1.3C247,94.3,247.8,94.1,248.5,94.2
                z"/>
                <path className="st0" d="M248.6,108.7c0,0.4-0.1,0.7,0,1.2c-0.6,0.2-1.4,0.2-2.1,0.1c-0.2-0.3-0.2-1-0.1-1.3
                C247.2,108.6,248.2,108.4,248.6,108.7z"/>
                <path className="st0" d="M248.5,60.7c0.2,0.2,0.1,0.6,0.1,0.9c-0.6,0.1-1.8,0.2-2.4,0.1c0-0.5-0.1-0.8,0.1-1.1
                C246.7,60.6,247.9,60.4,248.5,60.7z"/>
                <path className="st0" d="M248.5,96.6c0,0.4,0.1,0.8-0.1,1.2c-0.6,0.3-1.4,0.2-2.3,0.2c0-0.3-0.1-0.8,0-1.2
                C246.8,96.3,247.7,96.8,248.5,96.6z"/>
                <path className="st0" d="M248.5,101c0.2,0.5,0.1,1.1,0.1,1.6c-0.5,0.4-1.8,0.2-2.3,0.1c0-0.7-0.1-1.2,0-1.7
                C246.9,100.9,247.6,101.1,248.5,101z"/>
                <path className="st0" d="M248.5,103.8c0,0.3,0.1,0.9,0,1.4c-0.6,0.3-1.5,0.4-2.2,0.2c-0.1-0.4-0.1-1.2-0.1-1.6
                C246.9,103.6,247.8,103.6,248.5,103.8z"/>
                <path className="st0" d="M248.4,44.8c0,2.3,0.3,4.1,0,6.1c-0.4-0.1-0.2-0.6-0.2-1c-0.1-1.2-0.1-2.9,0-4.2c0-0.4,0.2-0.8,0-1.1
                C247.9,44.5,248.4,44.7,248.4,44.8z"/>
                <path className="st0" d="M248.5,106.2c0.1,0.5,0.1,1.1,0.1,1.5c-0.4,0.3-1.6,0.2-2.2,0.1c-0.1-0.4-0.1-1.1,0-1.5
                C247,106.3,247.8,106.1,248.5,106.2z"/>
                <path className="st0" d="M248.5,98.8c0.1,0.4,0,0.8,0.1,1.3c-0.5,0.3-1.5,0.1-2.2,0.2c-0.1-0.4-0.2-1.1-0.1-1.5
                C247,98.7,247.7,98.7,248.5,98.8z"/>
                <path className="st0" d="M248.5,71.7c0.2,0.6-0.1,1.2,0,1.8c-0.8-0.4-1.5,0.4-2.5,0.1c-0.1-0.5-0.1-1,0-1.6
                C246.6,71.7,247.7,71.9,248.5,71.7z"/>
                <path className="st0" d="M248.5,110.8c0.1,0.3,0,0.7,0.1,1c-0.6,0.2-1.4,0.2-2.1,0.1c-0.1-0.3-0.1-0.6-0.1-1.1
                C246.9,110.7,247.9,110.7,248.5,110.8z"/>
                <path className="st0" d="M248.3,74c0.2,0.4,0,1.6,0.1,1.9c-0.7,0.1-1.7,0.2-2.3,0c-0.1-0.5-0.1-1,0-1.5C246.7,74.2,248,74.6,248.3,74z"/>
                <path className="st0" d="M248.1,130.2c0.1,1.1,0,2.5-0.1,3.5c-0.1,0.1-0.4,0.1-0.6,0.1c-0.1-1.2-0.3-2.5,0-3.7
                C247.8,130.1,247.8,130.1,248.1,130.2z"/>
                <path className="st0" d="M247.9,42.8c0.3,0.4,0.9,0.5,1.1,1c-1.5-0.8-4.4-1.5-6.7-0.8c-0.7,0.2-1.1,0.9-1.7,0.6
                c0.2-0.8,1.2-0.9,2.3-1.1C244.4,42.2,246.6,42,247.9,42.8z"/>
                <path className="st0" d="M247.4,46.3c0,0.4,0.1,0.8-0.1,1c-0.5,0-0.7,0.1-1.1,0c-0.1-0.2-0.1-0.8,0-1C246.3,46,247.1,46.2,247.4,46.3z"/>
                <path className="st0" d="M247.4,50.2c0,0.3,0,0.4,0,0.6c-0.3,0.1-0.8,0-1.1,0C245.9,49.9,247,50,247.4,50.2z"/>
                <path className="st0" d="M247.3,48.2c0,0.4,0,0.8-0.1,1c-0.3,0-0.7,0-0.9,0.1c-0.2-0.2-0.2-0.8,0-1.1C246.6,48.1,247,48,247.3,48.2z"/>
                <path className="st0" d="M247.2,45.2c-0.3,0.2-0.8,0.1-1.1,0c0-0.1,0-0.3,0-0.4C246.7,44.8,247.1,44.9,247.2,45.2z"/>
                <path className="st0" d="M246.5,130.2c0.2,0.9,0.1,2.3,0,3.5c-0.2,0.1-0.5,0-0.7,0c-0.1-1-0.3-2.4-0.1-3.5
                C246,130.2,246.3,130.1,246.5,130.2z"/>
                <path className="st0" d="M246,40.2c0.8,0.5,1.6,1,2.2,1.7c-1.8-0.8-4.5-0.6-6.7,0c-0.1-0.7-0.1-1.5,0-2.2c1.1-0.1,2.4-0.4,3.5-0.2
                c0.1,0.6-0.1,1.8,0.6,1.6C246,40.9,245.9,40.6,246,40.2z"/>
                <path className="st0" d="M245.3,94.4c0.1,5.4,0.3,12.1,0.2,17.4c-0.3-0.3-0.2-0.8-0.2-1.2c-0.2-5.4-0.3-10.8-0.2-16.1
                c0-0.1,0-0.1,0-0.2C245.2,94.4,245.2,94.4,245.3,94.4z"/>
                <path className="st0" d="M245.2,44.5c0.2,1.2,0.1,2.4,0.1,3.8c0,0.4,0.1,0.9-0.2,1.1c0-1.8-0.2-3.3,0-4.9
                C245.1,44.5,245.2,44.5,245.2,44.5z"/>
                <path className="st0" d="M245.1,60.6c0.6,4.2,0,8.5,0,12.6c0,1,0.3,1.9-0.1,2.8c-0.4-0.9-0.4-2.7-0.3-4.2c0.1-1.5,0-2.8,0.2-4
                c-0.3-2,0-4.7,0-7c0,0,0.1-0.1,0-0.2C245,60.6,245.1,60.6,245.1,60.6z"/>
                <path className="st0" d="M244.9,130.3c0,1,0.2,2.1,0,3.3c-0.2,0-0.4,0.1-0.6,0c0.1-1-0.3-2.2-0.2-3.3
                C244.4,130.3,244.7,130.2,244.9,130.3z"/>
                <path className="st0" d="M244.5,110.7c0,0.5,0,0.9,0.1,1.3c-0.8,0.2-2,0.2-2.9,0.2c-0.1-0.4,0-1,0-1.3
                C242.9,110.7,243.6,110.9,244.5,110.7z"/>
                <path className="st0" d="M244.5,106.4c0,0.4,0,0.8,0,1.3c-0.8,0.2-1.9,0.1-2.8,0c-0.1-0.4-0.1-1.1-0.1-1.4
                C242.8,106.1,243.6,106.1,244.5,106.4z"/>
                <path className="st0" d="M244.5,108.6c0,0.5,0,1-0.1,1.3c-0.6,0.2-1.8,0.1-2.7,0.2c-0.2-0.4-0.1-1.1,0-1.6
                C242.7,108.5,243.8,108.5,244.5,108.6z"/>
                <path className="st0" d="M244.4,103.7c0.1,0.4,0.1,1.1,0,1.6c-0.7,0.2-1.9,0.1-2.7,0c-0.1-0.4-0.1-1-0.1-1.4
                C242.4,103.7,243.5,103.5,244.4,103.7z"/>
                <path className="st0" d="M244.3,100.8c0.2,0.4,0.1,1.4,0.1,1.9c-0.9,0.1-1.9,0.3-2.8,0.2c-0.1-0.4-0.2-1.2-0.1-1.7
                C242.4,100.9,243.9,101.5,244.3,100.8z"/>
                <path className="st0" d="M244.2,98.7c0.2,0.4,0.2,1.1,0.2,1.8c-0.8-0.3-2.1,0.2-2.9-0.2c0-0.4-0.1-1,0-1.4
                C242.2,98.7,243.3,98.8,244.2,98.7z"/>
                <path className="st0" d="M244.3,48.2c0.2,0.2,0.1,0.8,0,1.1c-0.5,0.1-1,0.1-1.4,0c0-0.3-0.1-0.6,0-1C243.2,48.1,243.8,48.2,244.3,48.2
                z"/>
                <path className="st0" d="M244.3,50.2c0,0.2,0,0.4,0,0.6c-0.4,0.2-0.9,0.1-1.3,0C242.4,49.9,243.8,50.1,244.3,50.2z"/>
                <path className="st0" d="M244.3,46.2c0,0.4,0,0.8,0,1c-0.4,0.2-1,0.2-1.6,0.1c0-0.4-0.1-0.8,0.1-1.1C243.3,46.2,243.7,46.1,244.3,46.2
                z"/>
                <path className="st0" d="M244.2,94.5c0.2,1,0.1,2.2,0,3.3c-0.8,0.1-2,0.2-2.7,0.1c-0.1-0.3,0-0.9-0.1-1.3c0.6-0.4,2.2,0.4,2.6-0.4
                c-0.2-0.9-1.8-0.2-2.5-0.6c-0.1-0.3,0-0.9,0-1.2C242.5,94.5,243.3,94.4,244.2,94.5z"/>
                <path className="st0" d="M244.2,44.6c0,0.3,0,0.4,0.1,0.7c-0.5,0.1-1.1,0.2-1.5,0C242.8,44.7,243.7,44.6,244.2,44.6z"/>
                <path className="st0" d="M244.1,62.6c-0.1,0.5,0.1,1-0.1,1.6c-0.6,0.1-1.5-0.1-2.1,0c-0.2,0-0.4,0.2-0.6,0.2c0.1-0.6-0.1-1.2,0.1-1.6
                C242.2,62.6,243.4,62.3,244.1,62.6z"/>
                <path className="st0" d="M244,60.6c0,0.4,0.1,0.8,0,1c-0.9,0.1-1.6,0.1-2.5,0.2c-0.1-0.4-0.1-0.9,0-1.3
                C242.4,60.6,243.4,60.3,244,60.6z"/>
                <path className="st0" d="M244,67.2c0,0.4,0.1,1,0,1.4c-0.7,0.2-1.7,0.2-2.5,0.1c-0.1-0.4,0-0.9,0-1.4C242.2,67.2,243.2,67.1,244,67.2z"/>
                <path className="st0" d="M244,65.1c0.1,0.2,0,0.7,0,1.1c-0.6,0.3-1.6,0.2-2.5,0.2c-0.1-0.4-0.2-1.1-0.1-1.6
                C242,65.1,243.4,64.9,244,65.1z"/>
                <path className="st0" d="M244,69.6c0,0.4,0,0.9,0,1.3c-0.7,0.3-1.6,0.3-2.5,0.3c-0.1-0.6-0.1-1.2,0-1.7
                C242.2,69.5,243.3,69.4,244,69.6z"/>
                <path className="st0" d="M244,76c-0.7,0.1-1.5-0.1-2.3,0c-0.2-0.4-0.1-0.9-0.2-1.3c0.6-0.2,1.7-0.2,2.3-0.2C243.9,75,244,75.5,244,76z"/>
                <path className="st0" d="M243.9,71.9c0.2,0.4,0,1,0,1.5c-0.5,0.3-1.6,0.4-2.3,0.2c0-0.5,0-0.9,0-1.4C242.1,71.9,243,71.9,243.9,71.9z"/>
                <path className="st0" d="M243.3,130.3c0.2,0.9,0.5,2.5,0.1,3.5c-0.5-0.3-0.2-1.5-0.3-2.3C243.1,130.9,242.9,130.3,243.3,130.3z"/>
                <path className="st0" d="M241.9,49.4c0,0.5,0.3,1,0,1.3c-0.6,0.2-0.4-1-0.4-1.7c0-1.7-0.1-3.1,0-4.6c0.3-0.2,0.6-0.5,1.2-0.5
                c-0.1,0.4-0.7,0.4-0.8,0.8c0,0.1,0,0.4,0,0.6C242,46.3,241.8,48.3,241.9,49.4z"/>
                <path className="st0" d="M240.6,39.6c0.2,0.9,0.1,1.8,0.2,2.6c-0.3,0.5-1.2,0.5-1.3,1.1c0,0.5,1,1.3,1.1,1.8c0,1.8,0.3,4-0.1,5.7
                c-0.8,0.1-1.8,0.1-2.4-0.1c-0.2-3.8-0.4-7.5-0.3-11C238.5,39.4,239.7,39.5,240.6,39.6z"/>
                <path className="st0" d="M238,100.4c0,1-0.1,2.1-0.1,3.1c0,4.4,0.3,9.1,0,13.4c-1.4-0.1-3.1,0-4.6-0.1c-0.4-6.5-0.1-12.9-0.1-19.9
                c0-2.2,0-4.6,0-7c0-1-0.2-2.1-0.1-3.2c0.2-4.1,0.1-7.9,0.1-12.3c0-3.2-0.2-6.4-0.1-9.6c0-2.7-0.3-5.4-0.1-8.3
                c1.4-0.1,3.1-0.1,4.5,0.1c0.4,1.9,0.3,3.8,0.2,5.8c-0.1,1.9,0.1,3.9,0.1,5.8c0,0.5-0.1,1.1-0.1,1.6c0,0.4,0.1,0.8,0.2,1.2
                c0.1,2.1-0.2,4.2-0.2,6.1c0,1.3,0.1,2.6,0.1,3.9c0,1.3-0.1,2.6-0.1,3.9c0,3.1,0.1,6.4,0.2,9.6c0,1.1-0.1,2.5,0.1,3.3
                c0.1,0.3,0,0.4,0.3,0.4c-0.1,0.3-0.3,0.3-0.3,0.6C237.8,99.3,238,99.9,238,100.4z"/>
                <path className="st0" d="M236.9,39.5c0.1,3.4,0.4,7.5,0.3,11.1c-2.2,0.1-4.6,0-6.6-0.2c-0.1-1.3-0.2-2.7-0.1-4.4c0-0.4,0-0.9,0.1-1.3
                c0.1-0.2,0.5-0.9,0.7-1.1c0.3-0.3,0.6-0.2,0.7-0.5c0.1-0.4-1-1.2-1.4-1.4c-1.1-0.7-3.1-1.4-4.8-1.2c-2.1,0.3-3.7,0.8-4.5,2.3
                c0.1,0.5,0.7,0.7,0.9,1.3c0.3,0.8,0.3,2.3,0.3,3.5c0,1,0,2-0.2,2.8c-0.3,0.1-0.5,0-0.9,0c-0.3,0-0.7,0.2-1,0.2
                c-0.2,0-0.4-0.2-0.7-0.2c-2.4-0.3-5.2,0.1-7.2-0.3c-0.1-1.9,0-3.6-0.1-5.6c0.3-0.7,1.2-0.9,1.3-1.6c0.2-0.9-1.2-1.6-2.1-2
                c-0.7-0.3-1.6-0.5-2.3-0.6c0.1-0.2,0.2-0.8,0.3-1.2c1.4,0,2.6,0,4,0.2c0.5,2.8,0.4,6.3,0.7,9.2c0,0.4,0.1,0.9,0.4,1
                c0.8-0.4,0.2-1.6,0.2-2.5c0-2.4-0.3-5.4-0.4-7.7c1.1-0.1,2.5-0.1,3.5,0c0.2,2.4,0.2,5.5,0.4,8.2c0,0.8-0.2,1.8,0.4,2.4
                c0.6-0.4,0.4-1.5,0.4-2.2c-0.1-2.8-0.3-5.6-0.2-8.4c1.2-0.1,2.3-0.2,3.5-0.1c0.2,0.4,0,1.3,0.5,1.5c0.6-0.1,0.2-1,0.4-1.5
                c1.2,0,2.8-0.2,3.9,0c0.2,0.5,0.1,1.5,0.8,1.3c0.3-0.3-0.1-0.9,0.2-1.2c1.2-0.2,2.6-0.2,3.7,0c0.4,2.4,0.1,5.4,0.1,8
                c0,0.4,0.1,0.8,0.1,1.2c0,0.6-0.2,1.8,0.3,1.8c0.5,0,0.4-1,0.4-1.2c0-0.6,0-1,0-1.5c0-2.4,0-5.9,0.1-8.5
                C234.4,39.2,235.7,39.4,236.9,39.5z"/>
                <path className="st0" d="M232.2,68.9c-0.1,2.7,0.3,5.3,0.1,7.9c-0.1,1.3-0.1,2.7-0.1,4.1c0,1.3-0.1,2.5-0.1,3.8c0,2.6,0,5.2,0,7.7
                c-0.1,3.1-0.1,6.2-0.1,9.3c0,1.3,0.2,2.6,0.2,3.8c0,0.9-0.1,1.9-0.1,2.9c0,2.7,0.1,5.4,0,8.2c-3.8,0.1-7.7,0-11.4-0.2
                c-0.3-3-0.2-6.1-0.3-8.9c-0.2-2.4,0-5-0.1-7.5c-0.6-14.3-0.2-28.8-0.2-43.6c4-0.2,8.2-0.2,11.9,0C232,60.5,232.4,64.7,232.2,68.9z
                M231.8,78.1c0.2-0.5-0.1-1.1-0.1-1.6c-0.2-3.2,0.3-7,0-10.5c0-0.5-0.2-0.9-0.2-1.3c-0.2-2.2,0.1-4.5,0.1-6.5
                c-0.2-0.2-0.5-0.1-0.7-0.1c-1.8,0.2-4,0-6.1,0.2c-1.6,0.1-3.1-0.1-4.2,0c-0.2,0.8,0.2,1.7,0.2,2.6c0,2.1-0.1,4.3-0.1,6.5
                c0,2.2,0.2,4.3,0.1,6.4c0,1.7-0.2,3.2,0.2,4.7c1.4,0.1,2.9-0.2,4.5-0.2C227.5,78.2,229.8,78.3,231.8,78.1z M231.4,96
                c0.1-1.5,0.4-2.9-0.1-4.3c-3.3,0-7.1,0.1-10.2,0.1c-0.3,0.8,0,1.7,0,2.5c0.1,1.6,0,3.2,0,4.8c0.2,4.7,0.2,9.7,0.2,14.2
                c0,0.5-0.2,0.8,0,1.2c2.8,0.2,5.9,0,8.6,0.1c0.5,0,1,0.1,1.5-0.1c0.1-1.5-0.1-3.2,0-4.7c0.2-3-0.2-6.1,0-9.2
                C231.4,99.1,231.3,97.5,231.4,96z"/>
                <path className="st0" d="M230.8,66c0.2,1.8,0.1,3.9,0.1,5.8c-0.1,1.9,0.1,3.7-0.1,5.6c-2.6,0.3-6.2,0.1-9.1,0.2
                c-0.4-2.5-0.2-5.1-0.3-7.6c-0.1-3.6,0.3-7.3,0.1-10.8c2.8-0.2,6.5-0.4,9-0.1C230.7,61.3,230.6,63.6,230.8,66z M230.3,74
                c0-1,0.2-1.9,0.1-2.9c0-0.3-0.1-0.6-0.2-0.9c-0.2-3.5,0.4-7.3-0.3-10.4c-0.3-0.2-0.6-0.1-1-0.1c-1.7,0.1-3.8,0.2-5.6,0.1
                c-0.4,0-0.9-0.2-1.3,0.1c-0.2,5.6-0.1,11.5,0.2,16.8c2.4,0,5.6,0,7.9,0.2C230.8,75.9,230.3,74.9,230.3,74z"/>
                <path className="st0" d="M230.8,92.6c0.1,3.7-0.4,7.4-0.3,11.2c0.1,3.4,0.2,6.8,0,10.1c-2.3,0.1-6,0.2-8.3,0c0-6.8-0.4-14.7-0.3-21.3
                C224.9,92.7,227.9,92.3,230.8,92.6z M230,112.9c-0.3-4.1,0.3-9.1,0.1-13.6c-0.1-2-0.1-4,0.2-5.8c-0.3-0.3-0.8-0.2-1.1-0.1
                c-2.1,0.5-5-0.4-6.7,0.3c-0.2,2.4,0,4.9,0,7.4c0,2.4,0,4.7,0,7c0,1.6-0.2,3.3,0.2,4.8c1.5,0.3,3.3,0.2,4.9,0.3
                C228.4,113.2,229.4,113.2,230,112.9z"/>
                <path className="st0" d="M230,43.7c-0.5,1.4-0.1,3.5-0.4,5.1c-0.5-1.2,0.1-3.1-0.1-4.3c-0.2-0.8-0.9-1-1.3-1.5
                C228.8,43.1,229.6,43.4,230,43.7z"/>
                <path className="st0" d="M229.5,50.1c0.1,0,0.1,0.3,0,0.3C229.5,50.3,229.4,50.1,229.5,50.1z"/>
                <path className="st0" d="M229.6,72.1c0,0.6,0.1,1.2-0.1,1.7c-0.7,0-1.5,0.2-2.1,0.1c-0.2-0.4-0.1-1.2,0-1.7
                C228,72.1,229.2,72,229.6,72.1z"/>
                <path className="st0" d="M229.5,74.6c0,0.3,0.2,1,0,1.2c-0.6,0.1-1.5,0.2-2.1,0.1c-0.2-0.2-0.1-0.9-0.1-1.2
                C227.9,74.5,228.9,74.5,229.5,74.6z"/>
                <path className="st0" d="M229.5,69.3c0,0.6,0.2,1.2,0.1,1.8c-0.6,0.1-1.6,0.2-2.3,0.1c0-0.4-0.1-1.1,0-1.6
                C227.9,69.4,228.9,69.6,229.5,69.3z"/>
                <path className="st0" d="M229.5,62.3c0.1,1.3,0,2.2,0.1,3.4c-0.5,0.3-1.7,0.2-2.4,0.1c-0.1-0.4-0.1-1,0.1-1.4c0.7-0.1,1.9,0.1,1.8-0.6
                c-0.1-0.7-1.3-0.1-1.9-0.3c0-0.4-0.1-0.9,0.1-1.2C227.9,62.3,228.8,62.2,229.5,62.3z"/>
                <path className="st0" d="M229.5,66.8c0,0.6-0.1,1-0.1,1.8c-0.7-0.2-1.5,0.4-2.2,0.1c-0.1-0.6-0.2-1.2-0.1-1.8c0-0.1,0-0.1,0.1-0.1
                C227.8,66.8,229,66.7,229.5,66.8z"/>
                <path className="st0" d="M229.4,94.2c0.1,0.3,0.1,1,0,1.3c-0.5,0.2-1.3,0.3-1.8,0.2c-0.1-0.3,0-0.9-0.1-1.3
                C228.2,94.3,228.7,94.2,229.4,94.2z"/>
                <path className="st0" d="M229.4,100.9c0.1,0.4,0.1,1.1,0,1.5c-0.5,0.1-1.2,0.4-1.8,0.2c-0.1-0.3-0.2-1.1-0.1-1.7
                C228.2,100.8,228.6,101,229.4,100.9z"/>
                <path className="st0" d="M229.3,98.5c0.1,0.5,0.2,1.1,0.1,1.6c-0.8,0-1.3,0.1-1.9,0c-0.1-0.4-0.1-1.1,0.1-1.5
                C228.2,98.5,228.6,98.6,229.3,98.5z"/>
                <path className="st0" d="M229.3,60.4c0.1,0.2,0.1,0.7,0,0.9c-0.6,0.1-1.4,0.2-2.1,0.1c-0.1-0.3,0-0.7,0.1-1
                C227.8,60.3,228.7,60.4,229.3,60.4z"/>
                <path className="st0" d="M229.3,96.4c0.1,0.3,0,0.8,0,1.2c-0.4,0.2-1.1,0.1-1.7,0.1c-0.2-0.2-0.2-0.8-0.2-1.2
                C228,96.4,228.7,96.3,229.3,96.4z"/>
                <path className="st0" d="M229.3,103.4c0.1,0.4,0,1,0,1.5c-0.4,0.3-1.3,0.3-1.8,0.1c-0.2-0.4-0.1-1,0-1.4
                C228,103.4,228.7,103.2,229.3,103.4z"/>
                <path className="st0" d="M229.2,108.2c0.1,0.6,0.1,1.3,0.1,1.9c-0.5,0.2-1.2,0.2-1.8,0.1c-0.2-0.6-0.2-1.2-0.1-1.8
                C227.7,108,228.7,108.1,229.2,108.2z"/>
                <path className="st0" d="M229.3,105.9c-0.1,0.4,0,1-0.1,1.4c-0.4,0.2-1.3,0.2-1.8,0.1c-0.1-0.4-0.1-1.2,0-1.6
                C228.1,105.9,228.7,105.8,229.3,105.9z"/>
                <path className="st0" d="M229.1,111c0.1,0.3,0,0.9,0,1.2c-0.5,0.2-1.2,0-1.7,0.1c0-0.5-0.2-0.9,0-1.3
                C227.9,111.1,228.7,110.9,229.1,111z"/>
                <path className="st0" d="M229.1,130.2c0.1,0.2,0,0.5,0,0.8c0,0.9,0.2,2.2-0.3,2.8c-0.4-0.2-0.4-0.9-0.4-1.4c0-0.7,0-1.4,0-2.1
                c0,0,0.1-0.1,0.1-0.1C228.5,130.1,229,130.1,229.1,130.2z"/>
                <path className="st0" d="M228.6,49.6c0,0.3,0.1,0.7-0.1,0.9c-0.4,0-0.8,0-1.1-0.1c0-0.2-0.1-0.4-0.1-0.7
                C227.7,49.5,228.2,49.6,228.6,49.6z"/>
                <path className="st0" d="M228.6,47.7c0.1,0.2,0.1,0.8,0,1c-0.4,0-0.8,0-1.2,0c-0.1-0.2-0.1-0.8,0-1C227.7,47.6,228.3,47.6,228.6,47.7z"/>
                <path className="st0" d="M228.6,45.7c0.1,0.3,0.1,0.9-0.1,1.1c-0.4,0-0.7,0-1.1-0.1c-0.2-0.3,0-0.6,0-1
                C227.8,45.7,228.3,45.6,228.6,45.7z"/>
                <path className="st0" d="M228.6,44.7c-0.2,0.2-0.8,0.2-1.1,0.2c0.1-0.4-0.1-0.7,0-1C228.1,44,228.4,44.3,228.6,44.7z"/>
                <path className="st0" d="M227.6,130.1c0.2,1,0.1,2.6,0.1,3.6c-0.1,0.1-0.4,0-0.6,0.1c-0.2-1.2-0.3-2.5,0-3.6
                C227.1,130.1,227.4,130.1,227.6,130.1z"/>
                <path className="st0" d="M227.7,43c-1.9-0.3-3.3,0.2-4.2,1.2c-0.1,2.2,0.1,4.2-0.2,6.1c-0.2-0.2-0.2-0.6-0.2-0.9c0-0.3,0-0.6,0-1
                c0-1.4-0.3-3.1,0.1-4c0-0.2-0.2-0.4-0.2-0.6c0.6-0.7,2.1-1.1,3.4-1.1C226.8,42.7,227.5,42.7,227.7,43z"/>
                <path className="st0" d="M223.9,41.7c2-0.5,5.3-0.4,6.3,1c-1.7-0.7-4.2-1.2-6.2-0.5c-0.7,0.2-1.1,0.8-1.7,0.8
                C222.3,42.4,223.1,41.9,223.9,41.7z"/>
                <path className="st0" d="M226.6,99.6c0.1,4.1,0,8.2-0.1,12.1c-0.4-3.2-0.1-6.5-0.2-9.8c0-2.5,0-5-0.1-7.5c0-0.1,0.1-0.1,0.1-0.2
                c0.1,0,0.1,0,0.2,0C227,96.1,226.6,97.9,226.6,99.6z"/>
                <path className="st0" d="M226.6,44.4c0.1,1.2,0,2.9-0.1,4.3c0,0.6,0.1,1.2-0.1,1.6c-0.2-1.8,0-4,0.1-5.9
                C226.6,44.3,226.6,44.4,226.6,44.4z"/>
                <path className="st0" d="M226.4,60.5c0,5,0.2,10.1,0,15.2c-0.4-0.4-0.2-1-0.2-1.4c0-2.8-0.2-5.9-0.2-8.7c0-1.6,0.2-3.2,0.1-4.8
                c0-0.1,0-0.1,0.1-0.2C226.2,60.5,226.3,60.5,226.4,60.5z"/>
                <path className="st0" d="M226.2,130.2c-0.2,1.2,0.1,2.4,0,3.5c-0.1,0.1-0.4,0-0.7,0.1c-0.2-1.2-0.2-2.3-0.1-3.5
                C225.5,130,226.2,130.1,226.2,130.2z"/>
                <path className="st0" d="M225.7,105.9c0.1,0.5,0,1.2-0.1,1.6c-0.6,0.3-1.7,0.2-2.3,0c-0.1-0.4-0.1-1.3,0-1.5
                C223.9,105.9,224.8,105.8,225.7,105.9z"/>
                <path className="st0" d="M225.6,100.9c0.1,0.5,0.1,1.1,0,1.7c-0.6,0.3-1.5,0.3-2.4,0.2c-0.1-0.5,0-1.1,0-1.6
                C224,101,224.9,100.8,225.6,100.9z"/>
                <path className="st0" d="M225.6,103.5c0.1,0.4,0,1.1,0,1.4c-0.7,0.2-1.7,0.3-2.3,0.1c0-0.5,0-0.8,0-1.4
                C224,103.5,225,103.5,225.6,103.5z"/>
                <path className="st0" d="M225.6,43.9c0.2,0.2,0.1,0.8,0.1,1.1c-0.3,0.2-0.9,0.1-1.2,0.1c-0.1-0.2,0-0.3,0-0.6
                C224.8,44.3,225.1,44,225.6,43.9z"/>
                <path className="st0" d="M225.6,98.9c0.1,0.3,0,0.8-0.1,1c-0.7,0.2-1.3,0.4-2.2,0.2c-0.1-0.4-0.2-0.9,0-1.3
                C224,98.9,225,98.9,225.6,98.9z"/>
                <path className="st0" d="M225.6,108.4c0,0.4,0.1,1-0.1,1.4c-0.7,0.2-1.4,0.2-2.2,0.2c-0.2-0.4-0.2-1.3,0-1.7
                C224,108.5,225,108.4,225.6,108.4z"/>
                <path className="st0" d="M225.6,46c0,0.4,0,0.6-0.1,0.8c-0.3,0.1-0.8,0-1.2-0.1c-0.1-0.2-0.1-0.7,0-0.8
                C224.7,45.8,225.3,45.9,225.6,46z"/>
                <path className="st0" d="M225.5,47.7c0.1,0.3,0,0.7,0,1c-0.3,0.2-0.8,0.1-1.2,0.1c-0.1-0.3-0.1-0.9,0.1-1.1
                C224.7,47.7,225.2,47.6,225.5,47.7z"/>
                <path className="st0" d="M225.5,96.5c-0.1,0.5,0.2,1.1,0,1.5c-0.6,0.2-1.7,0.2-2.3,0c-0.2-0.4-0.1-1-0.1-1.5
                C223.8,96.3,224.8,96.3,225.5,96.5z"/>
                <path className="st0" d="M225.5,110.7c0.2,0.3,0.1,1.1,0,1.4c-0.6,0.2-1.7,0.2-2.3,0.1c-0.1-0.4,0-1.1,0-1.3
                C223.9,110.7,224.8,110.9,225.5,110.7z"/>
                <path className="st0" d="M225.5,49.7c0,0.2-0.1,0.3,0,0.6c-0.3,0.1-0.9,0.2-1.2,0.1c0-0.2,0-0.4,0.1-0.7
                C224.8,49.7,225.3,49.5,225.5,49.7z"/>
                <path className="st0" d="M225.5,94.3c0,0.4,0,0.8,0,1.3c-0.7,0.1-1.6,0.2-2.3,0c0-0.4-0.1-0.9,0-1.3C224,94.2,224.8,94.1,225.5,94.3z"/>
                <path className="st0" d="M225.3,62.4c0.1,0.4,0.1,0.6,0.1,1.1c-0.7,0.5-1.8,0.2-2.6,0.4c0-1,0-2.4,0.1-3.3c0.7-0.2,1.5-0.1,2.4-0.1
                c0.2,0.2,0.1,0.7,0.1,1.1c-0.6,0.3-2.6-0.3-2.4,0.7C223.1,62.7,224.6,62.4,225.3,62.4z"/>
                <path className="st0" d="M225.2,69.5c0.2,0.4,0,0.9,0.1,1.5c-0.7,0.2-1.6,0.4-2.4,0.3c0.1-0.6,0-1,0-1.7
                C223.5,69.4,224.5,69.4,225.2,69.5z"/>
                <path className="st0" d="M225.2,74.6c0.1,0.3,0.1,0.8,0,1.1c-0.5,0.1-1.5,0.2-2.2,0c-0.2-0.3-0.1-0.8-0.1-1.2
                C223.6,74.4,224.6,74.5,225.2,74.6z"/>
                <path className="st0" d="M225.2,64.4c0.2,0.5,0.1,1.2,0,1.6c-0.6,0.3-1.7,0.2-2.4,0.1c0-0.5-0.1-1.1,0.1-1.5
                C223.5,64.6,224.6,64.7,225.2,64.4z"/>
                <path className="st0" d="M225.1,72c0.4,0.2,0.1,1.2,0.1,1.7c-0.6,0.1-1.7,0.1-2.3-0.1c-0.2-0.4,0-0.8-0.1-1.3
                C223.5,72.1,224.4,72.1,225.1,72z"/>
                <path className="st0" d="M225.2,67.1c0,0.5,0,1,0,1.5c-0.7,0.2-1.7,0.3-2.4,0.1c0-0.4-0.1-1.2,0-1.8C223.6,67,224.6,66.9,225.2,67.1z"/>
                <path className="st0" d="M224.7,130.2c0.1,1.1,0.1,2.5,0,3.5c-0.8,0.1-0.6-1.2-0.6-2.1c0-0.5,0.1-1,0.1-1.4c0-0.1,0.1-0.1,0.1-0.2
                C224.5,130.1,224.6,130.1,224.7,130.2z"/>
                <path className="st0" d="M219.7,110.3c0,2.1,0,4.3,0,6.3c-1.2,0-2.5,0-3.9-0.1c-0.4-4.9-0.2-9.9-0.4-15c-0.1-2.5,0.1-5.1,0-7.7
                c0-1.4-0.2-2.8-0.2-4.2c0-3-0.1-6-0.1-8.9c0-4,0.3-8.3,0.1-12.5c-0.2-3,0.2-6.5,0-10c0-0.6-0.2-1.2,0-1.8c1.2-0.1,2.6-0.1,3.8,0
                c0.4,4.1,0.1,8.1,0.1,12.2c0,2,0.1,4,0,5.9c-0.1,2-0.1,4.1,0,6.2c0.1,3.8,0.1,7.8,0.2,11.6c0.2,4.2,0.1,8.3,0.1,12.3
                C219.4,106.6,219.7,108.4,219.7,110.3z"/>
                <path className="st0" d="M214.7,109.3c0,0.6-0.1,1.1-0.1,1.7c0,1.7,0.3,3.7,0,5.6c-2,0-4.1,0.2-6.1,0.3c-2.2,0-4.2,0.2-6.3,0
                c0.1-3-0.2-5.9-0.1-8.9c0.1-2.3,0.1-4.6,0-6.9c-0.1-2.3-0.3-4.7-0.3-7c-0.1-9.4,0-19.1,0-29.2c0-2.9-0.2-5.7-0.1-8.2
                c2.7-0.2,5.4,0,8.3-0.2c1.5-0.1,3,0,4.2,0.2c0.2,1,0.1,2,0.2,3.1c0.1,1.1,0.1,2.3,0.1,3.4c0,4.7,0.1,9.2-0.1,13.6
                c-0.1,2.7-0.1,5.4,0,7.9c0.1,2.8-0.1,5.6,0.2,8.5c0.2,2.2,0.1,4.4,0.1,6.7c-0.1,1.8,0,3.9-0.1,5.9
                C214.5,106.9,214.7,108.1,214.7,109.3z M214,110.7c0.1-2.9,0.3-6.2,0.2-9.6c0-1.7-0.2-3.3-0.1-5c0.1-1.5,0.2-3.1-0.2-4.4
                c-2.2,0.1-4.4,0.2-6.7,0.2c-1.6,0-3.1-0.2-4.4-0.2c-1,0-0.2,2.1-0.7,2.6c0.2,0.3,0.2,0.8,0.2,1.2c0,4.6,0.4,9.5,0.3,14.1
                c0,1.7-0.3,3.4,0.2,4.9c2.8,0.2,6.3,0,8.9,0c0.5,0,2.1-0.1,2.3-0.4c0.2-0.3,0.2-1,0.1-1.6C214.2,112,214,111.2,214,110.7z
                M213.6,78.1c0.3-0.9,0.3-2,0.2-3c-0.2-5.3-0.2-11-0.2-16.6c-0.3-0.3-0.7-0.3-1-0.3c-1.1,0.1-2.2,0.2-3.4,0.2
                c-2.3,0.1-4.8-0.2-6.8-0.1c-0.1,4.5,0,9.8,0.1,15c0,1.7-0.3,3.3,0.1,4.8c1.9-0.1,3.8,0.2,5.7,0.1c1.4-0.1,2.7-0.2,4-0.2
                C212.7,78,213.2,78.2,213.6,78.1z"/>
                <path className="st0" d="M213.3,98.5c0.1,1.9,0.2,3.9,0.2,5.7c0,1.2-0.1,2.3-0.1,3.4c0,1.2-0.2,2.3-0.2,3.5c0,0.8,0.2,1.6,0,2.3
                c-3.1,0.4-6.2,0.4-9.6,0.4c-0.2-3.7,0-7.8-0.1-11.4c0-1.8-0.3-3.6-0.3-5.8c0-1.3,0-2.7,0.1-4.1c3.4,0.2,6.5-0.2,9.9,0.1
                C213.5,94.7,213.2,96.6,213.3,98.5z M212.5,94.4c0.1-0.3,0.4-0.5,0.4-0.9c-0.2-0.3-0.5-0.2-0.7-0.2c-1.2,0-3,0.1-4.6,0.1
                c-1.4,0-2.8-0.3-3.6,0.3c-0.4,5.1,0.1,10.7,0,15.3c0,1.4-0.2,2.7,0.2,3.9c1.3-0.1,3.3,0.1,5,0c0.8,0,2.9,0.2,3.2-0.2
                c0.3-0.3,0-1,0-1.4c0-0.9,0-1.8-0.2-2.4c0.2-2.5,0.2-5.2,0.2-7.8c0-1,0.1-2,0-3.1C212.5,96.8,212.3,95.5,212.5,94.4z"/>
                <path className="st0" d="M213.1,70.2c0,0.4-0.1,0.8-0.1,1.1c0,1.9,0.3,4-0.1,5.9c-1.3,0.2-2.6,0-3.8,0.1c-0.3,0-0.7,0.1-1,0.2
                c-1.6,0.1-3.2-0.2-4.8-0.2c0-5.4-0.3-12.6,0-18.2c3.2,0.2,6.3-0.1,9.5,0c0.2,2.3,0,4.7,0.1,7.1C213,67.6,213,68.9,213.1,70.2z
                M212.3,66.5c0.1-1.5,0.1-2.8,0-4.6c0-0.8,0.3-1.9-0.1-2.3c-0.2-0.2-1-0.2-1.3-0.1c-2.1,0.5-4.9,0.1-7,0.3c-0.3,0.4,0,0.9,0,1.3
                c0,5.2,0.1,10.3,0.2,15.4c1,0.4,2.7-0.1,3.9,0.2c1.3-0.5,3.2,0.1,4.3-0.3C212.4,73.5,212.1,69.5,212.3,66.5z"/>
                <path className="st0" d="M212.3,42.2c0.4,0.2,1,1,0.2,1.2c-0.5,0.1-1.7-0.8-2.1-1c-1.1-0.4-3.1-0.7-4.6-0.3c-0.7,0.2-1,1-1.9,0.6
                c0.9-1.3,2.7-1.7,5-1.5C210.2,41.3,211.5,41.7,212.3,42.2z"/>
                <path className="st0" d="M211.6,103.7c0.2,0.4,0.1,1.2,0,1.7c-0.6,0.1-1.6,0.1-2.1,0c0-0.4,0-1,0-1.6
                C210.1,103.7,211,103.5,211.6,103.7z"/>
                <path className="st0" d="M211.7,44.1c0,2.2,0.1,4.3-0.1,6.2c-0.4-0.2-0.3-0.9-0.3-1.3c-0.1-1.5,0.2-3.5-0.2-4.8
                c-1.2-0.6-3.2-1.2-4.9-0.5c-0.2,0.1-0.3-0.3-0.7-0.2c-0.4,0.3-0.2,0.9-0.2,1.3c0,1.7-0.2,3.8-0.3,5.6c-0.4-0.5-0.2-1.3-0.2-2
                c0-1.5,0-3.3,0.2-4.9C206.8,42.2,210.2,43,211.7,44.1z"/>
                <path className="st0" d="M211.5,94.1c0.2,0.3,0.2,0.9,0.1,1.3c-0.6,0.2-1.6,0.2-2.2,0c-0.1-0.4,0.1-0.8,0.1-1.1
                C210.1,94.1,211,94.3,211.5,94.1z"/>
                <path className="st0" d="M211.6,101.3c0.1,0.4,0.2,1,0,1.4c-0.6,0.2-1.5,0.2-2.1,0.1c-0.1-0.4-0.2-1.1-0.1-1.5
                C210.1,101.3,210.8,101.2,211.6,101.3z"/>
                <path className="st0" d="M211.6,96.3c0.1,0.4,0,1,0,1.5c-0.6,0-1.6,0.3-2.2-0.1c0-0.5-0.1-1,0.1-1.5C210.1,96.2,210.9,96.4,211.6,96.3
                z"/>
                <path className="st0" d="M211.6,98.6c0.2,0.4,0,1.4,0,1.9c-0.7,0.1-1.7,0.1-2.2-0.1c0.1-0.5,0-1,0-1.6C210,98.6,211,98.8,211.6,98.6z"/>
                <path className="st0" d="M211.6,62.7c0.1,0.3,0.1,0.9,0,1.2c-0.7,0-1.5,0.1-2.4,0c0-0.3-0.1-0.9,0.1-1.3
                C209.8,62.6,211,62.5,211.6,62.7z"/>
                <path className="st0" d="M211.6,64.7c0.1,0.4,0,0.8,0,1.3c-0.8,0.3-1.6,0.2-2.3,0.2c-0.2-0.5-0.1-0.8-0.1-1.4
                C209.9,64.6,210.9,64.8,211.6,64.7z"/>
                <path className="st0" d="M211.4,60.3c0.4,0.1,0.2,1.2,0.2,1.5c-0.8,0-1.6,0-2.3-0.1c-0.1-0.3-0.1-0.9,0-1.1
                C209.9,60.5,210.7,60.5,211.4,60.3z"/>
                <path className="st0" d="M211.5,110.9c0.1,0.2,0.1,0.8,0,1.1c-0.6,0.1-1.6,0.3-2,0c-0.1-0.4-0.1-0.6-0.1-1
                C210,110.8,210.9,110.8,211.5,110.9z"/>
                <path className="st0" d="M211.5,106.1c0.1,0.5,0.1,1,0,1.4c-0.6,0.1-1.3,0.3-1.9,0.2c-0.1-0.4-0.1-1,0-1.4
                C210,106.1,210.8,106.1,211.5,106.1z"/>
                <path className="st0" d="M211.5,108.4c0.2,0.4,0.1,1.2,0,1.7c-0.6,0.2-1.5,0.2-2.1-0.1c0-0.5,0-1,0.1-1.4
                C210.2,108.5,210.9,108.2,211.5,108.4z"/>
                <path className="st0" d="M211.5,67c0,0.4,0.2,1.1,0,1.5c-0.8,0.1-1.4-0.2-2.2,0c-0.1-0.4-0.1-1,0-1.4C209.9,67,210.8,66.9,211.5,67z"/>
                <path className="st0" d="M211.5,130.3c0.2,1,0,2.4-0.1,3.4c-0.2,0.1-0.5,0.2-0.7,0c-0.2-1.1,0.1-2.1,0-3.4c0-0.1,0.1-0.1,0.2-0.2
                C211.1,130.3,211.4,130.2,211.5,130.3z"/>
                <path className="st0" d="M211.5,69.4c0,0.6,0.1,1,0,1.6c-0.6,0-1.6,0.2-2.2-0.1c0-0.5-0.1-1,0-1.6C210.1,69.2,210.8,69.2,211.5,69.4z"/>
                <path className="st0" d="M211.5,71.8c0,0.5,0,1.4-0.1,1.9c-0.4,0-1.5,0.2-1.9,0c-0.1-0.5-0.1-1.2-0.2-1.8
                C209.8,71.6,210.7,71.7,211.5,71.8z"/>
                <path className="st0" d="M211.4,74.6c0,0.4,0.1,0.8,0,1.2c-0.5,0-1.3,0.1-1.9-0.1c-0.1-0.3-0.1-1,0.1-1.2
                C210,74.4,210.8,74.6,211.4,74.6z"/>
                <path className="st0" d="M210.6,64.4c0,0.3-0.4,0.1-0.3-0.1C210.4,64.3,210.5,64.3,210.6,64.4z"/>
                <path className="st0" d="M210.4,46.1c0.1,0.2,0,0.6,0,0.9c-0.3,0-1,0.2-1.3-0.1C208.9,45.9,209.7,46,210.4,46.1z"/>
                <path className="st0" d="M210.4,47.9c0,0.3,0.1,0.6,0,0.8c-0.3,0.1-0.8,0-1.2,0.1c-0.1-0.2-0.1-0.7,0-0.9
                C209.6,47.9,210,47.9,210.4,47.9z"/>
                <path className="st0" d="M210.4,49.6c0.1,0.2,0.1,0.4,0.1,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.2-0.1-0.2-0.6-0.1-0.8
                C209.6,49.6,210,49.6,210.4,49.6z"/>
                <path className="st0" d="M210.3,44.8c0,0.2,0.1,0.2,0.1,0.4c-0.3,0.1-0.9,0-1.2-0.1c0-0.2,0-0.5,0.1-0.6
                C209.5,44.7,210,44.6,210.3,44.8z"/>
                <path className="st0" d="M210,130.2c0.1,0.3,0.1,0.7,0.1,1.1c0,0.9,0,2.1-0.2,2.6c-0.5,0-0.4-0.7-0.4-1.1c0-0.8-0.1-1.5,0-2.4
                C209.6,130.2,209.8,130.2,210,130.2z"/>
                <path className="st0" d="M208.8,130.3c0.1,1.2,0,2.5,0,3.5c-0.2,0.1-0.6,0.1-0.8,0c-0.1-1.1-0.1-2.3,0-3.4
                C208,130.1,208.6,130.2,208.8,130.3z"/>
                <path className="st0" d="M208.8,39.2c0.2,0.3,0.3,1,0.3,1.2c-1.5-0.1-3.2-0.1-4.4,0.6c-0.6,0.4-1.6,1.2-1.7,1.6
                c-0.1,0.7,0.8,0.7,0.9,1.3c0,2.5,0,4.3-0.1,6.7c-0.2,0-0.6,0-0.9,0c-0.7-0.7-1.3-1.5-2-2.3c-0.3-0.4-0.6-0.8-0.9-1.1
                c-0.7-0.8-1.7-1.6-2.7-2.4c-1.9-1.5-3.9-2.7-6.2-4c0.1-0.3,0.2-1.1,0.4-1.5c0.9,0,2.2-0.1,3.2,0.1c0.2,0.7-0.1,2.4,0.6,2.4
                c0.7,0,0-1.8,0.3-2.5c1.1-0.1,2.5-0.1,3.5,0c0.2,1.4,0.2,3.5,0.2,5.1c0,0.5-0.2,1.9,0.4,1.9c0.5,0,0.3-0.6,0.3-1
                c0-1.7,0-4.1,0.1-5.9c1.1-0.1,2.2,0.1,3.3,0.2c0.2,0.4,0,1.3,0.4,1.5c0.6-0.1,0.2-1,0.5-1.5C205.9,39.2,207.5,39.4,208.8,39.2z"/>
                <path className="st0" d="M208.5,94.3c0.1,5.8,0.4,12,0,17.7c-0.3-0.2-0.2-0.7-0.2-1.1c0-0.6,0.1-1.1,0.1-1.7c0.2-2.4-0.2-4.9-0.3-7.4
                c0-2.5,0-5.2,0.2-7.4c0-0.1,0-0.1,0-0.2C208.4,94.3,208.5,94.3,208.5,94.3z"/>
                <path className="st0" d="M208.5,70.2c0,1.8,0.3,3.9-0.1,5.6c-0.3-0.5-0.2-1.2-0.2-1.7c0-4.6-0.2-8.5,0-13.3c0-0.1,0-0.1,0-0.2
                c0.1,0,0.1,0,0.2,0C208.4,63.8,208.5,67,208.5,70.2z"/>
                <path className="st0" d="M208.3,44.3c0.2,2.1,0,4.2,0,6.2c-0.3,0-0.2-0.4-0.2-0.6c-0.2-1.5,0-3.7-0.1-5.4c0-0.1,0.1-0.1,0.1-0.2
                C208.2,44.3,208.3,44.3,208.3,44.3z"/>
                <path className="st0" d="M207.6,106.6c0,0.4,0,1-0.1,1.3c-0.8,0.2-2,0.1-2.7-0.1c0-0.5-0.1-1,0-1.4
                C205.8,106.5,206.9,106.2,207.6,106.6z"/>
                <path className="st0" d="M207.6,108.8c0,0.5,0,0.9-0.1,1.3c-0.9-0.1-1.7,0.2-2.5,0c-0.2-0.3-0.1-0.9-0.1-1.5
                C205.6,108.6,206.9,108.6,207.6,108.8z"/>
                <path className="st0" d="M207.4,103.9c0.2,0.5,0.2,1.2,0.1,1.7c-0.8,0.1-2,0.1-2.6-0.1c-0.1-0.4-0.1-1.1,0-1.5
                C205.6,103.8,206.5,104,207.4,103.9z"/>
                <path className="st0" d="M207.4,94.2c0.1,0.3,0.1,0.8,0.1,1.2c-0.7,0.3-2,0.2-2.8,0c-0.1-0.4,0-0.8,0.1-1.3
                C205.6,94.1,206.6,94.1,207.4,94.2z"/>
                <path className="st0" d="M207.5,110.9c-0.1,0.4,0.1,0.8,0,1.2c-0.7,0.1-1.7,0.2-2.4,0c-0.1-0.3-0.2-0.6-0.2-1
                C205.6,110.9,206.6,110.8,207.5,110.9z"/>
                <path className="st0" d="M207.4,101.2c0,0.6,0.1,1.1,0.1,1.8c-0.6,0.3-1.9,0.3-2.6,0c0-0.5-0.1-1.3-0.1-1.8
                C205.4,101,206.6,101.1,207.4,101.2z"/>
                <path className="st0" d="M207.4,96.4c0,0.4,0.1,1-0.1,1.3c-0.7,0.2-1.9,0.2-2.7,0c0-0.4-0.1-0.9,0-1.3
                C205.6,96.4,206.6,96.2,207.4,96.4z"/>
                <path className="st0" d="M207.4,72c0,0.4,0.1,1.1,0,1.5c-0.8,0.1-1.6,0.2-2.5,0.1c-0.1-0.5-0.2-1.2,0-1.7C205.6,72,206.7,72,207.4,72z"/>
                <path className="st0" d="M207.4,98.6c0.2,0.5-0.1,1.1,0,1.7c-0.7,0.2-1.9,0.2-2.7,0.1c-0.1-0.5,0-1.3,0.1-1.7
                C205.7,98.6,206.6,98.6,207.4,98.6z"/>
                <path className="st0" d="M207.3,69.5c0,0.5,0.2,1.1,0,1.5c-0.8,0.2-1.7,0.1-2.6,0c0-0.4-0.1-0.9,0-1.4
                C205.5,69.4,206.4,69.5,207.3,69.5z"/>
                <path className="st0" d="M207.3,67.1c0.1,0.4,0.1,1.1,0,1.5c-0.8,0.1-1.8,0.2-2.6,0.1c-0.2-0.5,0.1-1,0-1.6
                C205.4,67,206.5,66.9,207.3,67.1z"/>
                <path className="st0" d="M207.3,74.5c0.1,0.3,0.1,1-0.1,1.3c-0.7,0.1-1.7,0.2-2.4,0.1c-0.1-0.4-0.2-0.9,0-1.3
                C205.6,74.4,206.6,74.3,207.3,74.5z"/>
                <path className="st0" d="M207.3,60.7c0.1,0.3,0,0.7,0.1,1.1c-0.8,0.3-1.9,0-2.7-0.2c-0.1-0.2,0-0.7,0-0.9
                C205.6,60.7,206.6,60.7,207.3,60.7z"/>
                <path className="st0" d="M207.3,130.3c0,1.4,0.2,2.3,0,3.6c-0.2,0-0.4,0.1-0.6,0c-0.2-1.1-0.2-2.9,0.2-3.7
                C207,130.2,207.1,130.3,207.3,130.3z"/>
                <path className="st0" d="M207.3,62.6c0.1,0.2,0.1,0.8,0,1.1c-0.6,0.3-1.9,0.3-2.7,0.2c-0.1-0.4,0-1,0.1-1.4
                C205.5,62.3,206.4,62.7,207.3,62.6z"/>
                <path className="st0" d="M207.2,49.8c0.1,0.1,0,0.5,0.1,0.7c-0.4,0-1,0.2-1.3,0C205.7,49.6,206.6,49.8,207.2,49.8z"/>
                <path className="st0" d="M207.2,46.1c0.1,0.3,0.1,0.9,0,1.1c-0.4,0-0.9,0-1.2-0.1c0-0.4,0-0.7,0.1-1C206.4,45.9,206.9,46.1,207.2,46.1
                z"/>
                <path className="st0" d="M207.2,64.7c0.1,0.4,0.1,0.9,0.1,1.3c-0.6,0.3-2,0.4-2.7,0.2c0-0.4-0.1-1,0-1.3
                C205.3,64.7,206.4,64.5,207.2,64.7z"/>
                <path className="st0" d="M207.2,48c0.1,0.2,0,0.4,0.1,0.8c-0.2,0.3-1,0.1-1.3,0.2c-0.1-0.3,0-0.8,0-1C206.3,47.9,206.9,47.9,207.2,48z"/>
                <path className="st0" d="M207.1,44.3c0.1,0.2,0.1,0.7,0,0.9c-0.2,0-0.9,0.1-1.1-0.2C206.3,44.7,206.8,44.3,207.1,44.3z"/>
                <path className="st0" d="M205.6,130.2c0.2,1.1,0.2,2.6,0,3.6c-0.3,0.1-0.9,0.2-1.3,0.1c-0.7,0-1.2,0-2,0c-2.1,0-4.3-0.2-6.5-0.1
                c-1.6,0-3.3,0-4.7-0.1c-0.1-1.1-0.6-2.9,0-3.8c0.2,0,0.1,0.3,0.3,0.3c4.3-0.2,8.7-0.3,13.4-0.2C205,130.1,205.3,130.1,205.6,130.2
                z"/>
                <path className="st0" d="M201.1,113.3c0.1,0.9,0.3,1.8,0.3,2.7c0,1-0.2,2.3-0.2,3.4c0,1.2,0.4,3.8,0,5.2c-0.1,0.4,0,0.4-0.4,0.4
                c-0.3,0-0.3-0.3-0.3-0.7c-0.1-1.6,0.2-3,0.1-5c0-1.5-0.2-3.1,0-3.9c0.1-0.4,0.4-0.5,0.4-0.9c0-0.5-0.3-0.6-0.6-1.1
                c0.2-0.3-0.1-0.5-0.1-0.9c0-0.3,0.2-0.6,0.2-0.8c0-0.4-0.5-0.6-0.6-0.9c-0.2-0.7,0.1-1.9,0.1-2.7c0.2-2.6-0.2-5.5-0.2-8.5
                c0-0.5,0.1-0.9,0.1-1.4c0-0.9-0.2-1.8-0.2-2.7c-0.1-3.9-0.3-7.4,0-11.3c0.1-1-0.1-2-0.1-3.1c0.4-6.8-0.1-14.4-0.2-20.3
                c0-0.4-0.2-0.9-0.1-1.3c0.1-0.6,0.8-1.1,0.1-1.7c0.3-0.3,0.4-0.8,0.6-1.2c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1
                c0.3,4.9,0.3,10.3,0.5,15.6c0,0.5,0.1,1,0.1,1.5c0,0.6-0.2,1.2-0.2,1.8c0,0.7,0.1,1.4,0.1,2.1c0,1.2-0.1,2.7-0.1,4.2
                c0.1,3.6-0.2,7.1,0.1,10.6c0.1,1,0,2.2,0.1,3.3c0,0.8,0.2,1.6,0.2,2.4c0.1,1.2,0,2.5,0,3.6c0,1.2,0.1,2.4,0.1,3.6
                C201.2,108,200.9,110.7,201.1,113.3z"/>
                <path className="st0" d="M199.9,114.2c0.4,0.3-0.1,1-0.2,1.4c0,0.4,0.1,0.8,0.1,1.3c-0.1,2.7,0,5.6-0.2,8.2c-1.9,0.4-3.9,0-6,0.1
                c-0.3-2.7-0.5-6.6-0.1-9.2c1,0.1,2-0.2,3.2-0.2c0.6,0,2.8,0.4,2.9-0.3c0.1-0.8-3.3-0.3-4.1-0.3c-0.5,0-1.2-0.2-1.8-0.1
                c-0.4,0.1-0.4,0.3-0.7,0.4c-0.2-0.5-0.1-1.4,0.3-1.6c0.3,0,0.1,0.4,0.3,0.5c0.7,0.3,1.6,0.1,2.7,0.1c0.9,0,1.6,0.2,2.3,0
                C199.2,114.3,199.6,113.9,199.9,114.2z"/>
                <path className="st0" d="M199.6,111.7c-0.1,0.3-0.3,0.7-0.2,1.1c-0.3-0.1-0.1-0.5-0.4-0.6c-1.5-0.1-2.9,0.2-4.3,0
                c-0.2,0.1-0.3,0.2-0.6,0.2c0-0.4-0.4-0.3-0.5-0.6c0.1-0.1,0.2-0.3,0.3-0.4c1.2,0.2,2.8-0.1,4.3,0
                C198.7,111.5,199.3,111.3,199.6,111.7z"/>
                <path className="st0" d="M199.2,112.9c0,0.2,0,0.5-0.1,0.7c-1.8,0.1-3.6,0.1-5.4,0.1c0-0.4,0.2-0.6,0.2-0.9c0.2,0,0.2,0.3,0.4,0.3
                C195.8,112.8,197.5,113.1,199.2,112.9z"/>
                <path className="st0" d="M199.2,56.8c0,0-0.1,0.1-0.1,0.2c-0.2,0.2-0.3-0.2-0.6-0.3c-0.1,0-0.3,0-0.5,0.1c-1.1,0-3.1-0.2-3.8,0
                c-0.2,0.1-0.4,0.2-0.4,0.4c-0.2-0.1-0.2-0.4-0.2-0.7c0,0,0.1,0,0.1,0c1.3,0,2.9,0,4.2,0C198.4,56.5,199,56.5,199.2,56.8z"/>
                <path className="st0" d="M198.9,93.2c0.2,5.9,0.4,11.7,0.2,17.3c-1.3,0.2-3-0.2-4.6-0.1c-0.2-2.7,0-5.8,0-8.8c0-1.1-0.1-2.3-0.1-3.4
                c0-1.6,0-3.7-0.1-5.4c0-0.4-0.1-1-0.1-1.3c0.1-0.2,0.5-0.2,0.6-0.6c0.1-0.4-0.3-0.6-0.4-0.9c-0.2-1.3,0-2.8,0-4.3
                c0-4.3,0.2-9,0-13.7c-0.1-1.5-0.2-3.2-0.2-4.9c0-2.6-0.1-5,0-7.5c1.5-0.2,3,0.1,4.3-0.3c0,0,0.1,0.1,0.1,0.1
                c0.4,5.3,0.5,11.2,0.4,16.9C199,82,198.7,87.8,198.9,93.2z"/>
                <path className="st0" d="M198,58.6c0.4-0.1,0.8-0.4,1,0.1c-0.1,0.2-0.2,0.4-0.4,0.4c0-0.2-0.1-0.4-0.3-0.4c-1.3,0.3-3.2-0.1-4.4,0.4
                c-0.2-0.1-0.4-0.2-0.4-0.5C194.5,58.5,196.7,58.8,198,58.6z"/>
                <path className="st0" d="M194,57.6c1.6,0,3-0.2,4.6,0c-0.6,0.3-1.7,0.2-2.7,0.2C195.3,57.7,194.5,57.9,194,57.6z"/>
                <path className="st0" d="M193.5,108.2c0,1,0.2,2.2-0.4,2.8c-0.2-2,0.1-4.6,0-6.9c-0.1-2.6-0.2-5.2,0.1-7.7c0.4,2.5,0,5.7,0.2,8.4
                C193.5,105.9,193.5,107.1,193.5,108.2z"/>
                <path className="st0" d="M193.4,74.9c0.2,3.6,0,7.1,0,10.4c0,1.6,0.1,3.1-0.1,4.7c-1.7-0.9-3.2-2.1-5.1-2.9c0.1-0.6,0.2-1.6-0.3-1.8
                c0.2-0.2,0.2-0.3,0.3-0.6c0.1-1.8-2.9-1.5-1.9,0.4c-0.2,0.5-0.3,1.3-0.2,1.8c-1.7,1.3-3.8,2.2-5.7,3.2c-0.3-6.3,0.3-13.6,0.2-20.4
                c0-0.5,0.1-1.1,0.2-1.6c0.3-1.9-0.2-3.8-0.2-5.7c0-0.6-0.1-1.2-0.2-1.8c0-0.6,0.2-1.2,0.3-1.8c0-0.4-0.1-0.8,0-1.1
                c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.4,0.1-0.8,0.4-1.1c3.6-0.3,7.6-0.2,11.2,0.1c0.2,0.4,0.3,0.8,0.4,1.3c-0.7,0.2-1.6,0.2-2.5,0.2
                c-1.7,0-3.7,0-5.4,0c-1,0-1.9,0.2-2.7,0.2c-0.4,0-1.5-0.2-1.4,0.5c0,0.3,0.4,0.3,0.6,0.4c-0.1,1.2-0.8,2.7-0.1,3.7
                c-0.2,0.5-0.3,1.3,0,1.7c-0.2,1.1-0.2,2.6,0.3,3.3c-0.2,0.3-0.4,0.6-0.4,1c0,0.3,0.2,0.5,0.3,0.8c-0.3,0.4-0.4,1.1,0,1.5
                c-0.6,0.4-0.3,1.3,0,1.8c-0.8,1.2,1,1.5,0.7,2.4c-0.3-0.2-0.1-0.6-0.4-0.7c-0.8-0.2-0.4,1.3,0,1.6c-0.2,0-0.4,0.1-0.4,0.2
                c0.3,0.7-0.2,1.2,0.3,1.7c-0.8,0.3-0.2,1.5,0.2,1.8c1.3,0.3,1.9-1,1.4-1.8c2.5-0.3,5-0.2,7.6,0c-0.1,0.4-0.5,0.6-0.4,0.9
                c-0.1,0.2-0.4-0.1-0.4,0.1c-0.1,0.5,0.6,0.4,0.8,0.3c0.3,0.1,0.2,0.5,0.6,0.5c-0.4,0.3-0.3,0.7-0.6,1.2c-0.1,0.3-0.4,0.4-0.4,0.7
                c0,0.4,0.4,1,0.8,1.1c0.6,0.2,1.6-0.1,1.7-0.6c0.1-0.6-0.4-0.7-0.6-1c-0.2-0.6,0.1-1.3-0.4-1.7c1-0.2,0.8-1.4-0.2-1.4
                c0.4-0.3,0.9-0.2,1-0.7c0.1-0.4-0.5-0.5-0.3-1.1c-0.2-0.4-0.5-0.6-0.7-1c0.7,0,0.8-0.8,0.4-1.2c0-0.3,0.3-0.3,0.3-0.6
                c-0.1-0.4-0.3-0.7-0.6-1.1c0.9-0.3,0.3-1.2,0-1.7c0.2-0.1,0.4-0.1,0.5-0.3c0.3-0.4-0.1-0.7-0.1-1.2c0-0.2,0.3-0.5,0.3-0.7
                c0-0.2-0.3-0.5-0.3-0.7c0-0.2,0.2-0.4,0.2-0.6c0-0.3-0.2-0.6-0.4-0.8c0.3-0.4,0.4-1,0-1.4c0-0.2,0.3-0.3,0.4-0.6
                c0-0.2-0.2-0.5-0.2-0.9c0-0.7,0.4-1.3-0.2-1.7c0.6-0.5,0.4-1.8,0.3-2.6c-0.5-0.1-1-0.5-1.6-0.5c-1-0.1-2.1,0.2-3.3,0.2
                c-1.3,0-2.7,0-3.9,0.1c-0.4,0-1,0.2-1.3-0.2c0.4-0.4,1-0.2,1.5-0.2c2.8-0.1,5.6-0.1,8.6,0c0.6,0.6,0.8,1.5,0.8,2.4
                c0,2.6-0.2,5.2,0,7.7c0,0.9,0.2,1.9,0.3,2.8C193.5,72.8,193.4,73.9,193.4,74.9z M190.7,80.4c0-0.2,0.1-0.2,0.1-0.4
                c-0.1,0-0.1-0.1-0.2-0.2c-0.3,0-0.5,0.1-0.7,0.1c0-0.2,0-0.4-0.1-0.5c-0.2,0-0.4,0-0.6,0c-0.3,0.5-1.2,0.3-1.4,0.9
                c-0.6,0.1-1.1,0-1.7,0.1c-0.4-0.3-0.7-0.8-1.5-0.6c-0.2-0.4-1-1-1.1-0.3c-0.1,0.5,0.4,0.8,0.6,1.2c-0.4,0.5,0.1,1.3,0.8,1.3
                c0.2,0.4,0.5,0.7,1.2,0.7c0.8,0.9,3.4-0.2,3.3-1c0-1-1.6-0.5-1.8,0.2c-0.2-0.3-0.8-0.5-1.2-0.3c-0.1,0.1,0,0.4-0.3,0.4
                c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.2,0.1-0.3,0.1-0.6c-0.4-0.4-0.7,0.2-0.9,0.2c-0.2-0.1-0.3-0.2-0.3-0.5c0.4-0.3,1.1,0.2,1.5-0.1
                c0.4,0.6,1.7,0.6,2-0.1c0.6,0.1,1-0.4,1.5-0.3c-0.1,0.3-0.3,0.4-0.3,0.8C189.8,81.6,190.2,80.6,190.7,80.4z M182.5,83.2
                c1.2,0,1.3-1.8,0.6-2.6c-0.1,0-0.3-0.1-0.5,0c-0.1-0.2-0.1-0.4-0.3-0.5c-1,0.3-1.1,1.4-0.8,2.5C181.9,82.8,182.1,83.2,182.5,83.2z
                M182.6,57.1c0-0.2,0-0.4-0.3-0.4C182.4,56.8,182.5,57,182.6,57.1z"/>
                <path className="st0" d="M193.2,93.7c0.1,0.4,0.1,1.1,0.1,1.4c-0.1-0.4-0.5-0.9-0.2-1.4C193.1,93.7,193.2,93.7,193.2,93.7z"/>
                <path className="st0" d="M193.2,91.6c-3.4,0-8.1,0.1-11.3,0.1c-0.4,0-1.3-0.2-1.3,0.2c-0.1,0.7,1.7,0.5,2.2,0.4c2.7,0,5.8,0,8.7,0
                c0.6,0,1.3-0.1,1.9,0.2c0,0.4-0.6,0.3-0.9,0.3c-0.7,0-1.4-0.1-1.9-0.1c-0.6,0-1.1,0.2-1.7,0.2c-2.8,0-5.8-0.1-8.6-0.1
                c-0.1-0.4-0.1-1.1,0-1.5c2.4-0.3,5.2,0.1,7.7-0.2c1.1-0.1,2.7,0,3.9,0.1C192.4,91.3,192.9,91.3,193.2,91.6z"/>
                <path className="st0" d="M192.5,115.5c0,0.5,0.1,1.1-0.1,1.5c-0.4-0.4-0.9-0.2-1.7-0.2c-2.4,0.1-5.8-0.2-8.2,0.1
                c-0.6,0.7-0.2,2-0.3,3.3c0,1-0.2,3.3,0.3,3.8c2-0.1,4.5-0.2,6.5-0.3c0.7-0.1,1.6,0,2.1,0.1c0.2,0.1,0.4,0.3,0.6,0.3
                c0.3,0,0.4-0.3,0.6-0.2c0,0.3,0.1,0.8-0.1,1.1c-3.1,0-7.3,0.1-10.6-0.1c0-2.5-0.1-5.2-0.2-7.9c0-0.4,0.1-0.8,0.1-1.2
                c0-0.3-0.2-0.3-0.3-0.6c0.7-0.2,1.5-0.1,2.4-0.2c1.1-0.1,2.3,0.1,3.4,0.1c1.3,0,2.7-0.2,3.8-0.3c0.2,0,0.7-0.1,0.9,0
                C192.2,115,192.2,115.5,192.5,115.5z"/>
                <path className="st0" d="M192.4,118c0.2,0.6,0.1,1.9,0,2.6C192.3,119.8,192.3,118.8,192.4,118z"/>
                <path className="st0" d="M192.4,121c0.3,0.2,0.2,1.1,0.1,1.3C192.2,122,192.4,121.5,192.4,121z"/>
                <path className="st0" d="M192.3,113.1c0.2,0.3-0.1,0.5-0.2,0.8c-2.9,0.6-6.9,0.2-10.1,0.4c0.2-0.5-0.2-0.8-0.2-1.2
                c-0.2-1.2-0.1-2.8-0.2-4.3c-0.2-3.2,0.1-6.9,0-10.4c0-1,0.3-1.9,0.1-2.8c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0.4,0.2,0.2,0.9,0.2,1.5
                c-0.1,2.5-0.1,5.4,0,8.1c0.2,2.8-0.1,5.7,0.4,8.3C185.8,113.6,189.2,113.4,192.3,113.1z"/>
                <path className="st0" d="M192,95.1c0.4,0.2,0.2,1,0.2,1.6c0,1,0,2.1-0.1,3.1c-0.1,0.9,0.2,2-0.2,2.7c-0.2-2.2-0.2-5.1-0.1-7.3
                C192,95.1,192,95.1,192,95.1z"/>
                <path className="st0" d="M192.3,90.4c-1,0.1-1.4-0.5-2.1-0.9c-0.8-0.4-1.6-0.7-2.2-1c-0.3-0.2-0.5-0.4-0.8-0.4c-0.2,0-0.2,0.2-0.5,0.3
                c-0.3,0.1-0.7,0.1-0.9,0.2c-1,0.4-2.1,1.2-2.8,1.8c-0.4,0-0.8,0-1.2,0.1c1.6-1.2,3.7-1.8,5.2-3C189,88.2,190.8,89.2,192.3,90.4z"/>
                <path className="st0" d="M192,102.5c0.2,3.1,0.2,7,0.2,10.2c-0.5-0.2-0.4-0.8-0.4-1.4c0.1-1.4,0.1-3.1,0.1-4.7
                C191.9,105.1,191.8,103.5,192,102.5z"/>
                <path className="st0" d="M191.8,81.5c0,0.3,0,0.5-0.2,0.6C191.3,82,191.4,81.5,191.8,81.5z"/>
                <path className="st0" d="M191.5,117.7c-0.1,1.7,0.1,3.5-0.1,5.2c-2.8,0-5.5,0.2-8.2,0.3c-0.2-1.7-0.5-4.1,0-5.6
                C185.5,117.6,189.1,117.5,191.5,117.7z"/>
                <path className="st0" d="M191.1,60.1c0.1,0.2-0.2,0.4-0.3,0.8c0,0.3,0.1,0.7,0.1,0.9c0,0.5-0.4,1.2-0.1,1.7c0,0.3-0.4,0.2-0.8,0.2
                c-0.2-0.5-0.1-1.1-0.1-1.7c0.5-0.1,0.6-0.7,0-0.8c0-0.4-0.1-0.9,0.1-1.2C190.4,59.7,191,59.8,191.1,60.1z"/>
                <path className="st0" d="M191,98.3c0.3,2.5,0.2,5.4,0.2,8.1c-0.3,0.1-1,0.1-1.3,0c0-0.8,0-1.6,0-2.3c0.2-0.2,0.8-0.1,0.9-0.4
                c-0.1-0.4-0.6-0.3-0.9-0.4c-0.2-0.8,0-1.3,0-2.2c0.2-0.1,0.7-0.1,0.8-0.3c0-0.4-0.5-0.3-0.7-0.4c0-0.6,0-1.3,0-1.9
                C190.4,98.3,190.6,98.3,191,98.3z"/>
                <path className="st0" d="M191.1,107c0,0.8-0.1,1.6-0.1,2.4c-0.2,0-0.9,0-1.2,0c0-0.7,0-1.5,0-2.2C190.2,107.1,190.8,107,191.1,107z"/>
                <path className="st0" d="M190.9,95.6c0.2,0.5,0.2,1.4,0,2c-0.3,0.1-0.7,0.1-0.9,0c0-0.6,0-1.5,0.1-2.1
                C190.5,95.6,190.6,95.4,190.9,95.6z"/>
                <path className="st0" d="M191,66.6c0,0.3-0.3,0.2-0.3,0.5c0.2,1-0.2,1.7,0.3,2.5c-0.4,0.5-0.3,1.5,0.1,2c-0.5,0.6,0.1,1.4-0.4,2.2
                c-0.2,0-0.6,0.1-0.8,0c-0.2-0.5,0-1.1-0.1-1.7c0.1-0.2,0.6-0.1,0.7-0.3c0-0.4-0.4-0.3-0.7-0.4c-0.1-0.5-0.1-1.3,0-1.8
                c0.3-0.1,0.8,0,0.8-0.3c0-0.4-0.4-0.4-0.8-0.4c-0.2-0.6,0.1-1.2,0-1.8c0.1-0.2,0.5-0.1,0.5-0.4c0-0.3-0.3-0.3-0.4-0.4
                c0-0.8-0.1-1.9,0.8-1.4C190.9,65.2,190.7,66,191,66.6z"/>
                <path className="st0" d="M191,110c0.1,0.7,0,1.7,0,2.3c-0.2,0.2-0.7,0.1-1.1,0.1c0.2-0.6,0.1-1.6,0.1-2.3
                C190.2,110,190.6,110.1,191,110z"/>
                <path className="st0" d="M189.9,74.4c0.4-0.1,1-0.3,1.3,0c-0.2,0.3-0.4,0.6-0.4,1.2c-0.2,0.1-0.7,0.1-0.9,0
                C189.8,75.3,189.9,74.8,189.9,74.4z"/>
                <path className="st0" d="M190.8,93.8c-0.1,0.3,0,0.6,0.1,0.9c-0.1,0.1-0.5,0.2-0.7,0.1C190,94.1,190.2,93.3,190.8,93.8z"/>
                <path className="st0" d="M190.3,76.5c0.2,0,0.6-0.2,0.6,0.3c-0.6,0.2-1.4,0.2-2.1,0.2c-1.8-0.1-3.8,0.2-5.8,0c0.4-0.4,1.1-0.2,1.7-0.2
                c0.4,0,0.7-0.1,1.1-0.1C186.7,76.7,189,76.6,190.3,76.5z"/>
                <path className="st0" d="M190.5,39.4c0.1,0.4,0,0.8,0,1.1c-1.1-0.3-2.1-0.7-3-1.2C188.3,39.3,189.7,39.2,190.5,39.4z"/>
                <path className="st0" d="M189.9,130.3c0.2,1,0.2,2.2,0.2,3.4c-0.6,0.2-1.2,0-1.9,0c-0.4-0.7-0.3-2.4-0.1-3.4c0.1,0,0.2,0,0.3,0
                c0.3,0.8,0,2.3,0.3,3.2c0.2,0.1,0.4,0.1,0.6,0c0.2-0.9-0.4-2.5,0.2-3.3C189.6,130.2,189.9,130.2,189.9,130.3z"/>
                <path className="st0" d="M189.3,93.7c0,0.3,0.2,0.8,0,1.1c-0.2,0.1-0.7,0.1-0.9,0C188.2,94,188.4,93.5,189.3,93.7z"/>
                <path className="st0" d="M189.3,112.5c-0.4,0-0.5,0.1-0.8,0c-0.2-0.7-0.1-1.7-0.1-2.3c0.1-0.1,0.5-0.1,0.7,0
                C189.2,110.8,189.1,112.1,189.3,112.5z"/>
                <path className="st0" d="M189.2,95.6c0.1,0.7,0.1,1.4,0,1.9c-0.3,0.1-0.5,0-0.9,0c-0.1-0.6,0-1.2,0.1-1.9
                C188.5,95.5,188.9,95.7,189.2,95.6z"/>
                <path className="st0" d="M189.1,62.2c0.1,0.4,0.1,1.2,0,1.6c-0.3,0.1-0.9,0.1-1.1-0.1c0.1-0.5-0.1-1,0-1.4
                C188.2,61.9,188.9,62.1,189.1,62.2z"/>
                <path className="st0" d="M189.1,98.3c0.1,0.6,0,1.3,0,1.9c-0.2,0.1-0.7,0.1-0.8,0c-0.1-0.4,0-1.4,0.1-1.9
                C188.6,98.3,189,98.3,189.1,98.3z"/>
                <path className="st0" d="M189.1,59.9c0.1,0.4,0.2,1,0,1.4c-0.2,0.1-0.8,0.1-1,0C188,60.4,188,59.7,189.1,59.9z"/>
                <path className="st0" d="M189.2,64.6c0,0.5-0.1,0.9-0.1,1.4c-0.3,0.1-0.7,0-1-0.1c-0.1-0.3,0-0.9-0.1-1.3
                C188.4,64.5,188.9,64.5,189.2,64.6z"/>
                <path className="st0" d="M189,107.2c0.1,0.6,0,1.5,0.1,2.2c-0.2,0-0.6,0.2-0.7,0C188.4,108.6,187.8,106.8,189,107.2z"/>
                <path className="st0" d="M189.1,104.1c0,0.5,0.1,1.6-0.1,2.2c-0.1,0.1-0.6,0.1-0.7,0c-0.1-0.7-0.2-1.5,0-2.2
                C188.4,104,188.9,104,189.1,104.1z"/>
                <path className="st0" d="M189.1,66.8c0,0.6,0,1.3-0.1,1.8c-0.2,0.1-0.7,0.1-0.9,0c-0.1-0.5,0-1.2-0.1-1.8
                C188.4,66.7,188.7,66.7,189.1,66.8z"/>
                <path className="st0" d="M189,69.5c0.1,0.5,0,1.3,0,1.7c-0.3,0.1-0.6,0-0.9-0.1c-0.1-0.5-0.1-1.2,0-1.7
                C188.5,69.5,188.7,69.4,189,69.5z"/>
                <path className="st0" d="M189,72c0.1,0.5,0,1,0,1.7c-0.2,0.1-0.6,0-0.8,0c-0.2-0.5-0.1-1.2,0-1.8C188.5,72,188.8,71.9,189,72z"/>
                <path className="st0" d="M189,101c0.1,0.8,0,1.5,0,2.3c-0.2,0.1-0.4,0.1-0.7,0c-0.1-0.6-0.1-1.6,0-2.2C188.4,101,188.8,101.1,189,101z"/>
                <path className="st0" d="M189,74.5c0,0.4,0,0.8-0.1,1.2c-0.2,0.1-0.5,0.1-0.7,0c-0.1-0.3-0.1-0.9,0-1.2
                C188.4,74.4,188.9,74.4,189,74.5z"/>
                <path className="st0" d="M187,88.8c1,0.4,1.8,0.9,2.7,1.5c-1.6,0.2-3.6,0.3-5.3,0.1C185,89.6,186.3,89.5,187,88.8z"/>
                <path className="st0" d="M187.6,110.9c0,0.6,0.2,1.3-0.2,1.7c-0.2-0.2-0.2-0.8-0.3-1.2c-0.2-1.4,0.1-3.4,0.2-4.9
                c0-0.9-0.1-1.8-0.1-2.7c0-3.2,0-6.7-0.1-9.9c0-0.1,0.1-0.1,0.2-0.2c0.5-0.2,0.3,0.9,0.3,1.3c0,1.9,0,3.5-0.1,5.6
                c-0.2,3.1,0.1,5.7,0.1,8.4C187.6,109.7,187.6,110.3,187.6,110.9z"/>
                <path className="st0" d="M187.5,85.6c0,0.4-0.3,0.7-0.7,0.6c0-0.1-0.1-0.3-0.1-0.4C186.9,85.6,187.4,85.6,187.5,85.6z"/>
                <path className="st0" d="M187.3,74.2c0,0.2,0.2,1.5-0.3,1.6c-0.6,0.1-0.3-1.6-0.2-1.7c-0.3-0.8-0.2-2.1-0.2-3.3c0-3.6,0-7.2,0.1-10.7
                c0-0.1,0-0.1,0.1-0.2c0.5-0.1,0.4,0.7,0.4,1.6C187.3,65.9,187.1,70,187.3,74.2z"/>
                <path className="st0" d="M187.1,130.4c0.1,1.1,0.3,2.3,0.1,3.4c-0.2,0-0.2-0.1-0.4-0.1c-0.1-1-0.3-2.6-0.1-3.4
                C186.9,130.3,187,130.3,187.1,130.4z"/>
                <path className="st0" d="M186.2,100.9c0.4,0.6,0.1,1.6,0.2,2.3c-0.4,0.1-1,0-1.4,0c-0.1-0.6-0.1-1.6,0.1-2.1
                C185.4,101,185.9,101,186.2,100.9z"/>
                <path className="st0" d="M186.4,104.2c0.2,1.7-0.1,3.6-0.1,5.3c-0.2,0-0.6-0.2-0.9-0.2c-0.1-0.6-0.2-1.3-0.2-2.1
                c0.3-0.1,0.8-0.1,0.9-0.4c0-0.4-0.4-0.5-0.9-0.4c-0.1-0.6-0.2-1.6,0-2.2C185.5,104.1,186.1,104,186.4,104.2z"/>
                <path className="st0" d="M186.2,97.9c0.3,0.6,0.1,1.4,0.2,2.2c-0.4,0.1-0.8,0.2-1.3,0.2c-0.1-0.6-0.1-1.6,0-2.1
                C185.5,98.1,186.1,98.3,186.2,97.9z"/>
                <path className="st0" d="M186.3,97.5c-0.3,0-0.8,0-1.2-0.1c-0.1-0.5-0.1-1.2,0-1.7c0.3-0.1,0.7,0,0.8-0.4c0-0.4-0.6-0.3-0.8-0.4
                c0-0.3-0.1-0.8,0-1.1c0.4-0.1,0.8,0,1.1,0C186.2,95,186.3,96.2,186.3,97.5z"/>
                <path className="st0" d="M186.3,109.7c0,1,0.1,1.9,0.1,2.9c-0.2,0.1-0.5,0.1-0.9,0.1c-0.1-0.8,0-1.9,0-2.5
                C185.7,110,186.1,109.9,186.3,109.7z"/>
                <path className="st0" d="M185.9,62.3c0,0.5,0.1,1.1-0.1,1.5c-0.2,0-0.6,0.2-0.9,0c-0.1-0.4,0-1-0.1-1.5
                C185.1,62.2,185.7,62.2,185.9,62.3z"/>
                <path className="st0" d="M185.8,59.9c0.1,0.6,0.2,1.1,0,1.6c-0.2-0.1-0.6,0-0.8-0.1C184.9,60.8,184.6,59.6,185.8,59.9z"/>
                <path className="st0" d="M185.8,64.6c0.2,0.5,0.1,1.1,0,1.7c-0.3,0-0.6,0.2-0.9,0.1c-0.1-0.5-0.2-1.1-0.1-1.6
                C185.1,64.6,185.4,64.6,185.8,64.6z"/>
                <path className="st0" d="M185.8,131.1c0,0.8,0.2,1.9,0,2.6c-0.3-0.1-0.4-0.7-0.4-1.1c0-0.4,0-1,0-1.4c0-0.3-0.2-0.8,0.2-0.8
                C185.9,130.3,185.7,130.8,185.8,131.1z"/>
                <path className="st0" d="M185.8,69.2c0.1,0.6,0.1,1.2,0,1.8c-0.2,0.1-0.6,0.1-0.7,0.1c-0.2-0.4,0-1.3-0.1-1.8
                C185.2,69.2,185.5,69.2,185.8,69.2z"/>
                <path className="st0" d="M185.8,71.8c0.1,0.5,0.2,1.5-0.1,1.9c-0.2,0-0.4,0-0.6,0C185.1,73,184.6,71.4,185.8,71.8z"/>
                <path className="st0" d="M185.8,74.5c0,0.4,0,0.7,0.1,1.3c-0.2,0.1-0.4,0.2-0.7,0.2C185.1,75.4,184.8,74,185.8,74.5z"/>
                <path className="st0" d="M185.8,67.1c0,0.5,0,1,0,1.5c-0.3,0.1-0.6,0-0.9,0c-0.1-0.4,0-0.9,0-1.4C185,67,185.6,67,185.8,67.1z"/>
                <path className="st0" d="M184.6,69c0.1,0.3-0.3,0.2-0.5,0.3c0,0.6,0.2,1.4,0,1.8c-0.5,0.1-1.2,0-1.3,0.4c0-0.1-0.1-0.2-0.1-0.3
                c0-0.2,0.2-0.2,0.2-0.4c0.2-0.9-0.3-1.1-0.6-1.6c1.1,0,0.7-1.3,0.2-1.6c0.1-0.3,0.4-0.3,0.4-0.7c0.3,0.1,0.6,0.2,1,0.2
                c0,0.4,0.2,1.1,0.1,1.5c-1-0.2-0.9,0.9-0.1,0.7c0.1,0,0.1-0.1,0.1-0.3C184.2,69.2,184.5,68.8,184.6,69z"/>
                <path className="st0" d="M184.5,110.1c0.1,0.9-0.1,1.8-0.1,2.6c-0.4,0-0.8,0.1-1.1-0.1c-0.2-0.6-0.2-1.7-0.2-2.5
                C183.5,110,184.2,110,184.5,110.1z"/>
                <path className="st0" d="M184.5,109.3c-0.3,0-0.9,0-1.3,0c0-0.7-0.3-1.8,0-2.2c0.4,0,0.9-0.1,1.2,0.1
                C184.4,107.8,184.5,108.4,184.5,109.3z"/>
                <path className="st0" d="M184.3,95.7c0.1,0.5-0.1,1.1,0,1.8c-0.4,0.1-1,0.1-1.4,0c0-0.5,0-1.1,0.1-1.7
                C183.4,95.7,183.9,95.6,184.3,95.7z"/>
                <path className="st0" d="M184.3,98.3c0.1,0.4,0.1,1.4,0,1.9c-0.4,0.1-1,0.1-1.3-0.1c-0.1-0.5-0.1-1.3-0.1-1.8
                C183.4,98.2,183.7,98.3,184.3,98.3z"/>
                <path className="st0" d="M184.3,104.1c0.1,0.6,0,1.5,0,2.2c-0.3,0.1-0.9,0.1-1.2,0c0-0.7-0.3-1.5-0.1-2.2
                C183.2,103.9,184,104,184.3,104.1z"/>
                <path className="st0" d="M184.2,93.8c0.1,0.3,0.2,0.8,0.1,1.1c-0.3,0.1-0.9,0.1-1.2,0c0.2-0.3,0-0.9,0.2-1.2
                C183.6,93.7,184,93.7,184.2,93.8z"/>
                <path className="st0" d="M184.3,101.1c0.1,0.8,0,1.5,0,2.1c-0.4,0.1-1,0.2-1.3,0.1c-0.1-0.6-0.1-1.5,0-2.1
                C183.3,101.1,183.8,101,184.3,101.1z"/>
                <path className="st0" d="M184.3,74.6c-0.1,0.4,0,0.9-0.1,1.3c-0.4,0.2-1.2,0.1-1.5-0.2c0.6-0.3,0.7-1.1,0.2-1.6
                c0.4-0.7-0.1-1.2-0.4-1.6c0.2-0.1,0.4-0.2,0.5-0.3c0-0.2,0-0.4,0-0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.2,0.4,0.1,1.4,0,1.9
                c-0.3,0.1-0.8,0-0.9,0.4C183.2,74.6,184,74.3,184.3,74.6z"/>
                <path className="st0" d="M183.9,60.1c0.1,0.3,0.2,1,0.1,1.4c-0.4,0-0.9,0-1.3-0.1c0.3-0.4,0.2-1,0.1-1.3
                C183.3,60.1,183.6,59.9,183.9,60.1z"/>
                <path className="st0" d="M184,62.2c0.2,0.4,0,1.1,0,1.6c-1.2-0.2-1.4,0.8,0,0.8c0.1,0.4,0,1.1,0,1.7c-0.4,0.1-1,0.2-1.2,0.2
                c-0.1-0.5,0.3-0.7,0.2-1.2c-0.1-0.6-0.8-0.8-0.9-1.3c0.1-0.2,0.3,0.2,0.6,0.1c0.7-0.2,0.1-0.9,0.1-1.2
                C182.7,62.1,183.1,62.1,184,62.2z"/>
                <path className="st0" d="M182.4,56.7c0.2,0,0.2,0.2,0.3,0.4C182.5,57,182.4,56.8,182.4,56.7z"/>
                <path className="st0" d="M182.3,93.7c0,0.2,0,0.4,0,0.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2-0.2-0.2-0.6,0-0.8
                C182,93.7,182.1,93.6,182.3,93.7z"/>
                <path className="st0" d="M182,76.3c0.2,0,0.2,0.1,0.2,0.2C182.1,76.5,182,76.5,182,76.3z"/>
                <path className="st0" d="M181.9,61.8c0.1,0.1,0.1,0.2,0.1,0.3C181.9,62.1,181.8,61.9,181.9,61.8z"/>
                <path className="st0" d="M180.8,115.8c0.2,3.1,0.3,6,0,9.2c-1.9-0.1-4.5,0.1-6.5-0.1c-0.4-2.9-0.1-6.2-0.2-9.2c0-0.5-0.2-1,0.2-1.4
                c1,0.2,2.4,0.2,3.9,0.2c0.7,0,1.7,0.1,1.7-0.4c0-0.6-1-0.3-1.3-0.3c-1.1,0.1-2.6,0-3.5-0.1c0-0.2,0.2-0.4,0.3-0.6
                c1.2-0.1,3-0.1,4.2,0c0.4,0.6,1,0.9,1.1,1.7c-1.2,0.4-3.6,0.2-5,0.3c-0.3,0-1.3,0-1.3,0.4c0,0.6,2.2,0.2,2.7,0.2
                C178,115.7,179.7,115.9,180.8,115.8z"/>
                <path className="st0" d="M180.8,93.7c0.4,6.1,0,13.7,0.1,20.1c-0.2,0-0.2-0.3-0.2-0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.2-0.4,0.2-0.7
                c0-0.3-0.3-0.6-0.4-0.9c-0.1-0.5-0.1-1.3-0.2-2.1c0-0.7,0-1.5,0-2.2c0.2-4.1,0.1-9.1,0-13.2c0-0.1,0.1-0.1,0.2-0.2
                C180.5,93.7,180.6,93.7,180.8,93.7z"/>
                <path className="st0" d="M180.4,56.3c0.1,0.2-0.3,0.4-0.3,0.6c-1.9,0-4.1,0.4-5.9,0.1c-0.1-0.2,0-0.5,0-0.7
                C176.3,56.2,178.2,56.4,180.4,56.3z"/>
                <path className="st0" d="M179.7,64.7c0.2,2.5-0.2,5.1,0,7.4c-0.3,0.9-0.1,2-0.1,3.2c-0.1,3.6-0.1,7.6-0.2,11.2c0,0.6-0.1,1.2,0,1.8
                c0,0.6,0.1,1.3,0,1.8c0,0.4-0.3,0.5-0.3,0.8c0,0.2,0.2,0.5,0.3,0.8c0.1,0.6,0,1.4,0,2.1c0,0.7,0,1.4,0,2.1c0,0.6-0.1,1.2-0.1,1.8
                c0,0.6,0.3,1.3,0.3,1.9c0,3.8-0.2,7.7-0.3,11.5c-1.3,0.1-3,0.1-4.1-0.2c0-1.3-0.2-2.4-0.2-3.8c0-0.3-0.1-0.6-0.1-0.9
                c0.1-3.7,0.3-7.4,0.2-11.1c0-1.3-0.2-2.6-0.2-3.9c0.1-3.8,0-7.6,0-11.3c0-6.2-0.1-13.3-0.2-20c0.5-0.2,1.2-0.1,2.2-0.1
                c0.8,0,2.3,0.2,2.5-0.3c0,0,0.1,0,0.1,0C179.5,61.1,179.6,62.9,179.7,64.7z"/>
                <path className="st0" d="M179.1,58.8c0.2-0.2,0.5,0.2,0.3,0.4C179.3,59.2,179.2,59,179.1,58.8z"/>
                <path className="st0" d="M179.6,112.1c-0.2,0.4-1.1,0.2-1.7,0.2c-1.1,0-2.3,0.2-3.1,0c0.1-0.4,0.7-0.4,1.1-0.4c0.5,0,1.1,0,1.7,0
                C178.4,111.9,179.2,111.8,179.6,112.1z"/>
                <path className="st0" d="M178.4,58c0-0.1,0.5-0.2,0.5,0C178.7,58,178.5,58,178.4,58z"/>
                <path className="st0" d="M178.5,58.8c0,0.2-0.4,0.2-0.5,0C178.2,58.8,178.3,58.8,178.5,58.8z"/>
                <path className="st0" d="M177.5,58.8c-0.9,0.3-2-0.1-2.9,0.1c0-0.2,0.1-0.3,0.2-0.4C175.7,58.7,176.7,58.7,177.5,58.8z"/>
                <path className="st0" d="M174.1,78.3c-0.1,1.7,0.2,3.6,0.1,5.2c-0.1,0.7,0,1.2,0,1.9c0,0.8-0.2,1.5-0.2,2.3c0,0.7,0.3,1.4,0.1,2.1
                c-0.3,0.2-0.7,0-1.1,0c0.3-0.4,0.7-0.9,0.5-1.5c-0.7-0.3-0.7,0.4-1,0.7c-0.1-0.8-0.6-1.1-1.5-1.1c0.3-1,1.4,0.5,1.7-0.2
                c0.2-0.5-0.6-0.8-1.2-0.9c0.2-0.2,0.4-0.4,0.6-0.4c0.3,0,0.8,0.8,1.1,0.2c0.5-1.3-3-1.1-1.9,0.3c-0.3,0.1-0.5,0.2-0.7,0.4
                c-0.1,0.4,0.1,0.5,0.2,0.7c-0.6,0.1-0.8,0.4-1,0.9c-0.2,0-0.3-0.1-0.4-0.1c0.6-0.8,1.7-2.1,1.6-3.6c-0.1-0.9-1.1-1.7-2-1.7
                c-0.6,0-1,0.6-1.6,0.3c0.2-0.4,0.7-0.5,0.8-1c0.8-0.3,1.5-0.6,1.7-1.5c0.9-0.3,0.1-0.9-0.2-1.2c-0.2-0.1-0.5,0.1-0.8,0.1
                c0-0.3,0-0.5,0.1-0.7c0.2-0.1,0.5-0.1,0.8-0.2c0.1,0.3,0.2,0.6,0.4,0.8c0.4,0.1,0.5-0.3,0.8-0.2c0.3,0.9-0.7,1.4-0.6,2.3
                c0.1,0.4,0.5,1.1,1,1.2c0.6,0.1,1.6-0.6,1.6-1.3c0-0.5-0.5-0.7-0.8-1.1c0.1-0.3,0.3-0.7,0.1-0.9c0.1-0.4,0.5-0.3,0.6-0.7
                c0.1-0.2,0-0.7-0.3-0.9c-0.1-0.1-0.3,0-0.6-0.1c-0.2,0-0.4-0.2-0.5-0.2c-0.3,0-1,0.1-1.1,0.1c-0.2,0.1-0.4,0.4-0.4,0.6
                c-0.2-0.1-0.4-0.3-0.8-0.2c-0.5,0-0.8,0.6-1.1,0.9c-1,0.4-0.4,1.3,0.8,0.8c-0.1,0.3-0.7,0.2-0.6,0.7c-0.6-0.3-1.3,0-1.7,0.2
                c-0.7,0.2-1.4,0-2.2,0.1c-1.2,0-2.5,0.1-3.5-0.6c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.4-0.5-0.6-0.9-0.7c0-0.2,0.1-0.3,0.1-0.6
                c0-1.1-2-1.7-1.6-0.2c-0.3-0.3-0.3-0.8-0.8-0.8c-1.2-0.1-1.1,1.9,0.2,1.8c-0.1,0.4-0.3,0.7-0.7,0.8c0,0.7-0.5,1.2-0.4,1.7
                c0.1,0.6,0.7,0.6,1.1,1c0.8,0,1.1,0.4,1.7-0.2c0.1-0.1,0.4-0.5,0.4-0.8c0-0.6-0.4-0.7-0.6-1.2c-0.1-0.4,0-0.9,0-1.2
                c0.4-0.1,0.4,0.4,0.6,0.6c0.2,0.1,0.4,0.1,0.6,0.2c-0.5,0.7,0.1,1.9,1,1.4c0.4,0.4,0.6,0.9,1.2,1.1c0.3,0.1,0.8-0.1,0.9,0.3
                c-0.8,0.4-1.3-0.5-2.1-0.4c-0.2,0-0.5,0.2-0.7,0.3c-0.3,0.2-0.9,0.9-1,0.9c0,0-0.4-0.3-0.6-0.4c-0.7-0.4-1.8-0.4-2.2,0.4
                c0.3,0.5,0.9-0.1,1.4-0.1c0.4,0.1,0.9,0.4,0.9,0.9c-0.4,0.1-0.4-0.3-0.8-0.4c-0.7-0.2-1.6,0-1.7,0.6c0,0.3,0,0.7,0.3,0.7
                c0.4,0,0.2-0.4,0.5-0.6c0.7-0.1,1.1,0.6,1.6,0.2c0.3,0.6-0.5,0.3-0.8,0.3c-0.6,0.1-1.1,0.8-0.6,1.3c0.4-0.3,1.4-0.9,1.9-0.4
                c-0.5,0.2-1.9-0.1-1.6,0.7c0.2,0.5,1.3-0.1,1.7,0.3c0.2,0,0.3-0.2,0.5-0.2c0.4,0.4,0.9,0.6,1.2,1c0,0.2-0.1,0.3-0.1,0.5
                c-0.8,0.2-2.1,0-3,0c0.3-0.3,1.1-1,0.3-1.2c-0.4-0.1-0.4,0.2-0.8,0.3c-0.5-0.1-0.3-0.5-0.8-0.6c-0.6-0.1-1.4,0.8-0.9,1.3
                c0.4,0,0.4-0.3,0.6-0.4c0.3,0.1,0.4,0.3,0.4,0.6c-0.6,0.1-1.3,0-2.1,0c-0.1-10.1,0.2-20.3-0.2-30.2c0.3,0,0.6,0.1,1,0.1
                c0.1,1,0.6,1.7,0.4,2.7c-0.1,0.3-0.4,0.5-0.4,0.8c0,0.3,0.3,0.5,0.3,0.9c0.1,0.3,0.1,1.1,0.1,1.4c-0.1,0.4-0.6,0.6-0.6,0.9
                c0,0.4,0.3,0.7,0.6,0.9c0,0.6,0,1.3,0,1.9c-0.6,0.3-0.5,1.4,0,1.8c-0.2,0.6,0.1,1,0,1.6c-0.1,0.6-0.7,0.8-0.5,1.4
                c0.1,0.3,0.3,0.3,0.6,0.5c-0.1,0.7,0.2,1.2,0.1,1.7c-0.1,0.3-0.4,0.4-0.5,0.7c-0.2,0.4-0.1,1.2,0.2,1.4c2.7-0.2,6.2,0,9.3-0.1
                c1.1,0,2.3-0.1,3.2-0.1c0.3,0,0.6,0.1,0.8,0.1c1.1,0,3.9,0.1,4-0.8c0.1-0.4-0.5-0.9-0.6-1.2c-0.1-0.6,0-1.5,0.1-2
                c0.1-0.4,0.5-0.6,0.4-1c0-0.3-0.4-0.6-0.5-1c-0.1-0.4,0-0.9,0-1.3c0-0.4,0.4-0.9,0.3-1.3c0-0.3-0.4-0.5-0.4-0.9
                c-0.2-0.7,0.1-1.3,0-1.9c1-0.2,0.7-1.4,0.1-1.8c-0.2-1,0.5-1.8,0.3-2.6c0-0.3-0.3-0.5-0.4-0.7c-0.3-0.9,0.3-1.4,0.3-2.1
                c0-0.3-0.3-0.9-0.4-1c-0.5-0.3-3.5-0.1-4.4,0c-1.1,0.1-3.2,0-4.4,0c-3.1,0-5.8,0.2-8.3,0.1c-0.5-0.5-0.2-1.5,0.2-2
                c5.6-0.3,12.1-0.3,17.7-0.3c0.1,0.5-0.1,0.6,0,0.9c0.1,0.4,0.6,0.7,0.6,1c0,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.7
                c0.2,4.5,0,8.9,0.3,13c0.1,1.6-0.2,3.2-0.1,4.4C174.1,77.4,174.2,77.7,174.1,78.3z"/>
                <path className="st0" d="M174,92c0.2,0,0.1,0.5,0.1,0.6c-0.1-0.1-0.2-0.2-0.2-0.3C173.8,92.1,174,92.1,174,92z"/>
                <path className="st0" d="M174.1,107.7c0,1.2,0.4,2.7-0.1,3.8c-0.2-0.6-0.2-1.2-0.2-1.9c0-2.5,0-5.4,0-8.2c0-1.5-0.2-3.7-0.1-5.3
                c0-0.8,0-2.7,0.3-2.9c0,0,0,0,0,0c0.1,0,0,0.1,0.1,0.1C174.1,98.1,174.1,102.9,174.1,107.7z"/>
                <path className="st0" d="M173.9,112.8c0.4,0,0.4,0.6,0.1,0.8C173.7,113.5,173.8,113,173.9,112.8z"/>
                <path className="st0" d="M173.7,138.3c0.1,0.3,0.1,1,0,1.3c-5.8,0.4-11.2,0.3-16.9,0.1c-0.1-0.4-0.1-0.9-0.1-1.4
                c4.5-0.2,9.4,0,13.9-0.3C171.5,138,172.6,138.3,173.7,138.3z"/>
                <path className="st0" d="M173.7,140.5c0.1,0.3,0.1,1,0,1.3c-5.8,0.4-11.3,0.1-16.9,0.1c-0.1-0.3-0.2-0.8-0.1-1.2
                C162.4,140.7,167.8,140.6,173.7,140.5z"/>
                <path className="st0" d="M173.5,130.3c0,2.4,0.2,4.9,0.1,7.2c-0.2-0.2-0.3-0.5-0.3-0.8c-0.1-0.9,0-1.9,0-2.7
                c-0.1-5.5-0.4-11.1-0.7-16.8c-0.1-2-0.1-3.9-0.1-5.6c0.1,0,0.2,0,0.2-0.1c0,0,0.1,0,0.1,0c-0.2,1.9,0.4,3.8,0.3,5.8
                c-0.2,3.3,0.1,6.6,0.2,10C173.4,128.2,173.5,129.1,173.5,130.3z"/>
                <path className="st0" d="M173.7,90.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.4,0.1-0.8,0-1.2,0c-2.8-0.1-6-0.1-9.1-0.1c-1.3,0-2.7-0.1-4-0.1
                c-0.6,0-1.6,0.1-2.4,0.1c-0.7,0-1.7,0.1-1.6-0.8c1-0.4,2.7,0,4.1,0c0.3,0,0.5-0.1,0.8-0.1c0.9,0,1.8,0.1,2.8,0.1
                c2.3,0,4.7,0.2,7,0.1C171,90.7,172.4,90.6,173.7,90.8z"/>
                <path className="st0" d="M172.8,95.3c0.2,5.1,0.4,10.3,0.1,15.2c-1.9,0.3-3.8,0.3-5.8,0.3c-0.4,0-0.8-0.1-1.1-0.1
                c-0.8,0-1.6,0.2-2.3,0.3c-0.8,0-1.6,0-2.4,0c-1.6-0.1-3.2,0.1-4.8-0.2c-0.3-5.1,0.3-10.4,0.1-15.4c0-0.1,0-0.1,0.1-0.2
                C162,95.3,167.4,95,172.8,95.3z M172.6,109.7c0-0.4-0.4-0.8-0.4-1.2c0-0.5,0.1-1.2,0.1-1.8c0-1.2-0.1-2.4-0.1-3.4
                c0-1.2,0-2.4,0-3.4c0-1.3,0.4-2.9-0.2-4c-3.8,0.2-7.9-0.1-11.9-0.1c-0.9,0-2.3,0-2.8,0.1c-0.1,0.2-0.3,0.7-0.3,1
                c0,1.3,0,3.1,0,4.8c0.1,2.1,0,3.9,0.1,5.6c0.1,1-0.2,1.9-0.1,2.7c1.3,0.2,2.8,0.1,4.2,0.1c3.1,0,6.5,0,9.8-0.1
                C171.6,109.9,172.3,110,172.6,109.7z"/>
                <path className="st0" d="M172.9,92.5c0,0.5,0.1,1.3,0,1.8c-0.3-0.2,0-1.1-0.4-1.3c-0.2-0.1-0.7,0-0.9-0.2c-0.3,0-0.3,0.3-0.5,0.4
                C170.8,92.1,172.2,92.5,172.9,92.5z"/>
                <path className="st0" d="M172.4,128.4c0.1,3,0,6,0,8.9c-1.9,0.2-4.7,0.2-6.7,0.2c-0.4-8.5-0.2-16.5-0.3-24.8c0-0.4-0.1-0.7-0.2-1.1
                c1.1-0.1,2.1,0.1,3-0.2c0.9,0.4,2.3,0.1,3.2,0.2c0.6,2.6,0.4,5.4,0.6,8.2c0.1,2.1,0.3,4.3,0.3,6.5
                C172.3,127,172.4,127.6,172.4,128.4z M170.8,129.8c0.1-5.2-0.2-11.5,0.4-16.4c-0.6-0.6-2-0.5-3.1-0.6c-0.7,0-1.4,0-1.9,0.3
                c-0.2,0.7,0,1.5,0,2.2c0.1,2.6,0,5.6,0.2,8.1c0.2,2.1-0.2,4.3,0.2,6.3c0.7,0.4,1.8,0.2,2.6,0.2
                C169.8,129.9,170.4,130.3,170.8,129.8z"/>
                <path className="st0" d="M172.1,69.6c0,0.2-0.1,0.2-0.1,0.3c-0.2-0.2-0.7-0.2-0.5-0.6C171.7,69.4,171.9,69.5,172.1,69.6z"/>
                <path className="st0" d="M171.7,66.1c0-0.2,0.1-0.2,0.3-0.2C172,66.1,171.9,66.3,171.7,66.1z"/>
                <path className="st0" d="M171.9,70.4c0.3,0.5,0,1.2,0,1.8c-0.4,0.5-1.2-0.2-1.6,0.3c-0.2-0.5-0.1-1.4-0.1-2.2
                C170.9,70.2,171.3,70.2,171.9,70.4z"/>
                <path className="st0" d="M171.9,60.4c0.1,0.2,0,0.6,0,1c-0.3,0.1-0.4,0.5-0.7,0.7c-0.3,0-0.2-0.4-0.3-0.5c-0.3-0.1-0.4,0-0.7,0.1
                c-0.2-0.2-0.1-0.8,0-1.1c0.4,0.1,0.7,0,0.9-0.3C171.4,60.3,171.5,60.5,171.9,60.4z"/>
                <path className="st0" d="M171.8,74.2c0.2,0.3,0.2,1.3,0,1.7c-0.6-0.3-1.4,0-1.7,0.2c0.1-0.8-0.2-1.7,0-2.3
                C170.4,74.4,171.3,74.4,171.8,74.2z"/>
                <path className="st0" d="M171.8,93.8c0,0.2,0.1,0.2,0.1,0.4c-0.2,0.1-0.6,0.2-0.7,0c0.1-0.2,0.1-0.4,0.1-0.6
                C171.4,93.6,171.5,93.9,171.8,93.8z"/>
                <path className="st0" d="M171.8,66.9c0,0.5,0.2,1.6-0.2,1.8c-0.4-0.2-1.1-0.4-1.4-0.2c-0.1-0.4-0.2-1.1-0.1-1.6c0.2,0,0.2,0.2,0.4,0.2
                C171.1,66.8,171.2,66.6,171.8,66.9z"/>
                <path className="st0" d="M171.3,81.8c0.2-0.1,0.6,0,0.6,0.2c-0.1,0-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.1-0.3-0.2
                C171.3,82,171.3,81.9,171.3,81.8z"/>
                <path className="st0" d="M172,63.3c0,0.7,0,1.4-0.3,1.9c-0.7-0.1-1.2-0.2-1.5,0.1c-0.1-0.7-0.1-1.2-0.1-1.9
                C170.8,63.4,171.4,62.7,172,63.3z"/>
                <path className="st0" d="M171.9,59.3c0,0.3-0.4,0.2-0.8,0.2c-0.5,0.1-1.2,0.5-1.3-0.2C170.3,59.3,171.2,59.2,171.9,59.3z"/>
                <path className="st0" d="M171.5,96.7c0.2,3.8,0,8.1-0.1,12.3c-1.9,0.3-4,0.1-5.9,0.2c-2.5,0.1-5,0.2-7.4-0.1c-0.3-2.3,0-4.7-0.2-6.9
                c-0.1-2,0.2-3.6,0.1-5.4c1.3-0.2,2.7-0.2,4.5-0.1c-0.2,0.2-0.6,0.3-0.5,0.7c0.2,0.5,0.9-0.2,1.3,0.2c-0.2,0.3-0.9,0.3-1.3,0.8
                c-0.1,0.1-0.2,0.4-0.3,0.5c-0.2,0.4-0.6,0.6-0.6,0.8c0.1,0.6,0.9,0.2,1.3,0c0.1-0.2,0.1-0.6,0.3-0.8c1.2-0.6,3.4-0.4,4.1,0.3
                c0.3,0.3,0.2,1.1,0.7,1c0.4,0,0.4-0.5,0.3-1c-0.3-0.8-1.4-1.4-2.5-1.6c0.8-0.4,2.4,0.5,2.3-0.9C168.9,96.8,170.1,96.7,171.5,96.7z"/>
                <path className="st0" d="M171.5,88.9c-0.5-0.1-0.9,0.1-1,0.4c0,0.2,0.3,0.2,0.4,0.4c-0.1,0.2-0.6,0-0.9,0.1c0.2-0.4,0-0.6-0.1-0.9
                C170.5,89.2,171.1,88.3,171.5,88.9z"/>
                <path className="st0" d="M171.3,66.1c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.1-0.5-0.2-0.4-0.4C171,65.9,171.2,66,171.3,66.1z"/>
                <path className="st0" d="M171.1,77.2c-0.3,0.1-0.7-0.1-1.2,0C170,76.7,171.1,76.7,171.1,77.2z"/>
                <path className="st0" d="M170.3,69.2c0.1,0,0.4,0,0.3,0.2c-0.2,0.1-0.6,0-0.8,0.2C169.9,69.5,170.1,69.4,170.3,69.2z"/>
                <path className="st0" d="M170,73.2c0.4,0,0.5,0.4,0.3,0.7C170.1,73.7,170,73.5,170,73.2z"/>
                <path className="st0" d="M170.1,93.5c0.3,0.1,0.2,0.4,0.2,0.7c-0.2,0-0.5,0.1-0.7,0C169.7,94,170,93.8,170.1,93.5z"/>
                <path className="st0" d="M170.3,62.4c-0.1,0.1-0.3,0.1-0.4,0c0-0.2,0.1-0.1,0-0.3C170.2,62.1,170.3,62.2,170.3,62.4z"/>
                <path className="st0" d="M170.2,114.9c0,1.6,0.2,3.8-0.2,5.5c-0.4-0.4-0.6-1.5-1.4-1.3c-0.3,0.1-1.1,1.2-1.2,1.7
                c-0.2,0.9,0.1,2.2,0.8,2.4c0.7,0.2,1.3-0.3,1.7-0.9c0.2,1.6,0.2,3.9,0.1,5.7c-0.7,0.4-1.8-0.4-2.3,0.2c-0.2,0.6,0.6,0.5,1.1,0.6
                c0.4,0.1,1,0.2,1.3,0.2c0.1,0.5-0.5,0.3-0.7,0.3c-0.7,0-1.6,0.1-2.1-0.1c-0.2-1.5-0.3-2.9-0.3-4.4c0-3.6-0.2-7.3-0.1-11
                c0.4-0.2,1.3-0.1,2,0c0.5,0,1.2,0,1.2,0.4c-0.6,0.8-2.7-0.6-2.8,0.7C168.1,115,169.2,115.2,170.2,114.9z"/>
                <path className="st0" d="M170,66.3C169.2,66,170.5,65.6,170,66.3L170,66.3z"/>
                <path className="st0" d="M168.6,84.3c1.1-0.2,1.7,0.4,1.5,1.4c-0.3-0.2-0.3-0.5-0.5-0.8C169.3,84.7,168.8,84.7,168.6,84.3z"/>
                <path className="st0" d="M169.4,92.4c0.2-0.1,0.4,0,0.4,0.2C169.6,92.7,169.4,92.6,169.4,92.4z"/>
                <path className="st0" d="M169.4,70.6c0,0.6,0,1.2,0,1.8c-0.6,0.3-0.6,1.3-0.1,1.8c-0.1,0.6,0.1,1.1,0,1.7c-0.4,0.1-1.1,0.1-1.6,0
                c-0.2-0.4-0.1-1.1,0-1.5c0.6-0.1,1.3,0.1,1.2-0.5c-0.1-0.5-0.8-0.1-1.2-0.4c0-0.4,0-1,0-1.3c0.5-0.2,1.4,0,1.4-0.5
                c0-0.6-0.8-0.3-1.4-0.4c-0.2-0.4-0.1-1.1-0.1-1.6c0.2-0.1,0.7,0,1.1,0C168.9,70,169.1,70.3,169.4,70.6z"/>
                <path className="st0" d="M169.3,63.1c0.2,0.4,0.2,1.4,0,1.9c-0.1,0.4-0.7,0.7-0.6,1.4c-0.2,0-0.7,0.1-1.1,0c-0.1-0.4,0-1.1,0.1-1.6
                c1.3,0.3,1.2-1.1-0.1-0.7c-0.1-0.4-0.1-1,0-1.4c0.4-0.2,1.1-0.1,1-0.6c0-0.5-0.6-0.3-1-0.3c-0.1-0.4,0-1-0.1-1.4
                c0.4-0.1,1.2-0.2,1.6-0.1c0.4,0.8-0.4,1.6-0.3,2.3C168.9,62.8,169.2,62.9,169.3,63.1z"/>
                <path className="st0" d="M169.1,66.8c0.2,0.4,0.2,1.1,0.2,1.6c-0.2,0.6-1.1,0.4-1.7,0.4c-0.1-0.5,0-1.2,0.1-1.6c0.6,0,1.2,0,1.3-0.4
                C169.1,66.8,169.1,66.8,169.1,66.8z"/>
                <path className="st0" d="M169.1,69.3c0.2-0.1,0.2,0.4,0,0.3C169.1,69.5,169.1,69.4,169.1,69.3z"/>
                <path className="st0" d="M169.1,85.7c0.2,0.6-0.1,1-0.2,1.5c-0.3,0.2-0.8,0.1-1.1,0c-0.8,0.3-0.5,1.6-1.4,1.8c0-1.1-1.2-0.4-1.7-0.8
                c-0.4,0.2-0.8,0.3-0.9,0.8c-0.6-0.1-0.8-0.7-1-1.2c-0.6-0.2-1.2-0.2-1.6-0.6c-0.3-0.8-0.2-1.5,0.6-1.5c0.4,0,0.6,0.4,1.1,0.3
                c-0.3,1.3,2.8,0.6,1.8-0.5c0.1-0.2,0.6-0.1,0.7-0.1c-0.9,1.2,0.9,1.9,1.6,1c0-0.2,0-0.4,0.1-0.6C167.7,85.7,168.5,84.9,169.1,85.7
                z"/>
                <path className="st0" d="M169.4,89.8c-0.2,0.1-0.4,0.1-0.7,0.1c0-0.3-0.2-0.5-0.2-0.8c0.1,0,0.2-0.1,0.3-0.1
                C168.8,89.5,169.1,89.7,169.4,89.8z"/>
                <path className="st0" d="M168.9,69c0.2,0,0.2,0.1,0.2,0.3c0,0-0.1,0.1-0.2,0.1C168.8,69.2,168.9,69.1,168.9,69z"/>
                <path className="st0" d="M169,80.9c0.2,0.1-0.1,0.4-0.1,0.7c-0.5,0-0.7,0.6-1.2,0.3c0.2-0.2,0.5-0.2,0.4-0.7
                C168.5,81.3,168.9,81.2,169,80.9z"/>
                <path className="st0" d="M169.1,93.4c-0.1,0.2-0.2,0.6-0.1,0.9c-0.7,0-1.2,0.1-1.9,0.1c-0.3-0.7-0.2-1.7-0.9-1.9c0.2-0.3,0.7,0,0.8,0
                c0,0.8,0.3,1.9,1.1,1.7c0.8-0.2-0.5-1-0.3-1.7c0.2-0.1,0.6-0.1,0.8,0c-0.2,0.2-0.6,0.7-0.2,1C168.7,93.5,168.9,93.4,169.1,93.4z"/>
                <path className="st0" d="M168.6,120.3c0.8-0.3,1.1,2.1,0.1,2.1C168,122.3,168.1,120.5,168.6,120.3z"/>
                <path className="st0" d="M168.8,77c-0.1,0.4-0.6,0.2-0.9,0.2c-2.5,0-5.8,0-8.5-0.1c-0.1-0.1,0-0.3-0.2-0.3c0,0,0-0.1,0-0.1
                c2.2,0,5.7,0.2,8.1,0.1C167.8,76.9,168.4,76.6,168.8,77z"/>
                <path className="st0" d="M167.9,89.8c-0.3,0.1-0.8,0-1.2,0.1c0.3-0.4,0.8-0.5,1-0.9C167.7,89.2,167.5,89.7,167.9,89.8z"/>
                <path className="st0" d="M167.4,82c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.1-0.5,0.1-0.7,0.2c-0.4-0.5-1.1-0.2-1,0.5c-0.3,0-0.5,0-0.8,0
                c0-0.6-1.1-1.1-1.2-0.2c-0.3-0.2-0.7,0-1.2,0c-0.1-0.2-0.1-0.6-0.2-0.7c-0.7,0.1-1.1-0.5-1.7-0.1c-0.1-0.2-0.1-0.3,0.1-0.4
                c1,0,1.9,0.6,2.9,0.7c0.6,0,1.3-0.2,1.9,0C166.1,82.1,166.8,82.3,167.4,82z"/>
                <path className="st0" d="M166.9,72.2c-0.1,0.4,0,0.8,0,1.3c-0.4,0.2-1.1,0.1-1.6,0c0-0.5,0-0.8,0-1.3
                C165.8,72.1,166.4,72.1,166.9,72.2z"/>
                <path className="st0" d="M166.8,69.6c0,0.4,0.1,1.2,0,1.7c-0.5,0.2-1.1,0.1-1.6,0c0-0.3-0.1-1.2,0-1.7
                C165.7,69.5,166.4,69.5,166.8,69.6z"/>
                <path className="st0" d="M166.8,67.2c0.2,0.4,0.1,1.1,0,1.5c-0.4,0.1-1.1,0.1-1.5,0c-0.1-0.3-0.1-1.1,0-1.5
                C165.7,67.1,166.3,67.2,166.8,67.2z"/>
                <path className="st0" d="M166.8,64.9c0.1,0.5,0.1,1.1,0,1.6c-0.5,0-0.9-0.1-1.5-0.1c-0.2-0.5,0-0.9-0.1-1.5
                C165.6,64.8,166.3,64.8,166.8,64.9z"/>
                <path className="st0" d="M166.7,74.4c0.2,0.3,0.1,1.1,0.1,1.5c-0.3,0.2-1,0.1-1.5,0.1c-0.1-0.4-0.1-1.3,0.1-1.6
                C165.8,74.4,166.4,74.4,166.7,74.4z"/>
                <path className="st0" d="M166.7,62.7c0,0.4,0.1,1,0,1.4c-0.4,0.1-1,0-1.3,0c-0.1-0.3-0.2-1-0.1-1.4C165.5,62.3,166.3,62.6,166.7,62.7z"/>
                <path className="st0" d="M166.6,60.4c0.1,0.4,0.2,1.1,0,1.5c-0.5,0-0.9,0-1.3-0.2c-0.1-0.4-0.2-1,0-1.3
                C165.7,60.2,166.2,60.2,166.6,60.4z"/>
                <path className="st0" d="M166.5,84c0.2,0.3,0.4,0.5,0.7,0.7c-1-0.4-2.8-0.4-3.9-0.2c0-0.1,0-0.2-0.1-0.3c0.5,0,0.9-0.2,1.3-0.2
                c0.3,0,0.6,0.2,0.9,0.2C165.9,84.2,166.1,83.8,166.5,84z"/>
                <path className="st0" d="M163.6,94.3c-0.3-1.9,3-2.1,2.5-0.1C165.4,94.4,164.3,94.4,163.6,94.3z"/>
                <path className="st0" d="M164.3,89.1c0.4-0.1,0.9,0.2,1.4,0c-0.1,0.4-1,0.2-1.3,0.2C164.3,89.2,164.3,89.1,164.3,89.1z"/>
                <path className="st0" d="M164.9,135.9c0,0.6,0.2,1.1-0.1,1.6c-2.3,0.1-4.6,0.3-6.9,0c-0.3-5.2-0.3-10-0.3-14.7c0-3.7-0.1-7.6,0.1-11.2
                c1.9-0.1,4.2,0.1,6.6,0c0.3,3.8,0.4,7.7,0.5,11.5c0,2.9,0,5.6,0.1,8.6C164.8,133.1,164.9,134.4,164.9,135.9z M163.5,125.9
                c0.2-4.3,0.1-8.9,0.3-12.7c-1.5-0.2-3.4-0.3-4.8-0.1c-0.2,0.2-0.3,1.5-0.3,2c0,1.2,0.1,2.6,0.2,4c0.2,3.7-0.1,7.7,0.1,10.8
                c0.7,0.2,2.2,0.1,3.1,0.1c0.5,0,1.1,0.2,1.4-0.1C163.9,129.3,163.4,127.1,163.5,125.9z"/>
                <path className="st0" d="M164.6,65.1c0.1,1.2-0.1,2.5-0.1,3.8c0,2.5,0,5-0.2,7.1c-0.5-0.3-0.2-1.3-0.2-1.9c0-0.9-0.1-1.9-0.1-2.8
                c0-0.8,0-1.7,0-2.5c-0.1-2.9-0.1-5.7,0-8.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0c0.4,0.4,0.2,1,0.2,1.6c0,0.8,0,1.7,0,2.5
                C164.4,64.6,164.6,64.8,164.6,65.1z"/>
                <path className="st0" d="M161.6,94.1c1.4,0.1,0.6-2.1,2.1-1.8c-0.5,0.5-0.9,1-1,2.1c-1.1,0-2.5,0-3.5-0.1c0-0.4,0-0.8,0.1-1.1
                c0.5,0.2,0.7,1.1,1.4,1c0.3-0.1,0.1-0.4,0.6-0.5C161.6,93.6,161.4,94,161.6,94.1z"/>
                <path className="st0" d="M163.5,89.9c-0.4-0.1-0.9,0-1.3-0.1c0-0.3,0.3-0.4,0.3-0.7C162.8,89.4,163.3,89.5,163.5,89.9z"/>
                <path className="st0" d="M163.3,71.9c0,0.5,0,1,0,1.4c-0.4,0.1-1,0-1.4,0c-0.1-0.4-0.2-1-0.1-1.4C162.2,71.9,162.8,71.8,163.3,71.9z"/>
                <path className="st0" d="M163.2,62.5c0.1,0.4,0.1,1,0,1.4c-0.4,0.2-1,0.1-1.5,0.1c-0.2-0.5,0.1-0.9-0.1-1.5
                C162.1,62.4,162.7,62.3,163.2,62.5z"/>
                <path className="st0" d="M163.2,69.6c-0.1,0.5,0.2,1,0,1.4c-0.4,0.2-1,0.2-1.4,0c-0.1-0.4,0-1-0.1-1.4
                C162.2,69.6,162.8,69.4,163.2,69.6z"/>
                <path className="st0" d="M163.2,60.2c0.1,0.5,0,0.8,0,1.4c-0.5,0.1-1.2,0.1-1.6,0.1c0-0.4-0.1-1,0-1.5
                C162.1,60.1,162.8,60.1,163.2,60.2z"/>
                <path className="st0" d="M163.3,74.3c0,0.6-0.1,1.1-0.2,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.1-0.4-0.1-1.2,0-1.6
                C162.3,74.2,163,74.2,163.3,74.3z"/>
                <path className="st0" d="M163.1,67.3c0.1,0.4,0,0.9,0.1,1.4c-0.4,0.1-1,0.2-1.5,0c-0.1-0.4-0.1-1.2,0.1-1.5
                C162.2,67.3,162.8,67.2,163.1,67.3z"/>
                <path className="st0" d="M163.2,64.9c0,0.5,0,1.1-0.1,1.6c-0.4,0.1-1,0.1-1.4,0c-0.1-0.4-0.1-1.2,0-1.6
                C162.2,64.8,162.8,64.7,163.2,64.9z"/>
                <path className="st0" d="M163,113.9c-0.2,4.9-0.1,9.7-0.2,14.3c-0.4,0.3-2.9-0.6-2.9,0.1c-0.1,0.7,2.4,0.7,2.9,0.4
                c0,0.3-0.1,0.3-0.1,0.5c-0.8-0.1-2.1,0-3-0.1c-0.2-2.3,0-5.1,0-7.5c0.3,0.3,0.4,0.7,0.7,1c0.6,0.2,1,0.3,1.3,0.2
                c0.6-0.2,1-1.3,1-2.2c0-0.8-0.8-1.9-1.6-1.8c-0.8,0-1.1,1.2-1.3,1.9c0-2.1-0.5-4.7-0.2-7c0-0.1,0.1-0.1,0.2-0.2
                C160.9,113.8,161.8,113.8,163,113.9z M159.8,114.8c0.5,0.2,2.9,0.7,2.8-0.1c0-0.4-0.6-0.3-1.1-0.3
                C160.7,114.3,159.8,113.9,159.8,114.8z"/>
                <path className="st0" d="M161.6,88.4c0.2-0.1,0.6-0.1,0.6,0.2C162,88.7,161.7,88.6,161.6,88.4z"/>
                <path className="st0" d="M162,92.3c0.1,0.3,0,0.6-0.2,0.8c-0.2-0.6-1.5-1.1-1.2,0.1c-0.4,0-0.5-0.4-0.5-0.8
                C160.7,92.2,161.4,92.3,162,92.3z"/>
                <path className="st0" d="M161.6,120.2c0.4,0.6-0.1,1.4-0.2,1.9C159.9,122.6,160.8,118.8,161.6,120.2z"/>
                <path className="st0" d="M160.3,87.1c-1.3-0.8-0.1-3.1,1.3-2.6C160.7,85,159.7,85.7,160.3,87.1z"/>
                <path className="st0" d="M161,71.9c0,0.5,0.1,1.1-0.1,1.5c-1.2,0.3-2.3-0.3-1.9-1.7C159.5,71.9,160.5,71.9,161,71.9z"/>
                <path className="st0" d="M161,74.3c0.1,0.5,0.1,1.3,0,1.7c-0.8,0-1.2,0-1.8,0c0-0.5-0.1-1.2,0.1-1.7C159.6,74.1,160.5,74.3,161,74.3z"/>
                <path className="st0" d="M160.8,69.5c0.3,0.3,0.1,1.2,0.2,1.5c-0.6,0.2-1.3-0.1-1.8,0c0.2-0.4,0.8-0.8,0.4-1.4
                C159.8,69.4,160.4,69.5,160.8,69.5z"/>
                <path className="st0" d="M160.9,67.2c0,0.5,0,1,0,1.4c-0.5,0.1-1.3,0-1.6,0c0-0.4-0.1-1.4,0.2-1.7C159.8,67.2,160.4,67.1,160.9,67.2z"/>
                <path className="st0" d="M160.8,62.5c0,0.3,0.1,1,0,1.4c-0.4,0.1-0.9,0.1-1.4,0.1c-0.1-0.3,0.4-0.4,0.5-0.7c0.1-0.5-0.1-0.7-0.3-1
                c0-0.9-0.1-1.8,0.3-2.3c0.2,0.2,0.6,0.1,0.8,0.2c0.2,0.3,0.1,1,0.1,1.5c-0.3,0.1-0.9,0-1,0.3C159.7,62.6,160.5,62.4,160.8,62.5z"/>
                <path className="st0" d="M160.8,64.8c0,0.4,0.1,1.1,0,1.6c-0.2,0.1-0.7-0.1-0.9-0.1c0.1-0.8-0.6-0.8-0.6-1.6
                C159.9,64.8,160.3,64.7,160.8,64.8z"/>
                <path className="st0" d="M158.9,59.4c0,0.2-0.1,0.3-0.1,0.4c-0.5,0-0.7-0.1-1-0.2c-0.2,0-0.7,0.3-0.7-0.1
                C157.6,59.4,158.5,59.4,158.9,59.4z"/>
                <path className="st0" d="M156.8,63.2c0.1-0.7,1.3-0.2,1.9-0.2c0,0.4-0.3,0.1-0.4,0.1C157.8,63.1,157.3,63.2,156.8,63.2z"/>
                <path className="st0" d="M158.6,60.8c0.1,0.4,0,1,0,1.3c-0.5-0.1-1-0.2-1.5,0c-0.1-0.3-0.1-1.1,0-1.4
                C157.6,60.6,158.2,60.7,158.6,60.8z"/>
                <path className="st0" d="M158.8,66.1c-0.1,0.5-0.8,0.1-1.1,0C157.9,65.9,158.5,66.1,158.8,66.1z"/>
                <path className="st0" d="M158.4,69.9c0.1,0,0.2,0.1,0.2,0.2C158.5,70.2,158.3,70,158.4,69.9z"/>
                <path className="st0" d="M158.7,92.3c-0.1,0.4-0.1,0.9-0.2,1.2c-0.3-0.4-0.6-0.7-0.6-1.3C158.1,92.2,158.4,92.3,158.7,92.3z"/>
                <path className="st0" d="M158.4,64c0.2,0.4,0.2,1,0,1.3c-0.5-0.2-1.1,0-1.5,0c-0.1-0.5-0.1-1.2,0.1-1.5
                C157.4,64.2,157.9,63.8,158.4,64z"/>
                <path className="st0" d="M158.4,67.2c0,0.5,0.2,1.3,0,1.8c-0.5-0.2-1,0-1.4,0.2c-0.1-0.5,0-1.2-0.1-1.9C157.3,67,158,66.9,158.4,67.2z"/>
                <path className="st0" d="M158.4,93.6c0,0.2,0,0.4,0,0.6c-0.2,0.1-0.5,0.2-0.6,0C158,93.9,158.2,93.8,158.4,93.6z"/>
                <path className="st0" d="M158.2,74.5c0.2,0.5,0.1,1.1,0.1,1.8c-0.4-0.2-0.7-0.1-1.2-0.1c-0.3-0.4-0.2-1.1-0.2-1.7
                C157.3,74.6,157.9,74.3,158.2,74.5z"/>
                <path className="st0" d="M158.2,70.8c0.2,0.6,0.1,1.3,0.1,2.1c-0.4-0.3-0.9-0.3-1.4-0.2c-0.4-0.3-0.2-1.4-0.2-2
                C157.1,71,157.7,70.9,158.2,70.8z"/>
                <path className="st0" d="M158.3,82.7c-0.5,0.2-0.6-0.4-1.2-0.3c0.2-0.3,0.6-0.4,0.6-0.9C157.8,82,158,82.3,158.3,82.7z"/>
                <path className="st0" d="M156.5,73.3c0.3,0.1,0.5,0.3,0.9,0.2c0,0.4-0.6,0-0.8,0.3C156.6,73.6,156.4,73.4,156.5,73.3z"/>
                <path className="st0" d="M157.1,135.9c0,0.5,0.3,1.3-0.3,1.6c-0.5-0.4-0.2-1.2-0.2-1.9c0-1.1-0.2-2.3-0.3-3.5c-0.1-2.4-0.1-5-0.1-7.5
                c0-0.8-0.1-1.6-0.1-2.5c0-1.8,0.1-3.7,0.1-5.4c0-0.6-0.2-1.2-0.2-1.7c0-0.4,0.4-0.5,0.4-0.9c0.2-0.8-0.1-1.7,0.1-2.4
                c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0,0.1,0c0.3,0.3,0.1,0.8,0.1,1.2c0,1.8-0.1,3.5-0.1,5.4c0,1.3,0.1,2.7,0.1,4
                c0,2.6,0.1,5.3,0.1,7.9C157,132.1,157.1,133.9,157.1,135.9z"/>
                <path className="st0" d="M156.9,93.8c0,0.2,0.2,0.2,0.2,0.4c-0.6,0.4-0.4-0.6-0.5-1.1c-0.1-0.4-0.5-1.1,0.4-0.8
                C156.7,92.9,157.1,93.3,156.9,93.8z"/>
                <path className="st0" d="M156.8,66.1c0.1-0.1,0.3-0.1,0.3,0.2c-0.1,0-0.2,0-0.3,0C156.8,66.2,156.8,66.2,156.8,66.1z"/>
                <path className="st0" d="M157,77.2c-0.2,0.1-0.3,0-0.4,0c0,0,0.1-0.1,0-0.2C156.9,76.9,157,77,157,77.2z"/>
                <path className="st0" d="M156.6,69.9c0.2,0,0.2,0.1,0.2,0.2C156.7,70.1,156.6,70.1,156.6,69.9C156.6,70,156.6,69.9,156.6,69.9z"/>
                <path className="st0" d="M156,77.3c0-0.2,0.4-0.1,0.3,0.2C156.1,77.6,156.1,77.4,156,77.3z"/>
                <path className="st0" d="M155.8,96.6c0.2,4.4-0.3,8.9-0.2,13.5c0,1.2,0.2,2.4,0.1,3.7c-0.4,0-0.4-0.6-0.6-0.8c0.3-0.6,0.1-1.1,0-1.8
                c-0.3-2.6-0.1-5.7-0.1-9c0-2.4,0-4.7,0-7.2c0-0.8,0-1.7,0.1-2.4c0-0.1,0-0.1,0-0.2c0.1,0,0.1,0,0.2,0c0.1,0.7,0.5,1.2,0.6,1.9
                c0,0.3-0.1,0.6-0.1,1C155.7,95.7,155.8,96.1,155.8,96.6z"/>
                <path className="st0" d="M155.6,138.2c-0.1,1.3,0.1,2.5,0.1,3.7c-2.3,0.1-4.6-0.1-7,0c-2.3,0.1-4.6,0.2-7.1,0.2c-1.3,0-2.7,0-3.9,0.1
                c-0.8,0.1-1.8-0.1-2.6-0.1c-1.4-0.1-2.9,0-4.3-0.1c-1.3-0.1-2.7-0.1-4,0c-2,0.1-4.2,0-6.3,0c-0.7,0-1.4,0.1-2.1,0.1
                c-2-0.1-4.1,0-6.2-0.1c-1.5-0.1-3-0.1-4.6-0.1c-5.5,0.1-11-0.1-16.5-0.1c-1.3,0-2.6,0.1-3.9,0.1c-1.5,0-2.9-0.1-4.3,0
                c-2.7,0.1-5.4,0-8.2,0.2c-0.7,0-1.6,0.4-2.5,0.2c-0.1-1.2-0.2-2.7-0.2-3.8c4.2,0,8.7-0.3,13.1-0.3c4.5,0,8.8,0.4,13.2,0
                c1.7,0.4,4-0.1,6.2,0.1c5.4,0.3,11.1,0.1,16.4,0.2c0.6,0,1.1-0.1,1.7-0.1c0.6,0,1.2,0.2,1.7,0.2c1.1,0,2.5-0.2,3.9-0.2
                c0.9,0,2.1,0,3.1-0.1c0.2,0,0.6-0.1,0.7-0.3c0-0.7-2-0.2-2.4-0.2c-1.6,0-3.7,0.1-5.2,0.2c-0.5,0-1.2-0.2-1.7-0.2
                c-5.4,0.1-12.2,0.2-18.1-0.1c-1.3-0.1-2.6,0.1-3.8,0.2c-0.9,0.1-1.9-0.2-2.4,0.2c-0.7-0.4-1.7-0.4-2.6-0.4c-2.4,0-4.9,0-7.5-0.1
                c-2.5-0.1-5.1,0.1-7.5,0.2c-0.9,0-1.8,0-2.7,0c-2.1,0-4,0-5.9-0.1c-0.1-1-0.2-2.4,0-3.2c1.6-0.1,3.4-0.2,5.2-0.2
                c1.7,0,3.5-0.3,5.1-0.1c0.3,0,0.4,0.2,0.7,0.2c1.1-0.4,3.4-0.3,4.4,0.2c0.3-0.2,0.4-0.4,0.7-0.6c0.1-0.3-0.3-0.4-0.4-0.8
                c-0.3-1,0.1-2.5,0.1-3.5c1.3,0.2,3.6,0.2,5.6,0.3c1.7,0.1,3.1,0,4.3,0c0.5,0,2.2,0.3,2.1-0.4c-0.1-0.5-1.6-0.3-2.2-0.3
                c-2.7,0.1-6.8-0.1-9.6,0c-1.3-0.5-3.6,0-5.4,0c-0.6,0-1.2-0.3-1.7,0.1c0.3,1.2,0.2,2.6,0.2,4.1c-2.9,0.2-6,0.2-9,0.1
                c-0.1-1.1,0.1-2.3,0-3.3c2.1-0.2,4.3-0.1,6.6-0.2c0.6,0,1.5,0.2,1.8-0.4c-0.5-0.6-1.6-0.2-2.3-0.2c-2,0.1-4.2,0-6.2-0.1
                c0-1,0-2.3,0-3.2c4-0.2,8.1-0.2,12.3-0.2c1.6,0,3.2-0.1,4.7,0c4.2,0.2,8.2,0,12.5-0.1c2.3-0.1,4.6-0.2,6.9-0.1
                c1,0,2.1,0.3,3.2,0.3c3.8,0.2,8.1-0.2,12.6,0c0.5,0,1.9,0.2,1.9-0.3c0.1-0.6-1.4-0.4-1.7-0.4c-0.9,0-1.5-0.1-2.2-0.1
                c-3.3,0.2-7,0.1-10.3,0.1c-1.2,0-2.3-0.3-3.5-0.3c-5.5,0-11,0.4-16.6,0.4c-3.6,0-7.1-0.3-10.6-0.3c-3.1,0-6.1,0.3-9.1,0.2
                c-0.1-0.2,0-0.5,0-0.7c1-0.4,2.1,0,3.2-0.1c0.3,0,0.5-0.2,0.7-0.2c0.8,0,1.7,0.1,2.6,0c5.8-0.3,11.7-0.4,17.7,0.1
                c1.2,0.1,2.4-0.1,3.5-0.2c1.1,0,2.2,0,3.3,0.1c4.5,0,9.1-0.2,13.5-0.1c1,0,1.9-0.1,2.9-0.1c1.9,0,3.9,0.1,5.8-0.1
                c0.6-0.1,1.5,0.1,2.3,0.2c0,0.3,0,0.6,0,0.9c-0.4,0.3-1.3,0-1.3,0.6c0,0.8,2.7-0.1,3.2,0.5c1.6-0.5,4.2,0,6.2,0.1
                c1.2,0,2.3-0.2,3.6-0.2c5,0,10.4,0.2,15.9,0.1c0.2,0.9-0.1,2.2,0.1,3.2c-2.4,0.2-5,0.3-7.6,0.3c-0.8,0-1.7-0.1-2.5-0.1
                c-4.3,0-8.5,0-12.7,0.1c-3.4,0.1-7.2-0.2-10.9-0.1c-0.6,0-2-0.1-2.6,0c-1,0.1-0.6,0.8,0.2,0.9c-0.1,0.9,0.1,2.3,0,3.3
                c-4.1,0.1-8.4,0-12.5,0.2c-0.4-1-0.4-2.6-0.1-3.7c3.2,0,5.3-0.2,8.7,0c0.4,0,2.8,0.3,2.8-0.3c0-0.5-2-0.4-2.6-0.4
                c-3.1-0.2-6.5,0.2-9.5,0c-1.9-0.2-3.7,0.1-5.7,0c-0.5,1.3-0.1,3.3-0.2,4.8c1.3,0.6,3.2,0.3,4.8,0.3c0.6,0,1.3,0.4,1.8-0.1
                c2.4,0.2,5,0,7.4,0c1.9,0,3.7,0.2,5.5,0.1c1.8-0.1,3.6,0,5.2,0.2c1.2-0.4,3.1-0.2,4.7-0.1c2,0.1,4.7,0.1,6.5-0.1
                c0.8-0.1,2.8,0.3,2.9-0.3c0.1-0.6-1.5-0.4-1.8-0.4c-1.2,0-2.7,0.2-3.8,0.2c-2.7,0-5.5-0.2-7.9-0.3c-0.1-1-0.2-2.3,0-3.3
                c4.9,0.1,9.1-0.1,13.7,0c0.2,0.8,0.1,1.4,0.1,2.3c0,0.4-0.1,1.2,0,1.6c0.3,0.7,2.7,0.4,3.5,0.3c0.9-0.1,3.2,0.3,3.4-0.4
                c0.1-0.4-0.1-0.5-0.3-0.7c0.2-1,0.1-2.2,0.2-3.1c3.1,0.1,6.1-0.2,9.4-0.1c0,1.1,0.2,2.5,0.1,3.5c-2.3-0.1-5.1,0-7.8,0
                c-0.5,0-1.1-0.3-1.3,0.2c0.3,0.6,1.3,0.4,2,0.4c2.3,0,5.1-0.2,7.2,0.2c0.1,0.9,0.1,1.9,0.2,2.8c-1.5,0.2-3.1,0.1-4.6,0.2
                c-1.2,0.1-2.5,0-3.7,0c-0.5,0-1,0-1.6,0c-2.3-0.1-4.7,0.3-6.8,0.2c-0.3,0-0.5-0.2-0.8-0.2c-0.9,0-2.4,0.2-3.5,0.1
                c-0.4,0-0.8,0-1.3,0c-0.2,0-1-0.2-1,0.3c0,0.5,2.3,0.4,2.8,0.4c1.3,0,2.3-0.1,3.2-0.1C143.6,138.2,149.9,138.1,155.6,138.2z
                M99.9,133.8c-0.4-0.4-1-0.2-1.5-0.2c-2.8,0.1-5.7-0.2-8.3-0.1c-0.5,0-1.8-0.2-1.8,0.6c0.3,0.3,0.8,0.1,1.2,0.1
                c3-0.1,5.8,0.3,9.1,0.1C99,134.3,99.9,134.4,99.9,133.8z"/>
                <path className="st0" d="M155.3,115.7c0.2,2.8,0,6.6,0,9.6c-2,0.3-4.4,0.3-6.5,0.1c-0.2-2.9-0.2-6.8-0.3-9.6c0-0.2-0.2-0.7-0.2-0.9
                c0.1-0.3,0.5-0.3,0.7-0.6c0.4,0.2,0.9,0.3,1.6,0.4c1.2,0.1,3.1,0.2,3.7,0c0.2-0.1,0.3-0.3,0.5-0.4c0.2,0.2,0.5,0.4,0.3,0.8
                c-0.9,0.2-2.6,0.1-3.9,0.2c-0.9,0.1-1.9-0.2-2.6,0.2c0,0.7,0.8,0.4,1.3,0.4C151.6,115.9,153.9,115.9,155.3,115.7z"/>
                <path className="st0" d="M154.5,56.7c0,0.3-0.3,0.3-0.5,0.3c-0.3,0-0.2,0.8-0.7,0.6c0-0.2,0.3-0.2,0.3-0.4c0.1-0.3-0.3-0.4-0.1-0.5
                C153.7,56.6,154.3,56.6,154.5,56.7z"/>
                <path className="st0" d="M154.3,97c0,1-0.1,2.1-0.1,3.2c0.1,3.6-0.4,7.7-0.2,10.8c-1.4,0.4-3.1,0.1-4.6,0.1c-0.3-3.3,0.1-6.8-0.1-10.2
                c-0.1-3.2-0.2-6.5-0.3-9.4c0.5-0.1,0.9,0.1,1-0.3c0.2-0.6-1-0.7-1.2-1.2c0.2-9.4,0.5-20.4,0.3-30.1c1.6-0.1,2.9-0.1,4.5,0
                c0.2,2.7,0.4,5.6,0.2,8.4c-0.1,2.2,0.2,4.9,0.2,7.4c0,1-0.1,2.1-0.1,3.1c0.1,2.8,0,5.4-0.2,8.1c0,0.9,0.1,1.8,0,2.7
                c0,0.3-0.1,0.6-0.1,0.8c0,0.4,0.2,0.7,0.3,1.1c0.1,1.3-0.1,2.6-0.1,3.9C154.1,95.9,154.2,96.5,154.3,97z"/>
                <path className="st0" d="M153.9,112.4c0.2,0.5,0.3,1,0.6,1.5c-1.5,0-3.2,0-4.9-0.2c-0.3-0.6,0.1-1.2-0.2-1.7c1.3-0.3,3.4,0,4.8-0.2
                C154.2,112.2,154,112.2,153.9,112.4z M153.8,113c0.2-0.8-1.9-0.5-2.3-0.4c-0.6,0-1.9-0.1-1.8,0.4c0,0.6,1.6,0.3,2.4,0.2
                C152.6,113.2,153.7,113.5,153.8,113z"/>
                <path className="st0" d="M153.8,58.2c0.4,0.1,0.1,0.6,0.1,0.8c-1.5,0-2.8,0.1-4.3,0c0-0.2-0.4-0.3-0.3-0.6
                C150.5,58.4,152.6,58.7,153.8,58.2z"/>
                <path className="st0" d="M152.9,56.6c-0.9,0.5-3.3-0.4-3.8,0.4c-0.2-0.1-0.6-0.1-0.6-0.5C149.8,56.3,151.4,56.5,152.9,56.6z"/>
                <path className="st0" d="M149.3,57.4c0.7,0.1,2,0.1,3,0.1c-0.2,0.2-0.7,0.2-0.9,0.2c-0.7,0-1.4,0-2-0.1
                C149.3,57.6,149.3,57.5,149.3,57.4z"/>
                <path className="st0" d="M148.4,55.2c0.1,0.3,0.3,0.4,0.5,0.5c-4.6,0.2-9.1-0.1-13.8-0.2c0.1-0.8,0-1.9,0-2.7c-0.1-0.3-0.6,0-0.7-0.3
                c1.2,0.1,2.2,0,3.3,0.2c-0.1,0.3-0.4,0.4-0.5,0.7c-0.1,1,0.2,2,0.8,1.9c0.8-0.1-0.1-1,0-1.6c0.1-0.5,0.8-0.5,0.4-1
                c2.5-0.1,5.2-0.1,7.6,0c0,0.2-0.3,0.2-0.3,0.4c0,0.3,0.3,0.4,0.4,0.7c0.1,0.5-0.3,0.8-0.4,1.2c0,0.1,0.1,0.2,0.2,0.3
                c0.7-0.1,1.1-1.1,0.9-1.8c-0.1-0.3-0.5-0.4-0.4-0.7c0.8,0,1.2,0.1,2,0.1C148.4,53,148.3,54.7,148.4,55.2z M144.1,55.3
                c0-0.2,0-0.2,0-0.5c-0.4-0.3-1.1-0.4-1.5-0.7c0.4-0.3,1.5-0.1,1.4-0.8c-0.1-0.3-0.8-0.4-1.1-0.3c-0.6,0.1-0.6,0.5-0.7,0.9
                c-0.1-0.8-1.7-1.4-2.3-0.8c0,0.8,1.3,0.5,1.3,1c-0.6-0.1-1.1,0.2-1.1,0.8c0.3,0.3,0.5,0,0.9,0c0.2,0,0.4,0.1,0.5,0.1
                c0.3,0,0.6-0.3,0.9-0.3C142.9,55,143.5,55.9,144.1,55.3z"/>
                <path className="st0" d="M148.4,60.3c0,0.6,0.2,1.4,0.1,1.9c0,0.4-0.2,0.7-0.2,0.9c-0.1,2.7,0.2,5.3,0.2,7.9c0,6-0.1,12.4-0.3,18.6
                c-1.7-0.9-3.5-1.7-5-2.9c0.1-0.4,0.1-0.9,0.1-1.3c-0.1-0.2-0.3-0.2-0.5-0.3c0.3-0.3,0.7-0.6,0.6-1c-0.1-0.4-0.6-0.5-0.8-0.8
                c-1-0.3-1.4,0.4-1.6,1.1c0.4,0.6-0.4,1.5,0.1,2.3c-0.2,0.2-0.5,0.3-0.7,0.4c-1.6,0.9-3.1,1.8-4.7,2.7c-0.2-2.2,0-4.4,0-6.7
                c0-2.4-0.2-5,0-7.6c0-0.4,0-0.7-0.1-1.1c-0.1-1.3,0.2-2.7,0.2-4c0-1.7-0.1-3.4-0.2-5.2c0-1.1,0-2.4,0.1-3.4c0-0.8-0.1-1.7,0.1-2.2
                c0.1-0.5,0.6-0.6,0.5-1.2c0-0.3-0.3-0.6-0.3-0.8c0.1-0.3,0.4-0.2,0.6-0.5c0.2-0.3,0-0.4,0.2-0.7c3.7-0.2,7.2,0.1,10.9,0.2
                c0.1,0.3,0,0.5,0.1,0.8c0.1,0.3,0.5,0.4,0.5,0.8c-0.4,0.2-0.9,0.2-1.4,0.2c-3.3,0-7,0.1-10.2,0.2c-0.4,0.4-0.2,1.1-0.2,1.7
                c0,0.6-0.3,1.3,0.1,1.8c-0.4,0.5-0.1,1-0.1,1.6c0,0.3-0.2,0.7-0.2,1c0,0.2,0.2,0.5,0.2,0.7c0,0.6-0.3,1.2,0.1,1.8
                c-0.4,0.5-0.4,1.4,0,1.9c-0.1,0.4-0.4,0.6-0.3,1.1c0,0.2,0.3,0.4,0.3,0.7c0,0.5-0.5,1.2,0.1,1.8c-0.4,0.4-0.5,1.2-0.1,1.7
                c-0.1,0.3-0.2,0.5-0.2,0.8c0.1,0.7,1.1,0.9,0.8,1.6c-0.2,0-0.1-0.4-0.3-0.5c-1.2,0-0.2,1.2-0.5,1.7c0,0.3,0.3,0.3,0.4,0.5
                c-1.1,0.4,0.2,1.4,0,2.3c-0.1,0.3-0.4,0.4-0.6,0.7c-0.4,0.7,0.2,2.3,0.8,2.3c0.5,0,1.1-0.3,1.2-0.5c0.4-0.6-0.1-1.3-0.2-2.1
                c0-0.5,0-1,0-1.3c0.3,0.1,0.8,0.6,1.2,0.8c0.4,0,1,0.2,0.9,0.4c-0.6,0-1.1-0.7-1.5-0.1c0,0.2,0.5,0.9,0.8,1c0.1,0.1,0.4,0,0.4,0.1
                c0.3,0.2,0.6,1,1.3,0.4c0.2,0,0.4,0.2,0.6,0.2c0.2,0,0.3-0.2,0.6-0.2c0.2,0,0.5,0.1,0.7,0.1c0.2,0,0.3-0.2,0.6-0.3
                c0.2,0,0.4,0,0.5,0c0.8-0.3,1.5-1,1.5-1.7c0.3,0,0.6-0.2,0.8-0.3c-0.2,0.6-0.6,1-0.6,1.6c0,0.3,0.2,1,0.4,1.3
                c0.8,1.3,2.8-1.2,1.3-1.8c-0.1-0.4,0-1-0.3-1.2c0.7-0.3,0.8-1.6-0.1-1.7c-0.7-0.1-1.5,0.6-1.7,1.2c-2.1,0.4-5.5,1-6.8-0.5
                c-0.3-0.1-0.6,0-0.6-0.3c-0.4,0-0.6-0.1-0.7-0.3c1.1-0.1,2.3-0.1,3.4-0.1c0.9,0,1.8-0.2,2.6-0.2c1.3,0.1,3.7,0.6,4.3-0.6
                c-0.3-0.9,0.4-1.6-0.3-2.3c1.1-0.4,0.2-1.3-0.2-1.8c0.9-0.1,0.7-1.2,0.3-1.7c0.6-0.2,0.6-1.1,0.2-1.6c0.4-0.5,0.4-1.3-0.1-1.7
                c0.4-0.5,0.1-1.4-0.2-1.8c0.2,0,0.5-0.1,0.6-0.3c0.1-0.4-0.2-0.6-0.3-1.2c0-0.4,0.2-0.8,0.1-1.1c-0.1-0.4-0.5-0.6-0.4-0.8
                c0.4,0,0.5-0.2,0.8-0.4c-0.1-0.3-0.2-0.7-0.4-0.9c0.4-0.8,0.3-1.8,0.4-2.8C148.5,59.1,148.4,59.8,148.4,60.3z"/>
                <path className="st0" d="M148.5,98c0,0.5-0.1,1-0.2,1.6c0,1.2,0.1,2.5,0.1,3.6c0,1.9,0.2,4,0.2,6.1c0,1-0.2,2-0.1,2.9
                c0,0.5,0.4,1.4-0.3,1.7c-0.4-2.3-0.1-4.8-0.2-7.4c-0.1-2.6-0.2-5.4-0.2-8.2c0-0.9,0.3-2.1-0.1-2.8c0.3-0.2,0.1-0.9,0.3-1.2
                c0.1,0,0.1,0,0.1,0.1C148.2,95.5,148.5,96.9,148.5,98z"/>
                <path className="st0" d="M148.5,91.5c-0.2,0.2-0.2,0.7-0.2,1.2c-0.6,0.1-1.3,0.3-1.9,0.1c0.4-0.4,1.3,0.1,1.4-0.5
                c0.1-0.7-0.8-0.4-1-0.4c-2.2,0.2-5.3-0.1-7.2-0.1C142.1,91.6,145.7,91.6,148.5,91.5z"/>
                <path className="st0" d="M147.9,90.6c-0.5,0-0.9,0.1-1.3,0c-0.6-0.1-1.8-0.8-2.3-1.2c-0.6-0.4-1.8-1.6-2.8-1.3
                c-0.3,0.1-0.5,0.4-0.9,0.6c-0.3,0.2-0.6,0.2-0.8,0.4c-0.2,0.1-0.3,0.4-0.6,0.5c-0.5,0.3-1.2,0.5-1.6,0.8c-0.4,0.3-0.9,0.8-1.8,0.5
                c1.2-1.1,2.9-1.9,4.4-2.7c0.6-0.3,1.2-0.7,1.8-1c0.3-0.1,0.8,0.4,1.2,0.6C144.7,88.8,146.5,89.4,147.9,90.6z"/>
                <path className="st0" d="M147.8,123.7c0.1,0.5,0,1.2,0,1.7c-3.8-0.1-7.4,0-11.4,0c-0.2-2.4-0.2-5.2-0.1-7.7c0-0.5-0.1-1.1,0-1.6
                c0.1-0.4,0.5-0.7,0.5-1.1c3.5-0.2,6.8-0.1,10.7-0.1c0.3,0.4,0.1,1.1,0.2,1.8c-0.1,0.1-0.2-0.1-0.5-0.2c-0.4-0.1-0.8,0.1-1.3,0.1
                c-2.9,0.2-6.1-0.2-9,0.1c-0.2,1.3-0.1,3.1-0.1,4.7c0,1-0.2,2.2,0.4,2.8c3.6,0.1,6.8-0.2,10.3,0
                C147.7,124.3,147.5,123.8,147.8,123.7z"/>
                <path className="st0" d="M147.5,119c0.2,1.2,0.3,2.7,0.2,3.8C147.4,121.7,147.5,120.1,147.5,119z"/>
                <path className="st0" d="M147.5,117.4c0.2,0.2,0.1,0.7,0.1,1.1C147.4,118.2,147.5,117.7,147.5,117.4z"/>
                <path className="st0" d="M147,112.8c0.2,0.3,0.3,0.9,0.2,1.4c-3.3,0.1-7.4,0.1-10.6,0.2c0.2-1.7,0-4.1,0-6.3c0-4.1,0.4-8.3,0.1-12.4
                c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0,0.2,0c0.1,3.9,0.2,8.6,0.2,13.1c0,1.6-0.4,3.3,0.1,4.8c3.2,0.4,6.3,0.2,9.3,0.2
                C146.9,113.5,146.7,112.9,147,112.8z"/>
                <path className="st0" d="M147.2,95.6c0,2.2-0.2,5.2-0.2,7.6c0,3.1,0.4,6.4-0.1,9.3c-0.4-5.2-0.1-11.3,0-16.7
                C147,95.6,147.1,95.6,147.2,95.6C147.2,95.6,147.2,95.6,147.2,95.6z"/>
                <path className="st0" d="M147.2,93.8c0,0.3,0,0.7-0.2,0.8c-0.4,0-0.2-0.7-0.2-0.9C147,93.6,147.1,93.7,147.2,93.8z"/>
                <path className="st0" d="M146.8,65.8c0.2,0,0.3,0.4,0.2,0.4C146.8,66.2,146.8,66,146.8,65.8z"/>
                <path className="st0" d="M146.6,81.6c0.2,0.2,0.5,0.3,0.3,0.6C146.7,82.2,146.6,81.8,146.6,81.6z"/>
                <path className="st0" d="M146.5,117.6c0.3,1.9,0.2,4.1,0.2,5.9c-2.6,0-6.3,0.4-9.1,0.1c-0.2-1.9-0.3-3.9,0-5.8
                C140.5,117.4,143.8,117.7,146.5,117.6z"/>
                <path className="st0" d="M145.9,74.5c0.3,0.4,0.4,1,0,1.5c-0.2,0.1-0.8,0.1-1,0c-0.1-0.4-0.1-1.1,0-1.5
                C145.2,74.4,145.6,74.5,145.9,74.5z"/>
                <path className="st0" d="M146.1,98.5c0.1,0.5,0.1,1.3,0,1.7c-0.2,0-0.5,0-0.8,0.1C145.2,99.6,144.9,98,146.1,98.5z"/>
                <path className="st0" d="M146.1,96c0,0.5,0.2,1.1,0,1.5c-0.2,0.1-0.5,0.1-0.8,0.1C145.2,96.9,144.9,95.6,146.1,96z"/>
                <path className="st0" d="M146,62.8c0.5,0.6-0.3,1-0.1,1.7c-0.2,0.1-0.8,0.1-1,0c0-0.5-0.1-1.2,0.1-1.7C145.2,62.7,145.7,62.7,146,62.8
                z"/>
                <path className="st0" d="M145.8,67.6c0.4,0.5,0.4,1.2,0.2,1.8c-0.3,0.2-1,0.1-1.3,0c0.1-0.6-0.2-1.4,0.1-1.8
                C145.2,67.5,145.4,67.6,145.8,67.6z"/>
                <path className="st0" d="M146.2,60.1c0,0.4-0.2,1.2-0.1,1.6c-0.1,0.3-0.9,0.2-1.2,0.2c-0.1-0.4,0-0.9-0.1-1.4
                C145.2,60.2,145.8,60.2,146.2,60.1z"/>
                <path className="st0" d="M146.1,101.2c0,0.7,0,1.7,0,2.6c-0.2,0.2-0.6,0.2-0.8,0.1c-0.1-0.7-0.1-1.8,0-2.6
                C145.5,101.2,145.8,101.1,146.1,101.2z"/>
                <path className="st0" d="M146.2,70.2c-0.3,0.5,0,1,0.1,1.5c-0.4,0.1-1,0-1.4-0.1c0.1-0.6-0.1-1.1,0-1.5
                C145.2,70,145.7,70.2,146.2,70.2z"/>
                <path className="st0" d="M146,107.8c0,0.7,0.1,1.7-0.1,2.4c-0.2,0-0.6,0.1-0.8,0c-0.1-0.6-0.1-1.7,0-2.3
                C145.2,107.6,145.8,107.7,146,107.8z"/>
                <path className="st0" d="M146,111c0.1,0.5,0.1,1,0.1,1.6c-0.2,0.2-0.5,0.1-0.8,0.1C145.1,112.1,144.8,110.7,146,111z"/>
                <path className="st0" d="M145.9,104.8c0.2,0.5,0.1,1.2,0.2,1.9c-0.2,0.1-0.5,0.2-0.8,0.2c-0.2-0.6,0-1.4-0.1-2.1
                C145.3,104.7,145.7,104.8,145.9,104.8z"/>
                <path className="st0" d="M145.9,72.5c0.1,0.3,0.2,0.7,0.3,1c-0.3,0.2-0.9,0.2-1.3,0.1c-0.1-0.2-0.1-0.8,0-1
                C145,72.4,145.6,72.6,145.9,72.5z"/>
                <path className="st0" d="M145.9,65.4c-0.1,0.5,0.1,0.9,0.2,1.3c-0.3,0.2-1,0.1-1.3,0c-0.1-0.4,0-1-0.1-1.4
                C145.1,65.2,145.7,65.3,145.9,65.4z"/>
                <path className="st0" d="M145.8,93.6c0.2,0.4,0.1,1,0.2,1.5c-0.1,0.1-0.6,0.1-0.7,0C145.3,94.5,144.9,93.5,145.8,93.6z"/>
                <path className="st0" d="M144.2,76.9c0.8,0.1,1.6-0.1,2.3,0.2c-0.7,0.3-1.8,0.2-2.7,0.2c-1,0-2.1-0.2-3.1-0.2c-1.1,0-2.1,0.4-3.2-0.1
                c0.6-0.6,2.8-0.2,3.9-0.2C142.4,76.9,143.3,76.8,144.2,76.9z"/>
                <path className="st0" d="M144.9,130.4c0.2,1,0,2,0.1,3.1c-0.2,0.1-0.6,0.2-0.8,0.1c-0.2-0.9-0.2-2.3,0-3.2
                C144.3,130.3,144.7,130.3,144.9,130.4z"/>
                <path className="st0" d="M144.4,96c0,0.4,0.2,1.1,0,1.6c-0.3,0.1-0.8,0.1-1.2,0.1c-0.1-0.5-0.2-1.1,0-1.7
                C143.5,96,144.1,95.9,144.4,96z"/>
                <path className="st0" d="M144.4,93.8c0,0.4,0.1,1,0,1.3c-0.3,0-0.8,0-1.1,0c-0.1-0.4,0-0.9-0.1-1.4C143.6,93.6,144.1,93.5,144.4,93.8z"/>
                <path className="st0" d="M144.4,98.5c0.1,0.5,0.2,1.4,0,1.8c-0.4,0-0.8,0-1.2,0c-0.2-0.5,0.1-1.2,0-1.8
                C143.6,98.5,143.9,98.6,144.4,98.5z"/>
                <path className="st0" d="M135.9,92.5c3.2,0.4,6.7-0.1,9.8,0.2c-3.3,0.3-7,0.2-10.1,0.2c0-0.2-0.1-1,0.2-1.1
                C136.1,91.8,135.7,92.2,135.9,92.5z"/>
                <path className="st0" d="M144.4,101.3c0.1,0.7,0,1.7,0,2.5c-0.3,0-0.9,0.1-1.2,0c-0.1-0.8-0.1-1.8,0-2.5
                C143.6,101.3,144,101.3,144.4,101.3z"/>
                <path className="st0" d="M144.3,104.7c0.2,0.8,0.1,1.6,0,2.3c-0.3,0-0.8,0.1-1-0.1c-0.2-0.7,0-1.4-0.1-2.2
                C143.5,104.6,144.1,104.6,144.3,104.7z"/>
                <path className="st0" d="M144.3,107.8c0,0.8,0.1,1.6,0,2.4c-0.2,0.1-0.7,0-1,0c0-0.8-0.2-1.9,0-2.5C143.7,107.6,144,107.7,144.3,107.8
                z"/>
                <path className="st0" d="M143.5,80.8c0.3,0,0.7-0.3,0.9-0.1c-0.1,0.3-0.5,0.3-0.9,0.4C143.5,81,143.5,80.9,143.5,80.8z"/>
                <path className="st0" d="M144.3,111.1c0,0.6,0,1.1-0.1,1.7c-0.4-0.1-0.6,0.1-0.9,0c-0.2-0.4,0-1,0-1.7C143.5,111,144,111,144.3,111.1z"/>
                <path className="st0" d="M140.8,39c1-0.1,2.3,0.1,3.4,0c-0.2,0.3-0.6,0.3-0.9,0.4c-0.7,0.3-1.3,0.7-1.9,1c-1.1,0.6-2.1,1-3.2,1.7
                c-2,1.3-4.1,2.4-6,3.7c-1,0.7-1.8,1.4-2.7,2.1c-0.4-3-0.2-5.7-0.2-8.9c0.7-0.2,1.5-0.2,2.3-0.1c0,1.5-0.1,3.5-0.1,5
                c0,0.4,0,0.8,0.3,1c0.4-0.1,0.3-0.7,0.3-1.1c0.1-1.6,0.1-3.6,0.3-5c1,0,1.9-0.1,2.8,0.1c0.2,1.2-0.1,2.5,0.3,3.5
                c0.8,0.1,0.5-0.8,0.4-1.3c0-0.8,0.2-1.6,0.1-2.2c1.2-0.1,2.6-0.3,3.7,0c0.1,0.7-0.4,1.9,0.3,1.9C140.8,40.8,140.4,39.5,140.8,39z"/>
                <path className="st0" d="M144,60.4c0.1,0.4,0.1,1.1,0,1.5c-0.2,0.1-0.6,0-1,0c0-0.5,0-1.1,0-1.5C143.3,60.3,143.8,60.3,144,60.4z"/>
                <path className="st0" d="M143.9,70.2c0.1,0.4,0.2,1,0.1,1.5c-0.3,0.1-0.8,0-1.1-0.1c0.1-0.5-0.1-1.1,0.1-1.5
                C143.3,70.1,143.7,70.1,143.9,70.2z"/>
                <path className="st0" d="M144,62.8c0,0.5,0.1,1.2-0.1,1.6c-0.3,0-0.6-0.1-0.9-0.1c0-0.3,0-1.1,0-1.6C143.3,62.7,143.8,62.8,144,62.8z"/>
                <path className="st0" d="M144,74.6c0,0.4,0.1,1.1-0.1,1.5c-0.3,0-0.6,0-0.7-0.1c-0.1-0.4-0.1-0.9-0.1-1.3
                C143.3,74.4,143.7,74.4,144,74.6z"/>
                <path className="st0" d="M138.2,59.3c2.6-0.3,5.2-0.1,7.8-0.1c-1.6,0.5-3.7,0.4-5.9,0.2C139.4,59.3,138.6,59.6,138.2,59.3z"/>
                <path className="st0" d="M144,67.6c0,0.6,0.1,1.3-0.1,1.7c-0.4,0-0.6,0-0.9-0.1c-0.2-0.4-0.1-1.1-0.1-1.6
                C143.3,67.5,143.7,67.5,144,67.6z"/>
                <path className="st0" d="M143.9,65.3c0.1,0.4,0,0.9,0.1,1.4c-0.3,0.1-0.8,0.1-1.1,0c-0.1-0.4,0-1,0.1-1.4
                C143.2,65.2,143.7,65.3,143.9,65.3z"/>
                <path className="st0" d="M143.9,72.6c0.1,0.3,0.1,0.8,0,1.1c-0.2,0.1-0.7,0.1-0.9-0.1c0-0.3-0.1-0.8,0.1-1
                C143.3,72.5,143.8,72.5,143.9,72.6z"/>
                <path className="st0" d="M143.4,89.8c0.5,0.3,1,0.7,1.2,1c-1.9,0.2-4.1,0.1-6.1,0.2c0.3-0.4,0.8-0.6,1.2-0.9c0.6-0.4,1.1-0.8,1.8-1.2
                C142.2,88.9,142.8,89.4,143.4,89.8z"/>
                <path className="st0" d="M143.3,130.3c0.3,0.9,0.1,2.3,0.1,3.3c-0.1,0.1-0.5,0.1-0.6,0c-0.1-1.1-0.2-2.3,0.2-3.3
                C143,130.3,143.2,130.3,143.3,130.3z"/>
                <path className="st0" d="M142.7,80.8c0.1,0.1,0.2,0.6,0,0.7c-0.2,0-0.3-0.1-0.4-0.2C142.3,81,142.3,80.7,142.7,80.8z"/>
                <path className="st0" d="M141.8,85.3c0.3,0,0.5-0.1,0.8-0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.1-0.5,0.1-0.7,0
                C141.7,85.6,141.7,85.4,141.8,85.3z"/>
                <path className="st0" d="M142.3,93.7c0.1,6.1,0.2,13.1,0,19.1c-0.3-0.3-0.2-0.8-0.2-1.2c0-1.3,0-2.8-0.1-4.1c0-0.3-0.2-0.6-0.2-0.9
                c-0.1-1.9,0-3.9,0.1-5.9c0-2.3-0.1-4.7,0.1-6.7c0-0.1,0.1-0.1,0.1-0.2C142.2,93.7,142.3,93.7,142.3,93.7z"/>
                <path className="st0" d="M142,84.2c0.2,0,0.4-0.1,0.4,0.2C142.3,84.4,141.9,84.4,142,84.2z"/>
                <path className="st0" d="M142.3,62c0,1.7-0.1,2.9-0.2,4.7c-0.1,3.1,0.2,6.5,0,9.2c-0.4-0.1-0.3-0.6-0.3-0.9c-0.1-2.2,0-4.2,0.1-6.4
                c0-2.2,0-4.7-0.1-7c0-0.4-0.2-0.7-0.1-1c0-0.1,0-0.1,0.1-0.2C142.3,60.2,142.2,61.3,142.3,62z"/>
                <path className="st0" d="M142,130.4c-0.1,1,0.1,2.2-0.1,3.3c-0.1,0-0.3,0-0.4,0c-0.4-0.9-0.1-2.1-0.2-3.2
                C141.4,130.3,141.8,130.3,142,130.4z"/>
                <path className="st0" d="M141.5,80.8c0.1,0.2,0.3,0.3,0.3,0.6c-0.4,0-0.4-0.3-0.8-0.3C141.1,80.9,141.2,80.8,141.5,80.8z"/>
                <path className="st0" d="M141.3,108c0.1,0.6,0.1,1.6,0,2.2c-0.3,0.1-0.9,0.1-1.2,0c0-0.7,0-1.5,0.1-2.2
                C140.4,107.9,141,107.9,141.3,108z"/>
                <path className="st0" d="M141.3,111c0,0.3,0.1,1.2,0,1.7c-0.2,0.1-0.7,0-1,0.1c-0.2-0.5,0-1.3-0.2-1.7C140.3,111,141,111,141.3,111z"/>
                <path className="st0" d="M141.2,104.6c0.1,0.7,0.2,1.8,0.1,2.5c-0.3,0.1-0.9,0.1-1.1-0.1c0-0.8-0.1-1.8,0.1-2.4
                C140.6,104.7,140.8,104.6,141.2,104.6z"/>
                <path className="st0" d="M141.2,95.9c0.2,0.5,0,1.4,0.1,1.9c-0.3-0.1-0.8,0-1.1-0.1c-0.2-0.4-0.1-1.2,0-1.7
                C140.4,95.9,140.8,96,141.2,95.9z"/>
                <path className="st0" d="M141.3,98.7c-0.1,0.6,0,1.1,0,1.8c-0.3,0.1-0.9,0.1-1.1,0c-0.1-0.6-0.1-1.2,0-1.8
                C140.6,98.7,140.9,98.5,141.3,98.7z"/>
                <path className="st0" d="M141.2,93.9c0,0.4,0.1,1-0.1,1.3c-0.4-0.1-0.8,0-1-0.2c-0.1-0.4,0-0.6,0-1.1C140.3,93.7,141,93.6,141.2,93.9z"/>
                <path className="st0" d="M141.2,101.3c0.1,0.7,0,1.6,0.1,2.4c-0.3,0.1-0.9,0.1-1.1,0c0.1-0.9-0.2-1.7,0-2.4
                C140.5,101.4,140.8,101.4,141.2,101.3z"/>
                <path className="st0" d="M140.9,67.6c0.2,0.5,0,1,0.1,1.6c-0.2,0.2-0.8,0.2-1.1,0.2c-0.1-0.5-0.1-1.3,0-1.7
                C140.2,67.6,140.6,67.6,140.9,67.6z"/>
                <path className="st0" d="M140.9,72.5c0.1,0.3,0,0.8-0.1,1.1c-0.2,0.1-0.7,0.1-0.9,0.1C139.8,73,139.9,72.1,140.9,72.5z"/>
                <path className="st0" d="M140.9,74.6c0,0.4,0.2,1,0.1,1.4c-0.2,0.1-0.6,0.1-0.8,0c-0.1-0.3-0.2-1-0.1-1.4
                C140.3,74.4,140.7,74.5,140.9,74.6z"/>
                <path className="st0" d="M140.9,70.1c0.1,0.4,0,0.9,0,1.5c-0.2,0.1-0.7,0.1-0.9,0c-0.1-0.4-0.1-1.1,0-1.5
                C140.3,70.1,140.7,70,140.9,70.1z"/>
                <path className="st0" d="M140.9,65.2c0,0.6,0,1,0,1.6c-0.2,0.2-0.8,0.3-1.1,0.1c0.1-0.5-0.1-1.2,0.1-1.6
                C140.2,65.2,140.7,65.1,140.9,65.2z"/>
                <path className="st0" d="M140.8,62.6c0.1,0.5,0.1,1.1,0.1,1.6c-0.2,0.2-0.6,0.2-1,0.2c-0.1-0.4,0-1.3,0-1.8
                C140.1,62.5,140.5,62.6,140.8,62.6z"/>
                <path className="st0" d="M140.8,60.3c0.1,0.4,0,0.9,0,1.4c-0.2,0.2-0.7,0.2-0.9,0.1c0-0.5,0-1.2,0-1.6
                C140.2,60.3,140.6,60.2,140.8,60.3z"/>
                <path className="st0" d="M140.6,130.4c0,1,0.1,2.1,0,3.3c-0.2,0.1-0.4,0-0.7,0c-0.3-1,0-2.5,0-3.3
                C140.1,130.3,140.5,130.3,140.6,130.4z"/>
                <path className="st0" d="M140.3,80.8c0.2,0.1,0.4,0.3,0.5,0.5c-0.3,0-0.3-0.3-0.6-0.4C140.2,80.8,140.3,80.8,140.3,80.8z"/>
                <path className="st0" d="M139.3,104.7c0.1,0.9,0.1,1.6,0,2.5c-0.5,0-0.9,0-1.3,0c-0.2-0.7-0.2-1.9,0-2.5
                C138.4,104.6,139,104.5,139.3,104.7z"/>
                <path className="st0" d="M139.3,101.3c0,0.6,0.1,1.6,0,2.5c-0.4,0.1-1,0.1-1.4,0c-0.2-0.8,0.1-1.6,0-2.4
                C138.2,101.1,138.8,101.3,139.3,101.3z"/>
                <path className="st0" d="M139.3,107.9c0.1,0.7-0.1,1.5-0.1,2.2c-0.4,0.1-0.9,0-1.2-0.1c0.1-0.7-0.3-1.7,0.1-2.2
                C138.4,107.9,139,107.8,139.3,107.9z"/>
                <path className="st0" d="M139.2,98.5c0.1,0.5,0,1.2,0.1,1.8c-0.4,0.1-1,0.1-1.3,0.1c-0.2-0.5-0.1-1.4,0-1.9
                C138.3,98.4,138.8,98.5,139.2,98.5z"/>
                <path className="st0" d="M139.3,96c0,0.6,0,1.2-0.1,1.6c-0.4,0.1-1,0-1.3,0c-0.2-0.5,0-1.3,0-1.7C138.2,96,139,95.9,139.3,96z"/>
                <path className="st0" d="M138.7,91.8c0.2,0,0.4,0,0.6,0C139.2,91.9,138.7,92,138.7,91.8z"/>
                <path className="st0" d="M139.1,93.8c0.2,0.2,0.2,0.9,0.1,1.2c-0.4,0.1-1,0-1.3,0c0-0.3,0-1,0.2-1.3C138.5,93.8,138.9,93.7,139.1,93.8
                z"/>
                <path className="st0" d="M139.1,70.1c0.1,0.4,0.2,1,0,1.6c-0.4,0.1-1,0-1.3,0c-0.1-0.3-0.3-0.5-0.3-0.9c0.1-0.3,0.5-0.3,0.5-0.7
                C138.4,70.1,138.9,70,139.1,70.1z"/>
                <path className="st0" d="M139.1,111c0.1,0.6,0.1,1.1,0,1.7c-0.2,0.1-0.8,0.1-1.1,0c-0.2-0.5-0.1-1.2-0.2-1.7
                C138.2,110.9,138.8,111,139.1,111z"/>
                <path className="st0" d="M139,62.7c0.2,0.6,0,1,0.1,1.6c-0.3,0.3-0.9-0.1-1.2,0.2c-0.2-0.2-0.3-0.5-0.3-0.8c0.3-0.2,0.5-0.6,0.6-1.1
                C138.4,62.7,138.8,62.6,139,62.7z"/>
                <path className="st0" d="M139.1,67.6c0,0.6,0.2,1.2,0,1.7c-0.9,0.3-1.5-0.2-1.8-0.7c0.1-0.2,0.5,0,0.6-0.1c0.2-0.4,0.1-1-0.1-1.4
                c0.3-0.6,0.1-1.2-0.2-1.7c0.1-0.8,0.8-0.3,1.4-0.3c0.1,0.5,0.2,1.1,0,1.6c-0.3,0.1-1-0.1-1,0.3C138,67.6,138.8,67.4,139.1,67.6z"/>
                <path className="st0" d="M139.1,75.9c-0.4,0.1-1,0-1.4-0.2c0.1-0.3,0.3-0.6,0.2-1.1c0.3,0,0.8-0.2,1.1,0
                C139.1,75.1,139.1,75.4,139.1,75.9z"/>
                <path className="st0" d="M139.1,72.4c0.1,0.3-0.1,0.8,0,1.2c-0.3,0.1-0.9,0.2-1.3,0.1c0.4-0.4-0.1-1-0.1-1.4
                C138.1,72.3,138.6,72.5,139.1,72.4z"/>
                <path className="st0" d="M139,60.2c0.1,0.6,0.1,1.1,0,1.7c-0.5-0.1-0.9,0.2-1.1,0.2C138.1,61.2,137.8,59.8,139,60.2z"/>
                <path className="st0" d="M137.5,91.8c0.1-0.2,0.5,0,0.8-0.1C138.1,92,137.8,91.8,137.5,91.8z"/>
                <path className="st0" d="M137.3,73.9c0.1,0,0.2,0,0.4,0c0,0.1-0.1,0.2-0.2,0.3C137.4,74.2,137.3,74.1,137.3,73.9z"/>
                <path className="st0" d="M137.2,59.4c0.2,0,0.2,0.3,0.1,0.4C137.1,59.9,137.2,59.5,137.2,59.4z"/>
                <path className="st0" d="M137,62.7c0.3-0.1,0.4,0.3,0.3,0.5C136.9,63.2,136.9,62.9,137,62.7z"/>
                <path className="st0" d="M137,81.5c0,0.4,0.4,0.6,0.2,0.9c-0.3-0.1-0.4-0.4-0.4-0.7C136.7,81.6,136.8,81.5,137,81.5z"/>
                <path className="st0" d="M137.1,67.8c0.2,0,0.2,0.5,0.1,0.6C137,68.3,136.9,68,137.1,67.8z"/>
                <path className="st0" d="M137.2,69.7c0.1,0.1,0.1,0.5-0.1,0.4c-0.1-0.1-0.1-0.3-0.1-0.4C137.1,69.7,137.1,69.7,137.2,69.7z"/>
                <path className="st0" d="M137.1,93.8c0,0.5,0,1.2-0.6,0.9C136.5,94.1,136.5,93.5,137.1,93.8z"/>
                <path className="st0" d="M136,95.6c0.1,0.7-0.1,1.6-0.1,2.4c0,4.7-0.1,9-0.2,13.7c-0.3-0.4-0.2-0.9-0.2-1.3c-0.1-1.6,0.1-3.1,0.1-4.7
                c0-0.5-0.1-1.1-0.1-1.7c0-0.5,0.1-1,0.1-1.5c0-1.4-0.2-2.8-0.2-4.3c0-1.4,0.1-2.8,0.1-4.2c0,0,0.1-0.1,0-0.2c0.1,0,0.1,0,0.2,0.1
                C135.5,94.5,136,95.1,136,95.6z"/>
                <path className="st0" d="M135.2,114.2c0.4,0.1,0.4,0.6,0.7,0.9c-0.8,0.9-2.3,0.4-3.7,0.3c-0.9,0-1.8,0.4-2.6-0.1
                c-0.1-0.2-0.1-0.5,0.1-0.7c0.2,0,0.3,0.2,0.6,0.2c1.8-0.3,3.9,0.4,4.9-0.4C135.2,114.4,135.2,114.3,135.2,114.2z"/>
                <path className="st0" d="M135.2,116.4c0,2.9,0.1,5.9,0,9c-1.7,0.2-3.5-0.1-5.2-0.2c0.2-2.9-0.3-5.9,0.2-8.8
                C132,116.2,133.7,116.2,135.2,116.4z"/>
                <path className="st0" d="M135.2,58.2c0.2,0.3-0.1,0.6-0.2,0.8c-1.4,0.2-3.2-0.6-4.3,0.2c-0.3-0.3-0.3-0.7-0.1-1.2
                c0.3,0,0.1,0.4,0.3,0.4c0.5,0.1,2.1,0.1,2.9,0.2C134.5,58.6,134.9,58.8,135.2,58.2z"/>
                <path className="st0" d="M135,112.3c-0.1,0.5-0.3,1.3,0.1,1.8c-0.6,0-1.2,0-1.7-0.1c-1.1-0.1-2.3,0.3-3.2-0.1c0-0.6,0.8-0.3,1.2-0.4
                c1-0.1,2.6,0.3,2.9-0.4c-0.3-0.5-1.2-0.4-1.9-0.3c-0.7,0-1.4,0.1-2.1,0c-0.1-0.1-0.2-0.6,0.1-0.7
                C131.9,112.3,133.6,112.2,135,112.3z"/>
                <path className="st0" d="M134.9,57.8c-0.1,0.2-0.7,0.1-0.7-0.1C134.5,57.7,134.9,57.6,134.9,57.8z"/>
                <path className="st0" d="M134.7,59.9c0.1,4.8,0,9.9,0.1,14.9c0,2.5-0.2,4.6-0.2,6.8c0.1,2.3,0,5.4,0,7.6c0,0.4,0.1,0.9,0.1,1.1
                c-0.2,0.4-1.1,0.6-1.1,1c0.1,0.5,0.7,0.3,1,0.6c0.2,2.1,0,4.7,0.1,7.3c0,4.1,0.1,8.3-0.2,12.2c-1.3-0.1-2.6,0.3-3.7,0
                c-0.5-3.6-0.2-7.3-0.2-11c0-6.2-0.1-13.1-0.1-19.6c0-3.4-0.1-7.2,0-10.1c0-0.9-0.2-1.7-0.2-2.6c0-1.8,0.2-4.4,0.2-6.4
                c0-1-0.1-2,0.3-2.3c0.1,0.1,0.2,0.2,0.3,0.2C132.5,59.6,133.4,59.7,134.7,59.9z"/>
                <path className="st0" d="M135.7,56.5c-0.4,0.3-1.3,0.4-1.9,0.4c-0.9,0-2-0.5-2.8-0.2c-0.2,0-0.2,0.2-0.2,0.4c-0.2-0.3-0.6-0.4-0.8-0.7
                C131.8,56.1,133.6,56.3,135.7,56.5z"/>
                <path className="st0" d="M134.3,53.4c0.1,0.7,0.1,1.2-0.4,1.5c0,0.2,0,0.4,0.2,0.5c-0.2,0.1-0.7,0.1-0.9,0.1c1.5-0.6,0.5-3.5-1.3-2.5
                c-0.2,0.2-0.3,0.5-0.4,0.7c-0.1-0.4,0.3-0.8-0.1-1.1c0.8-0.1,1.9-0.1,2.6,0C133.5,52.9,133.8,53.5,134.3,53.4z"/>
                <path className="st0" d="M133.1,57.6c-0.6,0.2-1.9-0.2-2.3,0.2c-0.2-0.1-0.1-0.3-0.1-0.6C131.2,57.5,132.4,57.3,133.1,57.6z"/>
                <path className="st0" d="M132.7,53.6c0.4,0.2,0.4,1.2-0.2,1c0,0-0.1,0-0.1,0C132.2,54.3,132.2,53.6,132.7,53.6z"/>
                <path className="st0" d="M131.4,54.9c0.2,0.1,0.5,0.4,0.4,0.6c-0.2,0-0.4,0-0.6-0.1C131.4,55.2,131.4,55,131.4,54.9z"/>
                <path className="st0" d="M130.5,52.6c0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.6,0.2,1.2,0,1.6c-0.4,0-0.2-0.3-0.2-0.5c0-0.6-0.1-1.6-0.2-2.2
                c0,0,0.1-0.1,0.1-0.2C130.3,52.6,130.4,52.6,130.5,52.6z"/>
                <path className="st0" d="M129.7,57.4c0.1,0.5-0.7,1.2-0.1,1.8c-0.1,2.9-0.1,5.7-0.1,8.6c0,0.9-0.2,1.9-0.2,2.8c0,0.7,0.1,1.5,0.2,2.2
                c0.1,2.7,0,5.6,0.1,8.3c0.1,5.4-0.1,10.7,0.1,16.3c0,1.1-0.1,2.2-0.1,3.3c0,3,0.2,6,0,8.8c0,0.7,0,1.4-0.1,2.1
                c-0.1,0.5-0.4,1.1-0.1,1.7c-0.1,0.3-0.5,0.4-0.7,0.7c0,0.6-0.2,0.6-0.2,1.1c0,0.6,0.4,0.6,0.5,1.1c0.1,0.7,0.1,1.6,0,2.4
                c0,1.9,0.2,3.9,0.1,5.2c0,0.4-0.1,1.3-0.4,1.3c-0.5,0-0.3-1.2-0.3-1.5c0-3-0.1-7.3,0-10.9c0-1.7,0.4-3.4,0.3-5.2
                c-0.1-1.7,0-3.4-0.1-5.1c0-1.7,0-3.4,0-5.1c0-1.7,0-3.4,0-5.2c0-2-0.1-3.9-0.1-6c0.1-2.2-0.2-4.4-0.1-6.5c0-0.3,0.1-0.5,0.2-0.8
                c0.2-2-0.2-4-0.1-6c0.1-2.6,0-5.2,0-7.8c0-2.9-0.2-5.8-0.2-8.7c0,0,0-0.2,0.1-0.2c0.4-0.1,0.4,0.3,0.7,0.6
                C129.3,57.1,129.7,57.3,129.7,57.4z"/>
                <path className="st0" d="M129.2,52.5c0.3,0.8,0.2,2.2,0.1,2.9c-3.6-0.3-7.6,0.4-11.5,0.2c-5.1-0.2-11.6,0.1-16.5,0
                c-1.9,0-3.8-0.1-5.8-0.1c-0.7,0-1.4,0.2-2.1,0.2c-0.8,0-1.5-0.1-2.3-0.2c-1.5-0.1-3.1,0.1-4.7,0c-2.7-0.2-5.4-0.2-8.1,0
                c-1.1,0.1-2.2,0-3.3,0.1c-1,0.1-2,0.1-3-0.1C72,54.6,72,53.8,72,53c2.8-0.3,5.6-0.3,8.5-0.3c3.3,0,6.8-0.1,10.2,0.1
                c1.4,0.1,2.9,0.1,4.3,0.1c1.3,0,2.6-0.2,3.9-0.2c1.3,0,2.5,0.2,3.8,0.2c1.9,0,3.8-0.2,5.7-0.3c2-0.1,4.1-0.2,5.9-0.2
                C119.4,52.2,124.4,52.6,129.2,52.5z"/>
                <path className="st0" d="M128.3,45.5c0,1,0.5,2.4,0.2,3.3c-0.2,0.6-0.9,0.7-1.2,1.2c-0.5-0.1-1.2,0-1.8-0.1c-0.2-1.3-0.2-2.6-0.2-3.9
                c0-0.5-0.1-1.3,0.1-1.9c0.1-0.5,1.1-0.8,1.2-1.3c0-0.5-0.8-1-1-1.2c-0.5-0.4-0.9-0.6-1.5-0.7c-0.2,0.3,0.4,0.5,0.3,0.6
                c-0.6-0.1-1.2-0.8-1.8-1.1c-1.9,0-3.6,0.1-5.1,0.6c-0.2-0.6,0-1.2,0-1.9c1,0,2.2-0.2,3.1,0c0.1,0.2-0.1,0.8,0.2,0.9
                c0.6,0.1,0.3-0.6,0.6-0.9c0.2-0.2,0.2,0.1,0.5,0c0.2,0,0-0.3,0.1-0.3c0.9,0,1.9-0.1,2.6,0.1c0.2,0.5-0.2,1.6,0.3,1.8
                c0.6-0.1,0.2-1.3,0.5-1.7c0.9-0.2,1.9,0,2.9,0C128.6,40.9,128.3,43.7,128.3,45.5z"/>
                <path className="st0" d="M127.7,86.6c0.1,3.6,0.4,7.1,0.2,10.6c-0.1,2.1-0.2,4.1-0.2,6.1c-0.1,2,0.2,4,0.1,6c0,0.8-0.2,1.5-0.2,2.3
                c0,1.8,0.1,3.6,0,5.4c-3.8,0.1-8.1,0.1-12.2-0.1c-0.2-8.3,0.1-16.4-0.1-24.6c0-0.8-0.1-1.5-0.1-2.3c0-0.8,0.2-1.5,0.2-2.3
                c0-0.4-0.1-0.8-0.1-1.2c0-0.3,0.1-0.6,0.2-1c0-0.9-0.1-1.9-0.1-2.9c0-4.2,0.3-9.4,0.2-14.2c0-2.2,0-4.4,0.1-6.5
                c0.1-1.9-0.4-3.7,0-5.6c3.8,0,8-0.1,11.8-0.3c0.3,0.2,0.2,0.8,0.3,1.2c0,0.4,0.1,0.9-0.1,1.2c-0.1,0-0.2-0.1-0.8-0.1
                c-0.6,0-1.4,0.2-1.9,0.2c-0.9,0-2.2-0.1-3.4-0.1c-1.2,0-2.4,0.1-3.3,0c-0.7,0-1.7-0.2-1.9,0.1c-0.4,0.5-0.1,2.4-0.1,3.3
                c0,1.2-0.2,2.3-0.1,3.3c0.1,2.5,0.2,4.7,0.1,6.8c-0.1,1.7-0.3,4.2-0.1,6.1c3.1,0.2,6.1,0.2,9.6,0c0.6,0,1.4,0.1,1.7-0.2
                c0.3-0.4,0-3,0-3.5c0-1.5,0-2.8,0-4c0-1.1-0.2-2.2-0.2-3.3c0-1.9,0.3-3.8,0.3-5.6c0-0.7-0.2-1.5,0.2-2.1
                c0.3,8.6,0.5,17.1,0.2,25.5C127.6,85.5,127.6,86.1,127.7,86.6z M127,114.8c-0.2-7.5,0.4-15.5,0.4-22.8c-2.3-0.1-4.6-0.2-6.7-0.2
                c-0.3,0-0.6,0.1-0.8,0.1c-1.4,0-3-0.4-3.9,0c-0.3,0.4,0,0.8-0.1,1.3c0,0.3-0.2,0.6-0.2,0.9c-0.1,3.1,0.1,6.1,0.1,9.2
                c0,1-0.2,2-0.2,3.1c-0.1,2.7-0.1,5.5,0.1,8.3C119.4,114.7,123.2,114.9,127,114.8z"/>
                <path className="st0" d="M127.4,117.8c0.1,1.8,0.1,3.5,0.2,5.7c-1.6-0.5-3.5,0-5.5-0.1c-0.4,0-0.9-0.1-1.3-0.1c-0.5,0-1,0.2-1.4,0.2
                c-0.9,0-2.1,0-3.2-0.1c-2.3-0.1-4.7,0.2-7.2,0.2c-1.9,0-3.7-0.1-5.6,0c-7,0.2-13.9-0.3-20.7-0.1c-1.7,0.1-3.4,0.2-5,0.2
                c-0.4,0-0.8-0.1-1.2-0.1c-0.4,0-0.7,0.2-1,0.2c-1.1,0-2.3,0-3.3-0.1c0-1.9,0.1-3.9,0.1-5.8c2.8-0.3,5.7-0.2,8.5-0.1
                c3.2,0.1,6.6-0.2,9.6-0.1c0.5,0,1,0.2,1.4,0.2c1.3,0,2.7-0.2,4.1-0.1c0.3,0,0.6,0.2,0.8,0.2c0.4,0,0.9-0.2,1.4-0.3
                c2-0.2,4.3,0.1,6.2,0c1.3-0.1,3,0.2,4.5,0.2c2,0,4-0.3,5.9,0c1.3-0.2,3.3-0.2,4.8-0.2C122.2,117.7,124.8,117.8,127.4,117.8z"/>
                <path className="st0" d="M126.6,92.8c0.2,7.1-0.6,14.2-0.4,21.3c-1.5,0.3-3.3,0-5,0c-1.6,0-3.4,0.1-4.7-0.1c-0.1-2.7-0.3-5.5-0.1-8.2
                c0.2-2,0.2-3.9,0.2-5.8c0-1.1,0.2-2.3,0.1-3.4c0-0.2-0.2-0.4-0.2-0.6c0-0.5,0-1.2,0-1.8c0-0.6-0.2-1.3,0.2-1.7
                C119.9,92.5,123.3,92.6,126.6,92.8z M125.8,103.5c0.1-2.3,0.3-5.4,0.2-8.1c0-0.7,0.1-1.5-0.2-1.7c-0.3-0.3-0.8-0.1-1.2-0.1
                c-1.5,0-3.3-0.1-4.7-0.1c-0.8,0-1.5,0.2-2.2-0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.8,0,1.6,0,2.4c0,0.8-0.1,1.7-0.1,2.4
                c0,1.4,0,2.9,0,4.3c-0.1,3.3-0.1,6.8-0.3,10c1.9,0.5,5,0,7.2,0.1c0.6,0,1.2,0.3,1.4-0.1c0.2-0.2-0.1-0.7-0.2-1
                c-0.2-1.4,0.1-2.9,0.1-4.2C125.8,106.1,125.8,104.9,125.8,103.5z"/>
                <path className="st0" d="M126.4,59.3c0.1,6.4-0.4,11.6,0,17.9c-3,0.3-6.4,0.4-9.6,0.2c-0.2-0.9,0-1.8,0-2.7c0-0.5-0.1-1-0.1-1.5
                c0-0.3,0.2-0.5,0.2-0.8c0-0.7,0.1-1.5,0-2.3c0-3.7-0.1-7.3,0-10.9c0-0.1,0-0.1,0.1-0.2C120.2,59.2,123.2,59,126.4,59.3z
                M125.9,70.9c0.1-2.3-0.2-4.6-0.2-6.7c0-1.5,0.4-3.1-0.2-4.3c-2.2,0.1-4.5-0.2-6.6-0.1c-0.4,0-0.9,0.1-1.3,0.3
                c0.1,5-0.5,10.1-0.2,15c0,0.5-0.1,1.1,0.2,1.6c1.3,0,2.7,0,4.2-0.1c1.4,0,3,0.2,4.1-0.3c0-0.6-0.4-2,0.1-2.6
                C125.6,73,125.8,72,125.9,70.9z"/>
                <path className="st0" d="M123.5,41.5c0.4,0.7,2.5,0.7,1.7,1.7c-0.4,0.1-0.6-0.3-0.9-0.6c-1.4-1-4.8-1.3-6.4-0.4
                c-0.4,0.2-0.6,0.7-1.2,0.6c0-0.4,0.5-0.8,0.9-0.9C119.1,41.2,121.8,41.1,123.5,41.5z"/>
                <path className="st0" d="M125.2,94.5c0.4,4.3-0.2,8.9-0.2,13.3c-0.9-0.1-1.9,0.1-2.8-0.2c-0.1-0.4-0.1-1.1,0-1.4
                c0.5-0.4,2.6,0.3,2.6-0.4c0-0.7-1.9-0.4-2.6-0.4c-0.3-0.4-0.1-1.2,0-1.8c0.4-0.3,2.8,0.4,2.8-0.5c0-0.7-2.1-0.2-2.7-0.5
                c-0.2-0.5,0-1.1,0.1-1.6c0.5-0.3,2.7,0.4,2.8-0.3c0.1-0.8-2.3-0.3-2.8-0.6c-0.1-0.3-0.1-1.1,0-1.4c0.3-0.3,2.7,0.2,2.8-0.4
                c0.1-0.8-2.5-0.1-2.8-0.5c0-0.4-0.1-1.2,0.1-1.6c0.6-0.3,2.2,0.3,2.3-0.4c0.2-0.8-1.7-0.2-2.3-0.5c0-0.3,0-0.7,0-0.9
                C123.2,94.4,124.3,94.5,125.2,94.5z"/>
                <path className="st0" d="M125.1,69.1c0,0.5,0.1,1.2-0.1,1.7c-0.6,0.2-1.5,0.1-2.2-0.1c0-0.3-0.1-1.1,0.1-1.4
                C123.6,69.1,124.4,68.9,125.1,69.1z"/>
                <path className="st0" d="M125,66.8c0.1,0.4,0.2,1,0.1,1.4c-0.7,0.1-1.5,0.2-2.2,0.1c0-0.5,0-0.9,0-1.4C123.5,66.9,124.3,66.9,125,66.8
                z"/>
                <path className="st0" d="M125,71.7c0,0.4,0,1.2-0.1,1.7c-0.6,0.2-1.4,0.1-2.1,0.1c-0.2-0.4,0-1.2,0-1.8
                C123.3,71.3,124.5,71.7,125,71.7z"/>
                <path className="st0" d="M124.9,64.7c0.1,0.3,0.1,0.7,0.1,1.2c-0.5,0.3-1.5,0.3-2.2,0.2c-0.1-0.4-0.1-1.1,0.2-1.4
                C123.7,64.7,124.3,64.7,124.9,64.7z"/>
                <path className="st0" d="M124.9,62.5c0.1,0.3,0,0.7,0,1.1c-0.5,0.3-1.4,0.5-2.1,0.3c-0.1-0.3,0-0.9,0-1.3
                C123.4,62.4,124.2,62.5,124.9,62.5z"/>
                <path className="st0" d="M125,60.7c0,0.3,0,0.6,0,0.9c-0.5,0.2-1.5,0.3-2.1,0.1c0-0.4-0.1-0.8,0.1-1C123.8,60.8,124.4,60.6,125,60.7z"/>
                <path className="st0" d="M124.9,108.5c0,1.1-0.1,2.5-0.2,3.6c-0.9-0.1-1.7,0.1-2.4,0c-0.1-0.4-0.2-0.8-0.1-1.3
                c0.7-0.3,2.7,0.2,2.4-0.6c-0.2-0.6-1.7,0-2.4-0.2c0-0.4-0.2-1-0.1-1.4C123,108.5,124.3,108.6,124.9,108.5z"/>
                <path className="st0" d="M124.8,74.4c0.1,0.3,0.1,1,0,1.5c-0.6,0.1-1.4,0.1-2,0c0-0.4-0.2-1-0.1-1.5C123.2,74,124.4,74.2,124.8,74.4z"/>
                <path className="st0" d="M124.5,43.9c0.1,1.5,0.1,3.3,0.1,4.8c0,0.4,0,1-0.2,1.3c-0.4-0.1-0.3-1-0.3-1.6c0-1.1,0-2.1,0-3.3
                c0-0.3,0.1-0.7,0.1-1c-0.2-0.6-1.8-1.4-2.8-1.4c-0.5,0-1.4,0-1.9,0.1c-0.8,0.2-1,0.9-1.6,1.2c0,2.1,0.1,4.4-0.1,6.2
                c-0.3-0.3-0.3-0.8-0.3-1.2c0-0.7,0-1.5,0-2.3c0-1.1,0-2.3,0.1-3.2C119,42,123.3,42.3,124.5,43.9z"/>
                <path className="st0" d="M124.3,130.3c0.2,1,0.2,2.5,0,3.3c-0.1,0-0.2,0.1-0.4,0.1c-0.3-0.9-0.2-2.4,0-3.5
                C124,130.3,124.2,130.2,124.3,130.3z"/>
                <path className="st0" d="M123.3,47.4c0.1,0.3,0.1,0.7,0,1.1c-0.3,0.1-0.9,0-1.2,0c0-0.4-0.1-0.6,0-1C122.5,47.3,122.8,47.4,123.3,47.4
                z"/>
                <path className="st0" d="M123.3,45.6c0.1,0.2,0.1,0.8,0,1c-0.3,0.1-0.9,0.1-1.2,0c-0.1-0.3-0.1-0.8,0-1.1
                C122.5,45.5,122.9,45.5,123.3,45.6z"/>
                <path className="st0" d="M123.3,49.3c0,0.2,0,0.6-0.1,0.7c-0.2,0.1-0.8,0.1-1.1,0c0-0.3-0.1-0.4-0.1-0.7
                C122.3,49.2,123,49.1,123.3,49.3z"/>
                <path className="st0" d="M123,130.3c0.1,1.2,0,2.2,0,3.4c-0.2,0-0.4,0-0.6-0.1c-0.2-1-0.2-2.5,0-3.4
                C122.7,130.2,122.9,130.2,123,130.3z"/>
                <path className="st0" d="M123.2,44.7c-0.3,0.1-0.7,0-1.1,0c-0.1-0.2-0.1-0.5,0-0.7C122.6,44.1,123,44.2,123.2,44.7z"/>
                <path className="st0" d="M121.9,60.7c0.3,4.7,0.2,9.9,0,14.1c0,0.4,0.2,0.9-0.2,1.2c-0.4-0.4-0.2-1-0.2-1.5c0-0.6,0-1.3,0-1.9
                c0.2-3.7-0.1-7.8,0.2-11.7c0-0.1,0.1-0.1,0.1-0.2C121.8,60.7,121.9,60.7,121.9,60.7z"/>
                <path className="st0" d="M121.7,130.3c0,1,0,2.2,0,3.4c-0.1,0-0.3,0-0.4,0c-0.4-1-0.1-2.4-0.1-3.4
                C121.3,130.3,121.5,130.3,121.7,130.3z"/>
                <path className="st0" d="M121.4,94.5c0.1,0,0.1,0,0.2,0c-0.2,5.7-0.2,11.8-0.3,17.6c-0.5-0.6-0.2-1.6-0.2-2.3
                c-0.2-4.8,0.2-9.9,0.3-15.2C121.4,94.6,121.4,94.5,121.4,94.5z"/>
                <path className="st0" d="M121.2,47.2c0.2,0.1,0.2,0.5,0.1,0.6C121.1,47.8,121.2,47.4,121.2,47.2z"/>
                <path className="st0" d="M120.7,64.7c0.1,0.5,0.1,1.2,0.1,1.6c-0.7,0-1.7,0.1-2.4,0c-0.1-0.4,0-1.1-0.1-1.6
                C119,64.6,120,64.6,120.7,64.7z"/>
                <path className="st0" d="M120.7,60.7c0.1,0.3,0.1,0.6,0,0.9c-0.7,0.1-1.7,0.2-2.2,0c-0.1-0.2,0-0.6,0.1-0.8
                C119.1,60.5,120.1,60.6,120.7,60.7z"/>
                <path className="st0" d="M120.7,62.3c0.2,0.4,0.1,1.2-0.1,1.7c-0.5,0-1.7,0.1-2.2-0.2c0-0.5,0-1,0.2-1.4
                C119.1,62.4,120,62.4,120.7,62.3z"/>
                <path className="st0" d="M120.7,67.1c0.2,0.3,0.1,1.1,0,1.4c-0.6,0.1-1.6,0-2.4,0c0-0.5-0.1-1.1,0-1.4C119,67.1,120,67,120.7,67.1z"/>
                <path className="st0" d="M120.7,69.3c0.1,0.6,0.1,1.2,0.1,1.8c-0.7,0.1-1.6,0.1-2.5,0c-0.2-0.5-0.1-1.2,0-1.7
                C118.9,69.1,120,69.4,120.7,69.3z"/>
                <path className="st0" d="M120.7,71.9c0.1,0.5,0.1,1.4-0.1,1.8c-0.6-0.3-1.5-0.2-2.3-0.2c-0.1-0.5-0.3-1.2,0-1.7
                C119,71.9,119.9,71.9,120.7,71.9z"/>
                <path className="st0" d="M120.5,94.4c0.1,0.4,0.1,1,0,1.3c-0.7,0.1-1.4,0-2.2,0c-0.1-0.4-0.2-0.9,0-1.3
                C119.1,94.4,119.8,94.3,120.5,94.4z"/>
                <path className="st0" d="M120.5,74.2c0.2,0.3,0.1,1.3,0,1.7c-0.6,0.2-1.4-0.1-2.1,0c-0.1-0.4-0.3-1.2,0-1.6
                C119.1,74.2,120,74.4,120.5,74.2z"/>
                <path className="st0" d="M120.5,96.6c0.1,0.4,0,1.1,0,1.4c-0.7,0.1-1.5,0.1-2.3,0.1c-0.2-0.5-0.1-1.2,0.1-1.7
                C119.2,96.4,119.9,96.4,120.5,96.6z"/>
                <path className="st0" d="M120.5,98.9c0.1,0.4-0.1,0.8,0,1.5c-0.5,0-1.5,0.3-2.2,0.1c0-0.6-0.1-1.1,0-1.5
                C119.1,99,119.8,98.7,120.5,98.9z"/>
                <path className="st0" d="M120.4,101.2c0.1,0.5,0,1.3,0,1.8c-0.5,0-1.5,0-2.2,0c-0.2-0.5-0.1-1.1,0.1-1.7
                C119,101.2,119.6,101.1,120.4,101.2z"/>
                <path className="st0" d="M120.3,43.7c0,0.5,0.1,0.7,0.1,1.1c-0.5,0.1-1.3,0.1-1.7,0C118.7,43.9,119.4,43.4,120.3,43.7z"/>
                <path className="st0" d="M120.4,45.7c0,0.3,0,0.7,0,0.9c-0.4,0.2-1.4,0.2-1.8,0c0-0.4,0-0.7,0.1-1C119.3,45.7,120,45.5,120.4,45.7z"/>
                <path className="st0" d="M120.4,47.6c0,0.3,0,0.7-0.1,0.9c-0.5,0.2-1.2,0.1-1.7-0.1C118.2,47.1,119.7,47.5,120.4,47.6z"/>
                <path className="st0" d="M120.3,49.4c0.1,0.2,0.1,0.5,0,0.7c-0.5,0.1-1.2,0.2-1.6,0C118.3,48.9,119.5,49.5,120.3,49.4z"/>
                <path className="st0" d="M120.3,130.3c0.1,1.1,0,2.4,0.1,3.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2-1.1-0.2-2.4,0-3.4
                C120.1,130.3,120.2,130.3,120.3,130.3z"/>
                <path className="st0" d="M120.2,106.1c0.2,0.6,0.1,1,0,1.8c-0.6,0.1-1.5,0.2-2.1,0c-0.2-0.5-0.1-1.2,0-1.7
                C118.8,106,119.6,106.3,120.2,106.1z"/>
                <path className="st0" d="M120.3,103.5c-0.1,0.6,0,1.3-0.1,1.9c-0.7-0.1-1.4,0.1-2.1-0.1c-0.2-0.5-0.1-1.1,0-1.6
                C118.9,103.6,119.8,104,120.3,103.5z"/>
                <path className="st0" d="M120.2,108.5c0.2,0.3,0.1,0.8,0.1,1.3c-0.5,0.2-1.4,0.3-2.1,0.2c-0.1-0.3,0-0.9-0.1-1.3
                C118.6,108.5,119.5,108.7,120.2,108.5z"/>
                <path className="st0" d="M120.2,110.8c0.1,0.4,0.1,1.1,0,1.4c-0.6-0.1-1.5,0.2-2.1,0.1c-0.1-0.4-0.1-0.9,0-1.3
                C118.7,110.8,119.6,110.9,120.2,110.8z"/>
                <path className="st0" d="M116.5,39.1c0.2,0.7-0.1,1.9,0.3,2.3c-0.2,0.3-1,0.6-1.1,1c0,0.5,0.6,0.7,0.8,1.2c0.2,0.7,0.1,2.3,0.1,3.2
                c0,1.2,0,2.3-0.1,3.3c-3.4,0.2-6.1,0.3-9.4,0.3c-0.5-1.5-0.3-4.4-0.3-6.2c0-0.6,1.1-1.1,1.1-1.6c0-0.5-1-1.3-1.6-1.6
                c-0.3-0.2-0.8-0.4-1.1-0.6c0.6-0.1,0.4-0.9,0.5-1.5c0.9-0.3,2.3,0,3.2,0c0.2,2.7,0.1,4.5,0,7.3c0,0.8-0.2,3.1,0.3,3.2
                c0.6,0.1,0.4-1.6,0.4-1.9c0-3.1-0.2-5.5,0.1-8.6c0.9-0.2,2.1-0.1,3.1-0.1c0.3,2.1,0.1,5.1,0.2,7.3c0,0.5-0.1,1.2-0.1,2.1
                c0,0.4,0,1.6,0.4,1.6c0.6,0,0.3-2.3,0.3-3c-0.1-2.8,0-5.2,0.1-8C114.7,39,115.6,39,116.5,39.1z"/>
                <path className="st0" d="M114.3,69.9c0,1.1,0.2,2.2,0.2,3.2c0,1.7-0.2,3.5-0.2,5.2c0.1,1.6-0.2,3.2-0.2,4.8c0,1.3,0.1,2.6,0.2,3.9
                c0,1.3-0.1,2.6-0.1,4c0,1.4,0.1,2.7,0.2,4.1c0,1.4-0.2,2.8-0.1,4.2c0,0.6,0.2,1.1,0.2,1.7c0,1.2-0.1,2.4,0,3.6
                c0,2.1-0.1,4.1-0.1,6.1c0.1,2,0.3,4.1,0,6.2c-1.6,0.1-2.8,0.1-4.3,0.1c0-3.6,0-7.8,0-11.8c0-1.5-0.1-3.1-0.1-4.5
                c0-0.8,0.3-2,0.3-2.8c0-2.9-0.3-5.9-0.3-8.5c0-1.3,0-2.6,0-4c0-1.3-0.2-2.7-0.2-4.2c0-4.2,0.3-7.7,0.3-12.4c0-2.5,0.1-6.4-0.1-9.6
                c-0.1-1-0.1-2.7,0.3-2.9c0,0,0.2,0,0.2,0c1.1-0.1,2.9,0.1,3.9,0.1C114.9,60.8,114.2,65.4,114.3,69.9z"/>
                <path className="st0" d="M109,105.4c0,1.5,0.1,3.1,0.1,4.6c0,2.2,0,4.7-0.2,7c-3.8,0-7.9,0-11.8,0c-0.3-1.5-0.3-3.1-0.3-4.7
                c0-4.3,0-7.9-0.2-12.1c-0.2-2.8,0.2-5.9,0-8.8c-0.1-2.1-0.1-4.2-0.2-6.3c0-0.6,0.1-1.2,0.1-1.8c-0.1-1.8-0.2-4.1-0.2-6.1
                c0-1.7,0.1-3.5,0.2-5.2c0.2-5.1,0.1-10.3,0.2-15.6c4.2-0.1,8,0.1,12.1,0.1c0.3,12.1-0.3,23.6,0.1,35.8c-2.8-0.3-5.8,0.1-8.9,0
                c-1,0-2-0.4-2.9,0.1c0.3,2.5,0.1,5.8,0.2,8.8c0.1,2.3,0.3,4.6,0.3,6.8c0,2.3,0,4.6,0,6.8c1.2,0.3,2.8,0,4.4,0c0.8,0,1.5,0,2.3,0.1
                c0.8,0,1.5-0.1,2.2-0.1c0.7,0,1.8,0.3,2.1-0.1c0.2-0.2,0-0.6,0-1.2c0-1,0-3,0.1-4.1c0-2-0.2-3.8-0.2-5.3c0-3.5-0.2-7.8,0.3-11
                C109.2,97,109.1,101,109,105.4z M108.6,60.1c0-0.5,0.3-1,0-1.6c-1.7-0.3-3.5,0.2-5.6,0.1c-1.7,0-4-0.4-5.3-0.1
                c-0.3,0.7-0.2,1.5-0.2,2.3c0,0.8-0.1,1.7-0.1,2.5c0,1.7,0.2,3.3,0.2,4.9c0,3.3,0,6.7,0.1,9.8c1.2,0.3,2.7,0.3,4.1,0.2
                c2.2-0.1,4.5-0.2,6.6-0.2c0.2-0.5,0.1-1.1,0.1-1.6c0.1-3.8-0.1-7.7-0.1-11.7C108.5,63.2,108.5,61.5,108.6,60.1z"/>
                <path className="st0" d="M108,106.1c0,2.6,0.2,5.2,0,7.7c-2.9,0.1-6.3,0.1-9.4,0.1c0-3.8-0.2-8.9-0.4-13.1c-0.1-2.2,0.1-4.2,0-6.4
                c0-0.6-0.2-1.2,0.1-1.7c3.3,0.1,6.4-0.1,9.7,0.2c0,3.1-0.1,6.7-0.1,10.3C107.9,104.2,108,105.2,108,106.1z M107.4,113.4
                c0.2-0.4,0-0.9,0-1.4c0-0.8,0.1-1.7,0.1-2.5c0-0.8-0.1-1.7-0.1-2.6c0-2,0.1-4,0-5.8c0-0.6-0.2-1.1-0.1-1.7c0-0.3,0.2-0.5,0.2-0.8
                c0-0.2-0.1-0.5-0.2-0.7c-0.2-1.4,0.1-2.7,0.1-4.1c-1.8-0.5-4-0.1-5.8-0.1c-0.8,0-1.7-0.3-2.3,0.2c-0.5,6.2,0.3,12.6,0.1,19.2
                c0.8,0.4,1.8,0.2,2.7,0.2C103.8,113.3,105.8,113.7,107.4,113.4z"/>
                <path className="st0" d="M107.7,59.3c0.2,3.3-0.1,6.6-0.1,10c0,2.7,0.5,5.5,0,8c-3.3,0-6,0.4-9.1,0.1c0-4.5-0.1-10.3-0.1-15.5
                c0-1-0.2-2.1,0.3-2.9C101.4,59.4,104.8,59.2,107.7,59.3z M107.1,77c0.4-0.4,0-0.8,0-1.3c-0.2-1.4-0.1-3,0-4.3
                c0-1.9-0.3-3.9,0.2-4.9c-0.6-0.9-0.1-2.8-0.3-4.3c-0.1-0.7-0.1-1.5,0.1-2.1c-0.2-0.3-0.6-0.2-0.8-0.2c-2.1,0.2-5,0.1-7.3,0.1
                c-0.4,0.9-0.2,2.2-0.2,3.1c0,2.3,0.2,4.8,0.2,7.2c0,2.2-0.1,4.6,0.3,6.7C101.7,76.9,104.9,76.8,107.1,77z"/>
                <path className="st0" d="M106.1,41.7c0.3,0.2,1.1,0.8,0.6,1c-0.3,0.2-0.5-0.4-0.9-0.6c-1.5-0.8-4.4-1.1-6-0.2
                c-0.4,0.2-0.6,0.6-1.1,0.8c-0.5-0.6,0.5-1,0.9-1.3c0.5-0.3,1.1-0.6,1.8-0.7C103.4,40.5,104.9,40.9,106.1,41.7z"/>
                <path className="st0" d="M106.7,108.5c0.1,0.5,0.1,1.4,0,1.8c-0.7,0.2-1.6,0.1-2.2-0.1c0-0.5-0.3-1.1-0.1-1.6
                C105,108.4,105.8,108.4,106.7,108.5z"/>
                <path className="st0" d="M106.6,103.9c0.1,0.4,0.1,0.9,0,1.3c-0.7,0.2-1.7,0.1-2.4-0.1c-0.1-0.3-0.1-1,0-1.3
                C105,103.7,105.8,103.8,106.6,103.9z"/>
                <path className="st0" d="M106.6,101.3c0,0.5,0.1,1.2,0,1.7c-0.8,0-1.7,0.1-2.3-0.2c0-0.4-0.2-1.3,0-1.6
                C105,101.2,105.8,101.3,106.6,101.3z"/>
                <path className="st0" d="M106.6,106c0.1,0.4,0.1,1.1,0,1.5c-0.5,0.2-1.8,0.2-2.3,0c-0.1-0.4-0.1-1.2,0-1.6
                C104.9,105.8,105.9,106,106.6,106z"/>
                <path className="st0" d="M106.5,111.1c0.1,0.5,0,1.1,0,1.5c-0.5,0.2-1.6,0.1-2.2,0c0-0.3,0-1.1,0-1.6
                C105.1,110.9,105.8,111,106.5,111.1z"/>
                <path className="st0" d="M106.5,99.1c0.1,0.4,0.1,1,0.1,1.5c-0.6,0.1-1.9,0.2-2.4-0.1c-0.1-0.4-0.1-1.1,0.1-1.5
                C105,99,105.8,98.9,106.5,99.1z"/>
                <path className="st0" d="M106.4,94.5c0.1,0.3,0.1,0.7,0.1,1.1c-0.7,0.2-1.4,0.1-2.3,0c0-0.4-0.1-0.9,0.1-1.2
                C104.9,94.5,105.8,94.5,106.4,94.5z"/>
                <path className="st0" d="M106.5,96.5c0.1,0.5,0.1,1.4-0.1,1.8c-0.8,0-1.5,0-2.2-0.1c-0.2-0.4-0.1-1.1-0.1-1.6
                C104.8,96.3,106,96.5,106.5,96.5z"/>
                <path className="st0" d="M106.3,69.4c0.1,0.6,0,1.3,0,1.8c-0.8,0.1-1.4,0-2.1-0.1c-0.1-0.6-0.1-1.2,0-1.7
                C104.8,69.2,105.6,69.3,106.3,69.4z"/>
                <path className="st0" d="M106.3,74.6c0,0.5,0,1.1,0,1.4c-0.6,0.1-1.4,0.2-2,0.1c0-0.5-0.1-1.1,0-1.5C104.8,74.4,105.9,74.3,106.3,74.6
                z"/>
                <path className="st0" d="M106.2,64.5c0,0.4,0.1,1.1,0,1.5c-0.7,0.1-1.5,0.2-2.1,0c-0.1-0.3,0-0.8-0.1-1.2
                C104.7,64.6,105.8,64.7,106.2,64.5z"/>
                <path className="st0" d="M106.2,66.9c0.1,0.6,0.1,1.1,0.1,1.6c-0.5,0.4-1.6,0.2-2.2,0.1c-0.2-0.5,0-1-0.1-1.6
                C104.8,66.7,105.6,66.8,106.2,66.9z"/>
                <path className="st0" d="M106.2,72c0.1,0.5,0.2,1.1,0,1.6c-0.6,0.1-1.3,0.2-1.9,0.1c0-0.6-0.2-1.2-0.1-1.7
                C104.7,72,105.5,71.9,106.2,72z"/>
                <path className="st0" d="M106.2,62.7c0.1,0.4,0.1,1.1,0.1,1.4c-0.4-0.5-1.6,0.1-2.1-0.3c0-0.4-0.2-0.9,0-1.2
                C104.8,62.7,105.7,62.7,106.2,62.7z"/>
                <path className="st0" d="M106.1,60.8c0,0.4,0.1,0.7,0.1,1.1c-0.6,0.2-1.3,0-1.9,0c-0.1-0.3,0-0.6,0-1
                C104.8,60.6,105.6,60.8,106.1,60.8z"/>
                <path className="st0" d="M106.1,46.6c0,1.3,0,2.7,0,3.8c-0.5-0.3-0.2-1-0.3-1.6c-0.1-1.1,0.3-3.6-0.2-4.6c-0.2-0.5-1-1-1.4-1.1
                c-0.7-0.3-2.2-0.5-3.2-0.2c-0.5,0.2-0.7,0.6-1.2,0.9c0.2,1.8-0.1,3.7,0,5.3c0,0.4,0.2,0.9,0,1.3c-0.5-0.2-0.3-0.9-0.3-1.4
                c-0.1-1.9-0.2-3.8-0.1-5.8c1.2-1.6,5.5-1.3,6.6,0.2c0,0.2-0.2,0.2-0.2,0.4C106.1,44.5,106.1,45.7,106.1,46.6z"/>
                <path className="st0" d="M105.5,130.2c0,0.6-0.1,1.3-0.1,2c0,0.5,0.3,1.2,0,1.6c-0.7-0.1-0.5-1.1-0.4-1.8c0-0.6,0.1-1.2,0.1-1.7
                c0-0.1,0-0.1,0.1-0.2C105.3,130.1,105.5,130.1,105.5,130.2z"/>
                <path className="st0" d="M105,38.8c0.2,0.4,0.1,1.1,0.1,1.4c-1.1-0.2-2.4-0.5-3.7-0.3c0-0.3-0.2-0.8,0-1.1
                C102.6,38.7,103.7,38.9,105,38.8z"/>
                <path className="st0" d="M104.9,49.2c0,0.4,0,0.9,0,1.2c-0.2,0.1-0.7,0.1-0.8,0C103.9,49.7,104,48.8,104.9,49.2z"/>
                <path className="st0" d="M104.8,47.7c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.2-0.9,0.2c-0.1-0.1-0.1-0.5,0-0.6
                C104.2,47.7,104.5,47.7,104.8,47.7z"/>
                <path className="st0" d="M104.8,45.5c0.1,0.4,0,0.8,0,1.3c-0.2,0.1-0.8,0.1-1,0c0-0.5,0-0.8,0-1.3C104.2,45.6,104.4,45.5,104.8,45.5z"/>
                <path className="st0" d="M104.8,44.7c-0.2,0.1-0.7,0.1-0.9,0c-0.1-0.2-0.1-0.6,0-0.7C104.2,44.2,104.6,44.4,104.8,44.7z"/>
                <path className="st0" d="M104.2,130.1c0,1.2,0.1,2.6-0.1,3.7c-0.1,0-0.3,0-0.4,0c-0.2-1.1-0.2-2.5,0-3.7
                C103.9,130.1,104,130,104.2,130.1z"/>
                <path className="st0" d="M103.4,94.5c0.2,6.5,0.1,11.5,0.2,18.1c-0.5-0.9-0.2-2.5-0.2-3.7c-0.1-4.5-0.4-9.2-0.2-14.2
                c0-0.1,0-0.1,0-0.2C103.3,94.5,103.3,94.5,103.4,94.5z"/>
                <path className="st0" d="M103.4,74.2c0,0.2,0.2,1.9-0.3,1.8c-0.4,0-0.3-1.2-0.3-1.5c0-0.8,0-1.3,0-1.8c0-1.7,0.2-3.7,0.2-5.8
                c0-1-0.1-2,0-3c0-1,0.2-2.1,0.3-2.9c0-0.1,0-0.1,0-0.2c0,0,0.1,0.1,0.2,0.1C103.2,65.3,103.2,69.9,103.4,74.2z"/>
                <path className="st0" d="M103,48.4c0,0.8,0.3,1.5,0,2.1c-0.6-0.4-0.4-1.2-0.4-1.9c-0.1-1.5,0-2.9,0-4.7c0,0,0.1-0.1,0.1-0.2
                c0,0,0.1,0,0.1,0c0.4,0.5,0.3,1.4,0.3,2.2C103.1,46.7,103,47.6,103,48.4z"/>
                <path className="st0" d="M102.9,130.1c-0.2,1,0.2,2.5-0.1,3.7c-0.1,0-0.2,0-0.4,0c-0.3-1-0.3-2.3-0.3-3.7
                C102.4,130.1,102.6,130,102.9,130.1z"/>
                <path className="st0" d="M102.5,108.6c0.1,0.5,0.1,1.1,0,1.6c-0.7,0-1.7,0.2-2.5,0c-0.1-0.5-0.2-1.2,0-1.6
                C100.8,108.5,101.9,108.4,102.5,108.6z"/>
                <path className="st0" d="M102.6,110.9c-0.1,0.5,0,1.1-0.1,1.6c-0.8,0.2-1.9,0.1-2.5,0c0-0.5-0.2-1.2,0-1.6
                C100.9,110.9,101.9,110.9,102.6,110.9z"/>
                <path className="st0" d="M102.5,106c0.1,0.5,0.1,1.2,0.1,1.7c-0.7,0.1-2,0.3-2.6,0c0-0.6-0.1-1.2,0.1-1.7
                C100.9,106,101.8,106,102.5,106z"/>
                <path className="st0" d="M102.4,103.7c0.1,0.5,0.2,1.1,0.1,1.5c-0.7,0.2-1.7,0.1-2.5,0.1c0-0.5-0.2-0.9,0-1.5
                C100.6,103.7,101.6,103.6,102.4,103.7z"/>
                <path className="st0" d="M102.4,101.2c0.1,0.4,0.1,1.2,0,1.6c-0.7,0.1-1.7,0.4-2.5,0.2c-0.1-0.6-0.2-1.3,0-1.8
                C100.7,101.1,101.6,101,102.4,101.2z"/>
                <path className="st0" d="M102.3,96.4c0.2,0.4,0.1,1.1,0,1.6c-0.8,0.1-1.8,0-2.6-0.1c-0.2-0.4,0-0.9,0-1.4
                C100.6,96.3,101.3,96.5,102.3,96.4z"/>
                <path className="st0" d="M102.3,94.5c0.1,0.4,0.1,0.6,0.1,1.1c-0.8,0.2-1.7,0.2-2.6,0c-0.1-0.3,0-0.8,0-1.1
                C100.5,94.4,101.7,94.4,102.3,94.5z"/>
                <path className="st0" d="M102.3,98.9c0.1,0.3,0.1,1,0,1.3c-0.6,0.2-1.8,0.2-2.5,0.1c-0.1-0.6-0.1-1-0.1-1.5
                C100.7,98.8,101.5,98.9,102.3,98.9z"/>
                <path className="st0" d="M102.2,60.8c0.1,0.3,0,0.8,0,1.2c-0.8,0-1.8,0.1-2.5-0.1c-0.1-0.3,0-0.8,0-1.1
                C100.5,60.7,101.3,60.7,102.2,60.8z"/>
                <path className="st0" d="M102.1,62.8c0.1,0.4,0.1,0.9,0,1.2c-0.8,0.1-1.7,0.3-2.4,0.1c-0.1-0.3-0.1-0.9,0-1.2
                C100.4,62.7,101.3,62.8,102.1,62.8z"/>
                <path className="st0" d="M102.1,67.2c0.1,0.4,0.1,0.7,0.1,1.1c-0.6,0.4-1.6,0.4-2.5,0.2c-0.1-0.3-0.1-0.8-0.1-1.2
                C100.3,67.1,101.4,67.1,102.1,67.2z"/>
                <path className="st0" d="M102.2,64.9c0,0.4,0,0.8,0,1.2c-0.4,0.4-1.9,0.3-2.6,0.2c-0.1-0.4,0-0.9,0.1-1.3
                C100.4,64.9,101.5,64.8,102.2,64.9z"/>
                <path className="st0" d="M102.1,69.2c0.1,0.6,0,1.2-0.1,1.9c-0.6,0.1-1.4-0.1-2.2,0c-0.2-0.5-0.1-1.2-0.2-1.7
                C100.3,69.1,101.3,69.4,102.1,69.2z"/>
                <path className="st0" d="M101.9,71.9c0.1,0.5,0.1,1.3,0,1.7c-0.5,0.2-1.4,0.2-2.1,0.1c-0.1-0.5-0.2-1.4,0-1.8
                C100.4,71.7,101.4,71.8,101.9,71.9z"/>
                <path className="st0" d="M102,74.8c0,0.4,0,0.8-0.1,1.2c-0.6,0.1-1.3,0.1-1.9,0c-0.1-0.3,0-0.9-0.1-1.3
                C100.5,74.4,101.5,74.4,102,74.8z"/>
                <path className="st0" d="M101.7,45.4c0,0.4,0,0.8-0.1,1.3c-0.3,0-0.7,0.2-0.9,0c-0.1-0.5,0-0.9,0-1.3
                C101.1,45.3,101.3,45.4,101.7,45.4z"/>
                <path className="st0" d="M101.7,43.8c0,0.2,0,0.5,0,0.7c-0.2,0.1-0.8,0.2-1,0.1C100.8,44.1,101.1,43.6,101.7,43.8z"/>
                <path className="st0" d="M101.7,49.5c0.1,0.2,0.1,0.6,0.1,0.9c-0.2,0.2-0.6,0.1-0.9,0C100.8,49.9,100.8,49.1,101.7,49.5z"/>
                <path className="st0" d="M101.5,47.5c0.2,0.2,0.1,0.7,0.1,1c-0.2,0.1-0.5,0.1-0.8,0.2c-0.2-0.2,0-0.6-0.1-1.1
                C101,47.6,101.3,47.6,101.5,47.5z"/>
                <path className="st0" d="M101.2,130.1c0.4,0.5,0.2,1.4,0.2,2.1c0,0.5,0.1,1,0.1,1.5c-0.1,0.1-0.4,0.1-0.6,0c-0.2-1.2-0.3-2.5,0-3.6
                C101,130.2,101.1,129.9,101.2,130.1z"/>
                <path className="st0" d="M100.4,38.7c0.1,0.4,0.2,0.9,0.1,1.3c-1,0.5-1.9,1-2.7,1.7c-0.2-1-0.2-2,0-3C98.8,38.7,99.5,38.6,100.4,38.7z"/>
                <path className="st0" d="M98.4,133.6c0.5,0,1.1-0.2,1.5,0.2c0,0.6-0.9,0.5-1.3,0.6c-3.3,0.2-6-0.2-9.1-0.1c-0.4,0-0.9,0.2-1.2-0.1
                c0-0.7,1.3-0.5,1.8-0.6C92.6,133.4,95.6,133.7,98.4,133.6z"/>
                <path className="st0" d="M98.6,44.7c0.1,1.8-0.2,4,0.1,5.6c-0.2,0.2-0.8,0.2-1.1,0.1c0.1-2.3,0.1-5.1,0.2-7.3
                C98.4,43.4,98.6,43.8,98.6,44.7z"/>
                <path className="st0" d="M93.6,38.7c1.1-0.1,2.4,0.1,3.5,0c-0.1,2.6,0,5.6-0.2,8.2c-0.1,1.2,0,2.4-0.1,3.5c-0.9,0.2-2.3,0.1-3.2,0
                c-0.2-1.2-0.1-3.1-0.1-4.7c0-0.7-0.2-1.5-0.2-2.2c0-0.7,0.1-1.3,0.1-2C93.5,40.5,93.1,39.4,93.6,38.7z"/>
                <path className="st0" d="M96.2,110.9c0,2,0,4.1,0.1,6.1c-1.3,0.1-3.3,0.1-4.6,0c-0.3-3.1,0.2-6.4,0-9.8c-0.3-5.6,0.1-11,0-16.3
                c0-1.7-0.1-3.2,0-4.9c0.1-1.6-0.1-3.2,0-4.8c0.4-8.1,0.1-16.6,0.1-24.8c1.3,0,2.9-0.1,4.2-0.1c0.2,5.1,0.3,10.3,0.1,15.5
                c-0.1,1.7-0.3,3.4-0.3,5.1c0.1,2.1,0.2,4.2,0.3,6.3c0,2.2,0.1,5,0,7.6c-0.1,4.7,0.1,9.4,0,13.9C96,106.8,96.2,108.9,96.2,110.9z"/>
                <path className="st0" d="M92.6,38.7c0.3,4.1,0.1,7.8,0.3,11.7c-1.1,0.3-2.9,0.2-4,0c-0.3-0.9-0.2-1.9-0.2-3.2c0-0.9-0.2-2-0.1-2.7
                c0.1-0.8,1.1-1.4,1-2.2c0-0.4-0.5-0.6-0.6-0.8c0.7-0.4,0.1-1.8,0.4-2.8C90.5,38.7,91.5,38.6,92.6,38.7z"/>
                <path className="st0" d="M90.7,89.7c0,0.6,0.2,1.2,0.2,1.8c0,0.4-0.1,0.9-0.1,1.3c0,3.3-0.1,6.9,0,10.2c0.1,2.6-0.1,5.2,0,7.8
                c0.2,2.1-0.1,4.2-0.3,6.2c-4.5,0-8.7,0.2-12.7-0.1c-0.2-2.9-0.2-6.2-0.3-9.3c-0.2-4.4-0.2-8.8-0.2-13.2c0-3.2-0.2-6.3-0.1-9.5
                c0.1-3.2,0-6.5,0-9.7c0-6.4,0.3-12.7,0.3-18.9c4.2-0.4,9.3-0.1,13.3,0c0.5,10.4,0.1,20.7-0.1,30.8C90.7,88,90.7,88.8,90.7,89.7z
                M90.2,92.9c0-0.5,0.2-0.5,0.1-0.8C90,91.9,89.6,92,89.1,92c-2.5,0.1-5.2,0.1-7.7,0.1c-0.4,0-0.9,0.1-1.3,0.1
                c-0.6,0-1.4-0.3-1.9,0.1c0,7.5,0,15.3,0.5,22.3c0.6,0.4,1.2,0.1,1.9,0.1c3.3-0.2,6.8,0.2,9.4,0c0.4-0.9,0.1-1.6,0.1-2.7
                c-0.1-3.9-0.1-8.6,0-12.5c0-1.7,0-4,0.1-5.7C90.2,93.5,90.2,93.2,90.2,92.9z M89.8,66.8c0-2.7,0.2-5.3,0.2-7.9
                c-1.8-0.5-3.7,0.1-5.5,0.1c-0.3,0-0.6-0.2-0.9-0.2c-0.4,0-0.8,0.1-1.1,0c-0.4,0-0.8-0.2-1.3-0.2c-1.2-0.1-2.5-0.2-3.2,0.3
                c0,6.2,0.7,13.2,0.3,19.4c1.2,0.7,2.9,0.3,4.8,0.2c0.7,0,1.4,0,2.1,0c0.7,0,1.4-0.1,2-0.1c0.6,0,2.1,0.4,2.5,0
                c0.3-0.3,0.2-1.4,0.2-2.1C89.5,73.2,89.8,69.9,89.8,66.8z"/>
                <path className="st0" d="M78.9,92.9c3.4,0,7.1-0.2,10.6-0.1c-0.3,6.9-0.2,13.8-0.2,21.2c-1,0.2-2.1-0.2-3,0.2c-2.1-0.4-4.8,0-6.9-0.2
                C78.9,107.6,78.8,99.4,78.9,92.9z M88.8,107.8c0-4.5-0.1-9.1,0-13.8c-0.8-0.2-1.7-0.1-2.7-0.1c-2.2,0.1-4.5-0.3-6.4,0.2
                c0,6.3,0,12.7,0.4,18.6c0.4,0.1,0.9,0.3,1.4,0.3c2,0.1,4.4,0.2,6.3,0.1c0.4,0,0.9-0.1,1.2-0.4C88.7,111.2,88.8,109.4,88.8,107.8z"/>
                <path className="st0" d="M89.2,59.6c-0.2,5.8-0.2,12.1-0.3,18.2c-2.9,0-6.5,0.1-9.6,0.1c-0.2-3.1-0.1-6.3-0.3-9.4
                c-0.2-2.3-0.2-4.7-0.1-6.9c0-0.7,0-1.5,0-2.1c0.4,0,1.4-0.1,2-0.1c0.5,0,1,0.2,1.4,0.3C84.6,59.9,87.1,59.3,89.2,59.6z M88.4,72.6
                c0-4,0.1-8.6,0.1-12.5c-2-0.6-5.5,0.1-7.8-0.1c-0.4,0-0.8-0.4-1-0.1c-0.2,0.4-0.1,1.5,0,2.2c0.1,3.7,0.3,8.4,0.3,12.5
                c0,0.8-0.1,1.6,0.2,2.3c2.5-0.3,5.6,0,8.3,0C88.6,75.6,88.4,74.1,88.4,72.6z"/>
                <path className="st0" d="M87.2,41.5c0.2,0.1,1.4,0.8,1.4,1.1c0,0.1-0.2,0.2-0.2,0.3c-1.6-1.2-4.8-1.5-6.9-0.7
                c-0.5,0.2-0.6,0.7-1.1,0.8c-0.1-0.5,0.5-0.8,0.7-1.1c0.8-0.5,1.9-0.9,3.3-1C85.3,41.1,86.5,41.1,87.2,41.5z"/>
                <path className="st0" d="M88.6,38.8c0.2,0.8-0.2,1.9,0.1,2.7c-0.7-0.2-1.3-0.8-2.2-1c-0.5-0.1-1.1,0.1-1.5-0.3c0-0.4,0-0.8,0-1.2
                C86.1,38.7,87.5,38.6,88.6,38.8z"/>
                <path className="st0" d="M88,96.6c0,1.4,0.2,2.7,0.1,4c-0.8,0.2-2,0.1-2.7,0c-0.1-0.4-0.1-1.1,0-1.5c0.7-0.2,2.4,0.2,2.2-0.7
                c-0.2-0.3-1.2,0-1.5-0.3c-0.3,0-0.4,0.1-0.7,0.1c-0.2-0.5-0.1-1.1-0.1-1.6C86.3,96.6,87.2,96.4,88,96.6z"/>
                <path className="st0" d="M88.1,101.4c0.1,0.6,0,1.4,0,1.8c-0.7-0.3-2.1,0.1-2.8-0.3c0-0.5,0-1,0.1-1.4
                C86.3,101.3,87.2,101.5,88.1,101.4z"/>
                <path className="st0" d="M88.1,103.7c0,1.2,0,2.8-0.1,4.2c-0.7-0.2-1.7,0.1-2.5-0.1c-0.2-0.5,0-0.9-0.1-1.6c0.7-0.3,2.5,0.2,2.3-0.7
                c-0.1-0.7-1.7,0.3-2.3-0.2c-0.2-0.4,0-1.1,0-1.5C86.2,103.8,87.5,103.8,88.1,103.7z"/>
                <path className="st0" d="M88,108.4c0.1,0.4,0.1,1.1,0,1.4c-0.9,0-1.7,0.2-2.5,0c-0.1-0.3-0.2-0.9-0.1-1.3
                C86.3,108.4,87.5,108.5,88,108.4z"/>
                <path className="st0" d="M88,110.7c0,0.3,0.1,1.1,0,1.5c-0.8,0.2-1.6,0.2-2.4,0.1c0-0.5-0.2-1.1-0.1-1.6
                C86.2,110.5,87.3,110.6,88,110.7z"/>
                <path className="st0" d="M88,94.7c0.1,0.3,0,0.7,0,1.1c-0.7,0.2-1.8,0.2-2.6,0c0-0.3,0-0.8,0.1-1C86.3,94.6,87.3,94.5,88,94.7z"/>
                <path className="st0" d="M87.8,49.3c0,0.4,0.3,0.9-0.1,1.2c-0.6,0-0.3-0.6-0.3-1c0-1.1-0.2-2-0.2-3.3c0-0.8,0.1-1.7-0.2-2.2
                c-0.3-0.3-1.4-1-2-1.1c-1.4-0.2-3.2,0.2-3.8,1.2c0.2,1.9,0.3,4.5,0.1,6.4c-0.5-0.2-0.3-0.9-0.3-1.4c0-1.2-0.2-3.2-0.1-4.8
                c0.2-2.5,6-2.1,7.1-0.7c0,0.3-0.2,0.3-0.2,0.6C87.9,45.8,87.8,47.7,87.8,49.3z"/>
                <path className="st0" d="M87.7,64.8c0.1,2.4,0,4.5-0.1,6.6c-0.7,0.2-1.5-0.1-2.2-0.1c0-0.5-0.1-0.9,0-1.4c0.6-0.3,2.3,0.2,2.2-0.5
                c-0.1-0.6-1.6,0-2.1-0.4c-0.1-0.6-0.1-1.4-0.1-2c0.5-0.3,1.9,0.3,1.8-0.7c-0.3-0.4-1.3-0.1-1.8-0.3c-0.1-0.3-0.2-1,0-1.2
                C86.1,64.7,87,64.8,87.7,64.8z"/>
                <path className="st0" d="M87.6,60.8c0.2,0.9,0.1,1.9,0.1,3.1c-0.8,0.2-1.8,0.2-2.5-0.1c0-0.5,0-0.7,0-1.2c0.5-0.3,2.4,0.1,2.5-0.5
                c0.1-0.6-1.7-0.3-2.4-0.3C84.7,60.2,86.8,60.8,87.6,60.8z"/>
                <path className="st0" d="M87.6,72.1c0,0.3,0.1,1.2,0,1.6c-0.5,0.2-1.5,0.3-2.2,0.1c0-0.5-0.1-1.1,0-1.6C86.1,72.1,86.9,71.9,87.6,72.1
                z"/>
                <path className="st0" d="M87.6,74.6c0.1,0.4,0.1,1.1,0,1.4c-0.7,0.1-1.4,0-2.1-0.1c-0.1-0.2-0.1-0.8,0-1.1
                C86,74.6,87.1,74.7,87.6,74.6z"/>
                <path className="st0" d="M86.9,129.9c0,1,0.2,2.6-0.2,3.5c-0.7,0-0.4-1.3-0.3-2.1c0-0.5,0-1,0.2-1.4C86.6,129.8,86.8,129.8,86.9,129.9
                z"/>
                <path className="st0" d="M86.4,49.3c0.3,0.2,0.1,0.8,0.2,1.2c-0.2,0.1-0.8,0.1-1,0c-0.1-0.3,0-0.7-0.1-1
                C85.7,49.4,86.2,49.2,86.4,49.3z"/>
                <path className="st0" d="M86.4,47.2c0.1,0.3,0.1,1,0.1,1.2c-0.4,0-0.6,0.2-1,0.2c-0.2-0.2-0.1-0.8-0.2-1.2
                C85.5,47.2,85.9,47.4,86.4,47.2z"/>
                <path className="st0" d="M86.3,45.3c0.1,0.3,0,0.7,0,1.1c-0.3,0.1-0.7,0.1-1.1,0.1c-0.1-0.2-0.1-0.8,0-1C85.6,45.4,86,45.4,86.3,45.3z"/>
                <path className="st0" d="M86.1,44.5c0,0.3-0.7,0.2-0.8,0.1c0-0.2,0-0.4,0.1-0.5C85.7,44.2,85.8,44.4,86.1,44.5z"/>
                <path className="st0" d="M85.6,129.9c-0.1,1.3,0.1,2.3-0.2,3.5c-0.1,0-0.3,0-0.4,0c-0.1-1.1-0.1-2.6,0.2-3.6
                C85.5,129.8,85.5,129.7,85.6,129.9z"/>
                <path className="st0" d="M84.6,94.7c0.2,5.8,0.1,11.8,0.1,17.6c-0.6-0.3-0.3-1.4-0.3-2.2c0-4.2-0.2-8.3-0.3-12.2c0-0.9,0-2.1,0.1-3.1
                c0-0.1,0-0.1,0.1-0.2C84.4,94.7,84.5,94.7,84.6,94.7z"/>
                <path className="st0" d="M84.4,62.2c0.2,4.4,0.4,9.3,0.1,13.8c-0.4-0.5-0.2-1.2-0.2-1.7c-0.3-4.3-0.5-9-0.3-13.4
                c0-0.1,0.1-0.1,0.3-0.1C84.5,61.1,84.4,61.5,84.4,62.2z"/>
                <path className="st0" d="M84.4,44c0,2,0.2,4.4,0,6.5c-0.2,0-0.2-0.3-0.2-0.4c-0.1-0.9,0-1.9,0-2.9c0-1-0.1-2-0.1-3
                c0-0.1,0.1-0.1,0.1-0.2C84.3,43.9,84.3,44,84.4,44z"/>
                <path className="st0" d="M84.2,39.6c0,0.3,0.1,0.4,0,0.6c-0.2,0.1-0.7,0.1-0.8,0C83.6,39.9,83.9,39.7,84.2,39.6z"/>
                <path className="st0" d="M84.1,129.9c0.1,1,0.1,2.6,0,3.4c-0.1,0.1-0.4,0-0.6,0.1c-0.1-1.1-0.1-2.5-0.1-3.4
                C83.6,129.9,83.8,129.9,84.1,129.9z"/>
                <path className="st0" d="M83.6,108.5c0,0.5,0,1,0,1.4c-0.7,0-2,0.2-2.7,0c-0.1-0.3,0-0.8-0.1-1.1C81.4,108.5,82.8,108.8,83.6,108.5z"/>
                <path className="st0" d="M83.4,106.2c0.1,0.5,0.1,1.1,0.1,1.8c-1,0-2,0.2-2.9-0.1c0-0.4-0.2-1.2-0.1-1.8
                C81.6,106.1,82.6,106.2,83.4,106.2z"/>
                <path className="st0" d="M83.5,110.6c0.1,0.4,0.1,1.1,0,1.5c-0.6,0.2-2.1,0.2-2.5-0.1c-0.1-0.3-0.2-0.8-0.1-1.2
                C81.7,110.7,82.7,110.8,83.5,110.6z"/>
                <path className="st0" d="M83.4,103.8c0.1,0.4,0,1.1,0,1.6c-0.8,0-2.2,0.1-2.9-0.2c0.1-0.5-0.1-0.9,0-1.3
                C81.4,103.8,82.4,104,83.4,103.8z"/>
                <path className="st0" d="M83.4,101.4c0.1,0.5,0.1,1.3,0.1,1.8c-0.9,0.1-2.1,0.1-2.9,0.1c-0.1-0.5-0.1-1.2,0-1.7
                C81.4,101.5,82.7,101.5,83.4,101.4z"/>
                <path className="st0" d="M83.3,98.9c0.2,0.4,0.1,1.2,0.1,1.9c-0.7-0.3-2.1,0.1-2.9-0.2c-0.1-0.5-0.2-1.2,0-1.7
                C81.5,98.9,82.6,99,83.3,98.9z"/>
                <path className="st0" d="M83.3,47.4c0,0.3,0.2,0.9,0,1.2c-0.2,0.1-0.8,0.2-1.1,0.1c0-0.3-0.1-0.9,0.1-1.3
                C82.5,47.4,83.1,47.3,83.3,47.4z"/>
                <path className="st0" d="M83.3,72.3c0.1,1.3,0.1,2.4,0,3.7c-0.7,0.1-1.9,0.1-2.6,0c-0.1-0.3-0.2-0.9,0-1.2c0.7-0.2,2.7,0.3,2.4-0.6
                c-0.2-0.6-1.6-0.1-2.4-0.3c-0.1-0.2-0.2-1.1,0-1.5C81.5,72.3,82.5,72.3,83.3,72.3z"/>
                <path className="st0" d="M83.3,49.6c0.1,0.3,0,0.4,0.1,0.9c-0.2,0.2-0.8,0.1-1,0c-0.1-0.2,0-0.6-0.1-0.8C82.4,49.5,83,49.5,83.3,49.6z"/>
                <path className="st0" d="M83.3,94.7c0,0.4,0.1,1,0,1.3c-0.9-0.2-1.9,0-2.8-0.1c-0.1-0.4-0.1-0.8,0-1.2C81.4,94.7,82.6,94.5,83.3,94.7z"/>
                <path className="st0" d="M83.3,96.6c0.1,0.4,0.1,1.2,0,1.7c-0.9-0.1-2,0.2-2.8-0.1c0.1-0.6,0-1,0.1-1.5C81.3,96.5,82.5,96.9,83.3,96.6
                z"/>
                <path className="st0" d="M83.3,45.7c0,0.3,0.1,0.6-0.1,0.8c-0.4,0.1-0.6,0-1,0c-0.1-0.2-0.1-0.7,0-0.8C82.6,45.8,83,45.6,83.3,45.7z"/>
                <path className="st0" d="M83.1,44.1c0.1,0.2,0.2,0.6,0.1,0.8c-0.3,0.1-0.8,0.2-1.2,0.1C82,44.2,82.5,44.1,83.1,44.1z"/>
                <path className="st0" d="M83.1,60.8c0.1,0.8-0.1,1.8,0,2.8c-0.6,0.4-1.9,0.2-2.7,0.1c-0.2-0.2-0.1-0.8,0-1.1c0.5-0.2,2.7,0.3,2.6-0.4
                c-0.1-0.6-1.9-0.1-2.6-0.5c-0.1-0.2-0.1-0.7,0-0.9C81.2,60.7,82.4,60.7,83.1,60.8z"/>
                <path className="st0" d="M83,66.8c0.2,1.5,0.2,3,0.3,4.6c-0.7,0.1-1.8,0.2-2.6,0c-0.1-0.4,0-1.3-0.1-1.9c0.6-0.3,2.5,0.3,2.2-0.7
                c-0.5-0.3-1.7,0.1-2.2-0.2c-0.1-0.5-0.2-1.1-0.1-1.6C81.3,66.9,82.3,67.1,83,66.8z"/>
                <path className="st0" d="M80.4,64.7c0.7-0.2,1.8,0,2.6,0c0,0.4-0.1,0.8,0,1.3c-0.8,0-1.7,0.4-2.5,0C80.4,65.7,80.5,65.2,80.4,64.7z"/>
                <path className="st0" d="M82.5,130c0,1.2,0.2,2.4,0,3.4c-0.1,0-0.3,0-0.4,0c-0.2-0.9-0.2-2.2-0.2-3.4C82,129.8,82.3,129.9,82.5,130z"/>
                <path className="st0" d="M79.8,43.8c0.2,2.1,0.3,4.7,0.1,6.7c-1.1,0-2.6,0.2-3.7,0c-0.1-1.3-0.2-2.9-0.2-4.1c0-1.1,0.5-1.1,1.2-1.7
                c0.6-0.5,1.2-1,1.7-1.3C79.3,43.2,79.6,43.6,79.8,43.8z"/>
                <path className="st0" d="M76.9,112.3c0,1.6,0.2,3.1,0,4.6c-1.3,0.1-3.2,0.2-4.7,0.2c-0.1-3.1,0-6.2,0-9.4c0-1.4-0.2-2.8-0.2-4.2
                c0-3,0.2-5.9-0.1-8.8c-0.2-2-0.1-4.4-0.1-6.7c0-5.9-0.2-11.4,0.1-17.2c0.1-1.6,0-3.2,0-4.8c0-3.2,0.3-6.3,0.2-9.4
                c1.3-0.1,2.7-0.1,3.9-0.2c0.1,0,0.1-0.1,0.2,0c0.3,0.1,0.4,1.3,0.4,1.7c0,0.4-0.2,0.8-0.2,1.2c0,0.8,0,1.6,0.1,2.4
                c0.1,2.3,0.1,4.7,0,7c-0.2,5.2-0.3,10.5,0,15.8c0,0.9-0.1,1.7-0.1,2.6c0,0.9,0,1.9,0.1,2.8c0,5.7,0.1,11.9,0.4,17.4
                C76.9,109,76.9,110.6,76.9,112.3z"/>
                <path className="st0" d="M75.3,46.2c0.2,1.4,0,2.8,0.1,4.2c-1,0.2-1.9,0-2.9-0.1c-0.1-0.6-0.2-1.3-0.2-1.9c0.8-0.9,2-1.4,2.9-2.3
                C75.3,46.2,75.3,46.2,75.3,46.2z"/>
                <path className="st0" d="M71.4,49.1c0.3,0.1,0.2,0.9,0.2,1.3c-0.5,0.1-1.1,0.1-1.7,0.1C70.3,49.9,70.9,49.5,71.4,49.1z"/>
                <path className="st0" d="M257.2,117.4c0.3,1.6,0.3,3.7,0.2,5.2c-0.5,0.2-1.2,0-1.7,0c-2.4,0-4.7,0.3-7,0.3c-6.9,0.2-13.1,0.1-19.4,0.1
                c-4.5,0-9.2,0-13.3,0c-1.2,0-2.5-0.2-3.7-0.2c-3.3,0-6.6,0.2-9.8,0.1c-0.1-1.7-0.2-3.6-0.1-5.3c5.1,0,10.1-0.4,15.4-0.3
                c0.4,0,0.9,0,1.3,0.1c0.4,0,0.9,0.2,1.3,0.2c0.4,0,0.7-0.3,1-0.3c0.5,0,1,0.1,1.5,0.1c2.8,0.1,6-0.1,8.9,0.1c0.3,0,0.7,0.2,1,0.2
                c1.9,0.1,3.8-0.4,5.6,0.1c0.3-0.1,0.6-0.2,0.8-0.2c2.1-0.2,4.5-0.1,6.8-0.1c2.7,0,5.4-0.2,7.9-0.3
                C254.7,117.3,256.2,117.4,257.2,117.4z"/>
                <path className="st0" d="M163.1,53.7c0,0.2-0.4,0.2-0.4,0C162.8,53.5,163,53.6,163.1,53.7z"/>
                <path className="st0" d="M196.6,53.9c0.1,0.2,0.1,0.3,0.1,0.6c-0.4,0.2-0.9,0-0.8-0.6C196.1,53.8,196.4,53.8,196.6,53.9z"/>
                <path className="st0" d="M150.8,54.3c0-1,1.6-0.4,0.9,0.2C151.4,54.5,151.1,54.4,150.8,54.3z"/>
                <path className="st0" d="M149,52.9c0.6-0.2,1.2,0.1,1.9,0c-0.5,0.4-1.2,0.9-1,1.9c-0.2,0.1-0.5,0.2-0.7,0c0-0.3-0.1-0.6-0.1-0.8
                c0.3-0.1,0.6-0.1,0.7-0.5c0,0-0.1-0.1-0.1-0.2C149.7,53.1,149,53.1,149,52.9z"/>
                <path className="st0" d="M177.6,53.5c0.2,0.7-0.6,0.8-1.1,0.6C176.4,53.3,177.2,53.1,177.6,53.5z"/>
                <path className="st0" d="M175.9,52.6c-0.1,0.6-0.2,1.1-0.1,1.8c-0.3,0-1.1,0-1.4-0.1c-0.4-0.7,0.9-0.6,0.9-1.1c0-0.3-0.2-0.5-0.8-0.4
                C174.8,52.5,175.4,52.6,175.9,52.6z"/>
                <path className="st0" d="M165.7,106.6c-0.2,0.4-1.2,0.5-1.3-0.1C164.8,106.1,165.1,106.5,165.7,106.6z"/>
                <path className="st0" d="M165.1,96.7c-0.4-0.1-1.1-0.1-1.4,0c0.2,0.3,0.7,0.4,0.4,0.9c0.1,0.1,0.4,0.1,0.5,0c0-0.2-0.2-0.3-0.1-0.6
                C164.6,96.8,165.1,97,165.1,96.7z"/>
                <path d="M171,104.9c0-0.2,0.1-0.3,0-0.4c-0.3-0.2-0.8-0.1-0.7,0.4C170.5,105,170.8,105,171,104.9z"/>
                <path d="M170.1,99.7c0.9,0,1.2-0.8,0.6-1.3c-0.5-0.1-0.5,0.3-0.8,0.4c-0.8-0.2-1.4,0.2-1.5,0.9c0.2,0.4,0.5-0.1,0.9-0.1
                C169.6,99.5,169.9,99.7,170.1,99.7z"/>
                <path d="M170.6,106.4c0.3-0.5-0.1-1.3-0.6-1.4c-0.5-0.2-1.4,0-1.5,0.6c0.2,0.4,0.7,0.1,1.1,0.1
                C169.8,106,169.9,106.7,170.6,106.4z"/>
                <path d="M170.5,102.6c0.2-0.7-0.7-0.6-0.6,0C170,102.7,170.4,102.7,170.5,102.6z"/>
                <path d="M170.3,101.8c1.1-0.1,0.8-1.9-0.4-1.3c0,0,0,0.1,0,0.1C169.9,101,170.1,101.5,170.3,101.8z"/>
                <path d="M171.2,108c0-0.4-0.4-1.3-1-1.2c-0.3,0.1-0.2,0.5-0.6,0.5c0-0.2,0-0.5-0.1-0.7c-0.3-0.4-1.5-0.7-1.6-0.1
                c-0.1,0.6,0.7,0.3,0.9,0.7c-0.2,0.8,0.3,0.8,0.7,1.1c0.1,0.1,0.1,0.3,0.2,0.4C170.3,109.2,171.2,108.7,171.2,108z"/>
                <path d="M169.5,103.4c0.1,0.4,0.9,0.3,0.8-0.2c0,0-0.1,0-0.1-0.1C169.9,102.9,169.6,103.1,169.5,103.4z"/>
                <path d="M170,104.7c0.5-1-1.3-1-1.1-0.3C169,104.7,169.6,104.8,170,104.7z"/>
                <path d="M169.8,98.3c0.3-0.7-0.1-1.6-1-1.3c-0.2,0.2-0.1,0.5-0.2,0.7c-0.2,0-0.4,0.1-0.6,0.2C167.9,99,169.2,98.4,169.8,98.3z"/>
                <path d="M168.9,102.2c0.1-0.3,0.9-0.8,0.7-1.2c0-0.1-0.8-0.5-1-0.6c-0.9-0.2-1.9,0.2-2.6-0.3c-0.2-0.1-0.5-0.8-0.6-0.9
                c-0.8-0.6-1.7,0.3-1.7,0.9c-0.7,0.1-1.3,0.5-2,0.5c-0.6,0-1-0.6-1.5-0.4c0,0.4-0.3,0.5-0.4,0.7c0,0.3,0.2,0.4,0.4,0.8
                c0.2,0.5,0,1.4,0.8,1.3c0.4-0.6-0.1-1.4-0.3-1.9c0.8,0.4,2.8,0.3,3.4-0.2c0.4-0.3,0.3-0.8,0.7-0.9c0.4,0.2,0.4,0.6,0.7,0.8
                c0.7,0.6,1.9,0.4,2.9,0.7c-0.3,0.5-0.7,1.3-0.3,1.9c-0.2,1.6-1,2.5-2.2,3.1c-0.1-0.2,0.1-0.4,0-0.6c-0.6-0.5-1.8-0.5-2.2,0.2
                c-1.5-0.1-1.9-1.4-2.5-2.4c-0.2,0-0.4,0-0.4,0.1c0,1.7,1.3,2.7,2.6,3.3c-0.3,0.5-0.4,1.1-1,1.3c-0.2-0.1,0.1-0.5-0.1-0.7
                c-0.2-0.1-0.4-0.1-0.6,0c-0.3,0.4-0.2,1.2,0.1,1.5c1.2,0.3,1.7-0.6,2.1-1.3c0.7-0.2,1.3-0.2,1.9,0.1c0.1,0.4,1.1,1.1,1.5,1.1
                c0.6,0,1.2-1.2,0.5-1.4c-0.5-0.1-0.3,0.3-0.7,0.4c-0.4-0.1-0.6-0.4-0.7-0.7c1-1,2.1-1.8,2.3-3.5
                C168.8,103.1,168.7,102.7,168.9,102.2z"/>
                <path d="M164.1,108.1c-0.2,1.2,1.6,1.2,1.6,0.2C165.3,108.1,164.5,107.6,164.1,108.1z"/>
                <path d="M162.3,106.9c-0.3-1.1-1.7-0.4-1.8,0.3c0,0.4,0,0.7-0.4,0.7c-0.2-0.2-0.2-0.5-0.3-0.7c-0.5-0.3-1,0.2-0.9,0.7
                c0,0.2,0.8,0.8,0.9,0.9c1,0.4,1.7-0.8,1.6-1.6C161.4,107,162.2,107.3,162.3,106.9z"/>
                <path d="M160.4,98c0.3,0.2,1.3,0.3,1.3-0.3c-0.5-0.5-2.7-1.3-2.3,0.2C159.7,98.1,160,98.1,160.4,98z"/>
                <path d="M160.5,99.1c0.4,0.3,0.7-0.1,0.7-0.3c-0.1-0.5-1.1-0.5-1.6-0.3c-0.4-0.3-1.3-0.6-1.3,0.3
                C159,99.1,159.9,99.8,160.5,99.1z"/>
                <path d="M161.1,106.3c0.5-0.4,0-1.1-0.6-1c-0.4,0.1-1.1,1.2-1.1,1.4c0,0.2,0.2,0.3,0.3,0.3c0.7,0.1,0.6-0.6,1-0.8
                C160.8,106.3,161,106.2,161.1,106.3z"/>
                <path d="M160.7,104.9c0.1-1.3-2-0.3-1.1,0.4C159.9,105.2,160.3,105,160.7,104.9z"/>
                <path d="M160.4,103.7c0-0.7-0.7-0.9-1.3-0.7c-0.2,0.3-0.4,0.6-0.8,0.8c0,0.2-0.1,0.3-0.1,0.6c0.6,0.5,1.2-0.2,1.4-0.6
                C160,103.8,160.2,103.9,160.4,103.7z"/>
                <path d="M159.1,102.8c0.4,0.1,0.7-0.4,0.7-0.6c0-0.4-0.9-1.1-1.1-0.6C158.8,101.9,158.8,102.7,159.1,102.8z"/>
                <path d="M159.5,99.9c-0.8-0.7-1.5,1.2-0.3,1.2C159.3,100.7,159.5,100.4,159.5,99.9z"/>
                <path d="M159.2,106c0.2-0.4-0.3-0.7-0.6-0.6C158,105.8,159,106.7,159.2,106z"/>
                <path d="M173.2,84.8c-0.3,0.4-0.6,0-0.9,0.1c-0.4,0.1-0.5,0.8-1.1,0.5C170.8,84.4,173.1,83.2,173.2,84.8z"/>
                <path d="M162.3,79.6c0.6,1.3-1.7,1.2-1.7,0.2c-0.3,0-0.6,0-0.8-0.1c-0.2-1.3,1.6-0.6,1.8,0.1C161.9,79.8,162,79.5,162.3,79.6z"/>
                <path d="M160.5,88.9c0.2,0.7-0.8,0.8-0.9,0.3C159.5,88.7,160.2,88.7,160.5,88.9z"/>
                <path d="M179.3,41.5c-1.1,0-0.3,0.8,0.1,1c0.3,0.2,0.8,0.5,1.1,0.5c0.3,0,0.8-0.7,1.2-0.7c0.4,0,0.8,0.3,1.2,0.2
                c0.3-0.1,0.4-0.5,0.4-0.7c-0.2-0.2-0.5-0.1-0.8-0.1c-1.1-0.9-2.2,1.2-2.8-0.2c0.8,0,1.7-0.5,1.2-1.1c-0.5-0.1-0.6,0.2-0.9,0.3
                c-0.3-0.3-1.2-0.9-1.2,0C178.7,41,179.2,41,179.3,41.5z"/>
                <path d="M168.6,40.5c0.2-0.7-3-1.6-3-0.4c0.6,0.2,1.3,0.3,2,0.4C167.9,40.7,168.5,40.9,168.6,40.5z"/>
                <path d="M170.5,41.4c0,0.4,0.4,0.5,0.7,0.7c0.5,0.3,0.9,0.7,1.5,0.9c-0.5,0.7-0.4,1.9,0.7,1.9c0.1-0.1,0.1-0.2,0.2-0.2
                c0.1-0.5-0.3-0.5-0.3-0.9c0.2-0.4,0.7-0.6,0.6-1c-0.1-0.4-1-0.6-1.8-1c-0.4-0.2-0.8-0.7-1.4-0.6
                C170.6,41.3,170.5,41.3,170.5,41.4z"/>
                <path d="M177.5,42.6c0.1,0.3,0.4,0.2,0.7,0.4c0,0.5,0.1,1.1,0.7,1.2c0.4,0,0.7-0.2,1.1-0.2c0.6,0,2,0.7,1.9-0.4
                c-1-0.1-1.9-0.4-3.1-0.2c-0.1-0.2-0.1-0.5-0.1-0.8C178.6,42,177.2,41.8,177.5,42.6z"/>
                <path d="M182.8,47.2c-0.6-0.3-1.3-1.3-2.3-1.5c-0.8-0.1-1.8,0.5-2.7,0.1c-0.2-0.1-0.3-0.6-0.8-0.5c-0.6,1.2,1,1.5,2.1,1.4
                c0.4-0.1,0.8-0.3,1.3-0.2c0.9,0.1,1.7,1.3,2.3,1.5c0.7,0.2,1.2,0.2,1.9,0.2c0.7-0.4,1.4-1.1,1.4-2c0.1-2.2-3.6-2.3-2.7,0.1
                c0.2,0.3,1.5,0.7,1.4,0c0-0.5-0.6-0.2-0.7-0.6c-0.1-0.3,0.2-0.4,0.4-0.4c0.5-0.1,0.8,0.3,0.8,0.7
                C185.3,47.3,183.8,47.7,182.8,47.2z"/>
                <path d="M182.2,44.8c-0.1,0.2-0.3,1.1,0.3,1c0.3-0.1,0.4-0.8,0.6-1.2c0.2-0.2,0.6-0.6,1-0.7c1.6-0.4,2.8,0.9,2.7,2.7
                c1.2,0.4,0.7-1.5,0.4-2.1c-0.7-1.3-3.1-2.1-4.4-0.8C182.6,44.1,182.4,44.3,182.2,44.8z"/>
                <path d="M175.3,42.4c0.7,0.1,1.9-0.2,1.7-0.8c-0.2-0.7-0.8,0.1-1.1,0.1c-0.5,0.1-0.6-0.1-1.1-0.3c-0.2-0.7,0.5-1.1,0.1-1.5
                c0.9-0.5,1.7,0.2,2.3,0.6c-0.2,0.8,0.6,1.7,1.3,1c0.1-0.4-0.4-0.3-0.4-0.6c-0.1-0.2,0.1-0.4,0-0.7c-0.2-0.7-1.1-0.6-1.5-1.1
                c0,0-0.3-0.1-0.8-0.2c-0.6-0.1-0.6,0-0.6,0c-0.2,0.3-0.6,0.5-0.3,0.9c-0.8,0.2-1.1,1.4-0.7,2.1C174.4,42.1,174.9,42.3,175.3,42.4
                z"/>
                <path d="M169.2,40.4c0.2,0.5,0.7,0.9,1.2,0.8c0.2-0.1,0-0.5,0.2-0.5c1.2,0.3,1.8,1.5,3.2,1.4c0.4-0.6-0.1-0.5-0.6-0.8
                c-0.8-0.4-2.1-1.4-3.1-1.6c-0.6-0.1-1.4-0.2-1.6,0.3C168.6,40.3,169,40.3,169.2,40.4z"/>
                <path d="M186.9,47.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.6,0.7-1.1,1.6-1.9,2.1c-0.3,0.2-0.9,0.2-0.8,0.8c0.4,0.2,0.9,0,1.2-0.2
                c0.4-0.2,1.1-0.9,1.3-1.2C186.6,48.5,186.8,47.9,186.9,47.5z"/>
                <path d="M168.1,42.4c-0.3-0.1-1.4-0.5-1.3,0.2c0,0.3,0.5,0.2,0.9,0.3c0.6,0.2,1.4,0.8,2.1,0.7C170.1,42.7,168.9,42.6,168.1,42.4z"/>
                <path d="M171.1,46.4c0.1,0.3,0.5,0.5,0.8,0.3c0,0.3,0,0.5,0.2,0.6c0.7-0.1,1.1-0.4,1.7-0.6c0.8,2,2.5,3.2,4.9,3.6
                c0,0,2.5,0.2,3.8-0.1c0.4-0.1,0.9-0.2,0.9-0.6c-0.6-0.5-1.4,0-2,0c-3.2,0.2-6.1-0.6-6.9-3.1c1-0.1,2.3-1.3,2.6-2.1
                c0.1-0.4,0.1-1.4-0.4-1.4c-0.4,0.1-0.2,0.5-0.3,0.9c-0.1,0.5-0.8,1.3-1.3,1.6c-1,0.6-2.3,0.4-3.2,1.1c0.4-0.8,2-0.7,2.7-1.3
                c0.3-0.3,0.9-1.2,0.5-1.8c-0.7,0.1-0.6,0.8-1,1.2c-0.3,0.3-0.9,0.4-1.4,0.6c-0.5,0.2-0.9,0.5-1.3,0.7c1.4-3-1.8-5-4.2-5.1
                c-2.1-0.1-4.1,0.3-5.5,1.2c0,0-1.1,0.8-1.4,1.9c-0.2,0.9,0.4,2,0.4,2c1.3,1.5,2.5,2.2,5,2.3C167.5,48.4,170.1,47.8,171.1,46.4z
                M169.7,48.1c-0.4,0.2-1.5,0.3-1.5,0.3c-0.2,0.1-0.1,0.2-0.3,0.3c-0.5,0-1.3-0.2-1.7,0.3c0,0.6,0.5,0.4,0.9,0.4
                c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.5-0.2,0.7-0.2c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.1-0.1,0.4-0.2c0.6-0.2,1.1,0,1.2-0.6
                c0.7,0,1.5-0.6,1-1.1C171.3,47.1,170.1,47.9,169.7,48.1z M162.6,40.5c0.2,0.1,0.3,0.4,0.5,0.4c0.6,0.2,1.2-0.3,1.6-0.6
                c0.4,0.2,0.8,0.1,0.8-0.2c0-0.6-1.5-0.9-1.9-0.3c-1.6-0.2-3.6,0.3-4.9,0.9c-0.5,0.2-1.4,0.5-1.2,1.2c1-0.1,1.7-0.6,2.9-1
                C160.4,40.9,161.7,40.5,162.6,40.5z M160,41.3c-0.9,0.5-1.9,0.7-1.9,1.8c0,0.4,0.3,0.5,0.3,0.9c0,0.4-0.6,0.8-0.3,1.2
                c-0.9,0-0.9-1.1-1.9-0.9c0.1,0.8,0.5,0.9,1.1,1.4c0.3,0.2,0.4,0.2,0.7,0.2c0,0,1.8,0.2,2,0.1c0.3-0.1,0.4-0.3,0.4-0.3
                c0-0.2-0.3-0.3-0.3-0.3l-0.7-0.1c-0.3,0-0.6,0-0.7-0.2l0.1-0.2c0,0,0.7-1.4,0.4-1.6c-0.6-0.4,0.1-0.9,0.1-0.9s1.8-0.9,2.4-1.1
                c0.2-0.1,0.9-0.3,0.9-0.3c0.1-0.3-0.3-0.4-0.7-0.4C160.7,41,160,41.3,160,41.3z M160.7,46.2c-0.3-0.2-0.9,0.2-0.9,0.2l-2.1-0.3
                l0,0c-0.2,0-0.4-0.1-0.7-0.1c-0.5-0.8-1.8-0.8-2.2-1.7c0.1-0.4,0.5-0.9,0-1.1c-0.5,0.2-0.8,0.5-0.8,0.9c0,0.5,0.6,0.9,0.5,1.3
                c-0.1,0.3-0.7,0.6-1.1,0.7c-0.9,0.1-1.6-0.7-2.5-0.4c-0.8,0.2-1,1.2-1.9,0.8c0-0.4,0.4-0.5,0.6-0.8c0.5-1,0.3-2.1-0.3-2.7
                c-0.5-0.5-1.7-1.1-2.4-1.1c-1.9,0-2.5,2.5-2.1,4.4c-0.5,0.2-0.9,0.8-1.7,0.7c-1.1-0.1-1.6-1.2-2.6-1.3c-0.6,0-1.2,0.2-1.6,0.4
                c-0.6,0.5-1,1.8-2.1,1.7c-0.3,0-1.3-0.8-1.4-0.1c-0.1,0.7,2.1,1.3,2.6,0.6c0.1,0.3-0.2,0.6-0.6,0.7c-0.6,0.2-2.1-0.2-2.1,0.5
                c0,0.7,2,0.4,2.4,0.2c1-0.3,1.2-2,2.7-1.4c0.2,0.1,0.4,0.4,0.7,0.5c1.4,0.7,4.1,1.1,4.9-0.4c0.3,0.2,0.7,0.3,1,0.5
                c0.7,0.4,1.4,1.2,2.1,1.4c0,0,1.2,0.2,2.5,0.1c1.4,0,2.5,0,2.5,0c2.2-0.2,3-1.9,3.7-3.6c1.1,0,2.5,0.5,3.5,0.3
                c0.1-0.2,0-0.5-0.2-0.7C161.1,46.6,160.9,46.3,160.7,46.2z M141.8,48.4c-0.7-0.3-1.1-0.8-1.8-0.9c-0.6,0-1,0.2-1.4,0.4
                c-0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.6c1.3-0.9,2.3,0.9,3.4,1.1c1,0.2,1.5-0.4,2.2-0.8c0.4,0.2,0.6,0.6,0.7,1
                C144.8,49,143.1,49,141.8,48.4z M155.8,49c-1,0.5-1.8,0.8-3.4,0.8c-0.6,0-1.5-0.2-2.3-0.3c-0.3,0-0.6,0-0.9,0
                c-0.3-0.1-0.8-0.5-1.1-0.7c-1.1-0.7-1.5-0.8-2.2-1.8c-0.2-0.3-0.5-0.7-0.6-1c-0.2-0.7-0.2-1.9,0.1-2.5c0.6-1.4,2.9-0.9,3.4,0.4
                c0.3,1.3-0.8,1.5-0.7,2.4c-0.5,0-0.6-0.7-1.2-0.4c-0.2,0.6,0.4,1,0.7,1.1c0.4,0.2,1.4,0.3,2,0.2c0.7-0.1,1.2-0.8,1.7-0.8
                c0.7-0.1,1.2,0.6,2.1,0.5c0.9-0.1,1.2-0.8,1.9-1.1c0.7,0.2,1,0.7,1.5,1.1C156.6,47.7,156.6,48.3,155.8,49z M165.6,41.7
                c-0.1,0.3,0,0.6-0.2,0.8c-0.8-0.4-2.9,0.4-2.7,1.1c0.1,0.7,1.1,0,1.3-0.3c0.5,0,1.1-0.2,1.4-0.2c0,0.3,0.1,0.6,0,0.8
                c-0.9,0.4-2.6-0.1-3.6,0.2c-0.3,0.1-0.3,0.4-0.6,0.5C161.4,42.5,163.6,41.5,165.6,41.7z M166.3,47.5c-2.3,0.1-4.3-1.1-5-2.5
                c0.6,0.2,1-0.1,1.7,0c-0.1,1.1,1.1,1.9,2.2,1.6c0.2-1-1.5-0.6-1.6-1.5c0.5-0.2,1.4-0.2,1.9-0.1c0.1,0.9-0.1,2.6,0.8,2.4
                c0.8-0.2-0.2-1.6,0.2-2.3c0.9-0.2,2-0.1,2.9,0c-0.2,0.2-0.3,0.4-0.6,0.6c-0.6,0.3-1.8,0-1.8,0.8c1,0.5,2.5,0,2.9-0.7
                c0.1-0.3-0.1-0.4-0.1-0.6c0.4-0.2,1,0,1.1-0.4c0.1,0,0.1,0,0.1,0.1C170.6,46.7,168.8,47.4,166.3,47.5z M169.7,44.1
                c-1,0-2.2-0.1-3.3-0.1c-0.5-0.7,0.1-1.6-0.2-2.4c0,0,0.1,0,0.1,0c1.5,0.2,3.2,0.5,4.1,1.3c0.4,0.3,0.6,0.9,0.6,1.5
                C170.8,44,170.3,44.2,169.7,44.1z"/>
                <path d="M194.5,47.1c0-0.3,0.1-0.4-0.1-0.7c-0.5-0.3-0.7,0.2-1.2,0.3c-0.6-1-1.2-2-2.4-2.1c-0.7-0.1-1.8,0.5-2.2,1.1
                c-0.3,0.4-0.3,1-0.7,1.4c-0.2,0.3-0.8,0.4-0.7,0.8c0.2,0.7,1.2,0,1.4-0.4c0.3-0.5,0.4-1.2,0.8-1.6c1.2-1.4,2.8,0.2,3.1,1.4
                c0.3,0.2,0.7,0.2,1.2,0.2c0.1,0.4,0.5,0.7,0.4,1c-0.4,0-0.8,0-1.3,0c-0.6-0.2-0.5-1-0.8-1.4c-0.3-0.4-1.2-0.6-1.8-0.4
                c-0.7,0.2-0.8,1.3-1.3,1.8c-0.5,0.6-1.4,0.7-2.1,0.7c-0.4,0-1-0.2-1,0.5c0.7,0.4,1.7,0.5,2.4,0.3c0.1,0,0.3-0.3,0.5-0.4
                c0.2-0.1,0.5-0.2,0.6-0.3c0.7-0.6,0.8-2.2,1.9-1.7c0.3,0.4,0.6,1.5,1.3,1.7c0.7,0.3,1.6,0.1,1.9-0.6c0.3,0.2,0.4,0.5,0.8,0.4
                C195.7,48.5,194.6,48,194.5,47.1z"/>
                <path d="M147.3,44c0.5,0,0.1,0.6,0.3,1c0.6,0.4,0.9-0.3,0.8-1c-0.3-0.4-0.7-0.8-1.1-0.8c-1.7-0.1-1.3,3.4-0.1,2.2
                C147,45,146.7,44,147.3,44z"/>
                <path d="M150.2,42.8c0.2,0.5,1,0.6,1.6,0.3c0.3-0.4,0.2-1.2,0.9-1.2c0.8,0.4,0,1.1-0.6,1.2c-0.2,0.3-0.2,0.7-0.2,0.9
                c-0.4,0-1.2-0.1-1.1,0.4c0.1,0.5,1.7,0.5,2.1,0.1c0.1-0.3-0.1-0.6,0-0.9c0.4-0.3,1-0.4,1.1-1.1c0.1-0.6-0.5-0.8-0.6-1.3
                c-0.1-1,1.3-1.5,2.2-0.9c0,0.6,0.5,0.9,0.5,1.5c-0.6,0.7-1.4-0.5-2,0c0.1,1.2,2.7,1.5,2.8,0.2c0.1-0.7-0.5-1.4-0.8-1.8
                c-0.2-0.2-0.3-0.5-0.4-0.6c-1.3-0.9-3.3,0.4-3.2,1.9c-0.3-0.1-0.7,0-0.9,0.1c-0.2,0.2-0.2,0.5-0.3,0.7c-0.3-0.6-1.8-0.6-1.7,0.2
                C149.7,42.7,149.9,42.8,150.2,42.8z"/>
                <path d="M151,41.3c0.3-0.1,0.9,0,1.2-0.1c0.1-0.3,0.4-0.5,0.4-0.8c-0.7-0.3-1.3,0.2-2.1,0.2c-0.1,0.3-0.4,0.4-0.4,0.8
                C150.4,41.9,150.9,41.7,151,41.3z"/>
                <path d="M165.4,49.1c-0.1,0.7-1.1,0.3-1.4,0c-0.8,0.2-1.1-0.4-1.6-0.6c-0.3-0.2-0.7,0-0.9-0.4c0.4-1.2,1.5,0.1,1.9,0.2
                c0,0,0.6,0.3,1.1,0.3C165.1,48.6,165.5,48.9,165.4,49.1z"/>
                <g className="CustomLogoText">
                    <path d="M39.3,153.6c1.3,0.1,2.7,0.1,4.1,0.1c1.4,0,2.8-0.1,4.2-0.1v0.6c-1.1,0.1-2.2,0.3-2.2,1.5
                    c0,1,2.2,5.6,4.2,10.6c2.2-5.5,4-9.5,4-10.6c0-1.2-1.4-1.5-2.2-1.5v-0.6c1.1,0,2.1,0.1,3.2,0.1c1,0,1.9-0.1,2.9-0.1v0.6
                    c-1.3,0.2-1.7,0.6-2.2,1.6c-2.6,5.2-4.5,9.7-6.1,14h-1.3c-1.9-4.2-3.9-9.3-6-14c-0.5-1-1.1-1.5-2.4-1.7V153.6z"/>
                    <path d="M54.1,169.2c1.8-0.2,2.2-1.1,2.9-2.7l4.8-11.2l-0.4-1.2c1-0.2,2.1-0.5,3-0.9l5.3,14.4c0.4,1.1,1.5,1.5,2.5,1.5
                    v0.6c-1.3-0.1-3.2-0.1-4.4-0.1c-1.4,0-2.7,0-4.1,0.1v-0.6c1.7-0.1,2.5-0.5,2.5-1.4c0-0.6-0.6-1.9-0.8-2.6l-0.8-2.4h-4.9l-1.2,3.4
                    c-0.1,0.4-0.4,0.8-0.4,1.5c0,0.8,1.3,1.3,2.2,1.4v0.6c-1.1-0.1-2.1-0.1-3.2-0.1c-1,0-2,0-2.9,0.1V169.2z M64.2,161.9l-2-5.7h0
                    l-2.1,5.7H64.2z"/>
                    <path d="M72.2,169.2c3.1-0.4,3.3-0.9,3.4-3.8l0.1-9.5c-0.9-1-2.2-1.3-3.4-1.7v-0.6c0.9,0.1,1.7,0.1,2.6,0.1
                    c1.1,0,2.2,0,3.3-0.1c3.3,4,6.7,7.8,9.8,11.9h0c0-2.1,0-6.8-0.4-9c-0.3-1.7-1.3-2.1-2.8-2.3v-0.6c1.1,0.1,2.3,0.1,3.4,0.1
                    c1.1,0,2.3,0,3.4-0.1v0.6c-2.5,0.1-2.8,1-2.8,4.6v11.2h-1.4c-2.9-4.5-8.6-11-10.8-13.4v8.3c0,3.3,0.3,3.8,3.2,4.2v0.6
                    c-1.2-0.1-2.4-0.1-3.6-0.1c-1.3,0-2.7,0-4,0.1V169.2z"/>
                    <path d="M99,169.1c2.4-0.2,2.6-0.7,2.6-2.9V157c0-2.2-0.2-2.7-2.6-2.9v-0.6c1,0.1,2.1,0.2,3.1,0.2c2,0,4-0.2,7-0.2
                    c4.5,0,9.2,3,9.2,8.7c0,5.3-4,7.6-8.6,7.6c-2.7,0-3.6-0.2-6.7-0.2c-1.7,0-3,0-4,0.1V169.1z M104.8,167.8c0,0.9,1.7,1.2,3.6,1.2
                    c3.9,0,6.3-2.3,6.3-7c0-4.4-2.6-7.6-7-7.6c-1,0-1.9,0.1-2.9,0.4V167.8z"/>
                    <path d="M120,169.2c2.2-0.1,2.5-0.8,2.5-2.8v-9.3c0-1.8-0.3-2.7-2.5-2.8v-0.7c1.7,0,3.4,0.1,5,0.1
                    c3.1,0,6.3-0.1,9.5-0.1c0,0.6,0,1.2,0,1.7c0,1,0,2,0.2,2.8l-0.5,0.1c-0.8-2.5-2.3-3.7-5.6-3.7h-2.7v5.9h1.3c1.7,0,3.4-0.8,3.9-3.1
                    h0.5c0,1.1-0.1,2.3-0.1,3.5c0,1.2,0.1,2.3,0.1,3.5H131c-0.5-2.4-2-3.2-3.9-3.2h-1.3v7.3c0.8,0.2,1.7,0.3,2.9,0.3
                    c3.2,0,5.8-1.5,7.6-4.7l0.5,0.3c-0.8,1.7-1.6,3.4-2.1,5.3c-2.5-0.1-5-0.1-7.6-0.1c-3.2,0-5.8,0-7.1,0.1V169.2z"/>
                    <path d="M137.5,153.7c1.2,0,2.3,0.1,3.7,0.1c1.7,0,3.5-0.1,5.4-0.1c3.4,0,5.5,2.1,5.5,4.7c0,2.3-1.6,3.4-3.6,3.9
                    c1.4,2.2,4.1,6.5,7.1,7v0.4c-0.9,0-1.8-0.1-2.8-0.1c-0.8,0-1.6,0-2.4,0.1c-2-2.2-3.8-4.6-5.2-7.2c-0.6-0.1-1.4-0.2-1.7-0.4v4.2
                    c0,2.2,0.2,2.6,2.5,2.8v0.6c-1.1,0-2.5-0.1-3.9-0.1c-1.5,0-3,0-4.4,0.1v-0.6c2.4-0.2,2.6-0.6,2.6-2.8v-9.3c0-2-0.2-2.7-2.6-2.9
                    V153.7z M143.4,161.2c0.5,0.2,1.1,0.4,1.6,0.4c2.2,0,3.6-1.2,3.6-3.5c0-2-1.3-3.5-3.3-3.5c-0.6,0-1.3,0-1.9,0.3V161.2z"/>
                    <path d="M161.6,169.2c3.1-0.4,3.3-0.9,3.4-3.8l0.1-9.5c-0.9-1-2.2-1.3-3.4-1.7v-0.6c0.9,0.1,1.7,0.1,2.6,0.1
                    c1.1,0,2.2,0,3.3-0.1c3.3,4,6.7,7.8,9.8,11.9h0c0-2.1,0-6.8-0.4-9c-0.3-1.7-1.3-2.1-2.8-2.3v-0.6c1.1,0.1,2.3,0.1,3.4,0.1
                    c1.1,0,2.3,0,3.4-0.1v0.6c-2.5,0.1-2.8,1-2.8,4.6v11.2h-1.4c-2.9-4.5-8.6-11-10.8-13.4v8.3c0,3.3,0.3,3.8,3.2,4.2v0.6
                    c-1.2-0.1-2.4-0.1-3.6-0.1c-1.3,0-2.7,0-4,0.1V169.2z"/>
                    <path d="M200.4,161.7c0,5.3-3.9,8.4-9.4,8.4c-5.5,0-9.4-3.1-9.4-8.4c0-5.3,3.9-8.4,9.4-8.4
                    C196.5,153.3,200.4,156.4,200.4,161.7z M185.1,161.7c0,4,1.8,7.6,5.9,7.6c4.1,0,5.9-3.5,5.9-7.6c0-4-1.8-7.6-5.9-7.6
                    C186.9,154.2,185.1,157.7,185.1,161.7z"/>
                    <path d="M221.5,161.7c0,5.3-3.9,8.4-9.4,8.4c-5.5,0-9.4-3.1-9.4-8.4c0-5.3,3.9-8.4,9.4-8.4
                    C217.6,153.3,221.5,156.4,221.5,161.7z M206.2,161.7c0,4,1.8,7.6,5.9,7.6c4.1,0,5.9-3.5,5.9-7.6c0-4-1.8-7.6-5.9-7.6
                    C208,154.2,206.2,157.7,206.2,161.7z"/>
                    <path d="M237.7,158.8c-0.7-3.6-2.4-4-5.3-4v11.7c0,2.2,0.2,2.7,2.7,2.9v0.5c-1.4,0-2.8-0.1-4.2-0.1c-1.5,0-3,0-4.5,0.1
                    v-0.5c2.5-0.2,2.7-0.7,2.7-2.9v-11.7c-2.9,0-4.6,0.4-5.3,4h-0.6c0.1-1.2,0.2-2.4,0.2-5.1c2.4,0.1,4.8,0.1,7.2,0.1
                    c2.4,0,4.9-0.1,7.4-0.1c0,2.7,0.1,3.9,0.2,5.1H237.7z"/>
                    <path d="M251.3,158.5c-0.7-2.4-3-4.3-5.4-4.3c-1.5,0-2.6,0.8-2.6,2.3c0,3.5,9.4,3.1,9.4,9.1c0,2.8-2.3,4.6-5.8,4.6
                    c-1.6,0-3.2-0.7-4.9-0.7c-0.2,0-1,0.2-1.2,0.3c-0.2-1.8-0.5-3.7-1-5.6h0.5c0.7,2.4,3.5,4.9,6,4.9c2.2,0,3.6-0.6,3.6-2.5
                    c0-4-9.6-3.6-9.6-8.9c0-3,1.9-4.4,5.6-4.4c2,0,3.6,0.7,4.9,0.7h0.9c0,0.5-0.1,1-0.1,1.5c0,1,0.1,2,0.2,2.9H251.3z"/>
                    <path d="M257.2,156.7c0-1.3-0.6-2.4-2.5-2.4v-0.6c1.3,0.1,2.7,0.1,4,0.1c1.5,0,3-0.1,4.4-0.1v0.6
                    c-2.1,0-2.6,1.2-2.6,2.4v4.9c0.8-0.6,5.8-4.8,5.8-6.1c0-0.8-1-1.2-1.7-1.3v-0.6c1.3,0.1,2.5,0.1,3.8,0.1c1.4,0,2.6,0,4-0.1v0.6
                    c-3.3,0.4-5.5,3.2-8,5.2l5,7.2c1.1,1.5,1.9,2.4,3.8,2.6v0.6c-1.5-0.1-3-0.1-4.4-0.1s-2.9,0-4.4,0.1v-0.5c0.5-0.1,2-0.4,2-1.2
                    c0-0.7-3.8-5.8-4.5-6.8l-1.5,1.2v4.2c0,1.6,0.2,2.4,2.6,2.5v0.6c-1.5-0.1-3-0.1-4.4-0.1c-1.3,0-2.6,0-4,0.1v-0.6
                    c2.3-0.1,2.5-0.9,2.5-2.5V156.7z"/>
                    <path d="M272.4,169.2c1.8-0.2,2.2-1.1,2.9-2.7l4.8-11.2l-0.4-1.2c1-0.2,2.1-0.5,3-0.9l5.3,14.4
                    c0.4,1.1,1.5,1.5,2.5,1.5v0.6c-1.3-0.1-3.2-0.1-4.4-0.1c-1.4,0-2.7,0-4.1,0.1v-0.6c1.7-0.1,2.5-0.5,2.5-1.4c0-0.6-0.6-1.9-0.8-2.6
                    l-0.8-2.4H278l-1.2,3.4c-0.1,0.4-0.4,0.8-0.4,1.5c0,0.8,1.3,1.3,2.2,1.4v0.6c-1.1-0.1-2.1-0.1-3.2-0.1c-1,0-2,0-2.9,0.1V169.2z
                    M282.5,161.9l-2-5.7h0l-2.1,5.7H282.5z"/>
                    <path d="M104.2,182.1c0-2.2-0.4-2.6-2.6-2.8v-0.6c0.9,0.1,2.2,0.1,3.7,0.1c1.5,0,3.1-0.2,4.7-0.2c3.1,0,6,1.5,6,4.8
                    c0,2.9-2.6,4.3-5.3,4.3c-1.1,0-2.2-0.1-3.2-0.5v4.3c0,2.2,0.2,2.7,2.7,2.9v0.5c-1.4,0-2.8-0.1-4.2-0.1c-1.5,0-3,0-4.4,0.1v-0.5
                    c2.4-0.2,2.6-0.7,2.6-2.9V182.1z M107.5,186.4c0.5,0.2,1.2,0.4,1.9,0.4c2.2,0,3.4-1.5,3.4-3.7c0-2.1-0.9-3.7-3.1-3.7
                    c-0.8,0-1.4,0.1-2.1,0.4V186.4z"/>
                    <path d="M114.6,194.2c1.8-0.2,2.2-1.1,2.9-2.7l4.8-11.2l-0.4-1.2c1-0.2,2.1-0.5,3-0.9l5.3,14.4
                    c0.4,1.1,1.5,1.5,2.5,1.5v0.6c-1.3-0.1-3.2-0.1-4.4-0.1c-1.4,0-2.7,0-4.1,0.1v-0.6c1.7-0.1,2.5-0.5,2.5-1.4c0-0.6-0.6-1.9-0.8-2.6
                    l-0.8-2.4h-4.9l-1.2,3.4c-0.1,0.4-0.4,0.8-0.4,1.5c0,0.8,1.3,1.3,2.2,1.4v0.6c-1.1-0.1-2.1-0.1-3.2-0.1c-1,0-2,0-2.9,0.1V194.2z
                    M124.7,186.9l-2-5.7h0l-2.1,5.7H124.7z"/>
                    <path d="M135.8,181.6c0-1.8-1-2.2-2.5-2.4v-0.6c1.5,0.1,3,0.1,4.4,0.1c1.5,0,3,0,4.5-0.1v0.6c-1.5,0.2-3.1,0.6-3.1,2.4
                    v12.1c0.5,0.1,1.3,0.2,2.3,0.2c3.6,0,5.4-1.4,7-4.6l0.6,0.3c-0.8,1.7-1.5,3.4-1.9,5.3c-2.3,0-4.5-0.1-6.8-0.1c-2.3,0-4.7,0-7.1,0.1
                    v-0.6c1.3,0,2.6-0.2,2.6-1.7V181.6z"/>
                    <path d="M148.2,194.2c1.8-0.2,2.2-1.1,2.9-2.7l4.8-11.2l-0.4-1.2c1-0.2,2.1-0.5,3-0.9l5.3,14.4
                    c0.4,1.1,1.5,1.5,2.5,1.5v0.6c-1.3-0.1-3.2-0.1-4.4-0.1c-1.4,0-2.7,0-4.1,0.1v-0.6c1.7-0.1,2.5-0.5,2.5-1.4c0-0.6-0.6-1.9-0.8-2.6
                    l-0.8-2.4h-4.9l-1.2,3.4c-0.1,0.4-0.4,0.8-0.4,1.5c0,0.8,1.3,1.3,2.2,1.4v0.6c-1.1-0.1-2.1-0.1-3.2-0.1c-1,0-2,0-2.9,0.1V194.2z
                    M158.3,186.9l-2-5.7h0l-2.1,5.7H158.3z"/>
                    <path d="M179.3,183.8c-0.7-3.6-2.4-4-5.3-4v11.7c0,2.2,0.2,2.7,2.7,2.9v0.5c-1.4,0-2.8-0.1-4.2-0.1c-1.5,0-3,0-4.5,0.1
                    v-0.5c2.5-0.2,2.7-0.7,2.7-2.9v-11.7c-2.9,0-4.6,0.4-5.3,4h-0.6c0.1-1.2,0.2-2.4,0.2-5.1c2.4,0.1,4.8,0.1,7.2,0.1
                    c2.4,0,4.9-0.1,7.4-0.1c0,2.7,0.1,3.9,0.2,5.1H179.3z"/>
                    <path d="M192.9,183.5c-0.7-2.4-3-4.3-5.4-4.3c-1.5,0-2.6,0.8-2.6,2.3c0,3.5,9.4,3.1,9.4,9.1c0,2.8-2.3,4.6-5.8,4.6
                    c-1.6,0-3.2-0.7-4.9-0.7c-0.2,0-1,0.2-1.2,0.3c-0.2-1.8-0.5-3.7-1-5.6h0.5c0.7,2.4,3.5,4.9,6,4.9c2.2,0,3.6-0.6,3.6-2.5
                    c0-4-9.6-3.6-9.6-8.9c0-3,1.9-4.4,5.6-4.4c2,0,3.6,0.7,4.9,0.7h0.9c0,0.5-0.1,1-0.1,1.5c0,1,0.1,2,0.2,2.9H192.9z"/>
                    <path d="M196.2,194.2c2.2-0.1,2.5-0.8,2.5-2.8v-9.3c0-1.8-0.3-2.7-2.5-2.8v-0.7c1.7,0,3.3,0.1,5,0.1
                    c3.1,0,6.3-0.1,9.5-0.1c0,0.6,0,1.2,0,1.7c0,1,0,2,0.2,2.8l-0.5,0.1c-0.8-2.5-2.3-3.7-5.6-3.7H202v5.9h1.3c1.7,0,3.4-0.8,3.9-3.1
                    h0.5c0,1.1-0.1,2.3-0.1,3.5c0,1.2,0.1,2.3,0.1,3.5h-0.5c-0.5-2.4-2-3.2-3.9-3.2H202v7.3c0.8,0.2,1.7,0.3,2.9,0.3
                    c3.2,0,5.8-1.5,7.6-4.7l0.5,0.3c-0.8,1.7-1.6,3.4-2.1,5.3c-2.5-0.1-5-0.1-7.6-0.1c-3.2,0-5.8,0-7.1,0.1V194.2z"/>
                    <path d="M228.1,183.8c-0.7-3.6-2.4-4-5.3-4v11.7c0,2.2,0.2,2.7,2.7,2.9v0.5c-1.4,0-2.8-0.1-4.2-0.1c-1.5,0-3,0-4.5,0.1
                    v-0.5c2.5-0.2,2.7-0.7,2.7-2.9v-11.7c-2.9,0-4.6,0.4-5.3,4h-0.6c0.1-1.2,0.2-2.4,0.2-5.1c2.4,0.1,4.8,0.1,7.2,0.1
                    c2.4,0,4.9-0.1,7.4-0.1c0,2.7,0.1,3.9,0.2,5.1H228.1z"/>
                </g>
            </svg>
        );
    }

    render()
    {
        const {className} = this.props;
        const CA = ["EateryLogo", "VanDerNootska"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {this.Svg()}
            </div>
        );
    }
}

VanDerNootskaPalatset.defaultProps =
{
    className: ""
};

export default VanDerNootskaPalatset;