import React from "react";
import "./dialogs.scss";

import Globals from "Class/Globals";
import {UcFirst} from "Functions";

import Button from "Components/UI/Button";
import IconButton from "Components/UI/IconButton";

class Dialogs extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            dialogs: []
        };
    }

    componentDidMount()
    {
        Globals.Listen("dialog", this.OnDialog);
        Globals.Listen("dialog-destroy", this.OnDialogDestroy);
        Globals.Listen("navigation", this.OnNavigation);
    }

    componentWillUnmount()
    {
        Globals.Remove("dialog", this.OnDialog);
        Globals.Remove("dialog-destroy", this.OnDialogDestroy);
        Globals.Remove("navigation", this.OnNavigation);
    }

    Item = (dialog, key) =>
    {
        const {buttons, color, content, fillNarrow, header, tray} = dialog;
        const Color = color ? UcFirst(color) : "White";
        const CA = ["Dialog", header ? "HasHeader" : `Color${Color}`];
        const Buttons = [];

        if (fillNarrow)
        {
            CA.push("FillNarrow");
        }

        if (buttons)
        {
            buttons.forEach((button, index) => Buttons.push(<Button key={index} color="Black" {...button}/>));
        }

        return (
            <div
                className="DialogContainer"
                key={key}
                onClick={() => this.Remove(key)}
                ref={this.SetFocus}
            >
                <div className="DialogAlign">
                    <div className="DialogWrapper">
                        <div className={CA.join(" ")} onClick={e => e.stopPropagation()}>
                            {header ? <div className="DialogHeader">{header}</div> : ""}
                            <IconButton
                                className="DialogClose"
                                onClick={() => this.Remove(key)}
                                src="X"
                            />
                            <div className="DialogContent">
                                {content}
                            </div>
                            {tray ? (
                                <div className="DialogTray">
                                    {tray}
                                </div>
                            ) : ""}
                            {Buttons.length ? (
                                <div className="DialogBelowNarrow">{Buttons}</div>
                            ) : ""}
                        </div>
                    </div>
                    {Buttons.length ? (
                        <div className="DialogBelow" onClick={e => e.stopPropagation()}>{Buttons}</div>
                    ) : ""}
                </div>
            </div>
        );
    }

    OnDialog = (dialog) =>
    {
        const {dialogs} = this.state;

        dialogs[dialog.id] = dialog;
        this.setState({dialogs});
    }

    OnDialogDestroy = (id) =>
    {
        this.Remove(id);
    }

    OnNavigation = () =>
    {
        const {dialogs} = this.state;

        for (let key in dialogs)
        {
            this.Remove(key);
        }
    }

    Remove = (key) =>
    {
        const {dialogs} = this.state;
        const {onClose} = dialogs[key] || {};

        if (typeof onClose === "function")
        {
            onClose();
        }

        delete dialogs[key];
        this.setState({dialogs});
    }

    SetFocus = (dialog) =>
    {
        if (!dialog)
        {
            return;
        }

        const Focusables = dialog.querySelectorAll("[tabindex]:not([tabindex='-1'])");
        const Num = Focusables.length;
        
        if (!Num)
        {
            return;
        }

        setTimeout(() => Focusables[0].focus(), 800);
    }

    render()
    {
        const {dialogs} = this.state;
        const Keys = Object.keys(dialogs);
        
        if (!Keys.length)
        {
            return "";
        }

        const Dialog = dialogs[Keys[0]];

        return (
            <div className="Dialogs">
                <div className="DialogsOverlay"/>
                <div className="DialogsScroll">
                    {this.Item(Dialog, Dialog.id)}
                </div>
            </div>
        );
    }
}

export default Dialogs;