import React from "react";
import "./mainlogo.scss";

import Link from "Components/UI/Link";

class MainLogo extends React.Component
{
    Svg = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="112.15" height="32" viewBox="0 0 112.15 32">
            <g transform="translate(-1.1 -2.8)">
                <path d="M1.1,2.8V7.54H3.394V23.207H1.1v4.8H22.866V19.856H16.647v3.351h-5.8V17.321H17.7v-4.8H10.851V7.54h5.8v3.23h6.219V2.8Z"/>
                <g transform="translate(24.556 4.491)">
                    <path d="M89.849,36.987V35.628a2.265,2.265,0,0,0-2.143-1.057c-1.449,0-2.083.785-2.083,1.9a1.762,1.762,0,0,0,1.9,1.691,2.7,2.7,0,0,0,2.325-1.177M79.555,25.666A16.182,16.182,0,0,1,87.434,23.1c4.317,0,9.781,1.63,9.781,7.426V37.56h2.174v4.619H91.66l-.875-1.63a6.11,6.11,0,0,1-4.86,2.325c-3.441,0-7.124-1.841-7.124-6.038,0-4.075,3.441-6.641,7.517-6.641a5.074,5.074,0,0,1,3.562,1.268V30.194c0-1.63-1.479-2.023-3.441-2.023a11.192,11.192,0,0,0-5.374,1.66Z" transform="translate(-78.8 -18.662)"/>
                    <path d="M144.53,13.864h1.962V10.543L153.134,8.4v5.464h3.441v4.679h-3.441v6.43c0,1.238-.242,2.9,1.147,2.9a8.468,8.468,0,0,0,2.264-.6v4.619a17.275,17.275,0,0,1-4.377.694c-5.4,0-5.585-2.958-5.706-7.577v-6.43H144.5V13.864Z" transform="translate(-124.666 -8.4)"/>
                    <path d="M194.024,32.658h5.464c-.03-1.358-.362-2.9-2.657-2.9a2.864,2.864,0,0,0-2.808,2.9m11.713,8.3c-2.657,2.325-5.555,3.14-9.3,3.14-4.468,0-9.54-2.838-9.54-9.781,0-5.917,4.257-9.419,9.721-9.419,4.287,0,7.577,2.2,8.634,5.223a10.592,10.592,0,0,1,.06,6.1H193.934c.211.966.694,2.958,3.713,2.958a7.388,7.388,0,0,0,5.041-1.721Z" transform="translate(-154.266 -19.919)"/>
                    <path d="M262.8,42.528H252.6V37.819h1.6V29.185h-1.54V24.475h6.792L260.54,26.8c.936-1.721,1.872-3.2,5.585-3.2v6.491c-3.26,0-5.1.845-5.1,4.015v3.713h1.811v4.709Z" transform="translate(-200.132 -19.011)"/>
                    <path d="M315.1,31.089h-1.66V26.5h9.177v4.468h-1.721l-6.249,15.275c-1.057,2.234-2.566,5.132-7.819,5.1-1.872-.121-3.291-.875-5.313-2.475l2.717-4.257c1.54,1.208,2.264,1.751,3.019,1.9,1.117.211,1.962-.815,2.325-1.9l-.06-.121-7.034-13.343H301V26.5h11.109v4.589h-1.691l2.355,5.615Z" transform="translate(-233.921 -21.036)"/>
                </g>
            </g>
            </svg>
        );
    }

    render()
    {
        const {alt, className, link} = this.props;
        const CA = ["MainLogo"];

        if (!link)
        {
            CA.push("NoLink")
        }

        if (className)
        {
            CA.push(className);
        }
        return link ? (
            <Link className={CA.join(" ")} title={alt} to="/">{this.Svg()}</Link>
        ) : (
            <span className={CA.join(" ")}>{this.Svg()}</span>
        );
    }
}

MainLogo.defaultProps =
{
    alt: "Eatery",
    className: "",
    link: true
};

export default MainLogo;