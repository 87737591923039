import React from "react";
import "./segment.scss";

import {UcFirst} from "Functions";
import LoadImage from "Components/Layout/LoadImage";

class Segment extends React.Component
{
    render()
    {
        const {
            backgroundColor,
            backgroundImage,
            children,
            className,
            textColor,
            top
        } = this.props;
        const CA = ["Segment"];

        if (top)
        {
            CA.push("Top");
        }

        if (backgroundImage)
        {
            CA.push("HasBackground");
            CA.push(textColor === "black" ? "ColorTextBlack" : "ColorTextWhite");
        }
        else
        {
            CA.push("Color" + UcFirst(backgroundColor));
        }

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {backgroundImage ? <LoadImage className="SegmentBackground" src={backgroundImage}/> : ""}
                <div className="SegmentContent">
                    {children}
                </div>
            </div>
        );
    }
}

Segment.defaultProps =
{
    backgroundColor: "white",
    backgroundImage: "",
    backgroundPositionH: 50,
    backgroundPositionV: 50,
    className: "",
    textColor: "white",
    top: false
};

export default Segment;