import React from 'react';
import './tab.scss';

function Tab({active, title, onClick, children}) {
    const classNames = [
        'Tab',
        active && 'active',
    ].filter(Boolean).join(' ');

    return (
        <button
            className={classNames}
            onClick={onClick}
            title={title}
        >
            {children}
        </button>
    );
}

export default Tab;
