import React from "react";
import "./instagram.scss";

import Eatery from "Class/Eatery";
import Globals from "Class/Globals";

import LoadImage from "Components/Layout/LoadImage";
import Link from "Components/UI/Link";
import Segment from "Components/Layout/Segment";

class ModuleInstagram extends React.Component
{
    Item = (post, index) =>
    {
        const {caption, media_type, media_url, permalink, thumbnail_url} = post;
        const CA = ["ModuleInstagramItem"];
        let ItemIcon = "";

        switch (media_type)
        {
            case "CAROUSEL_ALBUM":
                CA.push("TypeGallery");
                break;

            case "VIDEO":
                CA.push("TypeVideo");
                break;

            default:
                CA.push("TypeImage");
        }

        return (
            <Link className={CA.join(" ")} href={permalink} key={index} title={caption}>
                <LoadImage src={thumbnail_url || media_url}/>
                {ItemIcon}
            </Link>
        );
    }

    render()
    {
        if (Globals.Setting("HideInstagram"))
        {
            return "";
        }

        const {backgroundColor, eatery, instagramFilter, index, title} = this.props;
        const Feed = Eatery.Instagram(eatery, instagramFilter);

        if (!Feed || !Feed.length)
        {
            return "";
        }

        const Items = [];

        Feed.forEach((post, index) => Items.push(this.Item(post, index)));

        return (
            <Segment backgroundColor={backgroundColor} className="Module ModuleInstagram" top={!index}>
                <h2>{title}</h2>
                <div className="ModuleInstagramItems">
                    {Items}
                </div>
            </Segment>
        );
    }
}

ModuleInstagram.defaultProps =
{
    backgroundColor: "white",
    eatery: "",
    filter: "",
    index: 0,
    title: ""
};

export default ModuleInstagram;