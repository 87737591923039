import React from "react";
import "./textfield.scss";

import {RandomToken, UcFirst} from "Functions";

class TextField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Input = false;
        this.FocusValue = false;
        this.SavedScroll = false;
        this.Token = RandomToken();

        this.state =
        {
            focus: false,
            value: ""
        };
    }

    componentDidMount()
    {
        this.setState({value: this.ParseValue(this.props.value)});
    }

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;

        if (v1 !== v2)
        {
            this.setState({value: this.ParseValue(v1)});
        }
    }

    Focus = () =>
    {
        if (this.Input)
        {
            this.Input.focus();
        }
    }

    OnBlur = (e) =>
    {
        const {id, onBlur, onChange} = this.props;
        const Value = this.ParseValue(e.currentTarget.value);
        const [X, Y] = this.SavedScroll;

        if (this.FocusValue !== Value)
        {
            onChange(e, Value, id);
        }

        window.scrollTo(X, Y);
        onBlur(e, Value, id);
        this.setState({focus: false});
    }

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const Value = this.ParseValue(e.currentTarget.value);
        
        this.FocusValue = Value;
        this.SavedScroll = [window.scrollX, window.scrollY];

        onFocus(e, Value, id);
        this.setState({focus: true});
    }

    OnInput = (e) =>
    {
        const {disabled, id, onInput} = this.props;

        if (disabled)
        {
            return;
        }

        const Value = this.ParseValue(e.currentTarget.value);

        onInput(e, Value, id);
        this.setState({value: Value});
    }

    OnKeyDown = (e) =>
    {
        e.stopPropagation();
    }

    OnKeyUp = (e) =>
    {
        const {disabled, id, onEnter} = this.props;

        if (e.which === 13 && !disabled)
        {
            const Value = this.ParseValue(e.currentTarget.value);
            onEnter(e, Value, id);
        }
    }

    ParseValue = (value) =>
    {
        const {maxLength} = this.props;
        let Return, Value = String(value);

        if (maxLength)
        {
            Value = Value.substr(0, maxLength);
        }

        switch (this.props.return)
        {
            case "float":
                Value = Value.replace(/[^\d.]/g, "");
                Return = parseFloat(Value);
                return isNaN(Return) ? 0 : Return;

            case "int":
            case "integer":
                Value = Value.replace(/[^\d]/g, "");
                Return = parseInt(Value, 10);
                return isNaN(Return) ? 0 : Return;

            default:
                return Value;

        }
    }

    Reset = () =>
    {
        this.setState({value: this.ParseValue(this.props.value)});
    }

    Value = () =>
    {
        return this.ParseValue(this.state.value);
    }

    render()
    {
        const {
            className,
            color,
            disabled,
            error,
            hollow,
            label,
            pattern,
            placeholder,
            type
        } = this.props;
        const {focus, value} = this.state;
        const CA = ["Field", "TextField"];
        const Color = UcFirst(color);

        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (value)
        {
            CA.push("HasValue");
        }
        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {label ? <label htmlFor={this.Token}>{label}</label> : ""}
                <input
                    className={hollow ? "Input Hollow" : `Input Color${Color}`}
                    disabled={disabled}
                    id={this.Token}
                    onBlur={this.OnBlur}
                    onChange={() => {}}
                    onFocus={this.OnFocus}
                    onInput={this.OnInput}
                    onKeyDown={this.OnKeyDown}
                    onKeyUp={this.OnKeyUp}
                    onMouseDown={e => e.stopPropagation()}
                    pattern={type === "number" ? "[0-9]*" : pattern}
                    placeholder={placeholder}
                    ref={input => this.Input = input}
                    tabIndex={disabled ? "false" : "0"}
                    type={type}
                    value={value}
                />
            </div>
        );
    }
}

TextField.defaultProps =
{
    className: "",
    color: "black",
    disabled: false,
    error: false,
    hollow: false,
    id: "",
    label: "",
    maxLength: 0,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    pattern: "",
    placeholder: "",
    return: "text",
    type: "text",
    value: ""
};

export default TextField;