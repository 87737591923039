import React from "react";

class Lumafabriken extends React.Component
{
    Svg = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 428 254.8">
                <polygon className="st0" points="151.2,161.1 151.2,85.2 208.5,61.9 273.9,92 273.6,129.8 262.8,126.1 262.5,154.4 279.6,158.9 278.6,170.7 231,158.3"/>
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="265.8262" y1="132.9507" x2="265.8262" y2="173.4296">
                    <stop offset="0" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <polygon className="st1" points="259,166.1 272.9,170.4 272.3,133.6 262.6,136.3 262.6,159.2 258.7,159.2"/>
                <polyline points="276.6,95.4 274.1,94.3 274.1,96.2 276.6,95.4"/>
                <linearGradient id="SVGID_00000172417988266151422110000011652750646691975100_" gradientUnits="userSpaceOnUse" x1="151.8866" y1="113.9984" x2="173.9665" y2="113.9984">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000172417988266151422110000011652750646691975100_)"}} points="173.3,117.7 151.9,110.9 152.2,110.3 174,117.2"/>
                <linearGradient id="SVGID_00000155147620854431620120000003645536246756725166_" gradientUnits="userSpaceOnUse" x1="152.2433" y1="103.0501" x2="175.3452" y2="103.0501">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000155147620854431620120000003645536246756725166_)"}} points="175,107.3 152.2,99.6 152.3,98.8 175.3,106.6"/>
                <linearGradient id="SVGID_00000096760928165004820870000009749015360952901273_" gradientUnits="userSpaceOnUse" x1="164.2242" y1="86.5764" x2="187.3261" y2="86.5764">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000096760928165004820870000009749015360952901273_)"}} points="187,90.8 164.2,83.1 164.3,82.3 187.3,90.2"/>
                <linearGradient id="SVGID_00000148639585571384393470000005538139351607323009_" gradientUnits="userSpaceOnUse" x1="265.955" y1="91.5638" x2="265.955" y2="21.797">
                    <stop offset="0" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <path style={{fill:"url(#SVGID_00000148639585571384393470000005538139351607323009_)"}} d="M260.1,86.1l0.9-62.9c0-0.3,2.2-0.5,4.9-0.5c2.7,0,4.9,0.2,4.9,0.5l0.9,67.4"/>
                <linearGradient id="SVGID_00000025406041052633991600000013712079340585678991_" gradientUnits="userSpaceOnUse" x1="271.401" y1="118.4608" x2="259.6716" y2="118.4608">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polyline style={{fill:"url(#SVGID_00000025406041052633991600000013712079340585678991_)"}} points="273.6,116.8 258.9,120.9 258.9,120.1 273.6,116.1"/>
                <linearGradient id="SVGID_00000129176693993205494590000002575959677061616051_" gradientUnits="userSpaceOnUse" x1="271.401" y1="107.7349" x2="259.6716" y2="107.7349">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polyline style={{fill:"url(#SVGID_00000129176693993205494590000002575959677061616051_)"}} points="274.2,105.8 258.7,110.3 258.7,109.6 274.2,105.1"/>
                <radialGradient id="SVGID_00000146468818321330211690000001202410278053490343_" cx="223.8845" cy="134.1896" r="16.9579" gradientUnits="userSpaceOnUse">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </radialGradient>
                <polygon style={{fill:"url(#SVGID_00000146468818321330211690000001202410278053490343_)"}} points="230.9,123.3 230.9,122.5 224,124.5 224,117.9 223.2,117.9 223.2,124.8 216.8,126.6 216.8,127.4 223.2,125.6 223.2,132.1 216.8,133.9 216.8,134.6 223.2,132.9 223.2,139.1 216.9,140.7 216.9,141.5 223.2,139.8 223.2,146.2 216.9,147.8 216.9,148.5 223.2,147 223.2,150.5 224,150.5 224,146.8 230.9,145 230.9,144.3 224,146 224,139.6 230.9,137.9 230.9,137.1 224,138.9 224,132.7 230.9,130.8 230.9,130.1 224,131.9 224,125.3"/>
                <radialGradient id="SVGID_00000078018943378348764600000001273168248928331174_" cx="206.5983" cy="134.9697" r="15.6296" gradientUnits="userSpaceOnUse">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </radialGradient>
                <path style={{fill:"url(#SVGID_00000078018943378348764600000001273168248928331174_)"}} d="M217.1,134.6v-0.8l-6.3-1.9v-6.4l6.3,2v-0.8l-6.3-2v-7.2H210v6.9l-6.3-2v-6.9h-0.8v6.6l-6.7-2.2v0.8l6.7,2.2v6.5l-6.7-2.1v0.8l6.7,2.1v6.2l-6.7-2v0.8l6.7,2v6.5l-6.7-1.9v0.8l6.7,1.9v7.9h0.8v-7.6l6.3,1.8v7.8h0.8v-7.6l6,1.7v-0.8l-6-1.7v-6.4l6,1.8v-0.8l-6-1.8v-6.1L217.1,134.6z M203.7,123.2l6.3,2v6.4l-6.3-2V123.2z M210,145.8l-6.3-1.8v-6.5l6.3,1.9V145.8z M210,138.6l-6.3-1.9v-6.2l6.3,2V138.6z"/>
                <linearGradient id="SVGID_00000057119172013327608870000016598772586381931394_" gradientUnits="userSpaceOnUse" x1="154.4263" y1="97.6577" x2="223.8642" y2="72.1696">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000057119172013327608870000016598772586381931394_)"}} points="208.3,78.1 151.5,99.4 151.5,98.7 208.3,77.2"/>
                <linearGradient id="SVGID_00000127032910623464009670000016366954864540238479_" gradientUnits="userSpaceOnUse" x1="164.4379" y1="118.7726" x2="164.4379" y2="78.9857">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="164.1" y="79.5" style={{fill:"url(#SVGID_00000127032910623464009670000016366954864540238479_)"}} width="0.7" height="40.3"/>
                <linearGradient id="SVGID_00000002373814170548621080000010508516345177055658_" gradientUnits="userSpaceOnUse" x1="178.6522" y1="112.9913" x2="178.6522" y2="74.8462">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="178.3" y="74.6" style={{fill:"url(#SVGID_00000002373814170548621080000010508516345177055658_)"}} width="0.7" height="39.9"/>
                <radialGradient id="SVGID_00000152950649592398080030000000821654994457276034_" cx="182.8126" cy="134.4651" r="15.8818" gradientUnits="userSpaceOnUse">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </radialGradient>
                <path style={{fill:"url(#SVGID_00000152950649592398080030000000821654994457276034_)"}} d="M194.3,141.3l-9.1,2.4v-6.4l9-2.5V134l-9,2.5v-6.3l8.9-2.6v-0.8l-8.9,2.6v-6.9l8.6-2.6v-0.8l-8.6,2.6v-6.6h-0.7v6.8l-5.4,1.7v-6.7h-0.7v7l-6.9,2.1v0.8l6.9-2.1v6.8l-6.9,2v0.8l6.9-2v6.2l-6.9,1.9v0.8l6.9-1.9v6.3l-6.9,1.8v0.8l6.9-1.8v7.5h0.7v-7.7l5.4-1.4v7.4h0.7v-7.6l9.1-2.4V141.3zM179,124.4l5.4-1.7v6.8l-5.4,1.6V124.4z M179,132l5.4-1.6v6.3l-5.4,1.5V132z M179,145.3V139l5.4-1.5v6.4L179,145.3z"/>
                <linearGradient id="SVGID_00000034790936066373042380000007572801552882445971_" gradientUnits="userSpaceOnUse" x1="280.4794" y1="61.5278" x2="211.5158" y2="92.6371">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="220.3" y="68.6" style={{fill:"url(#SVGID_00000034790936066373042380000007572801552882445971_)"}} width="0.7" height="39.7"/>
                <linearGradient id="SVGID_00000005987158665240650480000001632295275230396038_" gradientUnits="userSpaceOnUse" x1="284.0094" y1="69.3561" x2="215.047" y2="100.4648">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="230.5" y="73.4" style={{fill:"url(#SVGID_00000005987158665240650480000001632295275230396038_)"}} width="0.7" height="39.7"/>
                <linearGradient id="SVGID_00000065777476297567279890000015893576027655793289_" gradientUnits="userSpaceOnUse" x1="261.3353" y1="59.9931" x2="192.3729" y2="91.1018">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="207.9" y="64.1" style={{fill:"url(#SVGID_00000065777476297567279890000015893576027655793289_)"}} width="0.7" height="39.7"/>
                <linearGradient id="SVGID_00000114759504420270146360000013988325841141165717_" gradientUnits="userSpaceOnUse" x1="295.8333" y1="95.5669" x2="226.8712" y2="126.6755">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="267.5" y="90" style={{fill:"url(#SVGID_00000114759504420270146360000013988325841141165717_)"}} width="0.7" height="36.5"/>
                <radialGradient id="SVGID_00000062161968241109214040000001301831554902951859_" cx="246.7347" cy="138.8239" r="18.5046" gradientUnits="userSpaceOnUse">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </radialGradient>
                <path style={{fill:"url(#SVGID_00000062161968241109214040000001301831554902951859_)"}} d="M262.6,141.2v-0.8l-6.9-2.3v-6.4l6.8,2.4v-0.8l-6.8-2.4v-8.3H255v8.1l-6.8-2.3v-7.9h-0.7v7.7l-7.2-2.5V118h-0.7v7.4l-8.5-2.9v0.8l8.5,2.9v6.7l-8.6-2.8v0.8l8.6,2.8v6.1l-8.6-2.7v0.8l8.6,2.7v6.3l-8.6-2.5v0.8l8.6,2.5v7.3h0.7v-7.1l7.2,2.1v7.5h0.7v-7.2l6.8,2v7.4h0.7v-7.2l6.8,2v-0.8l-6.8-2v-6l6.8,2.1V147l-6.8-2.1V139L262.6,141.2z M255,131.6v6.4l-6.8-2.2v-6.5L255,131.6z M240.2,126.5l7.2,2.5v6.5l-7.2-2.4V126.5zM240.2,133.9l7.2,2.4v6l-7.2-2.3V133.9z M240.2,147v-6.2l7.2,2.3v6.1L240.2,147z M255,151.4l-6.8-2v-6.1l6.8,2.1V151.4zM255,144.6l-6.8-2.1v-6l6.8,2.2V144.6z"/>
                <linearGradient id="SVGID_00000168092986035367985470000016985658278154253722_" gradientUnits="userSpaceOnUse" x1="286.8039" y1="75.5509" x2="217.8419" y2="106.6595">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="239.4" y="77.5" style={{fill:"url(#SVGID_00000168092986035367985470000016985658278154253722_)"}} width="0.7" height="38.5"/>
                <linearGradient id="SVGID_00000009591914605975625930000018137858684918987965_" gradientUnits="userSpaceOnUse" x1="289.3751" y1="81.2512" x2="220.4131" y2="112.3597">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="247.4" y="81.6" style={{fill:"url(#SVGID_00000009591914605975625930000018137858684918987965_)"}} width="0.7" height="36.9"/>
                <linearGradient id="SVGID_00000031200877001241987840000000928930163906699669_" gradientUnits="userSpaceOnUse" x1="291.745" y1="86.5025" x2="222.7817" y2="117.6117">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="255" y="83.8" style={{fill:"url(#SVGID_00000031200877001241987840000000928930163906699669_)"}} width="0.7" height="38.3"/>
                <linearGradient id="SVGID_00000023989167806214008520000004307734898895741373_" gradientUnits="userSpaceOnUse" x1="293.8075" y1="91.0749" x2="224.8442" y2="122.184">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <rect x="261.4" y="86.4" style={{fill:"url(#SVGID_00000023989167806214008520000004307734898895741373_)"}} width="0.7" height="38.3"/>
                <radialGradient id="SVGID_00000052077980032142343440000015138291805990250634_" cx="161.4644" cy="140.7052" r="10.6621" gradientTransform="matrix(1 0 0 1.6698 0 -94.2401)" gradientUnits="userSpaceOnUse">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </radialGradient>
                <path style={{fill:"url(#SVGID_00000052077980032142343440000015138291805990250634_)"}} d="M171.4,141.1v-0.8l-6.6,1.8v-6l6.6-1.9v-0.8l-6.6,1.9v-6.5l6.6-2V126l-6.6,2v-6.3h-0.7v6.5l-4.7,1.4v-6.8h-0.7v7l-6.8,2.1v0.8l6.8-2.1v6.5l-6.9,2v0.8l6.9-2v5.9l-7,2v0.8l7-2v6.1l-7.1,1.9v0.8l7.1-1.9v8.3h0.7v-8.5l4.7-1.2v8.5h0.7v-8.7l6.5-1.7v-0.8l-6.5,1.7v-6.1L171.4,141.1z M159.4,130.4l4.7-1.4v6.5l-4.7,1.4V130.4z M159.4,137.7l4.7-1.4v6l-4.7,1.3V137.7z M164.1,149.2l-4.7,1.2v-6.1l4.7-1.3V149.2z"/>
                <polygon points="276.6,133.1 262.6,136.5 262.6,135.6 275.1,132.5"/>
                <rect x="216.5" y="119.8" width="0.8" height="32.6"/>
                <linearGradient id="SVGID_00000153665219575022915830000016088572554751071908_" gradientUnits="userSpaceOnUse" x1="196.1522" y1="116.3422" x2="216.8903" y2="116.3422">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <polyline style={{fill:"url(#SVGID_00000153665219575022915830000016088572554751071908_)"}} points="196.2,112.4 216.9,119.5 216.9,120.3 196.2,113.3"/>
                <polyline points="230.3,116.4 216.9,120.3 216.9,119.5 229,115.9"/>
                <linearGradient id="SVGID_00000135667876275399251530000002458582992417396895_" gradientUnits="userSpaceOnUse" x1="158.0098" y1="100.6783" x2="228.1199" y2="100.1277">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000135667876275399251530000002458582992417396895_)"}} points="208.3,90.9 151.7,110.9 151.7,110.1 208.3,90.1"/>
                <linearGradient id="SVGID_00000010312646032938349960000018152309835762646402_" gradientUnits="userSpaceOnUse" x1="183.9641" y1="85.3699" x2="294.3597" y2="97.2078">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polyline style={{fill:"url(#SVGID_00000010312646032938349960000018152309835762646402_)"}} points="208.3,78.1 273.6,105.8 273.6,105 208.3,77.2"/>
                <linearGradient id="SVGID_00000003090636758556073230000017648410296021963406_" gradientUnits="userSpaceOnUse" x1="203.7277" y1="92.0422" x2="286.0421" y2="117.3697">
                    <stop offset="6.134970e-03" style={{stopColor:"#FFFFFF"}}/>
                    <stop offset="0.4969" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#FFFFFF"}}/>
                </linearGradient>
                <polyline style={{fill:"url(#SVGID_00000003090636758556073230000017648410296021963406_)"}} points="208.3,90.9 273.6,116.8 273.6,116.1 208.3,90.1"/>
                <linearGradient id="SVGID_00000089564431630343201060000015268446331490297016_" gradientUnits="userSpaceOnUse" x1="149.9933" y1="109.4917" x2="208.3604" y2="109.4917">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <path style={{fill:"url(#SVGID_00000089564431630343201060000015268446331490297016_)"}} d="M196.2,69.5l12.2-4.9l-0.1-5.1L150,83.2v76.2l3.7-0.9v-32.6l16.5-5.3v33.7l2.5-0.6v-33.9l19.1-6.1v35.2l1.6-0.4l2.8,0.7v-36.9l12.1-3.9l0.1-5l-12.2,4V69.5z M153.7,121.4V86.6l38-15.3v37.6L153.7,121.4z"/>
                <linearGradient id="SVGID_00000145742306287983713560000008103978631283439267_" gradientUnits="userSpaceOnUse" x1="276.9262" y1="108.5467" x2="199.1751" y2="108.5467">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000145742306287983713560000008103978631283439267_)"}} points="276.6,95.4 276.6,91.2 208.2,59.6 208.4,64.7 273,93.7 273,127.3 208.4,103.5 208.3,108.5 230.3,116.4 230.3,148.6 231.6,148.3 231.6,116.9 262,127.8 262,157.1 263.1,157.5 263.1,128.2 276.6,133.1 276.6,128.7 274.2,127.8 274.2,94.3"/>
                <polygon className="st0" points="257.1,164.6 257.1,152.6 279,159.2 279,171.5"/>
                <linearGradient id="SVGID_00000021817740780088021110000012890427305845765011_" gradientUnits="userSpaceOnUse" x1="256.6267" y1="161.3157" x2="279.5514" y2="161.3157">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000021817740780088021110000012890427305845765011_)"}} points="256.6,151.9 256.6,153.2 256.6,156.9 257.7,157.8 257.7,153.5 278.6,159.8 278.6,170.7 279.6,170.7 279.6,160.1 279.6,158.9"/>
                <polygon points="210.9,176.4 210.9,153.2 193.3,148.5 193.3,176.4"/>
                <polygon className="st31" points="210.9,153.2 231.7,148.2 231.7,176.4 210.9,176.4"/>
                <linearGradient id="SVGID_00000077314053236372223090000007251973381320107925_" gradientUnits="userSpaceOnUse" x1="121.6373" y1="162.4561" x2="211.9366" y2="162.4561">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000077314053236372223090000007251973381320107925_)"}} points="193.3,148.5 121.6,166.6 121.6,176.4 193.3,176.4"/>
                <linearGradient id="SVGID_00000161609263735345887200000009333449812136827299_" gradientUnits="userSpaceOnUse" x1="231.6598" y1="162.3367" x2="282.3905" y2="162.3367">
                    <stop offset="0" style={{stopColor:"#6E6E6E"}}/>
                    <stop offset="1" style={{stopColor:"#000000"}}/>
                </linearGradient>
                <polygon style={{fill:"url(#SVGID_00000161609263735345887200000009333449812136827299_)"}} points="231.7,148.2 264.8,157.9 264.8,160.8 259.6,161.9 259.6,164.2 282.4,170.5 282.4,173.4 277.5,174.3 277.5,176.4 231.7,176.4"/>
                <g className="CustomLogoText">
                    <path d="M24.6,226.1h12.5v4.4H19.5V192h5.1V226.1z"/>
                    <path d="M46.6,192v22.7c0,6.3,2,12.1,9.9,12.1s9.9-5.8,9.9-12.1V192h5.1v24.4c0,8.5-5.6,15-15,15c-9.4,0-15-6.5-15-15V192H46.6z"/>
                    <path d="M87.2,205.1h-0.1l-4.8,25.4H77l8.4-40.1l14.1,30.3l14.1-30.3l8.4,40.1h-5.3l-4.8-25.4h-0.1l-12.3,26.2L87.2,205.1z"/>
                    <path d="M134.1,220.9l-4.7,9.6h-5.5l19.6-40.1l19.6,40.1h-5.5l-4.7-9.6H134.1z M143.5,200.9l-7.3,15.6h14.6L143.5,200.9z"/>
                    <path d="M173.3,196.4v10.8h14.3v4.4h-14.3v18.9h-5.1V192h19.9v4.4H173.3z"/>
                    <path d="M198.3,220.9l-4.7,9.6h-5.5l19.6-40.1l19.6,40.1h-5.5l-4.7-9.6H198.3z M207.7,200.9l-7.3,15.6H215L207.7,200.9z"/>
                    <path d="M232,192h5c7.7,0,15,1.9,15,10.6c0,2.8-1.6,6.1-4.4,7.3v0.1c4.9,0.8,8.2,5.3,8.2,9.8c0,7.7-7,10.8-14.3,10.8H232V192z M237.1,209.1h1.3c4.9,0,8.8-0.9,8.8-6.4c0-5.4-3.9-6.3-8.7-6.3h-1.4V209.1z M237.1,226.2h3.9c4.7,0,9.7-1.3,9.7-6.8c0-5.4-5.9-6.5-10.5-6.5h-3.2V226.2z"/>
                    <path d="M288.8,230.5h-6.2l-12.1-16.3h-2.1v16.3h-5.1V192h6.2c3.7,0,7.6,0.1,10.8,1.8c3.4,1.9,5.4,5.5,5.4,9.2c0,5.5-3.9,10.1-9.9,10.8L288.8,230.5z M268.4,210.3h1.6c5.2,0,10.9-0.9,10.9-7c0-6-6.1-6.9-11.2-6.9h-1.4V210.3z"/>
                    <path d="M299.4,230.5h-5.1V192h5.1V230.5z"/>
                    <path d="M331.2,192h6.7l-18.5,17.3l18.8,21.2h-6.9l-15.5-17.9l-2.1,1.8v16.1h-5.1V192h5.1v16.7L331.2,192z"/>
                    <path d="M343,192h22.1v4.4h-17v10.8h16.5v4.4h-16.5v14.6h17v4.4H343V192z"/>
                    <path d="M372.5,190.2l30.9,30.3V192h5.1v40.1l-30.9-30.3v28.7h-5.1V190.2z"/>
                </g>
            </svg>
        );
    }

    render()
    {
        const {className} = this.props;
        const CA = ["EateryLogo", "LumaFabriken"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {this.Svg()}
            </div>
        );
    }
}

Lumafabriken.defaultProps =
{
    className: ""
};

export default Lumafabriken;