import Eatery from 'Class/Eatery';
import Globals from 'Class/Globals';
import Icon from 'Components/Layout/Icon';

import FooterNavigation from 'Components/UI/FooterNavigation';
import Link from 'Components/UI/Link';
import Newsletter from 'Components/View/Newsletter';
import React from 'react';
import './footer.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state =
            {
                eatery: false,
                hide: false,
            };
    }

    componentDidMount() {
        Globals.Listen('eatery', this.OnEatery);
        Globals.Listen('var-independent', this.OnHide);

        this.setState({
            eatery: Eatery.GetEatery(),
            hide: Globals.Var('independent'),
        });
    }

    componentWillUnmount() {
        Globals.Remove('eatery', this.OnEatery);
        Globals.Remove('var-independent', this.OnHide);
    }

    OnEatery = (uri) => {
        this.setState({eatery: Eatery.Eatery(uri)});
    };

    OnHide = (hide) => {
        this.setState({hide});
    };

    render() {
        const {eatery, hide} = this.state;
        const {content} = eatery || {};
        const {facebook, instagram} = content || {};
        const SocialMedia = [];
        const PrivacyPolicy = Eatery.Option('privacyPolicy');
        const PrivacyPolicyTitle = Eatery.GetTitle(PrivacyPolicy);
        const CookiePolicy = Eatery.Option('cookiePolicy');
        const CookiePolicyTitle = Eatery.GetTitle(CookiePolicy);

        if (hide) {
            return '';
        }

        if (facebook) {
            SocialMedia.push(
                <Link href={facebook} key="facebook">
                    <Icon src="facebook" />
                    <span>Facebook</span>
                </Link>,
            );
        }
        if (instagram) {
            SocialMedia.push(
                <Link href={instagram} key="instagram">
                    <Icon src="instagram" />
                    <span>Instagram</span>
                </Link>,
            );
        }

        return (
            <div className="Footer">
                <div className="FooterColumn">
                    <Newsletter />
                </div>
                <div className="FooterColumn">
                    <FooterNavigation />
                </div>
                {SocialMedia.length ? (
                    <div className="FooterColumn FooterSocialMedia">
                        <h5>{Eatery.Option('socialMediaTitle')}</h5>
                        {SocialMedia}
                    </div>
                ) : ''}
                <div className="FooterCorner">
                    <Link to={PrivacyPolicy}>{PrivacyPolicyTitle}</Link>
                    <Link to={CookiePolicy}>{CookiePolicyTitle}</Link>
                </div>
            </div>
        );
    }
}

export default Footer;
