import React from "react";
import "./loading.scss";

import Spinner from "Components/Feedback/Spinner";

class Loading extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Label = "Laddar";
        this.Ms = 500;
        this.Timeout = false;
        this.state =
        {
            dots: ""
        };
    }

    componentDidMount()
    {
        this.Timeout = setTimeout(this.UpdateLabel, this.Ms);
    }

    componentWillUnmount()
    {
        clearTimeout(this.Timeout);
    }

    UpdateLabel = () =>
    {
        clearTimeout(this.Timeout);
        const {dots} = this.state;
        const Dots = dots.length < 3 ? dots + "." : "";
        const Ms = !Dots.length ? 3 * this.Ms : this.Ms;

        this.setState({dots: Dots});
        this.Timeout = setTimeout(this.UpdateLabel, Ms);
    }

    render()
    {
        const {dots} = this.state;

        return (
            <div className="Loading">
                <div className="LoadingContent">
                    <Spinner />
                    <div className="LoadingLabel">
                        {this.Label}
                        <div className="LoadingLabelDots">
                            <div className="LoadingLabelDots1">...</div>
                            <div className="LoadingLabelDots2">{dots}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;