import React from "react";

class Guldkanten extends React.Component
{
    Svg = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-80 -20 523.46 164.75" fill="#ffffff">
                <path d="M30.81,51.02v15.41h-11V51.02c0-2.42-1.98-4.4-4.4-4.4c-2.42,0-4.4,1.98-4.4,4.4v59.43c0,2.42,1.65,4.4,4.18,4.4c2.75,0,4.62-2.09,4.62-5.28V87.01h-4.4V76.88h15.41v47.87H20.47l0.88-8.25h-1.32c-1.65,6.05-5.28,9.35-10.23,9.35c-6.38,0-9.79-4.84-9.79-12.55V51.02c0-9.35,6.49-15.41,15.41-15.41C24.32,35.62,30.81,41.67,30.81,51.02z"/>
                <path d="M49.96,36.72v73.73c0,2.42,1.98,4.4,4.4,4.4s4.4-1.98,4.4-4.4V36.72h11v73.73c0,9.35-6.49,15.41-15.41,15.41c-8.91,0-15.41-6.05-15.41-15.41V36.72H49.96z"/>
                <path d="M89.35,36.72v74.06c0,1.76,0.99,2.97,3.19,2.97h11.67v11H88.36c-5.94,0-10.01-3.96-10.01-9.57V36.72H89.35z"/>
                <path d="M125.34,36.72c8.91,0,15.41,6.05,15.41,15.41v57.22c0,9.35-6.49,15.41-15.41,15.41h-15.41V36.72H125.34zM120.93,113.75h4.4c2.42,0,4.4-1.98,4.4-4.4V52.12c0-2.42-1.98-4.4-4.4-4.4h-4.4V113.75z"/>
                <path d="M160,74.02l9.79-37.31h11L169.9,74.68l12,50.07h-11l-8.91-38.41H160v38.41h-11V36.72h11v17.83l-1.32,19.48H160z"/>
                <path d="M209.08,36.72l9.57,88.04h-11.12l-1.76-21.79h-9.35l-1.87,21.79h-11.11l9.57-88.04H209.08z M197.3,91.96h7.48l-3.08-41.05h-1.32L197.3,91.96z"/>
                <path d="M256.83,36.72v88.04h-9.24l-8.03-28.06l-5.5-27.07h-1.32l2.2,29.49v25.64h-11V36.72h6.6c2.75,0,4.51,1.1,5.28,3.74l4.95,17.72l8.14,38.19h1.32l-4.4-41.49V36.72H256.83z"/>
                <path d="M292.71,36.72v11h-9.9v77.03h-11V47.72h-9.9v-11H292.71z"/>
                <path d="M324.18,36.72v11h-15.41v26.63h13.21v11h-13.21v25.42c0,1.76,0.99,2.97,3.19,2.97h12.22v11h-16.51c-5.94,0-9.9-3.96-9.9-9.57V36.72H324.18z"/>
                <path d="M363.46,36.72v88.04h-9.24l-8.03-28.06l-5.5-27.07h-1.32l2.2,29.49v25.64h-11V36.72h6.6c2.75,0,4.51,1.1,5.28,3.74l4.95,17.72l8.14,38.19h1.32l-4.4-41.49V36.72H363.46z"/>
                <path d="M67.15,18.48c-0.37,0.85-0.75,1.6-1.06,2.25c-0.31,0.65-0.68,1.4-1.19,2.32c-0.48,0.89-0.95,1.67-1.43,2.32c-1.5,2.01-3.34,3.72-5.39,3.72c-2.35,0-3.82-1.47-3.99-5.32c-0.03-1.6-0.1-2.35-0.37-3.31c-0.31-1.3-1.23-1.98-2.9-2.25c-0.24,1.06-0.51,2.08-0.78,3.07c-1.4,5.22-4.47,7.84-9.2,7.84l-0.85-3.48c1.94,0,3.38-0.41,4.3-1.19c0.92-0.78,1.64-2.18,2.15-4.13c0.24-0.99,0.51-2.22,0.78-3.61c0.31-1.43,0.55-2.63,0.72-3.55c0.2-0.95,0.44-1.91,0.75-2.9c0.58-2.01,1.43-3.17,2.62-3.68h3.44c-0.85,1.19-1.47,2.35-1.88,3.51c-0.37,1.16-0.82,2.86-1.33,5.15c2.73,0,4.94-0.85,6.14-2.08c1.19-1.23,1.77-2.62,1.77-3.99c0-2.32-1.84-4.36-5.76-4.36c-2.8,0-5.15,0.99-7.06,2.93c-1.91,1.94-2.86,4.36-2.86,7.23c0,1.67,0.37,3.14,1.13,4.4l-3.61,1.7c-0.92-1.6-1.4-3.48-1.4-5.66C39.78,7.26,46.32,1.5,54.4,1.67c2.93,0,5.18,0.72,6.75,2.11s2.35,3.14,2.35,5.18v0.68c-0.14,1.74-0.89,3.34-2.25,4.81c-1.36,1.47-3.07,2.49-5.15,3c1.06,0.61,1.5,1.5,1.67,2.59c0.07,0.41,0.1,0.75,0.1,1.06l0.14,2.28c0.14,1.64,0.38,2.45,1.3,2.45c0.58,0,1.33-0.51,2.22-1.53c1.77-2.05,3.17-4.53,3.99-6.48L67.15,18.48z"/>
                <path d="M80.93,18.48c-2.83,6.24-6.17,9.34-10.06,9.34c-3.68,0-6-2.56-6-6.48c0-0.37,0.03-0.75,0.07-1.13c0.51-4.81,3.61-10.47,8.39-10.43c2.45,0,3.72,1.53,3.72,3.85c0,0.31-0.03,0.65-0.07,1.02c-0.27,1.84-1.19,3.51-2.73,4.98s-3.24,2.39-5.11,2.69c0.31,1.5,1.23,2.25,2.69,2.25c3.34,0,5.97-3.58,7.5-6.75L80.93,18.48z M70.64,15.1c-0.72,1.47-1.19,3.03-1.47,4.64c2.08-0.68,3.61-2.59,4.13-4.74c0.1-0.48,0.17-0.89,0.17-1.19c0-0.65-0.2-0.95-0.65-0.95C72.07,12.85,71.35,13.6,70.64,15.1z"/>
                <path d="M93.55,18.48c-1.88,4.5-4.33,7.5-7.47,7.5c-0.1,0-0.2,0-0.31-0.03c-1.33,1.23-2.83,1.84-4.57,1.84c-2.49,0-4.33-1.06-5.52-3.14l1.84-1.57c0.85,1.19,1.88,1.77,3.07,1.77c1.91,0,3.07-1.67,3.07-3.58c0-0.92-0.34-1.98-0.61-2.49c-0.1-0.27-0.34-0.68-0.68-1.23c-0.31-0.58-0.51-0.92-0.61-1.06c-0.34,0.89-0.68,1.67-1.02,2.39l-1.7-0.41c0.72-1.53,1.3-3.1,1.81-4.67V13.3c0-0.31,0.03-0.61,0.07-0.92c0.27-2.05,1.94-3.65,4.3-3.95c0.55,0.58,1.12,1.23,1.7,1.98c-0.03,0.07-0.41,0.51-1.12,1.36c-0.72,0.85-1.26,1.53-1.64,2.11l1.19,1.64c0.58,0.78,1.02,1.4,1.3,1.84c0.55,0.85,1.23,2.45,1.23,3.78c0,0.85-0.17,1.67-0.48,2.45c0.65-0.2,1.26-0.68,1.91-1.4c1.33-1.5,1.98-2.86,2.66-4.36L93.55,18.48z"/>
                <path d="M98.6,23.35c1.5-1.53,2.56-3.51,3.41-5.52l1.64,0.65c-1.09,2.35-1.81,3.75-2.93,5.49c-1.6,2.45-3.27,3.72-5.69,3.85c-2.69,0-4.02-1.84-4.02-4.94c0-0.51,0.03-1.06,0.1-1.64c0.24-1.88,1.12-5.56,2.59-11.05l0.72-2.73h-3.61l0.78-2.63c1.4,0.03,2.59,0.03,3.58,0.03L96.52,0h4.19l-1.3,4.84l4.64-0.1l-0.82,2.83L98.7,7.5l-0.78,2.9c-1.4,5.15-2.25,8.8-2.56,10.91c-0.03,0.48-0.07,0.89-0.07,1.26c0,1.3,0.41,1.94,1.26,1.94C97.17,24.51,97.85,24.14,98.6,23.35z"/>
                <path d="M121.1,18.48c-1.36,2.97-2.52,4.98-3.89,6.72c-1.36,1.74-2.83,2.59-4.43,2.59c-1.77,0-2.97-1.09-3.27-3.1c-1.23,2.08-2.69,3.1-4.4,3.1c-2.69,0-3.92-2.18-3.92-5.49c0-0.48,0.03-0.99,0.07-1.5c0.17-1.74,0.65-3.44,1.4-5.11c0.78-1.67,1.77-3.1,3.03-4.23c1.26-1.13,2.56-1.7,3.92-1.7c1.57,0,2.56,0.82,2.76,2.45l0.75-1.98h3.65c-2.15,6.17-3.34,10.23-3.51,12.2c-0.03,0.24-0.03,0.44-0.03,0.61c0,1.02,0.34,1.53,0.99,1.53c0.68,0,1.57-0.72,2.62-2.15c1.06-1.47,1.94-3,2.66-4.6L121.1,18.48z M108.35,22.57c0.58-1.23,0.99-2.28,1.57-4.16c0.44-1.57,0.68-2.8,0.68-3.72c0-1.09-0.34-1.64-0.99-1.64c-1.91,0-3.78,4.7-4.23,8.11c-0.07,0.48-0.1,0.89-0.1,1.26c0,1.33,0.37,1.98,1.12,1.98C107.12,24.41,107.77,23.8,108.35,22.57z"/>
                <path d="M137.57,18.48c-1.06,2.32-1.84,3.85-2.97,5.52c-1.6,2.39-3.24,3.78-5.42,3.78c-1.74,0-2.97-0.99-3.24-2.73c-1.26,1.84-2.76,2.76-4.5,2.76c-1.94,0-3.41-1.64-3.24-4.19c0.1-1.53,1.19-6,3.27-13.4h4.06c-2.08,7.53-3.17,11.8-3.27,12.72c-0.1,1.02,0.24,1.53,1.02,1.53c1.26,0,2.39-1.5,3.41-4.5c0.51-1.88,1.43-5.11,2.76-9.75h4.02c-2.01,7.06-3.14,11.22-3.38,12.55c-0.03,0.27-0.07,0.51-0.07,0.72c0,0.72,0.27,1.06,0.85,1.06c0.65,0,1.47-0.72,2.45-2.11c1.02-1.4,1.91-2.93,2.63-4.6L137.57,18.48z"/>
                <path d="M152.03,18.48c-2.8,6.24-5.73,9.34-8.8,9.34c-2.73,0-4.36-1.77-4.36-4.6c0-2.69,1.4-6.14,3.44-9.24c-0.44,0.14-0.99,0.2-1.57,0.2c-0.51,0-1.02-0.07-1.47-0.17c-0.61,1.94-1.26,3.58-1.91,4.88l-1.67-0.44c0.82-1.81,1.47-3.51,1.98-5.18c-0.92-0.72-1.36-1.81-1.36-3.27c0-1.6,0.68-3.14,2.08-4.6l3,1.6l-1.43,4.4c0.38,0.24,0.82,0.37,1.4,0.37c1.3,0,2.56-0.51,3.78-1.53l2.86,2.35c-2.49,2.8-5.18,7.91-5.11,10.26c0,1.12,0.65,1.77,1.5,1.77c0.72,0,1.5-0.44,2.39-1.33c1.77-1.77,3-3.92,3.65-5.45L152.03,18.48z"/>
                <path d="M169.52,18.48c-1.36,2.97-2.52,4.98-3.89,6.72c-1.36,1.74-2.83,2.59-4.43,2.59c-1.77,0-2.97-1.09-3.27-3.1c-1.23,2.08-2.69,3.1-4.4,3.1c-2.69,0-3.92-2.18-3.92-5.49c0-0.48,0.03-0.99,0.07-1.5c0.17-1.74,0.65-3.44,1.4-5.11c0.78-1.67,1.77-3.1,3.03-4.23c1.26-1.13,2.56-1.7,3.92-1.7c1.57,0,2.56,0.82,2.76,2.45l0.75-1.98h3.65c-2.15,6.17-3.34,10.23-3.51,12.2c-0.03,0.24-0.03,0.44-0.03,0.61c0,1.02,0.34,1.53,0.99,1.53c0.68,0,1.57-0.72,2.62-2.15c1.06-1.47,1.94-3,2.66-4.6L169.52,18.48z M156.77,22.57c0.58-1.23,0.99-2.28,1.57-4.16c0.44-1.57,0.68-2.8,0.68-3.72c0-1.09-0.34-1.64-0.99-1.64c-1.91,0-3.78,4.7-4.23,8.11c-0.07,0.48-0.1,0.89-0.1,1.26c0,1.33,0.37,1.98,1.12,1.98C155.54,24.41,156.19,23.8,156.77,22.57z"/>
                <path d="M185.85,18.48c-2.76,6.1-5.39,9.31-8.49,9.31c-2.15,0-3.38-1.53-3.38-3.85c-0.14-2.32,2.66-8.63,2.56-9.72c0-0.65-0.31-0.99-0.92-0.99c-1.33,0-2.83,1.81-3.92,5.08l-2.45,8.97h-3.89l4.53-17.05h4.02l-0.41,1.53c1.36-1.36,2.83-2.05,4.36-2.05c1.74,0,3.07,1.09,3.07,3.24c0,0.17,0,0.34-0.03,0.51c-0.07,0.95-0.55,2.59-1.43,4.88c-0.85,2.28-1.36,3.82-1.47,4.57c-0.1,0.99,0.24,1.64,1.02,1.64c0.65,0,1.5-0.72,2.56-2.11s1.94-2.93,2.66-4.6L185.85,18.48z"/>
                <path d="M202.46,18.48c-1.19,2.62-2.42,4.67-3.68,6.14c-1.23,1.47-2.76,2.69-4.64,3.65c-0.58,2.15-1.57,3.92-2.97,5.28c-1.4,1.36-3.1,2.05-5.15,2.05c-2.42,0-3.89-1.36-3.78-3.27c0.27-2.66,2.69-3.48,5.05-4.06c1.26-0.27,2.39-0.58,3.37-0.92c0.17-0.72,0.31-1.47,0.44-2.28c-1.06,1.43-2.32,2.15-3.72,2.15c-2.56,0-3.85-2.11-3.85-5.39c0-0.41,0-0.82,0.03-1.26c0.17-1.7,0.61-3.38,1.33-5.01c0.75-1.64,1.74-3,2.93-4.12c1.23-1.13,2.52-1.67,3.89-1.67c1.5,0,2.52,0.85,2.8,2.42l0.68-1.94h3.72c-0.85,2.35-1.5,4.4-2.01,6.17c-0.38,1.26-1.06,4.3-2.08,9.03c2.59-1.67,4.4-4.02,6-7.6L202.46,18.48z M189.95,29.9l-0.89,0.24c-1.57,0.44-3.48,0.78-3.48,1.91c0,0.51,0.34,0.75,1.02,0.75C188.04,32.8,189.16,31.84,189.95,29.9z M192.2,18.44c0.37-1.57,0.58-2.8,0.58-3.68c0-1.12-0.34-1.7-0.99-1.7c-1.77-0.03-3.78,4.6-4.13,7.98c-0.03,0.34-0.07,0.65-0.07,0.95c0,1.3,0.41,1.94,1.26,1.94C190.15,23.93,191.28,22.09,192.2,18.44z"/>
            </svg>
        );
    }

    render()
    {
        const {className} = this.props;
        const CA = ["EateryLogo", "Guldkanten"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {this.Svg()}
            </div>
        );
    }
}

Guldkanten.defaultProps =
{
    className: ""
};

export default Guldkanten;