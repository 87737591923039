import React from "react";
import "./help.scss";

class Help extends React.Component
{
    constructor(props)
    {
        super(props);

        this.BlockClick = false;
        this.Focus = false;
        this.Icon = false;
        this.Mounted = false;
        this.Text = false;

        this.state =
        {
            expand: false
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
    }

    componentWillUnmount()
    {
        this.Mounted = false;
        document.removeEventListener("focusin", this.OnOtherFocus);
    }

    Block = (e) =>
    {
        const {blockDefault} = this.props;

        if (!blockDefault)
        {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
    }

    Collapse = (e) =>
    {
        this.Block(e);

        document.removeEventListener("focusin", this.OnOtherFocus);
        this.setState({expand: false});
    }

    Expand = (e) =>
    {
        this.Block(e);
        this.BlockClick = true;

        document.addEventListener("focusin", this.OnOtherFocus);
        this.setState({expand: true});

        setTimeout(() => this.BlockClick = false, 500);
    }

    OnClick = (e) =>
    {
        this.Block(e);
        const {expand} = this.state;

        if (this.BlockClick)
        {
            return;
        }
        if (expand)
        {
            this.Collapse(e);
        }
        else
        {
            this.Expand(e);
        }
    }

    OnKey = (e) =>
    {
        switch (e.which)
        {
            case 13:
            case 32:
                this.Toggle(e);
                break;
            default:
        }

        e.stopPropagation();
        e.preventDefault();
    }

    OnOtherFocus = (e) =>
    {
        if (e.target === this.Icon || this.Text.contains(e.target))
        {
            return;
        }

        this.Collapse(e);
    }

    Toggle = (e) =>
    {
        this.Block(e);

        const {expand} = this.state;
        this.setState({expand: !expand});
    }  

    render()
    {
        const {children, className, label} = this.props;
        const {expand} = this.state;
        const CA = ["Help"];

        if (label)
        {
            CA.push("HasLabel");
        }

        if (expand)
        {
            CA.push("Expand");
        }
        
        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");

        return (
            <div className={CS} onClick={e => e.stopPropagation()} onMouseDown={this.Block}>
                {label ? (
                    <div
                        className="HelpLabel"
                        onClick={this.OnClick}
                        onFocus={this.Expand}
                        onKeyDown={this.OnKey}
                        ref={icon => this.Icon = icon}
                        tabIndex="0"
                    >{label}</div>
                ) : (
                    <div
                        className="HelpIcon"
                        onClick={this.OnClick}
                        onFocus={this.Expand}
                        onKeyDown={this.OnKey}
                        ref={icon => this.Icon = icon}
                        tabIndex="0"
                    ><span>i</span></div>
                )}
                {expand ? (
                    <div className="HelpTextContainer"
                        onClick={this.Block}
                        onMouseDown={this.Block}
                    >
                        <div className="HelpText" ref={text => this.Text = text}>
                            {children}
                        </div>
                    </div>
                ) : ""}
            </div>
        );
    }
}

Help.defaultProps =
{
    blockDefault: false,
    className: "",
    label: ""
};

export default Help;