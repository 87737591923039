import React from "react";
import "./booking.scss";

import Booking from "Class/Booking";
import Eatery from "Class/Eatery";
import Globals from "Class/Globals";
import Parser from "Class/Parser";
import TagManager from "react-gtm-module";
import {DaysBetweenDates, DateParse, DateStamp} from "Functions";

import Button from "Components/UI/Button";
import Confirm from "Components/View/Confirm";
import Form from "Components/UI/Form";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import ProductItem from "Components/UI/ProductItem";
import Review from "./review.js";
import Spinner from "Components/Feedback/Spinner";

class Forms extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Forms =
        {
            "konferens-fest":
            [
                {
                    key: "detaljer",
                    name: "Detaljer",
                    fields:
                    {
                        from:
                        {
                            label: "Från",
                            type: "date",
                            limitLower: this.GetDateLimit1,
                            halfNarrow: true,
                            value: DateParse()
                        },
                        to:
                        {
                            alignRight: true,
                            label: "Till",
                            type: "date",
                            limitLower: this.GetDateLimit2,
                            halfNarrow: true,
                            value: DateParse()
                        },
                        time:
                        {
                            label: "Tid",
                            type: "repeating",
                            numRepeats: this.OptionsNumDays,
                            onRenderTag: this.DateTag,
                            onValue: this.GetValue,
                            fields:
                            {
                                time:
                                {
                                    type: "timeselect",
                                    value: 2
                                }
                            }
                        },
                        type:
                        {
                            label: "Typ av event",
                            type: "select",
                            options: Eatery.EventTypes,
                            placeholder: "Välj",
                            half: true,
                            value: -1
                        },
                        people:
                        {
                            label: "Antal personer",
                            type: "number",
                            placeholder: "Ange",
                            half: true
                        }

                    }
                },
                {
                    key: "lokal",
                    name: "Lokal",
                    contentUnder: this.ContentRoom,
                    fields:
                    {
                        eatery:
                        {
                            label: "Anläggning",
                            type: "select",
                            onRenderItem: this.ItemEatery,
                            options: this.OptionsEateryRoom,
                            placeholder: "Välj"
                        },
                        meetingRoom:
                        {
                            label: "Rum",
                            type: "select",
                            multiple: true,
                            onRenderItem: this.ItemRoom,
                            options: this.OptionsRoom,
                            placeholder: "Hjälp mig ta fram ett förslag"
                        }
                    }
                },
                {
                    key: "mat",
                    name: "Mat & dryck",
                    fields:
                    {
                        food:
                        {
                            label: "Mat & dryck",
                            type: "repeating",
                            numRepeats: this.OptionsNumDays,
                            onRenderTag: this.DateTag,
                            onValue: this.GetValue,
                            fields:
                            {
                                food:
                                {
                                    categories: this.GetFoodCategories,
                                    defaultPeople: this.GetFoodAmount,
                                    type: "food"
                                }
                            }
                        },
                        specialDiets:
                        {
                            label: "Allergier & specialdieter",
                            type: "textarea"
                        }
                    }
                },
                {
                    key: "tillval",
                    name: "Tillval",
                    contentOver: this.ContentExtras,
                    fields: {}
                },
                {
                    key: "info",
                    name: "Din info",
                    fields: {
                        name:
                        {
                            label: "Namn",
                            type: "text",
                            mandatory: true,
                            half: true
                        },
                        company:
                        {
                            label: "Företag",
                            type: "text",
                            half: true
                        },
                        email:
                        {
                            label: "E-mail",
                            type: "email",
                            mandatory: true
                        },
                        phone:
                        {
                            label: "Telefonnummer",
                            type: "phone",
                            mandatory: true,
                            half: true
                        },
                        reference:
                        {
                            label: "Eventuellt referensavtal",
                            type: "text",
                            half: true
                        },
                        message:
                        {
                            label: "Meddelande",
                            type: "textarea",
                            placeholder: "Övriga önskemål..."
                        },
                        terms:
                        {
                            type: "checkbox",
                            mandatory: true,
                            text: <span>Jag godkänner <Link href="/integritetspolicy">integritetspolicyn</Link></span>
                        },
                        save:
                        {
                            help: this.GetSaveNotice,
                            label: "Spara mina uppgifter",
                            type: "checkbox"
                        }
                    }
                },
                {
                    key: "slufor",
                    name: "Slutför",
                    contentOver: this.ContentReview1,
                    fields: {}
                }
            ],
            "catering":
            [
                {
                    key: "detaljer",
                    name: "Detaljer",
                    fields:
                    {
                        from:
                        {
                            label: "Från",
                            type: "date",
                            limitLower: this.GetDateLimit1,
                            halfNarrow: true,
                            value: DateParse()
                        },
                        to:
                        {
                            alignRight: true,
                            label: "Till",
                            type: "date",
                            limitLower: this.GetDateLimit2,
                            halfNarrow: true,
                            value: DateParse()
                        },
                        time:
                        {
                            label: "Tid",
                            type: "repeating",
                            half: true,
                            numRepeats: this.OptionsNumDays,
                            onRenderTag: this.DateTag,
                            onValue: this.GetValue,
                            fields:
                            {
                                time:
                                {
                                    type: "time"
                                }
                            }
                        },
                        people:
                        {
                            label: "Antal personer",
                            type: "number",
                            half: true,
                            placeholder: "Ange"
                        },
                        address:
                        {
                            label: "Eventets adress",
                            type: "textarea",
                            minRows: 1,
                            placeholder: "Ange"
                        },
                        type:
                        {
                            label: "Typ av event",
                            placeholder: "Ex. fest, mässa, filminspelning..."
                        },
                        delivery:
                        {
                            type: "radios",
                            options:
                            {
                                delivery: "Leverans",
                                pickup: "Avhämtning"
                            }
                        }

                    }
                },
                {
                    key: "mat",
                    name: "Mat & dryck",
                    contentOver: this.ContentCatering,
                    fields: {}
                },
                {
                    key: "ovrigt",
                    name: "Övrigt",
                    fields: {
                        specialDiets:
                        {
                            label: "Meddelande",
                            type: "textarea",
                            placeholder: "Allergier, specialdieter..."
                        },
                    }
                },
                {
                    key: "info",
                    name: "Din info",
                    fields: {
                        name:
                        {
                            label: "Namn",
                            type: "text",
                            mandatory: true,
                            half: true
                        },
                        company:
                        {
                            label: "Företag",
                            type: "text",
                            half: true
                        },
                        email:
                        {
                            label: "E-mail",
                            type: "email",
                            mandatory: true
                        },
                        phone:
                        {
                            label: "Telefonnummer",
                            type: "phone",
                            mandatory: true,
                            half: true
                        },
                        reference:
                        {
                            label: "Eventuellt referensavtal",
                            type: "text",
                            half: true
                        },
                        terms:
                        {
                            type: "checkbox",
                            mandatory: true,
                            text: <span>Jag godkänner <Link href="/integritetspolicy">integritetspolicyn</Link></span>
                        },
                        save:
                        {
                            help: this.GetSaveNotice,
                            label: "Spara mina uppgifter",
                            type: "checkbox"
                        }
                    }
                },
                {
                    key: "slufor",
                    name: "Slutför",
                    contentOver: this.ContentReview2,
                    fields: {}
                }
            ],
            "studio":
            [
                {
                    key: "detaljer",
                    name: "Detaljer",
                    fields:
                    {
                        from:
                        {
                            label: "Från",
                            type: "date",
                            limitLower: this.GetDateLimit1,
                            halfNarrow: true,
                            value: DateParse()
                        },
                        to:
                        {
                            alignRight: true,
                            label: "Till",
                            type: "date",
                            limitLower: this.GetDateLimit2,
                            halfNarrow: true,
                            value: DateParse()
                        },
                        time:
                        {
                            label: "Tid",
                            type: "repeating",
                            numRepeats: this.OptionsNumDays,
                            onRenderTag: this.DateTag,
                            onValue: this.GetValue,
                            fields:
                            {
                                time:
                                {
                                    type: "timeselect",
                                    value: 2
                                }
                            }
                        },
                        people:
                        {
                            label: "Antal personer på plats",
                            type: "number",
                            placeholder: "Ange"
                        }

                    }
                },
                {
                    key: "lokal",
                    name: "Lokal",
                    contentUnder: this.ContentRoom,
                    fields:
                    {
                        eatery:
                        {
                            label: "Inspelningsplats",
                            type: "select",
                            onRenderItem: this.ItemEatery,
                            options: this.OptionsEateryStudio,
                            placeholder: "Välj"
                        },
                        studio:
                        {
                            label: "Studio",
                            type: "select",
                            onRenderItem: this.ItemRoom,
                            options: this.OptionsStudio,
                            placeholder: "Hjälp mig ta fram ett förslag"
                        }
                    }
                },
                {
                    key: "mat",
                    name: "Mat & dryck",
                    fields:
                    {
                        food:
                        {
                            label: "Mat & dryck",
                            type: "repeating",
                            numRepeats: this.OptionsNumDays,
                            onRenderTag: this.DateTag,
                            onValue: this.GetValue,
                            fields:
                            {
                                food:
                                {
                                    categories: ["Fest/event", "Konferens/möte"],
                                    defaultPeople: this.GetFoodAmount,
                                    type: "food"
                                }
                            }
                        },
                        specialDiets:
                        {
                            label: "Allergier & specialdieter",
                            type: "textarea"
                        }
                    }
                },
                {
                    key: "tillval",
                    name: "Tillval",
                    contentOver: this.ContentExtras,
                    fields: {}
                },
                {
                    key: "info",
                    name: "Din info",
                    fields: {
                        name:
                        {
                            label: "Namn",
                            type: "text",
                            mandatory: true,
                            half: true
                        },
                        company:
                        {
                            label: "Företag",
                            type: "text",
                            half: true
                        },
                        email:
                        {
                            label: "E-mail",
                            type: "email",
                            mandatory: true
                        },
                        phone:
                        {
                            label: "Telefonnummer",
                            type: "phone",
                            mandatory: true,
                            half: true
                        },
                        reference:
                        {
                            label: "Eventuellt referensavtal",
                            type: "text",
                            half: true
                        },
                        message:
                        {
                            label: "Meddelande",
                            type: "textarea",
                            placeholder: "Övriga önskemål..."
                        },
                        terms:
                        {
                            type: "checkbox",
                            mandatory: true,
                            text: <span>Jag godkänner <Link href="/integritetspolicy">integritetspolicyn</Link></span>
                        },
                        save:
                        {
                            help: this.GetSaveNotice,
                            label: "Spara mina uppgifter",
                            type: "checkbox"
                        }
                    }
                },
                {
                    key: "slufor",
                    name: "Slutför",
                    contentOver: this.ContentReview3,
                    fields: {}
                }
            ]
        };

        this.state =
        {
            booking: false,
            error: false,
            id: "",
            sending: false,
            sent: false,
            stage: 0,
            stages: false,
            values: {}
        };
    }

    componentDidMount()
    {
        this.SetForm();
        Globals.Listen("booking-parameter", this.OnParameter);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps.type !== this.props.type)
        {
            this.SetForm();
        }
    }

    componentWillUnmount()
    {
        Globals.Remove("booking-parameter", this.OnParameter);
    }

    ContentCatering = () =>
    {
        const Food = this.GetField("food", []);
        const Location = this.GetField("eatery");
        const List = Eatery.FoodList(Location, ["Catering"]);
        const Items = [];

        if (List["Catering"])
        {
            List["Catering"].forEach(item =>
            {
                const {content, id, title} = item;
                const {price} = content;
                const CA = ["TemplateBookingCateringItem"]
                const Selected = Food.indexOf(id) >= 0;

                if (Selected)
                {
                    CA.push("Selected");
                }

                Items.push(<ProductItem
                    {...content}
                    buttons={[
                        {
                            hollow: true,
                            id,
                            label: "Läs mer",
                            onClick: this.OnCateringInfo
                        },
                        Selected ? {
                            color: "gold",
                            id,
                            label: "Ta bort",
                            onClick: this.OnCateringAdd
                        } : {
                            color: "black",
                            id,
                            label: "Lägg till",
                            onClick: this.OnCateringAdd
                        }
                    ]}
                    className={CA.join(" ")}
                    key={id}
                >
                    <h4>{title}</h4>
                    <div className="TemplateBookingCateringItemPrice">
                        {price ? `${price} kr/person` : "Pris på förfrågan"}
                    </div>
                </ProductItem>);
            });
        }

        return (
            <div className="TemplateBookingCatering">
                <div className="TemplateBookingCateringItems">
                    {Items}
                </div>
            </div>
        );
    }

    ContentExtras = () =>
    {
        const Extras = this.GetField("extras", []);
        const Location = this.GetField("eatery");
        const Activities = Eatery.ActivityList(Location);
        const Items = [];

        Activities.forEach(activity =>
        {
            const {content, id, title} = activity;
            const {about, price} = content;
            const CA = ["TemplateBookingExtraItem"]
            const Selected = Extras.indexOf(id) >= 0;
            const Buttons = [];

            if (about)
            {
                Buttons.push({
                    hollow: true,
                    id,
                    label: "Läs mer",
                    onClick: this.OnExtraInfo
                });
            }

            if (Selected)
            {
                CA.push("Selected");
                Buttons.push({
                    color: "gold",
                    id,
                    label: "Ta bort",
                    onClick: this.OnExtraAdd
                });
            }
            else
            {
                Buttons.push({
                    color: "black",
                    id,
                    label: "Lägg till",
                    onClick: this.OnExtraAdd
                });
            }

            Items.push(<ProductItem
                {...content}
                buttons={Buttons}
                className={CA.join(" ")}
                key={id}
            >
                <h4>{title}</h4>
                <div className="TemplateBookingExtraItemPrice">
                    {price ? `Från ${price} kr` : "Pris på förfrågan"}
                </div>
            </ProductItem>);
        });

        return (
            <div className="TemplateBookingExtra">
                <div className="TemplateBookingExtraItems">
                    {Items}
                </div>
            </div>
        );
    }

    ContentReview1 = () =>
    {
        const {values} = this.state;
        const Type = this.GetField("type", 0);
        const NoVat = !Type || parseInt(Type, 10) < 2;
        return (
            <div className="TemplateBookingReview">
                <div className="TemplateBookingReviewBefore">
                    {NoVat ? "Priser visas exklusive moms" : "Priser visas inklusive moms"}
                </div>
                <Review noVat={NoVat} values={values}/>
                <div className="TemplateBookingReviewAfter">
                    Bokningsförfrågan är ej bindande
                </div>
            </div>
        );
    }

    ContentReview2 = () =>
    {
        const {values} = this.state;
        const Type = this.GetField("type", 0);
        const NoVat = !Type || parseInt(Type, 10) < 2;
        return (
            <div className="TemplateBookingReview">
                <div className="TemplateBookingReviewBefore">
                    {NoVat ? "Priser visas exklusive moms" : "Priser visas inklusive moms"}
                </div>
                <Review noVat={NoVat} type={1} values={values}/>
                <div className="TemplateBookingReviewAfter">
                    Bokningsförfrågan är ej bindande
                </div>
            </div>
        );
    }

    ContentReview3 = () =>
    {
        const {values} = this.state;
        const Type = this.GetField("type", 0);
        const NoVat = !Type || parseInt(Type, 10) < 2;
        return (
            <div className="TemplateBookingReview">
                <div className="TemplateBookingReviewBefore">
                    {NoVat ? "Priser visas exklusive moms" : "Priser visas inklusive moms"}
                </div>
                <Review noVat={NoVat} type={2} values={values}/>
                <div className="TemplateBookingReviewAfter">
                    Bokningsförfrågan är ej bindande
                </div>
            </div>
        );
    }

    ContentRoom = () =>
    {
        const Uri = this.GetField("eatery");
        const Type = this.GetField("type", 0);
        const Location = Eatery.Eatery(Uri);
        const {eventPriceList, eventPriceListNoVat} = Location ? Location.content : {};
        const List = parseInt(Type, 10) < 2 ? eventPriceListNoVat : eventPriceList;

        if (!List)
        {
            return <div className="TemplateBookingLink Disabled">Prislista</div>;
        }

        return <Link className="TemplateBookingLink" onClick={e => this.OnPriceList(List)}>Prislista</Link>;
    }

    DateTag = (index) =>
    {
        const S = this.GetField("from", DateStamp());
        const E = this.GetField("to", S);
        const Days = DaysBetweenDates(S, E, true);

        return Days.length > 1 ? Days[index] : "";
    }

    GetDateLimit1 = () =>
    {
        return DateStamp();
    }

    GetDateLimit2 = () =>
    {
        const {values} = this.state;
        return DateStamp(values.from, true);
    }

    GetField = (key, defaultValue = "") =>
    {
        const {values} = this.state;

        if (!values || values[key] === undefined)
        {
            return defaultValue;
        }

        return values[key];
    }

    GetFoodAmount = () =>
    {
        return this.GetField("people") || 1;
    }

    GetFoodCategories = () =>
    {
        switch (this.GetField("type"))
        {
            case 0: case "0": return ["Konferens/möte"];
            default: return ["Fest/event"];
        }
    }

    GetSaveNotice = () =>
    {
        const {saveInfo} = Eatery.Option("confirmBooking", {});
        return saveInfo;
    }

    GetValue = (key, id) =>
    {
        return this.GetField(key);
    }

    ItemEatery = (eateryName, uri, selected) =>
    {
        const Location = Eatery.Eatery(uri);
        let City;

        if (Location)
        {
            City = Location.content.city;
        }

        return (
            <div className="TemplateBookingItemEatery">
                <Icon src={selected ? "Checked" : "Square"}/>
                <div className="TemplateBookingItemEateryContent">
                    {eateryName}
                    {City ? (
                        <div className="TemplateBookingItemEateryCity">
                            {City}
                        </div>
                    ) : ""}
                </div>
            </div>
        );
    }

    ItemRoom = (roomName, roomId, selected) =>
    {
        const MeetingRoom = Eatery.MeetingRoom(roomId);
        const Features = [];
        let PeopleMax, PeopleMin;
        if (MeetingRoom)
        {
            const {peopleMax, peopleMin, roomType} = MeetingRoom.content;
            PeopleMax = peopleMax;
            PeopleMin = peopleMin;
            if (roomType)
            {
                let i = 0;
                for (let key in roomType)
                {
                    Features.push(i++ ? roomType[key].toLowerCase() : roomType[key])
                };
            }
        }
        return (
            <div className="TemplateBookingItemRoom">
                <Icon src={selected ? "Checked" : "Square"}/>
                <div className="TemplateBookingItemRoomContent">
                    {roomName}
                    {Features.length ? (
                        <div className="TemplateBookingItemRoomFeatures">
                            {Features.join(", ")}
                        </div>
                    ) : ""}
                </div>
                {PeopleMax ? (
                    <div className="TemplateBookingItemRoomPeople">
                        <Icon src="People"/>
                        {PeopleMin ? `${PeopleMin}–${PeopleMax}` : `Max ${PeopleMax}`}
                    </div>
                ) : ""}
            </div>
        );
    }

    OptionsEateryRoom = () =>
    {
        const Eateries = Eatery.Eateries();
        const Options = {};

        if (!Eateries)
        {
            return Options;
        }

        const MeetingRooms = Eatery.MeetingRooms();
        const Uris = [];

        for (let id in MeetingRooms)
        {
            const Uri = MeetingRooms[id].content.eatery;

            if (Uris.indexOf(Uri) < 0)
            {
                Uris.push(Uri);
            }
        }

        for (let uri in Eateries)
        {
            if (Uris.indexOf(uri) < 0)
            {
                continue;
            }

            Options[uri] = Eateries[uri].title;
        }

        return Options;
    }

    OptionsEateryStudio = () =>
    {
        const Eateries = Eatery.Eateries();
        const Options = {};

        if (!Eateries)
        {
            return Options;
        }

        const Studios = Eatery.Studios();
        const Uris = [];

        for (let id in Studios)
        {
            const Uri = Studios[id].content.eatery;

            if (Uris.indexOf(Uri) < 0)
            {
                Uris.push(Uri);
            }
        }

        for (let uri in Eateries)
        {
            if (Uris.indexOf(uri) < 0)
            {
                continue;
            }

            Options[uri] = Eateries[uri].title;
        }

        return Options;
    }

    OptionsNumDays = () =>
    {
        const S = this.GetField("from", DateStamp());
        const E = this.GetField("to", S);

        return DaysBetweenDates(S, E);
    }

    OptionsRoom = () =>
    {
        const MeetingRooms = Eatery.MeetingRooms();
        const SelectedEatery = this.GetField("eatery");
        const Options = {};
        for (let id in MeetingRooms)
        {
            if (MeetingRooms[id].content.eatery !== SelectedEatery)
            {
                continue;
            }

            Options[id] = MeetingRooms[id].title;
        }
        return Options;
    }

    OptionsStudio = () =>
    {
        const Studios = Eatery.Studios();
        const SelectedEatery = this.GetField("eatery");
        const Options = {};

        for (let id in Studios)
        {
            if (Studios[id].content.eatery !== SelectedEatery)
            {
                continue;
            }

            Options[id] = Studios[id].title;
        }

        return Options;
    }

    OnCheck = (e, ready) =>
    {
        const {id, onCheck, type} = this.props;
        onCheck(e, ready, id, type);
    }

    OnCateringAdd = (e, id) =>
    {
        const {booking} = this.state;

        if (!booking)
        {
            return;
        }

        const Food = this.GetField("food", []);
        const Index = Food.indexOf(id);

        if (Index < 0)
        {
            Food.push(id);
        }
        else
        {
            Food.splice(Index, 1);
        }

        booking.SetParameter("food", Food);
    }

    OnCateringInfo = (e, id) =>
    {
        const Food = Eatery.Food(id);

        if (!Food)
        {
            return;
        }

        const {content, title} = Food;
        const {about, price} = content;

        Globals.Dialog(
        {
            content: (
                <div className="TemplateBookingCateringDialog">
                    <h2>{title}</h2>
                    <div className="TemplateBookingCateringDialogPrice">
                        {price ? `${price} kr/person` : "Pris på förfrågan"}
                    </div>
                    {Parser.Parse(about, "div", null, {className: "TemplateBookingCateringDialogAbout"})}
                </div>
            ),
            fillNarrow: true
        });
    }

    OnExtraAdd = (e, id) =>
    {
        const {booking} = this.state;

        if (!booking)
        {
            return;
        }

        const Extras = this.GetField("extras", []);
        const Index = Extras.indexOf(id);

        if (Index < 0)
        {
            Extras.push(id);
        }
        else
        {
            Extras.splice(Index, 1);
        }

        booking.SetParameter("extras", Extras);
    }

    OnExtraInfo = (e, id) =>
    {
        Globals.DialogActivity(id);
    }

    OnField = (e, value, key) =>
    {
        const {booking} = this.state;

        if (booking)
        {
            switch (key)
            {
                case "eatery":
                    booking.SetParameter("meetingRoom", -1);
                    break;

                default:
            }

            booking.SetParameter(key, value);
        }
    }

    OnParameter = (key, value, bookingId) =>
    {
        const {id, values} = this.state;

        if (bookingId !== id)
        {
            return;
        }

        values[key] = value;
        this.setState({values});
    }

    OnPriceList = (id) =>
    {
        Globals.DialogPriceList(id);
    }

    SetForm = () =>
    {
        const {id, onStages, type} = this.props;
        const Stages = this.Forms[type] || this.Form["konferens-fest"];
        const Defaults = {};
        const Insert = Globals.Var(`booking-${type}`);
        Stages.forEach(state =>
        {
            for (let id in state.fields)
            {
                Defaults[id] = state.fields[id].value || "";
            }
        });
        // Pre-fill if the booking process has been initiated from an item.
        if (Insert)
        {
            for (let id in Insert)
            {
                Defaults[id] = Insert[id];
            }
            Globals.Var(`booking-${type}`, false);
        }
        const Active = Booking.GetBookingByType(type, Defaults); 
        for (let id in Defaults)
        {
            if (Active.Params[id] === undefined)
            {
                Active.Params[id] = Defaults[id];
            }
        }
        const {Id, Params} = Active;
        onStages(Stages, id, type);
        this.setState({
            booking: Active,
            id: Id,
            stage: 0,
            stages: Stages,
            values: Params
        });
    }

    Submit = (callback) =>
    {
        const {id, onSending, type} = this.props;
        const {booking, sending} = this.state;
        if (!booking || sending)
        {
            return;
        }
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        onSending(true, null, id);
        this.setState({sending: true});
        Booking.Send(booking.Id, type, response =>
        {
            const {approved, error, field} = response || {};
            window.scrollTo(0, 0);
            if (!approved)
            {
                this.setState({
                    error: field || error || true,
                    sending: false
                });
            }
            else
            {
                // Create and send GTM data layer.
                let DataLayer;
                const EventDate = DateStamp(this.GetValue("from") || DateStamp(), true);
                const Location = Eatery.Eatery(this.GetValue("eatery"));
                const LocationName = Location ? Location.title : "";
                const People = parseInt(this.GetValue("people") || 0, 10);
                switch (type)
                {
                    case "catering":
                        const Food = this.GetValue("food") || [];
                        const FoodItems = [];
                        if (Array.isArray(Food))
                        {
                            Food.forEach((id) =>
                            {
                                const {title} = Eatery.Food(id) || {};
                                FoodItems.push(title || "Okänd vara");
                            });
                        }
                        DataLayer =
                        {
                            id: booking.Id,
                            date: EventDate,
                            event: "catering",
                            eventType: Eatery.EventTypes[this.GetValue("type") || 0],
                            pax: People,
                            deliveryType: this.GetValue("delivery") === "delivery" ? "Levereras till eventet" : "Hämtas hos Eatery",
                            food: FoodItems
                        };
                        break;
                    case "konferens-fest":
                        const Extras = this.GetValue("extras") || [];
                        const ExtrasItems = [];
                        if (Array.isArray(Extras))
                        {
                            Extras.forEach((id) =>
                            {
                                const {title} = Eatery.Activity(id) || {};
                                ExtrasItems.push(title || "Okänd aktivitet");
                            });
                        }
                        const Room = this.GetValue("meetingRoom") || [];
                        const RoomItems = [];
                        if (Array.isArray(Room))
                        {
                            Room.forEach((id) =>
                            {
                                const {title} = Eatery.MeetingRoom(id) || {};
                                RoomItems.push(title || "Okänt rum");
                            });
                        }
                        DataLayer =
                        {
                            id: booking.Id,
                            date: EventDate,
                            event: "konferens",
                            eventType: Eatery.EventTypes[this.GetValue("type") || 0],
                            venue: LocationName,
                            local: RoomItems.join(", "),
                            pax: People,
                            addons: ExtrasItems.join(", ")
                        };
                        break;
                    default:
                }
                if (DataLayer)
                {
                    TagManager.dataLayer({dataLayer: DataLayer});
                }
                Booking.SaveInfo(booking.Id);
                Booking.Clear(booking.Id, type);
                this.setState({
                    sending: false,
                    sent: true
                });
            }
            onSending(false, approved, id);
        });
    }

    SubmitClear = () =>
    {
        const {id, onClear, type} = this.props;
        this.setState({
            error: false,
            sent: false
        });
        onClear(id, type);
    }
    
    render()
    {
        const {className, stage} = this.props;
        const {error, sending, sent, stages, values} = this.state;
        const {
            errorMessage,
            errorTitle,
            sentMessage,
            sentTitle
        } = Eatery.Option("confirmBooking", {});
        if (!stages)
        {
            return "";
        }
        const CA = ["TemplateBookingFormContainer"];
        if (className)
        {
            CA.push(className);
        }
        if (sent)
        {
            CA.push("Sent");

            return (
                <div className={CA.join(" ")}>
                    <Confirm title={sentTitle}>
                        {Parser.Parse(sentMessage, "div", null, {className: "TemplateBookingConfirmContent"})}
                        <div className="ConfirmButtons">
                            <Button color="black" to="/">
                                Till startsidan
                            </Button>
                        </div>
                    </Confirm>
                </div>
            );
        }
        if (error)
        {
            CA.push("Error");
            return (
                <div className={CA.join(" ")}>
                    <Confirm
                        color="red"
                        icon="XBig"
                        title={errorTitle}
                    >
                        {Parser.Parse(errorMessage, "div", null, {className: "TemplateBookingConfirmContent"})}
                        <div className="ConfirmButtons">
                            <Button color="black" onClick={this.Submit}>
                                Skicka igen
                                {sending ? <Spinner/> : ""}
                            </Button>
                            <Button hollow={true} onClick={this.SubmitClear}>
                                Granska formuläret
                                <Icon src="ChevronLeftSmall"/>
                            </Button>
                        </div>
                    </Confirm>
                </div>
            );
        }
        const Stage = stages[stage] || stages[0];
        const StageLabels = [];
        const {contentOver, contentUnder, fields} = Stage;
        stages.forEach(s => StageLabels.push(s.name));    
        return (
            <div className={CA.join(" ")}>
                {contentOver ? contentOver() : ""}
                <div className="TemplateBookingFormWrapper">
                    <Form
                        disabled={sending}
                        fields={fields}
                        id={stage}
                        key={stage}
                        onChange={this.OnField}
                        onCheck={this.OnCheck}
                        values={values}
                    />
                </div>
                {contentUnder ? contentUnder() : ""}
            </div>
        );
    }
}

Forms.defaultProps =
{
    className: "",
    id: "",
    onCheck: () => {},
    onClear: () => {},
    onSending: () => {},
    onStages: () => {},
    type: "konferens-fest",
    stage: 0
};

export default Forms;