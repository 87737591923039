import React from "react";
import "./bookingstart.scss";

import Booking from "Class/Booking";
import Globals from "Class/Globals";
import Eatery from "Class/Eatery";
import {UcFirst} from "Functions";

import Button from "Components/UI/Button";
import Forms from "Components/View/Template/Booking/forms.js";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";

class BookingStart extends React.Component
{
    OnNext = () =>
    {
        const {room, stage, type} = this.props;
        const Active = Booking.GetBookingByType(type);
        const Room = Eatery.MeetingRoom(room);
        const Location = (Room && Room.content) ? Room.content.eatery : "";
        if (Active && Location)
        {
            Active.SetParameter("eatery", Location);
            Active.SetParameter("meetingRoom", [Room.uri]);
        }
        else if (Location)
        {
            Globals.Var(`booking-${type}`,
            {
                meetingRoom: [Room.uri],
                eatery: Location
            });
        }
        Globals.Trigger("redirect", `/bokning/${type}/${stage + 1}`);
    }

    render()
    {
        const {backgroundColor, className, stage, type} = this.props;
        const Color = UcFirst(backgroundColor);
        const CA = ["BookingStart", `Color${Color}`];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                <Link
                    className="BookingStartSimplified ColorBeige"
                    to={`/bokning/${type}`}
                >
                    <div className="BookingStartSimplifiedLabel">Bokningsförfrågan</div> 
                    <Icon src="Arrow"/>
                </Link>
                <div className="BookingStartDesktop">
                    <h3>Bokningsförfrågan</h3>
                    <Forms
                        stage={stage}
                        type={type}
                    />
                    <div className="Buttons">
                        <Button
                            color="Black"
                            label="Nästa"
                            onClick={this.OnNext}
                            small={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

BookingStart.defaultProps =
{
    backgroundColor: "beige",
    className: "",
    stage: 0,
    setParameters: {},
    type: "konferens-fest"
}

export default BookingStart;