import Booking from 'Class/Booking';
import Eatery from 'Class/Eatery';

import Globals from 'Class/Globals';

import Icon from 'Components/Layout/Icon';
import Segment from 'Components/Layout/Segment';
import SelectField from 'Components/UI/Field/SelectField';
import TextField from 'Components/UI/Field/TextField';
import ItemRoom from 'Components/UI/ItemRoom';
import ItemStaff from 'Components/UI/ItemStaff';
import Modules from 'Components/View/Modules';
import React from 'react';
import './conference.scss';

class TemplateConference extends React.Component {
    constructor(props) {
        super(props);

        this.state =
            {
                eatery: false,
                people: '',
                type: false,
            };
    }

    Item = (meetingroom, id) => {
        const {content, tags, title, uri} = meetingroom;
        const {
            eatery,
            image,
            imagePositionH,
            imagePositionV,
            peopleMin,
            peopleMax,
        } = content || {};
        const E = Eatery.Eatery(eatery);
        const EateryName = E ? E.title : '';

        return (
            <ItemRoom
                buttons={[
                    {hollow: true, label: 'Läs mer', to: uri},
                    {
                        color: 'Black',
                        id,
                        label: 'Bokningsförfrågan',
                        onClick: this.OnRoom,
                    },
                ]}
                className="TemplateConferenceItem"
                image={image}
                imagePositionH={imagePositionH}
                imagePositionV={imagePositionV}
                key={id}
                subTitle={EateryName}
                tags={tags}
                title={title}
                top={[
                    <Icon key="icon" src="People" />,
                    peopleMin
                        ? `${peopleMin} – ${peopleMax}`
                        : `Max ${peopleMax}`,
                ]}
            />
        );
    };

    OnField = (e, value, id) => {
        const State = {};
        State[id] = value;
        this.setState(State);
    };

    OnRoom = (e, id) => {
        const Active = Booking.GetBookingByType('konferens-fest');
        const Room = Eatery.MeetingRoom(id);
        const Location = Room ? Room.content.eatery : '';
        if (Active) {
            Active.SetParameter('eatery', Location);
            Active.SetParameter('meetingRoom', [Room.uri]);
        } else {
            Globals.Var(
                'booking-konferens-fest',
                {
                    meetingRoom: [Room.uri],
                    eatery: Location,
                },
            );
        }
        Globals.Trigger('redirect', '/bokning/konferens-fest');
    };

    render() {
        const {content} = this.props;
        const {eatery, people, type} = this.state;
        const {contents, pageTitle} = content || {};
        const Eateries = Eatery.Eateries();
        const EateryItems = {};
        const MeetingRooms = Eatery.MeetingRoom();
        const MeetingRoomsFiltered = {};
        const Staff = Eatery.Option('conferenceStaff', []);
        const Tags = Eatery.MeetingRoomTags();

        for (let id in MeetingRooms) {
            const {eatery} = MeetingRooms[id].content;

            if (MeetingRoomsFiltered[eatery] === undefined) {
                MeetingRoomsFiltered[eatery] = [];
            }

            MeetingRoomsFiltered[eatery].push(MeetingRooms[id]);
        }

        for (let uri in Eateries) {
            if (MeetingRoomsFiltered[uri] === undefined) {
                continue;
            }

            const {title} = Eateries[uri];
            EateryItems[uri] = title;
        }

        const FilterFrom = eatery
            ? MeetingRoomsFiltered[eatery] || []
            : MeetingRooms;
        const RoomList = Object.values(FilterFrom);
        const Items = [];

        RoomList.sort((a, b) => {
            const AP = a.content.priority || 0;
            const BP = b.content.priority || 0;
            if ((AP === undefined && BP !== undefined) || AP < BP) {
                return 1;
            }
            if ((BP === undefined && AP !== undefined) || AP > BP) {
                return -1;
            }
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });

        RoomList.forEach(room => {
            const {content, id, tags} = room;
            const {peopleMax, peopleMin} = content;

            if ((people && (people < peopleMin || people > peopleMax)) ||
                (type && tags.indexOf(Tags[type]) < 0)) {
                return;
            }

            Items.push(this.Item(room, id));
        });

        const StaffItems = [];

        if (Staff && Staff.length) {
            Staff.forEach(id => {
                StaffItems.push(<ItemStaff id={id} key={id} />);
            });
        }

        return (
            <div className="TemplateConference">
                <Segment backgroundColor="Beige" top={true}>
                    <div className="TemplateConferenceTop">
                        <h3>Konferens & fest</h3>
                        <h1>{pageTitle}</h1>
                    </div>
                    <div className="TemplateConferenceFieldsWrapper">
                        <div className="TemplateConferenceFields">
                            <div className="TemplateConferenceField notranslate">
                                <SelectField
                                    id="eatery"
                                    label="Anläggning"
                                    onChange={this.OnField}
                                    options={EateryItems} placeholder="Alla"
                                    value={eatery}
                                />
                            </div>
                            <div className="TemplateConferenceField">
                                <SelectField
                                    id="type"
                                    label="Typ av lokal"
                                    onChange={this.OnField}
                                    options={Tags}
                                    placeholder="Alla"
                                    value={type}
                                />
                            </div>
                            <div className="TemplateConferenceField">
                                <TextField
                                    id="people"
                                    label="Antal personer"
                                    onChange={this.OnField}
                                    onInput={this.OnField}
                                    placeholder="Ange"
                                    type="number"
                                    value={people}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="TemplateConferenceItemsWrapper">
                        <div className="TemplateConferenceItems">
                            {Items.length ? (
                                <div className="TemplateConferenceItemsCount">
                                    {`Visar ${Items.length} av ${Items.length}`}
                                </div>
                            ) : ''}
                            {Items.length ? Items : (
                                <div className="TemplateConferenceEmpty">
                                    Inga mötesrum matchar dina sökfilter
                                </div>
                            )}
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <div className="TemplateConferenceStaffItemsWrapper">
                        <div className="TemplateConferenceStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={2} />
            </div>
        );
    }
}

TemplateConference.defaultProps =
    {
        content: {},
    };

export default TemplateConference;
