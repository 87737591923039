import React from "react";
import "./newsletter.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import CheckboxField from "Components/UI/Field/CheckboxField";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import TextField from "Components/UI/Field/TextField";

class Newsletter extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            email: "",
            error: false,
            ready: false,
            sending: false,
            sent: false,
            terms: false
        }
    }

    componentDidMount()
    {
        this.Check();
    }

    Check = () =>
    {
        const {email, terms} = this.state;
        let Ready = !!terms;

        if (!email || !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            Ready = false;
        }

        this.setState({ready: Ready});
    }

    OnField = (e, value, id) =>
    {
        const State = {};
        State[id] = value;
        this.setState(State, this.Check);
    }

    Reset = () =>
    {
        this.setState({
            email: "",
            error: false,
            ready: false,
            sending: false,
            sent: false,
            terms: false
        });
    }

    Submit = () =>
    {
        const {email} = this.state;
        const {uri} = Eatery.GetEatery() || {};

        this.setState({
            error: false,
            sending: true
        });

        Eatery.Request("newsletter", {email, eatery: uri}, response =>
        {
            const {subscribed} = response || {};

            this.setState({
                error: !subscribed,
                sending: false,
                sent: subscribed
            });
        });
    }

    render()
    {
        const {
            email,
            ready,
            sending,
            sent,
            terms
        } = this.state;
        const {confirmTitle, confirmMessage} = Eatery.Option("confirmNewsletter", {});

        return sent ? (
            <div className="Newsletter">
                <div className="NewsletterConfirm">
                    <h3>{confirmTitle}</h3>
                    <Icon src="CheckBig"/>
                    {Parser.ParseParagraphs(confirmMessage)}
                    <Button
                        label="Tillbaka"
                        onClick={this.Reset}
                        small={true}
                    />
                </div>
            </div>
        ) : (
            <div className="Newsletter">
                <h4>Nyhetsbrev</h4>
                <div className="NewsLetterFields">
                    <TextField
                        disabled={sending}
                        hollow={true}
                        id="email"
                        onChange={this.OnField}
                        onInput={this.OnField}
                        placeholder="E-post"
                        type="email"
                        value={email}
                    />
                    <div className="NewsletterTray">
                        <div className="NewsletterTrayLeft">
                            <CheckboxField
                                disabled={sending}
                                id="terms"
                                onChange={this.OnField}
                                value={terms}
                            >
                                Jag godkänner <Link href={Eatery.Option("privacyPolicy")}>integritetspolicyn</Link>.
                            </CheckboxField>
                        </div><div className="NewsletterTrayRight">
                            <Button
                                disabled={!ready || sending}
                                label="Skicka"
                                onClick={this.Submit}
                                small={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Newsletter;