import React from "react";

class Item extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Node = false;
    }

    Focus = () =>
    {
        if (this.Node)
        {
            this.Node.focus();
        }
    }

    OnBlur = (e) =>
    {
        const {id, onBlur} = this.props;

        onBlur(e, id);
    }

    OnClick = (e) =>
    {
        const {disabled, id, onClick} = this.props;

        if (disabled)
        {
            return;
        }

        onClick(e, id);
    }

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;

        onFocus(e, id);
    }

    OnKeyDown = (e) =>
    {
        const {disabled, id, onClick, onKeyDown} = this.props;

        if (disabled)
        {
            return;
        }

        if (e.which === 13)
        {
            onClick(e, id);
        }

        onKeyDown(e, id);
    }

    OnKeyUp = (e) =>
    {
        const {disabled, id, onKeyUp} = this.props;

        if (disabled)
        {
            return;
        }

        onKeyUp(e, id);
    }

    OnMouseDown = (e) =>
    {
        const {disabled, id, onMouseDown} = this.props;

        if (disabled)
        {
            return;
        }

        onMouseDown(e, id);
    }

    OnMouseUp = (e) =>
    {
        const {disabled, id, onMouseUp} = this.props;

        if (disabled)
        {
            return;
        }

        onMouseUp(e, id);
    }

    render()
    {
        const {allowFocus, children, className, disabled, label, tabIndex, title} = this.props;
        const CA = ["Item"];

        if (disabled)
        {
            CA.push("Disabled");
        }
        if (className)
        {
            CA.push(className);
        }

        return (
            <div
                className={CA.join(" ")}
                onBlur={this.OnBlur}
                onClick={this.OnClick}
                onFocus={this.OnFocus}
                onKeyDown={this.OnKeyDown}
                onKeyUp={this.OnKeyUp}
                onMouseDown={this.OnMouseDown}
                onMouseUp={this.OnMouseUp}
                ref={node => this.Node = node}
                tabIndex={(allowFocus && !disabled) ? tabIndex : "false"}
                title={title}
            >
                {label || children}
            </div>
        );
    }
}

Item.defaultProps =
{
    allowFocus: true,
    className: "",
    disabled: false,
    id: "",
    label: "",
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    onMouseDown: () => {},
    onMouseUp: () => {},
    tabIndex: 0,
    title: ""
};

export default Item;