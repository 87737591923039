
import React from "react";
import "./activity.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";
import Tag from "Components/UI/Tag";

class Activity extends React.Component
{
    render()
    {
        const {className, id} = this.props;
        const Activity = Eatery.Activity(id);

        if (!Activity)
        {
            return "";
        }

        const {content: postContent, title} = Activity;
        const {about, locations, price} = postContent;
        const CA = ["Activity"];
        const Tags = [];

        if (className)
        {
            CA.push(className);
        }

        if (Array.isArray(locations))
        {
            locations.forEach(uri =>
            {
                const Location = Eatery.Eatery(uri);

                if (!Location)
                {
                    return;
                }

                Tags.push(<Tag key={uri} label={Location.title}/>);
            })
        }

        return (
            <div className={CA.join(" ")}>
                <h2>{title}</h2>
                <div className="ActivityPrice">
                    {price ? `Från ${price} kr` : "Pris på förfrågan"}
                </div>
                {Tags.length ? (
                    <div className="ActivityTags">{Tags}</div>
                ) : ""}
                {Parser.Parse(about, "div", null, {className: "ActivityAbout"})}
            </div>
        );
    }
}

Activity.defaultProps =
{
    className: "",
    id: 0
};

export default Activity;