import React from "react";
import "./mainnavigation.scss";

import Eatery from "Class/Eatery";
import Navigation from "Components/UI/Navigation";

class MainNavigation extends React.Component
{
    render()
    {
        const {className, vertical} = this.props;
        const CA = ["MainNavigation"];

        if (vertical)
        {
            CA.push("Vertical");
        }
        else
        {
            CA.push("Horizontal");
        }

        if (className)
        {
            CA.push(className);
        }

        return (
            <Navigation
                className={CA.join(" ")}
                collapsables={vertical}
                items={Eatery.Option("headerNavigation", [])}
                iconCollapse="ChevronDownSmall"
                iconExpand="ChevronDownSmall"
            />
        );
    }
}

MainNavigation.defaultProps =
{
    className: "",
    vertical: false
};

export default MainNavigation;