import React from "react";

import Eatery from "Class/Eatery";
import {DaysBetweenDates, DateStamp, NiceNumber} from "Functions";

class Review extends React.Component
{
    GetField = (key, defaultValue = "") =>
    {
        const {values} = this.props;

        if (!values || values[key] === undefined)
        {
            return defaultValue;
        }

        return values[key];
    }

    RenderCatering = () =>
    {
        const {className, noVat} = this.props;
        const Food = this.GetField("food", []);
        const From = this.GetField("from", DateStamp());
        const Times = this.GetField("time", []);
        const To = this.GetField("to", From);
        const People = this.GetField("people", 0);
        const Days = DaysBetweenDates(From, To, true, 2);
        const FoodItems = [];
        const FromFormatted = DateStamp(From, true, 1);
        const ToFormatted = DateStamp(To, true, 1);
        const TimeItems = [];

        Days.forEach((day, index) =>
        {
            TimeItems.push(
                <div className="TemplateBookingReviewRow" key={index}>
                    <div className="TemplateBookingReviewCell">Kl ({day})</div>
                    <div className="TemplateBookingReviewCell">{Times[index] ? Times[index].time || "Ej angivet" : "Ej angivet"}</div>
                </div>
            );
        });
        Food.forEach((id, index) =>
        {
            const {content, title} = Eatery.Food(id) || {};
            const {price, vatRate} = content || {};
            const VatRate = typeof vatRate === "number" ? vatRate : 25;
            const Price = noVat ? price : price * (1 + VatRate / 100);
            FoodItems.push(
                <div className="TemplateBookingReviewRow" key={id}>
                    <div className="TemplateBookingReviewCell">Val {index + 1}</div>
                    <div className="TemplateBookingReviewCell">{title}</div>
                    <div className="TemplateBookingReviewCell">{price ? `${NiceNumber(Price * (People || 1))} kr` : "Pris på förfrågan"}</div>
                </div>
            );
        });
        return (
            <div className={className ? `TemplateBookingReviewTable ${className}` : "TemplateBookingReviewTable"}>
                <div className="TemplateBookingReviewStage">
                    <h3>1. Detaljer</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Datum</div>
                        <div className="TemplateBookingReviewCell">{FromFormatted !== ToFormatted ? `${FromFormatted}-${ToFormatted}` : FromFormatted}</div>
                    </div>
                    {TimeItems}
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Eventets adress</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("address")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Typ av event</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("type")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Antal personer</div>
                        <div className="TemplateBookingReviewCell">{People || "Okänt"}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Leverans</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("delivery") === "delivery" ? "Levereras till eventet" : "Hämtas hos Eatery"}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>2. Mat & dryck</h3>
                    {FoodItems}
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>3. Övrigt</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Meddelande</div>
                        <div className="TemplateBookingReviewCell TextCell">{this.GetField("specialDiets")}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>4. Din info</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Namn</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("name")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Företag</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("company")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">E-post</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("email")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Telefonnummer</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("phone")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Referensavtal</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("reference")}</div>
                    </div>
                </div>
            </div>
        );
    } 

    RenderEvent = () =>
    {
        const {className, noVat} = this.props;
        const Extras = this.GetField("extras", []);
        const Food = this.GetField("food", []);
        const From = this.GetField("from", DateStamp());
        const Location = Eatery.Eatery(this.GetField("eatery"));
        const Room = this.GetField("meetingRoom", []);
        const Times = this.GetField("time", []);
        const To = this.GetField("to", From);
        const Days = DaysBetweenDates(From, To, true, 2);
        const ExtrasItems = [];
        const FoodItems = [];
        const FromFormatted = DateStamp(From, true, 1);
        const LocationName = Location ? Location.title : "Ingen vald";
        const RoomItems = [];
        const ToFormatted = DateStamp(To, true, 1);
        const TimeItems = [];
        Days.forEach((day, index) =>
        {
            const FoodDay = typeof Food[index] === "object" ? Food[index].food || [] : [];
            const FoodSubItems = [];
            const FoodPriceItems = [];
            let [O, T1, T2] = (Times[index] && typeof Times[index] === "object" && Array.isArray(Times[index].time)) ? Times[index].time : [];
            FoodDay.forEach(([id, people]) =>
            {
                const {content, title} = Eatery.Food(id) || {};
                const {price, vatRate} = content || {};
                const VatRate = typeof vatRate === "number" ? vatRate : 25;
                const Price = noVat ? price : price * (1 + VatRate / 100);
                FoodSubItems.push(<div key={id}>{title}, {people} pers</div>);
                FoodPriceItems.push(<div key={id}>{price ? NiceNumber(Price * people) + " kr" : "Pris på förfrågan"}</div>);
            });
            FoodItems.push(
                <div className="TemplateBookingReviewRow" key={day}>
                    <div className="TemplateBookingReviewCell">{day}</div>
                    <div className="TemplateBookingReviewCell">{FoodSubItems.length ? FoodSubItems : "Ingen mat eller dryck"}</div>
                    <div className="TemplateBookingReviewCell">{FoodPriceItems}</div>
                </div>
            );
            switch (O)
            {
                case 0: O = "Förmiddag"; T1 = "08:00"; T2 = "12:00"; break;
                case 1: O = "Eftermiddag"; T1 = "13:00"; T2 = "17:00"; break;
                case 3: O = "Kväll"; break;
                case 4: O = ""; break;
                default: O = "Heldag"; T1 = "08:00"; T2 = "17:00";
            }
            TimeItems.push(
                <div className="TemplateBookingReviewRow" key={day}>
                    <div className="TemplateBookingReviewCell">Tid {day}</div>
                    <div className="TemplateBookingReviewCell">{O ? `${O}, ` : ""}{T1}-{T2}</div>
                </div>
            );
        });
        Extras.forEach((id, index) =>
        {
            const {content, title} = Eatery.Activity(id) || {};
            const {price, vatRate} = content || {};
            const VatRate = typeof vatRate === "number" ? vatRate : 25;
            const Price = noVat ? price : price * (1 + VatRate / 100);   
            ExtrasItems.push(
                <div className="TemplateBookingReviewRow" key={id}>
                    <div className="TemplateBookingReviewCell">Tillval {index + 1}</div>
                    <div className="TemplateBookingReviewCell">{title}</div>
                    <div className="TemplateBookingReviewCell">{price ? `Från ${NiceNumber(Price)} kr` : "Pris på förfrågan"}</div>
                </div>
            );
        });
        if (Array.isArray(Room))
        {
            Room.forEach((id) =>
            {
                const {title} = Eatery.MeetingRoom(id) || {};
                RoomItems.push(title || "Okänt rum");
            });
        }
        else
        {
            RoomItems.push("Hjälp mig ta fram ett förslag");
        }
        return (
            <div className={className ? `TemplateBookingReviewTable ${className}` : "TemplateBookingReviewTable"}>
                <div className="TemplateBookingReviewStage">
                    <h3>1. Detaljer</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Datum</div>
                        <div className="TemplateBookingReviewCell">{FromFormatted !== ToFormatted ? `${FromFormatted}–${ToFormatted}` : FromFormatted}</div>
                    </div>
                    {TimeItems}
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Typ av event</div>
                        <div className="TemplateBookingReviewCell">{Eatery.EventTypes[this.GetField("type", 0)] || "Okänt"}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Antal personer</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("people", "Okänt")}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>2. Lokal</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Anläggning</div>
                        <div className="TemplateBookingReviewCell">{LocationName}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Rum</div>
                        <div className="TemplateBookingReviewCell">{RoomItems.join(", ")}</div>
                        <div className="TemplateBookingReviewCell">Pris på förfrågan</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>3. Mat & fika</h3>
                    {FoodItems}
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Kommentar</div>
                        <div className="TemplateBookingReviewCell TextCell">{this.GetField("specialDiets")}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>4. Tillval</h3>
                    {ExtrasItems}
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>5. Din info</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Namn</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("name")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Företag</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("company")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">E-post</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("email")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Telefonnummer</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("phone")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Referensavtal</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("reference")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Meddelande</div>
                        <div className="TemplateBookingReviewCell TextCell">{this.GetField("message")}</div>
                    </div>
                </div>
            </div>
        );
    }

    RenderStudio = () =>
    {
        const {className, noVat} = this.props;
        const Extras = this.GetField("extras", []);
        const Food = this.GetField("food", []);
        const From = this.GetField("from", DateStamp());
        const Location = Eatery.Eatery(this.GetField("eatery"));
        const StudioId = this.GetField("studio");
        const Times = this.GetField("time", []);
        const To = this.GetField("to", From);
        const Days = DaysBetweenDates(From, To, true, 2);
        const ExtrasItems = [];
        const FoodItems = [];
        const FromFormatted = DateStamp(From, true, 1);
        const LocationName = Location ? Location.title : "Ingen vald";
        const Studio = StudioId ? Eatery.Studio(StudioId) : false;
        const ToFormatted = DateStamp(To, true, 1);
        const TimeItems = [];
        Days.forEach((day, index) =>
        {
            const FoodDay = (Food[index] && typeof Food[index] === "object" && Array.isArray(Food[index].food)) ? Food[index].food : [];
            const FoodSubItems = [];
            const FoodPriceItems = [];
            let [O, T1, T2] = (Times[index] && typeof Times[index] === "object" && Array.isArray(Times[index].time)) ? Times[index].time : [];
            FoodDay.forEach(([id, people]) =>
            {
                const {content, title} = Eatery.Food(id) || {};
                const {price, vatRate} = content || {};
                const VatRate = typeof vatRate === "number" ? vatRate : 25;
                const Price = noVat ? price : price * (1 + VatRate / 100);

                FoodSubItems.push(<div key={id}>{title}, {people} pers</div>);
                FoodPriceItems.push(<div key={id}>{price ? NiceNumber(Price * people) + " kr" : "Pris på förfrågan"}</div>);
            });
            FoodItems.push(
                <div className="TemplateBookingReviewRow" key={day}>
                    <div className="TemplateBookingReviewCell">{day}</div>
                    <div className="TemplateBookingReviewCell">{FoodSubItems.length ? FoodSubItems : "Ingen mat eller dryck"}</div>
                    <div className="TemplateBookingReviewCell">{FoodPriceItems}</div>
                </div>
            );
            switch (O)
            {
                case 0: O = "Förmiddag"; T1 = "08:00"; T2 = "12:00"; break;
                case 1: O = "Eftermiddag"; T1 = "13:00"; T2 = "17:00"; break;
                case 3: O = "Kväll"; break;
                case 4: O = ""; break;
                default: O = "Heldag"; T1 = "08:00"; T2 = "17:00";
            }
            TimeItems.push(
                <div className="TemplateBookingReviewRow" key={day}>
                    <div className="TemplateBookingReviewCell">Tid {day}</div>
                    <div className="TemplateBookingReviewCell">{O ? `${O}, ` : ""}{T1}-{T2}</div>
                </div>
            );
        });
        Extras.forEach((id, index) =>
        {
            const {content, title} = Eatery.Activity(id) || {};
            const {price, vatRate} = content || {};
            const VatRate = typeof vatRate === "number" ? vatRate : 25;
            const Price = noVat ? price : price * (1 + VatRate / 100);
            ExtrasItems.push(
                <div className="TemplateBookingReviewRow" key={id}>
                    <div className="TemplateBookingReviewCell">Tillval {index + 1}</div>
                    <div className="TemplateBookingReviewCell">{title}</div>
                    <div className="TemplateBookingReviewCell">{price ? `Från ${NiceNumber(Price)} kr` : "Pris på förfrågan"}</div>
                </div>
            );
        });
        return (
            <div className={className ? `TemplateBookingReviewTable ${className}` : "TemplateBookingReviewTable"}>
                <div className="TemplateBookingReviewStage">
                    <h3>1. Detaljer</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Datum</div>
                        <div className="TemplateBookingReviewCell">{FromFormatted !== ToFormatted ? `${FromFormatted}–${ToFormatted}` : FromFormatted}</div>
                    </div>
                    {TimeItems}
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Typ av event</div>
                        <div className="TemplateBookingReviewCell">{Eatery.EventTypes[this.GetField("type", 0)] || "Okänt"}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Antal personer</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("people", "Okänt")}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>2. Lokal</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Anläggning</div>
                        <div className="TemplateBookingReviewCell">{LocationName}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Studio</div>
                        <div className="TemplateBookingReviewCell">{Studio ? Studio.title : "Hjälp mig ta fram ett förslag"}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>3. Mat & fika</h3>
                    {FoodItems}
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Kommentar</div>
                        <div className="TemplateBookingReviewCell TextCell">{this.GetField("specialDiets")}</div>
                    </div>
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>4. Tillval</h3>
                    {ExtrasItems}
                </div>
                <div className="TemplateBookingReviewStage">
                    <h3>5. Din info</h3>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Namn</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("name")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Företag</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("company")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">E-post</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("email")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Telefonnummer</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("phone")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Referensavtal</div>
                        <div className="TemplateBookingReviewCell">{this.GetField("reference")}</div>
                    </div>
                    <div className="TemplateBookingReviewRow">
                        <div className="TemplateBookingReviewCell">Meddelande</div>
                        <div className="TemplateBookingReviewCell TextCell">{this.GetField("message")}</div>
                    </div>
                </div>
            </div>
        );
    }

    render()
    {
        switch (this.props.type)
        {
            case 1:
                return this.RenderCatering();

            case 2:
                return this.RenderStudio();

            default:
                return this.RenderEvent()
        }
    }
}

Review.defaultProps =
{
    className: "",
    noVat: true,
    type: 0,
    values: {}
};

export default Review;