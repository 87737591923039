import React from "react";
import "./digitalmeetings.scss";

import Booking from "Class/Booking";
import Eatery from "Class/Eatery";
import Globals from "Class/Globals";

import FixedButton from "Components/UI/FixedButton";
import ItemRoom from "Components/UI/ItemRoom";
import ItemStaff from "Components/UI/ItemStaff";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";

class TemplateDigitalMeetings extends React.Component
{
    Item = (studio, id) =>
    {
        const {content, tags, title, uri} = studio;
        const {
            eatery,
            image,
            imagePositionH,
            imagePositionV
        } = content || {};
        const E = Eatery.Eatery(eatery);
        const EateryCity = E ? E.content.city : "";

        return (
            <ItemRoom
                buttons={[
                    {hollow: true, label: "Läs mer", to: uri},
                    {color: "Black", id, label: "Bokningsförfrågan", onClick: this.OnStudio}
                ]}
                className="DigitalMeetingItem"
                image={image}
                imagePositionH={imagePositionH}
                imagePositionV={imagePositionV}
                key={id}
                subTitle={EateryCity}
                tags={tags}
                title={title}
            />
        );
    }

    OnStudio = (e, id) =>
    {
        const Active = Booking.GetBookingByType("studio");
        const Studio = Eatery.Studio(id);
        const Location = Studio ? Studio.content.eatery : "";

        if (Active)
        {
            Active.SetParameter("eatery", Location);
            Active.SetParameter("studio", id);
        }
        else
        {
            Globals.Var("booking-studio",
            {
                eatery: Location,
                studio: id
            });
        }

        Globals.Trigger("redirect", "/bokning/studio");
    }

    render()
    {
        const {content} = this.props;
        const {contents} = content || {};
        const Staff = Eatery.Option("studioStaff", []);
        const StaffItems = [];
        const Studios = Eatery.Studios();
        const Items = [];

        for (let id in Studios)
        {
            Items.push(this.Item(Studios[id], id));
        }

        if (Staff && Staff.length)
        {
            Staff.forEach(id =>
            {
                StaffItems.push(<ItemStaff id={id} key={id}/>);
            });
        }

        return (
            <div className="TemplateDigitalMeetings">
                <Modules modules={contents}/>
                <Segment backgroundColor="Beige">
                    <div className="TemplateDigitalMeetingsItemsWrapper">
                        <h2>Våra studios</h2>
                        <div className="TemplateDigitalMeetingsItems">
                            {Items}
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <div className="TemplateDigitalMeetingsStaffItemsWrapper">
                        <div className="TemplateDigitalMeetingsStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
                <FixedButton
                    label="Till bokning"
                    to="/bokning/studio"
                />
            </div>
        );
    }
}

TemplateDigitalMeetings.defaultProps =
{
    content: {}
};

export default TemplateDigitalMeetings;