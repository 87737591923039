import React from "react";
import "./fixedbutton.scss";

import {UcFirst} from "Functions";
import Item from "Components/UI/Item";
import Link from "Components/UI/Link";

class FixedButton extends React.Component
{
    OnClick = (e) =>
    {
        const {disabled, id, onClick} = this.props;

        if (disabled || typeof onClick !== "function")
        {
            return;
        }

        onClick(e, id);
        
    }

    render()
    {
        const {
            children,
            className,
            color,
            disabled,
            hollow,
            href,
            label,
            target,
            title,
            to
        } = this.props;
        const CA = ["FixedButton"];
        const Content = [label || children];

        if (disabled)
        {
            CA.push("Disabled");
        }

        if (hollow)
        {
            CA.push("Hollow");
        }
        else
        {
            const Color = UcFirst(color);
            CA.push(`Color${Color}`)
        }

        if (className)
        {
            CA.push(className);
        }

        if (href || to)
        {
            return (
                <Link
                    className={CA.join(" ")}
                    href={href}
                    target={target}
                    title={title}
                    to={to}
                >{Content}</Link>
            );
        }
        
        return (
            <Item
                allowFocus={!disabled}
                className={CA.join(" ")}
                disabled={disabled}
                onClick={this.OnClick}
                onKeyDown={this.OnKeyDown}
                title={title}
            >{Content}</Item>
        );
    }
}

FixedButton.defaultProps =
{
    className: "",
    color: "Gold",
    disabled: false,
    href: "",
    id: "",
    label: "",
    onClick: false,
    target: "_blank",
    title: "",
    to: ""
};

export default FixedButton;