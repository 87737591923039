import React from "react";
import "./icon.scss";

class Icon extends React.Component
{
    Svg = () =>
    {
        switch (this.props.src.toLowerCase())
        {
            case "accessible":
                return (
                    <svg name="Accessible" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M7.544,25.2l-2.16-.61-.41,1.44,3.61,1.02,2.22-7.88,1.29-.01a8.623,8.623,0,0,1,.53-1.5l-2.96.01Z" fill="currentColor"/>
                        <path d="M15.7,14.176c.01-.01.02-.01.03-.02a8.171,8.171,0,0,1,1.57-.77l.08-.65a1.011,1.011,0,0,1,.99-.88c.04,0,.09.01.13.01a1,1,0,0,1,.87,1.07c.23-.02.48-.03.72-.03a6.672,6.672,0,0,1,.78.04,2.5,2.5,0,0,0-2.19-2.57c-.11-.01-.21-.02-.31-.02a2.493,2.493,0,0,0-2.47,2.2l-.19,1.6h-5.12v1.5h3.36a8.273,8.273,0,0,1,1.75-1.48Z" fill="currentColor"/>
                        <path d="M20.094,14.406a6.75,6.75,0,1,0,6.75,6.75,6.75,6.75,0,0,0-6.75-6.75Zm0,12a5.25,5.25,0,1,1,5.25-5.25A5.25,5.25,0,0,1,20.094,26.406Z" fill="currentColor"/>
                        <path d="M18.158,5.405a1,1,0,1,1-1,1,1,1,0,0,1,1-1m0-1.5a2.5,2.5,0,1,0,2.5,2.5,2.5,2.5,0,0,0-2.5-2.5Z" fill="currentColor"/>
                    </svg>
                );

            case "alcohol":
                return (
                    <svg name="Alcohol" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M11.116,13.259a4.96,4.96,0,0,0,4.812,4.812,4.812,4.812,0,0,0,4.812-4.812,4.291,4.291,0,0,0-.24-1.5L18.333,5.741H13.521l-2.163,6.014a3.785,3.785,0,0,0-.243,1.5Z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
                        <line y2="8.12" transform="translate(15.927 18.07)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
                        <line x1="8.42" transform="translate(11.717 26.19)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
                        <line x2="9.623" transform="translate(11.116 13.759)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
                    </svg>
                );

            case "area":
                return (
                    <svg name="Area" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M24.173,20.151v-8.5h2.461v-6.5h-6.5v2.5H11.66v-2.5H5.177v6.483h2.5v8.512h-2.5v6.493h6.492V23.862h8.469v2.793h6.5v-6.5Zm-2.534-13.5h3.5v3.5h-3.5Zm-14.962,0H10.16v3.483H6.677Zm3.488,18.488H6.673V21.651h3.492Zm1.5-2.781V20.151H9.173V11.639H11.66V9.151h8.479v2.5h2.534v8.5H20.134v2.212Zm13.474,2.793h-3.5v-3.5h3.5Z" fill="currentColor"/>
                    </svg>
                );

            case "arrow":
                return (
                    <svg name="Arrow" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M18.949,6.873,17.888,7.934l7.237,7.236-21.2.011v1.5l21.144-.011-7.206,7.207,1.061,1.061,9.044-9.046Z" fill="currentColor"/>
                    </svg>
                );

            case "buildingslarge":
                return (
                    <svg name="BuildingsLarge" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M24.849,12.005l0,11.5a2,2,0,0,1-2,2h-1.5v1.5h1.6a3.4,3.4,0,0,0,3.4-3.4v-13.1h-5v1.5Z" fill="currentColor"/>
                        <path d="M4.882,23.605a3.4,3.4,0,0,0,3.4,3.4h8.131a3.4,3.4,0,0,0,3.4-3.4V5.005H4.882Zm1.466-17.1h12v17.3a1.7,1.7,0,0,1-1.7,1.7h-8.6a1.7,1.7,0,0,1-1.7-1.7Z" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(7.848 8.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(11.348 8.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(14.848 8.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(7.81 11.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(11.31 11.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(14.81 11.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(7.848 15.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(11.348 15.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(14.848 15.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.366 13.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.329 17.005)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.366 20.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(7.81 18.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(11.31 18.505)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(14.81 18.505)" fill="currentColor"/>
                    </svg>
                );

            case "buildingsmid":
                return (
                    <svg name="BuildingsMid" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <rect width="2" height="2" transform="translate(14.966 7.927)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(18.466 7.927)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.966 7.927)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(14.929 11.427)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(18.429 11.427)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(7.973 19.887)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(11.355 19.909)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(14.942 19.915)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.929 11.427)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.966 14.927)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(21.929 18.427)" fill="currentColor"/>
                        <path d="M18.435,18.416v6.948h-12V18.416h12m1.5-1.5h-15v9.948h15Z" fill="currentColor"/>
                        <path d="M12.709,15.4V5.632h13.48V22.99a3.127,3.127,0,0,1-3.073,3.179H21.394" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
                    </svg>
                );

            case "bus":
                return (
                    <svg name="Bus" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M6.405,8.308v16.1h19V8.308a3.4,3.4,0,0,0-3.4-3.4H9.805A3.4,3.4,0,0,0,6.405,8.308Zm17.5,14.6h-16V8.108a1.7,1.7,0,0,1,1.7-1.7h12.6a1.7,1.7,0,0,1,1.7,1.7Z" fill="currentColor"/>
                        <rect width="1.501" height="2.5" transform="translate(10.902 24.403)" fill="currentColor"/>
                        <rect width="1.501" height="2.5" transform="translate(19.41 24.401)" fill="currentColor"/>
                        <path d="M12.326,20.157a1.25,1.25,0,1,1-2.5,0,1.186,1.186,0,0,1,.453-.943,1.153,1.153,0,0,1,.792-.3,1.224,1.224,0,0,1,.8.3,1.2,1.2,0,0,1,.453.943Z" fill="currentColor"/>
                        <path d="M21.905,20.141a1.25,1.25,0,0,1-2.5,0,1.186,1.186,0,0,1,.453-.943,1.153,1.153,0,0,1,.792-.3,1.224,1.224,0,0,1,.8.3,1.2,1.2,0,0,1,.453.943Z" fill="currentColor"/>
                        <rect width="16" height="1.5" transform="translate(7.905 8.408)" fill="currentColor"/>
                        <rect width="16.196" height="1.5" transform="translate(7.842 15.908)" fill="currentColor"/>
                    </svg>
                );

            case "card":
                return (
                    <svg name="Card" xmlns="http://www.w3.org/2000/svg" width="32.007" height="31.705" viewBox="0 0 32.007 31.705">
                        <path d="M27.459,3.869H4.548A4.548,4.548,0,0,0,0,8.417V23.311a4.548,4.548,0,0,0,4.548,4.548H27.459a4.548,4.548,0,0,0,4.547-4.547V8.417a4.548,4.548,0,0,0-4.547-4.548ZM4.548,5.369H27.459a3.051,3.051,0,0,1,3.047,3.048v1.666H1.5V8.417A3.051,3.051,0,0,1,4.548,5.369ZM27.459,26.358H4.548A3.051,3.051,0,0,1,1.5,23.311V14.083H30.507v9.228a3.051,3.051,0,0,1-3.047,3.048Z" fill="currentColor"/>
                    </svg>
                );

            case "check":
                return (
                    <svg name="Check" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" fill="currentColor"/>
                    </svg>
                );

            case "checkbig":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
                        <path d="M72.735,9.6,27.422,54.729,4.942,32.523l3.169-3.229L27.392,48.361,69.566,6.4Z" transform="translate(9.972 12.91)" fill="currentColor"/>
                    </svg>
                );

            case "checked":
                return (
                    <svg name="Checked" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M22.359,20.725a1.7,1.7,0,0,1-1.7,1.7h-9.52a1.7,1.7,0,0,1-1.7-1.7v-9.58a1.7,1.7,0,0,1,1.7-1.7h10l1.51-1.5H11.339a3.4,3.4,0,0,0-3.4,3.4v9.18a3.4,3.4,0,0,0,3.4,3.4h9.12a3.4,3.4,0,0,0,3.4-3.4V13.1l-1.5,1.49Z" fill="currentColor"/>
                        <path d="M25.859,6.885l-9.97,9.9-3.48-3.41-1.05,1.07L15.9,18.9l11.01-10.95Z" fill="currentColor"/>
                    </svg>
                );

            case "chevrondown":
                return (
                    <svg name="ChevronDown" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M15.924,21.027,7.615,12.718l1.061-1.061,7.248,7.248L23.133,11.7l1.061,1.061Z" fill="currentColor"/>
                    </svg>
                );

            case "chevrondownsmall":
                return (
                    <svg name="ChevronDownSmall" xmlns="http://www.w3.org/2000/svg" width="31.8" height="31.8" viewBox="0 0 31.8 31.8">
                        <path d="M15.9,19.1l-4.4-4.4,1.1-1.1,3.3,3.3,3.3-3.3,1.1,1.1Z" fill="currentColor"/>
                    </svg>
                );

            case "chevronleft":
                return (
                    <svg name="ChevronLeft" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811" style={{transform: "rotate(90deg)"}}>
                        <path d="M15.924,21.027,7.615,12.718l1.061-1.061,7.248,7.248L23.133,11.7l1.061,1.061Z" fill="currentColor"/>
                    </svg>
                );

            case "chevronleftsmall":
                return (
                    <svg name="ChevronLeftSmall" xmlns="http://www.w3.org/2000/svg" width="31.8" height="31.8" viewBox="0 0 31.8 31.8" style={{transform: "rotate(90deg)"}}>
                        <path d="M15.9,19.1l-4.4-4.4,1.1-1.1,3.3,3.3,3.3-3.3,1.1,1.1Z" fill="currentColor"/>
                    </svg>
                );

            case "chevronright":
                return (
                    <svg name="ChevronRight" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811" style={{transform: "rotate(-90deg)"}}>
                        <path d="M15.924,21.027,7.615,12.718l1.061-1.061,7.248,7.248L23.133,11.7l1.061,1.061Z" fill="currentColor"/>
                    </svg>
                );

            case "chevronrightsmall":
                return (
                    <svg name="ChevronRightSmall" xmlns="http://www.w3.org/2000/svg" width="31.8" height="31.8" viewBox="0 0 31.8 31.8" style={{transform: "rotate(-90deg)"}}>
                        <path d="M15.9,19.1l-4.4-4.4,1.1-1.1,3.3,3.3,3.3-3.3,1.1,1.1Z" fill="currentColor"/>
                    </svg>
                );

            case "chevronup":
                return (
                    <svg name="ChevronUp" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811" style={{transform: "rotate(180deg)"}}>
                        <path d="M15.924,21.027,7.615,12.718l1.061-1.061,7.248,7.248L23.133,11.7l1.061,1.061Z" fill="currentColor"/>
                    </svg>
                );

            case "chevronupsmall":
                return (
                    <svg name="ChevronUpSmall" xmlns="http://www.w3.org/2000/svg" width="31.8" height="31.8" viewBox="0 0 31.8 31.8" style={{transform: "rotate(180deg)"}}>
                        <path d="M15.9,19.1l-4.4-4.4,1.1-1.1,3.3,3.3,3.3-3.3,1.1,1.1Z" fill="currentColor"/>
                    </svg>
                );

            case "clock":
                return (
                    <svg name="Clock" xmlns="http://www.w3.org/2000/svg" width="31.8" height="31.8" viewBox="0 0 31.8 31.8">
                        <path d="M15.9,23.9a8,8,0,1,1,8-8A8.024,8.024,0,0,1,15.9,23.9Zm0-14.5a6.5,6.5,0,1,0,6.5,6.5A6.487,6.487,0,0,0,15.9,9.4Z" fill="currentColor"/>
                        <path d="M18,19.8l-2.8-3.6V11.7h1.5v3.9l2.5,3.3Z" fill="currentColor"/>
                    </svg>
                );

            case "e":
                return (
                    <svg name="E" width="43.532" height="50.415" viewBox="0 0 43.532 50.415">
                        <path d="M1.1,2.8v9.479H5.689V43.615H1.1v9.6H44.632v-16.3H32.194v6.7H20.6V31.841H34.307v-9.6H20.6V12.279H32.194v6.46H44.632V2.8Z" transform="translate(-1.1 -2.8)" fill="currentColor"/>
                    </svg>
                );

            case "expandbuilding":
                return (
                    <svg name="ExpandBuilding" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <rect width="2" height="2" transform="translate(9.461 11.405)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(12.961 11.405)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(16.461 11.405)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(9.424 14.905)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(12.924 14.905)" fill="currentColor"/>
                        <rect width="2" height="2" transform="translate(16.424 14.905)" fill="currentColor"/>
                        <path d="M28.612,18.408H25.07V14.864h-1.5v3.544H20.032v1.5H23.57v3.534h1.5V19.908h3.542Z" fill="currentColor"/>
                        <path d="M20.032,21.908h-9.4a2.655,2.655,0,0,1-2.65-2.65V9.868h12.05v7.04h1.5V8.368H6.483v10.89a4.159,4.159,0,0,0,4.15,4.15h10.9v-2h-1.5Z" fill="currentColor"/>
                    </svg>
                );

            case "facebook":
                return (
                    <svg name="Facebook" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g transform="translate(-954.497 29.999)">
                            <path d="M972.422-6h-4.069L966.5-6.612,964.656-6h-4.085a6.074,6.074,0,0,1-6.074-6.075V-23.925A6.073,6.073,0,0,1,960.571-30h11.851a6.074,6.074,0,0,1,6.075,6.074v11.851A6.075,6.075,0,0,1,972.422-6M964.644-20.611V-18h-3.009v3.426h3.009V-6h3.705v-8.574h2.762L971.636-18h-3.287v-2.224a1.713,1.713,0,0,1,1.931-1.851h1.495v-2.917a18.181,18.181,0,0,0-2.653-.232C966.414-25.223,964.644-23.582,964.644-20.611Z" fill="currentColor"/>
                        </g>
                    </svg>
                );

            case "givelove":
                return (
                    <svg name="GiveLove" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <rect width="1.5" height="9" transform="translate(4.945 17.905)" fill="currentColor"/>
                        <path d="M19.944,21.9v-.042A3.254,3.254,0,0,0,17,18.623l-3.055-.717-4.495.009L7.944,17.9v8l10.5,1,8.5-1v-.677a3.4,3.4,0,0,0-3.8-3.377Zm5.365,2.705-6.865.795-1.5-.14-7.5-.72,0-5.135,4.5,0,3.006.715a1.651,1.651,0,0,1,1.493,1.643h0l-5-.38v1.5l4,.38,1.5.14,4.365.027c1.242-.156,1.835.141,2,1.179Z" fill="currentColor"/>
                        <path d="M15.944,15.884v.02c-.01,0-.01-.01-.02-.01C15.934,15.894,15.934,15.894,15.944,15.884Z" fill="currentColor"/>
                        <path d="M15.924,15.894c-.01,0-.01.01-.02.01v-.02C15.914,15.894,15.914,15.894,15.924,15.894Z" fill="currentColor"/>
                        <path d="M16.511,15.08s5.336-3.024,5.336-6.308a2.935,2.935,0,0,0-3-3.137,2.723,2.723,0,0,0-2.322,1.5,2.811,2.811,0,0,0-2.477-1.5A2.9,2.9,0,0,0,11.3,8.747C11.471,11.517,15.415,14.531,16.511,15.08Z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
                    </svg>
                );

            case "hamburger":
                return (
                    <svg name="Hamburger" xmlns="http://www.w3.org/2000/svg" width="24" height="17.5" viewBox="0 0 24 17.5">
                        <g transform="translate(-16 -30.25)">
                            <line x2="24" transform="translate(16 31)" fill="none" stroke="currentColor" strokeWidth="1.5"/>
                            <line x2="24" transform="translate(16 39)" fill="none" stroke="currentColor" strokeWidth="1.5"/>
                            <line x2="24" transform="translate(16 47)" fill="none" stroke="currentColor" strokeWidth="1.5"/>
                        </g>
                    </svg>
                );

            case "heart":
                return (
                    <svg name="Heart" xmlns="http://www.w3.org/2000/svg" width="31.81" height="31.81" viewBox="0 0 31.81 31.81">
                        <path d="M21.6,9.45a5.6,5.6,0,0,0-2.47-.63,4.5,4.5,0,0,0-3.21,1.51,4.43,4.43,0,0,0-6.251-.413,4.515,4.515,0,0,0-.369.363A4.809,4.809,0,0,0,8,13.9c.26,4.3,6.05,8.58,7.58,9.35l.36.18.34-.2c.32-.18,7.73-4.44,7.73-9.34A4.631,4.631,0,0,0,21.6,9.45Z" fill="currentColor"/>
                    </svg>
                );

            case "hourglass":
                return (
                    <svg name="Hourglass" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M22.905,4.905h-14v6.5l5.8,4.25-5.8,4.25V23.47a3.436,3.436,0,0,0,3.436,3.436h7.125a3.439,3.439,0,0,0,3.439-3.439V19.905l-5.8-4.25,5.8-4.25Zm-1.48,15.95v2.86a1.634,1.634,0,0,1-1.64,1.634l-7.666-.038a1.7,1.7,0,0,1-1.694-1.7V20.855l5.55-3.99Zm-.02-10.45-5.56,4-5.44-4v-4l11,.04Z" fill="currentColor"/>
                    </svg>
                );

            case "instagram":
                return (
                    <svg name="Instagram" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M12.1,9.8c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2c0,0,0,0,0,0C14.2,10.8,13.3,9.8,12.1,9.8z" fill="currentColor"/>
                        <path d="M14.8,6.4H9.2c-1.6,0-2.9,1.3-2.9,2.9v5.6c0,1.6,1.3,2.9,2.9,2.9h5.5c1.6,0,2.9-1.3,2.9-2.9V9.2C17.6,7.7,16.4,6.4,14.8,6.4z M15.5,12c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C13.9,8.6,15.5,10.1,15.5,12C15.5,12,15.5,12,15.5,12z M15.7,9.2c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8C16.5,8.8,16.1,9.2,15.7,9.2z" fill="currentColor"/>
                        <path d="M17.9,0H6.1C2.7,0,0,2.7,0,6.1v11.8C0,21.3,2.7,24,6.1,24h11.8c3.4,0,6.1-2.7,6.1-6.1V6.1C24,2.7,21.3,0,17.9,0zM18.9,14.8c0,2.3-1.9,4.2-4.2,4.2H9.2c-2.3,0-4.2-1.9-4.2-4.2V9.2c0-2.3,1.9-4.2,4.2-4.2h5.5c2.3,0,4.2,1.9,4.2,4.2V14.8z" fill="currentColor"/>
                    </svg>
                );

            case "minus":
                return (
                    <svg name="Minus" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <rect width="14" height="1.5" transform="translate(8.905 15.155)" fill="currentColor"/>
                    </svg>
                );

            case "music":
                return (
                    <svg name="Music" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M25.333,6.449l-13,1.5L12.3,20.308a3.654,3.654,0,1,0,1.485,3.1l.015-9.169,10.01-1.155v5.707a3.649,3.649,0,1,0,1.526,2.96ZM21.672,23.905a2.157,2.157,0,1,1,2.135-2.4c.008.144.012.278.008.4a2.152,2.152,0,0,1-2.144,2Zm-11.534,1.5A2.157,2.157,0,1,1,12.3,23.248a2.157,2.157,0,0,1-2.159,2.157Zm3.7-12.683V9.286l10-1.154-.026,3.439Z" fill="currentColor"/>
                    </svg>
                );

            case "penpaper":
                return (
                    <svg name="PenPaper" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M22.512,23.061a2.359,2.359,0,0,1-2.331,2.348H11.664a2.36,2.36,0,0,1-2.35-2.35V7.819H20.37l.583-1.42H7.934V23.35a3.5,3.5,0,0,0,3.5,3.5l9.522-.055a3.491,3.491,0,0,0,2.881-2.9L23.9,17s-1.39,3.34-1.39,3.41Z" fill="currentColor"/>
                        <path d="M26.124,6.08s-2.53-1.02-2.62-1.02a.742.742,0,0,0-.67.44l-5.31,12.85-.01.03a1.936,1.936,0,0,0-.09,1.21l.67,2.6,2.3-1.37a1.823,1.823,0,0,0,.79-.92l5.33-12.87a.729.729,0,0,0-.39-.95Zm-6.18,13.46-1,.66-.26-1.18,5.13-12.47,1.29.54Z" fill="currentColor"/>
                        <path d="M19.744,9.33h-8.99v1.33h8.44Z" fill="currentColor"/>
                        <path d="M10.734,13.44h7.309l.551-1.33h-7.86Z" fill="currentColor"/>
                    </svg>
                );

            case "people":
                return (
                    <svg name="People" xmlns="http://www.w3.org/2000/svg" width="32.009" height="31.942" viewBox="0 0 32.009 31.942">
                        <path d="M15.55,16a2.476,2.476,0,0,0-1.29-.36H10.55A2.467,2.467,0,0,0,8.08,18.1v5.83h8.65V18.1A2.448,2.448,0,0,0,15.55,16Zm-.32,6.43H9.58V18.1a.969.969,0,0,1,.97-.96h3.71a.968.968,0,0,1,.97.96Z" fill="currentColor"/>
                        <path d="M15.55,16a2.476,2.476,0,0,0-1.29-.36H10.55A2.467,2.467,0,0,0,8.08,18.1v5.83h8.65V18.1A2.448,2.448,0,0,0,15.55,16Zm-.32,6.43H9.58V18.1a.969.969,0,0,1,.97-.96h3.71a.968.968,0,0,1,.97.96Z" fill="currentColor"/>
                        <path d="M12.394,10.687a1.158,1.158,0,1,1-1.154,1.162v0a1.158,1.158,0,0,1,1.154-1.158m0-1.5a2.658,2.658,0,1,0,2.654,2.662v0a2.656,2.656,0,0,0-2.654-2.658Z" fill="currentColor"/>
                        <path d="M21.55,14.445H17.84a2.428,2.428,0,0,0-1.34.4,3.694,3.694,0,0,1,1.1,1.13.974.974,0,0,1,.24-.03h3.71a.961.961,0,0,1,.96.965v4.335H18.23v1.5h5.78v-5.83a2.46,2.46,0,0,0-2.451-2.47Zm-6,1.56a2.312,2.312,0,0,0-.18.91v5.83h1.36V18.1A2.448,2.448,0,0,0,15.55,16Z" fill="currentColor"/>
                        <path d="M19.682,9.489a1.158,1.158,0,1,1-1.154,1.162v0a1.157,1.157,0,0,1,1.154-1.158m0-1.5a2.658,2.658,0,1,0,2.653,2.662v0a2.656,2.656,0,0,0-2.653-2.658Z" fill="currentColor"/>
                    </svg>
                );

            case "play":
                return (
                    <svg name="Play" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M8,0L28,15.9055L8,31.811Z" fill="currentColor"/>
                    </svg>
                );

            case "plus":
                return (
                    <svg name="Plus" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M23.9,15.155H16.655V7.915h-1.5v7.24H7.915v1.5h7.24V23.9h1.5V16.655H23.9Z" fill="currentColor"/>
                    </svg>
                );

            case "samsungflip":
                return (
                    <svg name="SamsungFlip" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="currentColor">
                        <path d="M11.5,16.5,9.06,26.43a.7.7,0,1,1-1.37-.31l2.36-9.62Z"/>
                        <path d="M17.68,27a.707.707,0,0,1-.84-.53L14.39,16.5h1.45l2.38,9.66A.713.713,0,0,1,17.68,27Z"/>
                        <path d="M4.958,5.028v9.941H21.021V5.028ZM19.59,13.584H6.386V6.406h13.2Z"/>
                        <path d="M28,14.053,25.334,10.04l-2.593,4.006,1.975,0a3.942,3.942,0,0,1-3.617,3.9l-.02,1.32a5.253,5.253,0,0,0,4.957-5.215Z"/>
                    </svg>
                );

            case "spinner":
                return (
                    <svg name="Spinner" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="12" y1="2" x2="12" y2="6"></line>
                        <line x1="12" y1="18" x2="12" y2="22"></line>
                        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                        <line x1="2" y1="12" x2="6" y2="12"></line>
                        <line x1="18" y1="12" x2="22" y2="12"></line>
                        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                    </svg>
                );

            case "square":
                return (
                    <svg name="Square" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M20.422,9.462a1.9,1.9,0,0,1,1.9,1.9v9.185a1.9,1.9,0,0,1-1.9,1.9H11.3a1.9,1.9,0,0,1-1.9-1.9V11.362a1.9,1.9,0,0,1,1.9-1.9h9.124m0-1.5H11.3a3.4,3.4,0,0,0-3.4,3.4v9.185a3.4,3.4,0,0,0,3.4,3.4h9.124a3.4,3.4,0,0,0,3.4-3.4V11.362a3.4,3.4,0,0,0-3.4-3.4Z" fill="currentColor"/>
                    </svg>
                );

            case "street":
                return (
                    <svg name="Street" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <rect width="21.95" height="1.5" transform="translate(4.951 25.414)" fill="currentColor"/>
                        <rect width="2.964" height="1.5" transform="translate(4.994 17.953) rotate(-0.074)" fill="currentColor"/>
                        <path d="M12.394,19.426l14.487.032,0-1.5-2.716-.006V7.914H15.964v10.02L12.4,17.926Zm5.07-10.012h5.205v8.525H17.464Z" fill="currentColor"/>
                        <path d="M9.429,13.594V23.92h1.5V13.594a3.868,3.868,0,1,0-1.5,0Zm.75-6.161A2.368,2.368,0,1,1,7.811,9.8a2.368,2.368,0,0,1,2.368-2.368Z" fill="currentColor"/>
                    </svg>
                );

            case "tv":
                return (
                    <svg name="Tv" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811" fill="currentColor">
                        <path d="M4.905,8.03v12.6a3.4,3.4,0,0,0,3.4,3.4H15.15l-.01,1.25H10.885v1.5h10v-1.5H16.641l.009-1.25h6.855a3.4,3.4,0,0,0,3.4-3.4V8.03Zm20.5,12.6a1.9,1.9,0,0,1-1.9,1.9H8.306a1.9,1.9,0,0,1-1.9-1.9V9.53h19Z"/>
                    </svg>
                );

            case "whiteboard":
                return (
                    <svg name="Whiteboard" xmlns="http://www.w3.org/2000/svg" width="31.8" height="31.8" viewBox="0 0 31.8 31.8">
                        <path d="M5,8V20.6A3.372,3.372,0,0,0,8.4,24H23.6A3.372,3.372,0,0,0,27,20.6h0V8ZM25.5,20.6a1.967,1.967,0,0,1-1.9,1.9H8.4a1.967,1.967,0,0,1-1.9-1.9V9.5h19Z" fill="currentColor"/>
                        <rect width="2.8" height="1.5" transform="translate(20 19.03) rotate(-45)" fill="currentColor"/>
                    </svg>
                );

            case "wifi":
                return (
                    <svg name="Wifi" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M17.129,23.332a1.946,1.946,0,0,0-1.275-.48,1.835,1.835,0,0,0-1.261.48,1.886,1.886,0,0,0-.72,1.5,1.988,1.988,0,0,0,3.976,0A1.908,1.908,0,0,0,17.129,23.332Z" fill="currentColor"/>
                        <path d="M15.86,8.4a16.356,16.356,0,0,0-10.6,3.88l1.04,1.23a14.787,14.787,0,0,1,19.13,0l1.03-1.23A16.282,16.282,0,0,0,15.86,8.4Z" fill="currentColor"/>
                        <path d="M8.13,15.682,9.16,16.9a10.37,10.37,0,0,1,13.4.01l1.03-1.23a11.972,11.972,0,0,0-15.46,0Z" fill="currentColor"/>
                        <path d="M11.06,19.152l1.03,1.23a5.837,5.837,0,0,1,7.55-.01l1.03-1.22a7.439,7.439,0,0,0-9.61,0Z" fill="currentColor"/>
                    </svg>
                );

            case "x":
                return (
                    <svg name="X" xmlns="http://www.w3.org/2000/svg" width="31.811" height="31.811" viewBox="0 0 31.811 31.811">
                        <path d="M27.943,26.883,16.949,15.888,27.894,4.944,26.833,3.883,15.888,14.828,4.928,3.867,3.867,4.928l10.961,10.96L3.878,26.838,4.939,27.9,15.888,16.949l10.995,11Z" fill="currentColor"/>
                    </svg>
                );

            case "xbig":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
                        <path d="M76.525,73.326,43.346,40.144,76.377,7.117l-3.2-3.2-33.03,33.03L7.069,3.867l-3.2,3.2L36.946,40.144,3.9,73.19l3.2,3.2L40.144,43.346,73.326,76.528Z" transform="translate(7.803 7.803)" fill="currentColor"/>
                    </svg>
                );

            case "lang":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 20 20">
                        <path d="M10,0c5.5,0,10,4.5,10,10s-4.5,10-10,10S0,15.5,0,10S4.5,0,10,0z M10,18.1c0.3,0,1-0.3,1.9-1.9c0.3-0.7,0.6-1.5,0.9-2.5H7.3c0.2,1,0.5,1.8,0.9,2.5C8.9,17.8,9.7,18.1,10,18.1z M7,11.9H13c0.1-0.6,0.1-1.2,0.1-1.9s0-1.3-0.1-1.9H7C6.9,8.7,6.9,9.4,6.9,10S6.9,11.3,7,11.9L7,11.9z M12.7,6.2c-0.3-1-0.5-1.8-0.9-2.5C11,2.2,10.3,1.9,10,1.9S8.9,2.2,8.1,3.8C7.8,4.5,7.5,5.3,7.3,6.2H12.7z M14.9,8.1C15,8.7,15,9.4,15,10s0,1.3-0.1,1.9h3c0.1-0.6,0.2-1.2,0.2-1.9s-0.1-1.3-0.2-1.9H14.9zM13.4,2.6c0.6,1,1,2.2,1.3,3.6h2.6C16.4,4.6,15,3.4,13.4,2.6z M6.6,2.6c-1.7,0.8-3,2-3.8,3.6h2.6C5.6,4.8,6.1,3.6,6.6,2.6L6.6,2.6zM1.9,10c0,0.6,0.1,1.3,0.2,1.9h3C5,11.3,5,10.6,5,10s0-1.3,0.1-1.9h-3C2,8.7,1.9,9.4,1.9,10z M17.2,13.8h-2.6c-0.3,1.4-0.7,2.6-1.3,3.6C15,16.6,16.4,15.4,17.2,13.8z M5.4,13.8H2.8c0.8,1.6,2.2,2.9,3.8,3.6C6.1,16.4,5.6,15.2,5.4,13.8L5.4,13.8z" />
                    </svg>
                );

            default:
                return (
                    <svg
                        name="Heart"
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.81"
                        height="31.81"
                        viewBox="0 0 31.81 31.81"
                    >
                        <path
                            d="M21.6,9.45a5.6,5.6,0,0,0-2.47-.63,4.5,4.5,0,0,0-3.21,1.51,4.43,4.43,0,0,0-6.251-.413,4.515,4.515,0,0,0-.369.363A4.809,4.809,0,0,0,8,13.9c.26,4.3,6.05,8.58,7.58,9.35l.36.18.34-.2c.32-.18,7.73-4.44,7.73-9.34A4.631,4.631,0,0,0,21.6,9.45Z"
                            fill="currentColor"
                        />
                    </svg>
                );
        }
    }

    render() {
        const {className} = this.props;
        const CA = ["Icon"];

        if (className) {
            CA.push(className)
        }

        return (
            <div {...this.props} className={CA.join(" ")}>
                {this.Svg()}
            </div>
        );
    }
}

Icon.defaultProps =
    {
        className: "",
        src: ""
    };

export default Icon;