
/**!
 *  Dot navigation.
 * 
 *  @prop integer active - Index of active dot.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the navigation should be disabled.
 *  @prop integer dots - Number of dots.
 *  @prop string id - Navigation id.
 *  @prop function onClick - Callback when a dot is clicked.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./dotnavigation.scss";

import IconButton from "Components/UI/IconButton";

class DotNavigation extends React.Component
{
    /*
     * Output an arrow button.
     *
     * @param integer step - Direction of the arrow.
     * 
     * @return JSX - The arrow button.
     */

    Arrow = (step) =>
    {
        const CA = ["DotNavigationArrow"];
        let Icon;

        if (step < 0)
        {
            CA.push("Left");
            Icon = "ChevronLeft";
        }
        else
        {
            CA.push("Right");
            Icon = "ChevronRight";
        }

        return (
            <IconButton
                className={CA.join(" ")}
                onClick={e => this.OnClickArrow(e, step)}
                src={Icon}
            />
        );
    }

    /*
     * Output a dot navigation item.
     *
     * @param integer index - Dot index.
     * 
     * @return JSX - The dot navigation item.
     */

    Item = (index) =>
    {
        const {active} = this.props;
        const CA = ["DotNavigationItem"];

        if (index === active)
        {
            CA.push( "Active" );
        }

        return <div className={CA.join(" ")} key={index} onClick={e => this.OnClick(e, index)}/>;;
    }

    /*
     * Callback when a dot is clicked.
     *
     * @param object e - The click event.
     * @param integer index - Dot index.
     * 
     * @return void
     */

    OnClick = (e, index) =>
    {
        const {disabled, id, onClick} = this.props;

        if (disabled)
        {
            return;
        }

        onClick(e, index, id);
    }

    /*
     * Callback when an arrow is clicked.
     *
     * @param object e - The click event.
     * @param integer step - Navigation step direction and length.
     * 
     * @return void
     */

    OnClickArrow = (e, step) =>
    {
        const {active, disabled, dots, id, onClick} = this.props;

        if (disabled)
        {
            return;
        }

        let NewIndex = active < 0 ? 0 : (active + step) % dots;

        if (NewIndex < 0)
        {
            NewIndex = dots + NewIndex;
        }

        onClick(e, NewIndex, id);
    }

    render()
    {
        const {className, disabled, dots, showArrows, showDots} = this.props;
        const CA = ["DotNavigation"];

        if (disabled)
        {
            CA.push( "Disabled" );
        }
        if (showArrows)
        {
            CA.push("HasArrows");
        }
        if (showDots)
        {
            CA.push("HasDots");
        }
        if (className)
        {
            CA.push(className);
        }
        
        const Dots = [];

        if (showDots)
        {
            for (let i = 0; i < dots; i++)
            {
                Dots.push(this.Item(i));
            }
        }

        return (
            <div className={CA.join(" ")}>
                {showArrows ? this.Arrow(-1) : ""}
                <div className="DotNavigationItems">{Dots}</div>
                {showArrows ? this.Arrow(1) : ""}
            </div>
        );
    }
}

DotNavigation.defaultProps =
{
    active: -1,
    className: "",
    disabled: false,
    dots: 0,
    id: "",
    onClick: () => {},
    showArrows: false,
    showDots: true
};

export default DotNavigation;