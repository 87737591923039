import React from "react";
import "./notifications.scss";

import Eatery from "Class/Eatery";
import Globals from "Class/Globals";
import {UcFirst} from "Functions";
import {default as JsCookie} from "js-cookie";

import Button from "Components/UI/Button";
import IconButton from "Components/UI/IconButton";
import Link from "Components/UI/Link";

class Notifications extends React.Component
{
    constructor(props)
    {
        super(props);
        this.OneByOne = true;
        this.state =
        {
            notifications: []
        };
    }

    componentDidMount()
    {
        Globals.Listen("notification", this.OnNotification);
        const PrivacyPolicy = Eatery.Option("privacyPolicy");
        const PrivacyPolicyTitle = Eatery.GetTitle(PrivacyPolicy);
        const CookiePolicy = Eatery.Option("cookiePolicy");
        const CookiePolicyTitle = Eatery.GetTitle(CookiePolicy);
        if (!JsCookie.get(Globals.CookieKey))
        {
            Globals.Notification({
                cta: "Acceptera",
                color: "black",
                id: "cookies",
                onConfirm: this.OnConfirmCookies,
                text: ["Vi använder verktyg som ex. cookies för att förbättra användarupplevelsen och samla in värdefull feedback. För mer information, vänligen läs vår ", <Link to={PrivacyPolicy} key="link1">{PrivacyPolicyTitle}</Link>, " och ", <Link to={CookiePolicy} key="link2">{CookiePolicyTitle}</Link>,"."],
                title: "Eatery andvänder cookies"
            });
        }
    }

    componentWillUnmount()
    {
        Globals.Remove("notification", this.OnNotification);
    }

    Item = (notification, key) =>
    {
        const {altFont, cancel, color, cta, href, text, title, to} = notification;
        const Color = color ? UcFirst(color) : "White";
        const CA = ["Notification", `Color${Color}`];
        if (altFont)
        {
            CA.push("AltFont");
        }
        else
        {
            CA.push("NormalFont");
        }
        return (
            <div className={CA.join(" ")} key={key}>
                <IconButton
                    className="NotificationClose"
                    onClick={() => this.Remove(key)}
                    src="X"
                />
                <div className="NotificationContent">
                    <h3>{title}</h3>
                    <p>{text}</p>
                    <Button
                        color={Color === "Black" ? "white" : "black"}
                        href={href}
                        label={cta || "ok"}
                        onClick={e => this.OnClick(e, key)}
                        small={true}
                        to={to}
                    />
                    {cancel ? <Button
                        hollow={true}
                        label={cancel}
                        onClick={() => this.Remove(key)}
                        small={true}
                    /> : ""}
                </div>
            </div>
        );
    }

    OnClick = (e, key) =>
    {
        const {notifications} = this.state;
        const {onConfirm} = notifications[key] || {};
        if (typeof onConfirm === "function")
        {
            onConfirm();
        }
        this.Remove(key);
    }

    OnConfirmCookies = () =>
    {
        JsCookie.set(Globals.CookieKey, "1", {expires: 14});
        Globals.AddTracking(true);
    }

    OnNotification = (notification) =>
    {
        const {notifications} = this.state;
        notifications[notification.id] = notification;
        this.setState({notifications});
    }

    Remove = (key) =>
    {
        const {notifications} = this.state;
        const {onClose} = notifications[key];
        if (typeof onClose === "function")
        {
            onClose();
        }
        delete notifications[key];
        this.setState({notifications});
    }

    render()
    {
        const {notifications} = this.state;
        const Items = [];
        for (let key in notifications)
        {
            Items.push(this.Item(notifications[key], key));
            if (this.OneByOne)
            {
                break;
            }
        }
        return (
            <div className="Notifications">
                {Items}
            </div>
        );
    }
}

export default Notifications;