import React from "react";
import "./radiosfield.scss";

class RadiosField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            value: false
        };
    }

    componentDidMount()
    {
        const {value} = this.props;
        this.setState({value});
    }

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        const {value: v3} = this.state;

        if (v1 !== v2 && v1 !== v3)
        {
            this.setState({value: v1});
        }
    }

    Disabled = (key) =>
    {
        const {disabled} = this.props;
        return disabled === true || (Array.isArray(disabled) && disabled.indexOf(key) >= 0);
    }

    Item = (label, key) =>
    {
        const {value} = this.state;
        const CA = ["RadiosFieldItem"];
        const Disabled = this.Disabled(key);

        if (Disabled)
        {
            CA.push("Disabled");
        }
        if (key === value)
        {
            CA.push("Selected");
        }

        return (
            <div
                className={CA.join(" ")}
                key={key}
                onClick={e => this.OnClick(e, key)}
                onKeyDown={e => this.OnKeyDown(e, key)}
                tabIndex={Disabled ? "false" : "0"}
            >
                <div className="Input"/>
                <div className="RadiosFieldItemLabel">{label}</div>
            </div>
        );
    }

    OnClick = (e, key) =>
    {
        const {id, onChange} = this.props;

        if (this.Disabled(key))
        {
            return;
        }

        this.setState({value: key});
        onChange(e, key, id);
    }

    OnKeyDown = (e, key) =>
    {
        switch (e.which)
        {
            case 13:
            case 32:
                break;

            default:
                return;
        }

        e.stopPropagation();
        e.preventDefault();

        this.OnClick(e, key);
    }

    render()
    {
        const {className, label, options} = this.props;
        const CA =["Field", "RadiosField"];
        const Items = [];

        if (className)
        {
            CA.push(className);
        }

        for (let key in options)
        {
            Items.push(this.Item(options[key], key));
        }

        return (
            <div className={CA.join(" ")}>
                {label ? <label>{label}</label> : ""}
                <div className="RadiosFieldItems">
                    {Items}
                </div>
            </div>
        );
    }
}

RadiosField.defaultProps =
{
    className: "",
    disabled: false,
    id: "",
    label: "",
    onChange: () => {},
    options: [],
    value: false
};

export default RadiosField;