import React from "react";
import "./collapsable.scss";

import Globals from "Class/Globals";
import gsap, {CSSPlugin, TweenLite, Power1} from "gsap";
gsap.registerPlugin(CSSPlugin);

class Collapsable extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Container = false;
        this.Content = false;

        this.state =
        {
            collapsed: false
        };
    }
    /**
     * Expand/collapse on mount.
     * 
     * @return void
     */

    componentDidMount()
    {
        const {collapsed} = this.props;
        
        this.CheckOverflow();
        this.Toggle(collapsed, true);

        window.addEventListener("resize", this.CheckOverflow);
    }

    /**
     * Expand/collapse when the prop changes.
     * 
     * @return void
     */

    componentDidUpdate()
    {
        const {collapsed: c1} = this.props;
        const {collapsed: c2} = this.state;

        if (c1 !== c2)
        {
             this.Toggle(c1);
        }
    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.CheckOverflow);
    }

    /**
     * Check whether the container has overflow, ie. if the collapseHeight is
     * shorter than the contents.
     * 
     * @return void
     */

    CheckOverflow = () =>
    {
        const {collapseHeight, onOverflow} = this.props;

        onOverflow(this.Content ? this.Content.offsetHeight > collapseHeight : false );

    }

    /**
     * Expand/collapse the view.
     * 
     * @param boolean collapsed - Whether the view should collapse.
     * @param boolean initial - Set duration to '0' for the initial setup.
     * 
     * @return void
     */

    Toggle = (collapsed, initial) =>
    {
        const {collapseHeight, duration} = this.props;

        if (!this.Container || !this.Content)
        {
            return;
        }

        const Height = collapsed ? collapseHeight : this.Content.offsetHeight;
        const Duration = initial ? 0 : duration;

        TweenLite.to(this.Container, Duration,
        {
            ease: Power1.easeInOut,
            height: Height,
            onComplete: () =>
            {
                if (!collapsed)
                {
                    this.Container.style.height = "auto";
                }
            }
        });

        this.setState({collapsed});
    }

    render()
    {
        const {children, className, collapseHeight, duration} = this.props;
        const {collapsed} = this.state;
        const CA = ["Collapsable"];

        if (collapsed && collapseHeight)
        {
            CA.push("SemiCollapsed");
        }
        else if (collapsed)
        {
            CA.push("Collapsed");
        }
        else
        {
            CA.push("Expanded");
        }

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")} ref={container => this.Container = container}>
                <div
                    className="CollapsableContent"
                    ref={content => this.Content = content}
                    style={{transitionDuration: `${duration}s`}}
                >
                    {children}
                </div>
            </div>
        );
    }
}

Collapsable.defaultProps =
{
    className: "",
    collapsed: true,
    collapseHeight: 0,
    duration: Globals.Duration,
    onOverflow: () => {}
}

export default Collapsable;
