import React from "react";
import "./catering.scss";

import Eatery from "Class/Eatery";
import Globals from "Class/Globals";
import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import Full from "Components/Layout/Full";
import Gallery from "Components/UI/Gallery";
import ItemStaff from "Components/UI/ItemStaff";
import LoadImage from "Components/Layout/LoadImage";
import LoadVideo from "Components/Layout/LoadVideo";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplateCatering extends React.Component
{
    ColumnRight = () =>
    {
        return (
            <div className="TemplateCateringRight"></div>
        );
    }

    render()
    {
        const {content, title} = this.props;
        const {
            about,
            contents,
            backgroundColor,
            backgroundImage,
            backgroundPositionH,
            backgroundPositionV,
            backgroundVideo,
            gallery,
            more,
            priceList,
            textColor
        } = content || {};
        const GalleryItems = [];
        const Staff = Eatery.Option("cateringStaff", []);
        const StaffItems = [];

        if (gallery)
        {
            gallery.forEach((media, index) =>
            {
                const {image, imagePositionH, imagePositionV, video} = media;

                if (video)
                {
                    GalleryItems.push(<LoadVideo key={index} src={video}/>);
                }
                else
                {
                    GalleryItems.push(<LoadImage key={index} src={image} style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}/>);
                }
            });
        }

        if (Staff && Staff.length)
        {
            Staff.forEach(id =>
            {
                StaffItems.push(<ItemStaff id={id} key={id}/>);
            });
        }

        return (
            <div className="TemplateCatering">
                <Full
                    backgroundColor={backgroundColor}
                    backgroundImage={backgroundImage}
                    backgroundPositionH={backgroundPositionH}
                    backgroundPositionV={backgroundPositionV}
                    backgroundVideo={backgroundVideo}
                    className="TemplateCateringTop"
                    textColor={textColor}
                    top={true}
                >
                    <h1>{title}</h1>
                </Full>
                <Segment>
                    <ThreeColumns right={this.ColumnRight()}>
                        {Parser.Parse(about, "div", null, {className: "TemplateCateringAbout"})}
                        <Modules modules={contents} startIndex={1}/>
                        {priceList ? <Button
                            color="beige"
                            label="Prislista"
                            onClick={() => Globals.DialogPriceList(priceList)}
                        /> : ""}
                        <Gallery>{GalleryItems}</Gallery>
                        {Parser.Parse(more, "div", null, {className: "TemplateCateringAbout"})}
                    </ThreeColumns>
                </Segment>
                <Segment>
                    <div className="TemplateCateringStaffItemsWrapper">
                        <div className="TemplateCateringStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
            </div>
        );
    }
}

TemplateCatering.defaultProps =
{
    content: {},
    title: ""
};

export default TemplateCatering;