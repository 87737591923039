import React from "react";
import "./header.scss";

import Globals from "Class/Globals";
import {CapFloat} from "Functions";

import Announcements from "Components/UI/Announcements";
import EateryCard from "Components/View/EateryCard";
import IconButton from "Components/UI/IconButton";
import Link from "Components/UI/Link";
import MainLogo from "Components/UI/MainLogo";
import MainNavigation from "Components/UI/MainNavigation";
import Spinner from "Components/Feedback/Spinner";
import GoogleTranslate from 'Components/UI/GoogleTranslate';

class Header extends React.Component
{
    constructor(props)
    {
        super(props);

        this.CardButton = false;
        this.Instant = false;
        this.LastScroll = 0;
        this.Mounted = false;
        this.Node = false;
        this.Offset = 0;

        this.state =
        {
            hide: false,
            independent: false
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        window.addEventListener("focusin", this.OnFocus);
        window.addEventListener("scroll", this.OnScroll);
        Globals.Listen("var-independent", this.OnIndependent);
    }

    componentWillUnmount()
    {
        this.Mounted = false;
        window.removeEventListener("focusin", this.OnFocus);
        window.removeEventListener("scroll", this.OnScroll);
        Globals.Remove("var-independent", this.OnIndependent);
    }

    OnCardDialog = () =>
    {
        Globals.Dialog({
            color: "Beige",
            content: <EateryCard/>,
            fillNarrow: true,
            id: "eateryCard",
            onClose: this.ResetFocus,
            tray: <Link to="/villkor-for-eaterykortet">Villkor</Link>
        });
    }

    OnFocus = (e) =>
    {
        if (!e.target || !this.Node || !this.Node.contains(e.target))
        {
            return;
        }
        if (this.Instant)
        {
            this.setState({hide: false});
        }
        else
        {
            this.Offset = 0;
            this.Node.style.transform = `translate3d(0,-0px,0)`;
        }
    }

    OnIndependent = (independent) =>
    {
        this.setState({independent});
    }

    OnMenu = () =>
    {
        Globals.Var("side", true);
    }

    OnScroll = () =>
    {
        const {hide} = this.state;
        const Scroll = Math.max(window.scrollY, 0);
        const Delta = Scroll - this.LastScroll;
        const Hide = Delta > 0;
        this.LastScroll = Scroll;

        if (!this.Instant && this.Node)
        {
            const Offset = CapFloat(this.Offset + Delta, 0, this.Node.offsetHeight);
            this.Offset = Offset;
            this.Node.style.transform = `translate3d(0,-${Offset}px,0)`;
        }
        else if (this.Instant && hide !== Hide)
        {
            this.setState({hide: Hide});
        }
    }

    ResetFocus = () =>
    {
        if (!this.Mounted || !this.CardButton)
        {
            return;
        }

        this.CardButton.Focus();
    }

    render()
    {
        const {loading} = this.props;
        const {hide, independent} = this.state;
        const CA = ["Header"];

        if (independent)
        {
            return "";
        }

        if (this.Instant)
        {
            CA.push("Instant");

            if (hide)
            {
                CA.push("Hide");
            }
        }

        if (loading)
        {
            return (
                <div className="Header">
                    <MainLogo/>
                    <Spinner className="HeaderSpinner"/>
                </div>
            );
        }

        return (
            <div className={CA.join(" ")} id="header" ref={node => this.Node = node}>
                <Announcements/>
                <div className="HeaderWrapper">
                    <IconButton
                        className="HeaderMenuButton"
                        onClick={this.OnMenu}
                        src="Hamburger"
                    />
                    <MainLogo/>
                    <MainNavigation/>
                    <IconButton
                        className="HeaderCardButton"
                        label="Eatery kortet"
                        onClick={this.OnCardDialog}
                        ref={cardButton => this.CardButton = cardButton}
                        src="Card"
                    />
                    <GoogleTranslate />

                </div>
            </div>
        );
    }
}

Header.defaultProps =
{
    loading: false
};

export default Header;