import React from "react";
import "./iconbutton.scss";

import Icon from "Components/Layout/Icon";
import Item from "Components/UI/Item";

class IconButton extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Button = false;
    }

    Focus = () =>
    {
        if (this.Button)
        {
            this.Button.Focus();
        }
    }

    OnKeyDown = (e) =>
    {
        const {onClick, onKeyDown} = this.props;

        if (e.which === 13)
        {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }
        else
        {
            onKeyDown(e);
        }
    }

    render()
    {
        const {className, label, src, title} = this.props;
        const CA = ["IconButton"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <Item
                {...this.props}

                className={CA.join(" ")}
                label=""
                onKeyDown={this.OnKeyDown}
                ref={button => this.Button = button}
                title={title || label}
            >
                <Icon src={src}/>
                {label ? (
                    <div className="IconButtonLabel">{label}</div>
                ) : ""}
            </Item>
        );
    }
}

IconButton.defaultProps =
{
    className: "",
    label: "",
    onClick: () => {},
    onKeyDown: () => {},
    src: "",
    title: ""
};

export default IconButton;

