import React from "react";
import "./tag.scss";

import Link from "Components/UI/Link";

class Tag extends React.Component
{
    render()
    {
        const {className, label, to} = this.props;
        const CA = ["Tag"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <Link className={CA.join(" ")} to={to}>{label}</Link>
        );
    }
}

Tag.defaultProps =
{
    className: "",
    label: "",
    to: ""
};

export default Tag;