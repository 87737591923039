import React from "react";
import "./formnavigation.scss";

import {UcFirst} from "Functions";
import Icon from "Components/Layout/Icon";
import Item from "Components/UI/Item";

class FormNavigation extends React.Component
{
    Item = (label, index) =>
    {
        const {color, disabled, disableForward, step} = this.props;
        const CA = ["FormNavigationItem"];

        if (index < step)
        {
            CA.push("Done", "Hollow");
        }
        else if (step === index)
        {
            const Color = UcFirst(color);
            CA.push("Active", `Color${Color}`);
        }
        else
        {
            CA.push("Hollow");
        }

        return (
            <Item
                className={CA.join(" ")}
                disabled={disableForward && (disabled || index > step)}
                id={index}
                key={index}
                onClick={this.OnClick}
            >
                {index < step ? <Icon className="FormNavigationItemCheck" src="check"/> : ""}
                <div className="FormNavigationItemLabel">{index + 1}. {label}</div>
                <div className="FormNavigationItemLabelNarrow">{index + 1}</div>
            </Item>
        );
    }

    OnClick = (e, index) =>
    {
        const {disabled, id, onClick, step} = this.props;

        if (disabled && index >= step)
        {
            return;
        }

        onClick(e, index, id);
    }

    render()
    {
        const {className, disabled, steps} = this.props;
        const CA = ["FormNavigation"];
        const Items = [];

        if (disabled)
        {
            CA.push("Disabled")
        }

        if (className)
        {
            CA.push(className);
        }

        steps.forEach((label, index) => Items.push(this.Item(label, index)));

        return (
            <div className={CA.join(" ")}>
               {Items} 
            </div>
        );
    }
}

FormNavigation.defaultProps =
{
    className: "",
    color: "Black",
    disabled: false,
    disableForward: true,
    id: "",
    onClick: () => {},
    step: 0,
    steps: []
}

export default FormNavigation;