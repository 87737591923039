import React from "react";
import "./productitem.scss";

import Button from "Components/UI/Button";
import LoadImage from "Components/Layout/LoadImage";

class ProductItem extends React.Component
{
    render()
    {
        const {
            buttons,
            children,
            className,
            image,
            imagePositionH,
            imagePositionV
        } = this.props;
        const CA = ["ProductItemWrapper"];
        const Buttons = [];

        if (className)
        {
            CA.push(className);
        }

        buttons.forEach((button, index) =>
        {
            Buttons.push(<Button key={index} small={true} {...button}/>, <br key={`b${index}`}/>);
        });

        return (
            <div className={CA.join(" ")}>
                <div className="ProductItem">
                    <div className="ProductItemImage">
                        <LoadImage
                            src={image}
                            style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}
                        />
                    </div>
                    <div className="ProductItemContent">
                        {children}
                        <div className="ProductItemButtons">
                            {Buttons}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProductItem.defaultProps =
{
    buttons: [],
    className: "",
    image: "",
    imagePositionH: 50,
    imagePositionV: 50
};

export default ProductItem;