import Eatery from 'Class/Eatery';
import Parser from 'Class/Parser';
import MainLogo from 'Components/UI/MainLogo';
import React from 'react';
import './pricelist.scss';

class PriceList extends React.Component {
    render() {
        const {className, id} = this.props;
        const PriceList = Eatery.PriceList(id);

        if (!PriceList) {
            return '';
        }

        const {content, email, phone} = PriceList.content;
        const CA = ['PriceList'];

        if (className) {
            CA.push(className);
        }

        return (
            <div className={CA.join(' ')}>
                {Parser.Parse(
                    content,
                    'div',
                    null,
                    {className: 'PriceListContent'},
                )}
                <div className="PriceListFooter">
                    <div className="PriceListFooterColumn">
                        {email}
                    </div>
                    <div className="PriceListFooterColumn">
                        {phone}
                    </div>
                    <MainLogo
                        className="PriceListLogo"
                        link={false}
                    />
                </div>
            </div>
        );
    }
}

PriceList.defaultProps =
    {
        className: '',
        id: 0,
    };

export default PriceList;
