import React from "react";
import "./eaterynavigation.scss";

import Eatery from "Class/Eatery";
import Globals from "Class/Globals";

import CollapsableView from "Components/UI/CollapsableView";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import MainLogo from "Components/UI/MainLogo";
import OpeningHours from "Components/Layout/OpeningHours";
import SelectField from "Components/UI/Field/SelectField";

class EateryNavigation extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.BlurOnUp = false;
        this.SelectEatery = false;
        this.state =
        {
            eatery: false
        };
    }

    componentDidMount()
    {
        Globals.Listen("eatery", this.OnEatery);
        this.setState({eatery: Eatery.GetEatery()});
    }

    componentWillUnmount()
    {
        Globals.Remove("eatery", this.OnEatery);
    }

    OnSelectEatery = (e, eatery) =>
    {
        Eatery.SetEatery(eatery);
    }

    OnEatery = (uri) =>
    {
        this.setState({
            eatery: Eatery.Eatery(uri),
            expandEateries: false
        });
    }

    render()
    {
        const {className} = this.props;
        const {eatery} = this.state;

        if (!eatery)
        {
            return "";
        }

        const {content, title, uri} = eatery;
        const {
            address,
            deviatingHours,
            email,
            external,
            location,
            openingHours,
            phone
        } = content || {};
        const CA = ["EateryNavigation"];

        if (className)
        {
            CA.push(className);
        }

        const Eateries = Eatery.Eateries();
        const EateryItems = {};

        for (let uri in Eateries)
        {
            EateryItems[uri] = Eateries[uri].title;
        }

        const Address = address.split("\n");
        const AddressItems = [];

        Address.forEach((row, index) =>
        {
            if (!location || index)
            {
                AddressItems.push(<div key={index}>{row}</div>);
            }
            else
            {
                AddressItems.push(<Link className="EateryNavigationContactLink" key={index} href={`https://www.google.com/maps/place/?q=place_id:${location.id}`}>{row}</Link>);
            }
        });
        const Menues = Eatery.Menues(uri);
        const MenuItems = [];
        for (let slug in Menues)
        {
            const {content: menuContent, title: menuTitle} = Menues[slug] || {};
            const {pdf} = menuContent || {};
            if (!menuContent)
            {
                continue;
            }
            if (pdf)
            {
                MenuItems.push(<Link key={slug} href={pdf}>{menuTitle}</Link>);
            }
            else
            {
                MenuItems.push(<Link key={slug} to={`${uri}/${slug}`}>{menuTitle}</Link>);
            }
        }
        return (
            <div className={CA.join(" ")}>
                <div className="EateryNavigationHeader">
                    <Icon className="EateryNavigationHeaderIcon" src="Heart"/>
                    <div className="EateryNavigationHeaderLabel">Mitt lokala</div>
                    <MainLogo link={false}/>
                    <SelectField
                        className="EateryNavigationHeaderToggle"
                        label="Ändra"
                        minimal={true}
                        onChange={this.OnSelectEatery}
                        options={EateryItems}
                        value={uri}
                    />
                </div>
                {external ? (
                    <Link className="EateryNavigationLink notranslate" href={external}>{title}</Link>
                ) : (
                    <Link className="EateryNavigationLink notranslate" to={uri}>{title}</Link>
                )}
                {MenuItems.length ? (
                    <CollapsableView label="Meny">
                        <div className="EateryNavigationMenues">
                            {MenuItems}
                        </div>
                    </CollapsableView>
                ) : ""}
                {openingHours ? (
                    <CollapsableView label="Öppettider">
                        <OpeningHours days={openingHours} deviations={deviatingHours}/>
                    </CollapsableView>
                ) : ""}
                <CollapsableView label="Kontakt">
                    <div className="EateryNavigationContact">
                        {AddressItems}
                    </div>
                    <div className="EateryNavigationContact">
                        {email ? <Link href={`mailto:${email}`}>{email}</Link> : ""}
                        {phone ? <Link href={`tel:${phone}`}>{phone}</Link> : ""}
                    </div>
                </CollapsableView>
            </div>
        );
    }
}

EateryNavigation.defaultProps =
{
    className: ""
};

export default EateryNavigation;