import React from "react";
import "./timeselectfield.scss";

import {ObjectCompare, PadNumber} from "Functions";
import Icon from "Components/Layout/Icon";
import SelectField from "Components/UI/Field/SelectField";
import TextField from "Components/UI/Field/TextField";

class TimeSelectField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.BlurCallback = false;
        this.BlurTimeout = false;

        this.state =
        {
            from1: "",
            from2: "",
            to1: "",
            to2: "",
            value: [-1]
        };
    }

    componentDidMount()
    {
        const {value} = this.props;
        const Value = this.ParseValue(value);
        const [Index, From, To] = Value;
        const State = {value: Value};

        if (Index > 2)
        {
            State[`from${Index - 2}`] = From || "";
            State[`to${Index - 2}`] = To || "";
        }
            
        this.setState(State);
    }

    componentDidUpdate(prevProps)
    {
        const V1 = this.ParseValue(this.props.value);
        const V2 = this.ParseValue(prevProps.value);
        const {value: V3} = this.state.value;

        if (ObjectCompare(V1, V2) || ObjectCompare(V1, V3))
        {
            return;
        }

        const [Index, From, To] = V1;
        const State = {value: V1};
            
        if (Index > 2)
        {
            State[`from${Index - 2}`] = From || "";
            State[`to${Index - 2}`] = To || "";
        }
            
        this.setState(State);
    }

    OnBlur = (callback) =>
    {
        clearTimeout(this.BlurTimeout);
        this.BlurCallback = callback;
        this.BlurTimeout = setTimeout(() =>
        {
            callback();
            this.BlurCallback = false;
        }, 100);
    }

    OnChange = (e, value, id) =>
    {
        const {onChange} = this.props;
        const From = this.state[`from${value - 2}`];
        const To = this.state[`to${value - 2}`];
        const Value = (From && To) ? [value, From, To] : [value];

        this.setState({value: Value});
        onChange(e, Value, id);
    }

    OnTime = (e, time, key) =>
    {
        const {id} = this.props;
        const Index = parseInt(key.substr(key.length - 1, 1), 10) + 2;
        const State = {};
        const Time = String(time);

        if (Time.match(/[:. ]/))
        {
            const [Hour, Minute] = time.split(/[:. ]/);
            const H = parseInt(Hour.replace(/[^\d]/g, ""), 10);
            const M = parseInt(Minute.replace(/[^\d]/g, ""), 10);
            State[key] = (isNaN(H) ? "00" : PadNumber(H)) + ":" + (isNaN(M) ? "00" : PadNumber(M));
        }
        else if (Time.length === 4)
        {
            const H = Time.substr(0, 2);
            const M = Time.substr(2, 2);
            State[key] = (isNaN(H) ? "00" : PadNumber(H)) + ":" + (isNaN(M) ? "00" : PadNumber(M));
        }

        this.setState(State);
        setTimeout(() => this.OnChange(e, Index, id));
    }

    OnTimeBlur = () =>
    {
        this.OnBlur(this.BlurCallback);
    }

    OnTimeFocus = () =>
    {
        clearTimeout(this.BlurTimeout);
    }

    ParseValue = (value) =>
    {
        if (typeof value === "object")
        {
            return value;
        }
        else
        {
            return [value];
        }
    }

    RenderItem = (key, i, selected) =>
    {
        const {from1, from2, to1, to2} = this.state;
        let Info, Label;
        const TextFieldProps =
        {
            hollow: true,
            maxLength: 5,
            onBlur: this.OnTimeBlur,
            onChange: this.OnTime,
            onFocus: this.OnTimeFocus,
            pattern: "[0-9]*",
            placeholder: "00:00",
            type: "text"
        };
        
        switch(key)
        {
            case 0:
                Info = "Kl 08:00–12:00";
                Label = "Förmiddag";
                break;

            case 1:
                Info = "Kl 13:00–17:00";
                Label = "Eftermiddag";
                break;

            case 2:
                Info = "Kl 08:00–17:00";
                Label = "Heldag";
                break;

            case 3:
                Info = [
                    "Kl", <TextField id="from1" key="c1" value={from1} {...TextFieldProps}/>,
                    "–", <TextField id="to1" key="c2" value={to1} {...TextFieldProps}/>
                ];
                Label = "Kväll";
                break;

            case 4:
                Info = [
                    "Kl", <TextField id="from2" key="c1" value={from2} {...TextFieldProps}/>,
                    "–", <TextField id="to2" key="c2" value={to2} {...TextFieldProps}/>
                ];
                Label = "Annan";
                break;

            default:
                return "";
        }

        return (
            <div className="TimeSelectFieldItem">
                <div className="TimeSelectFieldItemIcon">
                    <Icon src={selected ? "Checked" : "Square"}/>
                </div>
                <div className="TimeSelectFieldItemLabel">{Label}</div>
                <div className="TimeSelectFieldItemInfo">{Info}</div>
            </div>
        );
    }

    RenderValue = (key) =>
    {
        const {from1, from2, to1, to2} = this.state;
        let Time;

        switch(key)
        {
            case 0:
                return "Förmiddag, kl 08:00–12:00";

            case 1:
                return "Eftermiddag, kl 13:00–17:00";

            case 2:
                return "Heldag, kl 08:00–17:00";

            case 3:
                Time = (from1 && to1) ? `, kl ${from1}–${to1}` : "";
                return "Kväll" + Time;

            case 4:
                Time = (from2 && to2) ? `, kl ${from2}–${to2}` : "";
                return "Annan" + Time;

            default:
                return "";
        }
    }

    render()
    {
        const {className, placeholder} = this.props;
        const {value} = this.state;
        const [SelectedIndex] = value;
        const CA = ["TimeSelectField"];

        if (className)
        {
            CA.push(className);
        }

        return (
            <SelectField
                {...this.props}
                className={CA.join(" ")}
                closeOnSelect={false}
                onBlurCollapse={this.OnBlur}
                onChange={this.OnChange}
                onRenderItem={this.RenderItem}
                onRenderValue={this.RenderValue}
                options={5}
                placeholder={placeholder || "Välj tid"}
                placeholderOption={false}
                value={SelectedIndex}
            />
        );
    }
}

TimeSelectField.defaultProps =
{
    className: "",
    onChange: () => {},
    placeholder: "",
    value: -1
};

export default TimeSelectField;