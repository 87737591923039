import React from "react";
import "./menunew.scss";
import Eatery from "Class/Eatery";
import Globals from "Class/Globals";
import Parser from "Class/Parser";
import Button from "Components/UI/Button";
import LoadImage from "Components/Layout/LoadImage";
import MainLogo from "Components/UI/MainLogo";
import Segment from "Components/Layout/Segment";
import ImgBackground from "./background.jpg";
import ImgBadge from "./badge.png";
import ImgBorderLeft from "./border-left.png";
import ImgBorderRight from "./border-right.png";

class TemplateMenuNew extends React.Component
{
    render()
    {
        const {content, id, title} = this.props;
        const {content: menuContent, eatery, footerText, pdf, title: menuTitle} = content || {};
        const CA = ["TemplateMenuNew"];
        const Title = menuTitle || title;
        const LocationName = eatery.length === 1 ? Eatery.EateryName(eatery[0]) : "";
        const PrintHref = Eatery.Url(`skriv-ut/?id=${id}`);
        if (pdf)
        {
            return (
                <div className="TemplateMenuNew">
                    <Segment
                        backgroundColor="Beige"
                        top={true}
                    >
                        <h1 className="TemplateMenuNewTitle">{Title}</h1>
                        <div className="TemplateMenuNewTray">
                            <Button
                                color="Black"
                                href={pdf}
                                label="Ladda ner som PDF"
                            />
                        </div>
                    </Segment>
                </div>
            );
        }
        return (
            <div className={CA.join(" ")}>
                <Segment backgroundColor="White" className="TemplateMenuNewPageContainer" top={true}>
                    <div className="TemplateMenuNewPage">
                        <LoadImage className="TemplateMenuNewBackground" src={ImgBackground}/>
                        <LoadImage className="TemplateMenuNewBorder Left" src={ImgBorderLeft}/>
                        <LoadImage className="TemplateMenuNewBorder Right" src={ImgBorderRight}/>
                        <div className="TemplateMenuNewPageContentTop">
                            <MainLogo/>
                            <div className="TemplateMenuNewPageContentTopInfo">
                                {LocationName ? <span className="notranslate">{LocationName}</span> : ""}
                                <span>{Title}</span>
                            </div>
                        </div>
                        <div className="TemplateMenuNewPageContent">
                            {Parser.Parse(menuContent, "div", null, {className: "TemplateMenuNewPageContentText"})}
                        </div>
                        <div className="TemplateMenuNewPageContentBottom">
                            {footerText ? Parser.Parse(footerText, "div", null, {className: "TemplateMenuNewPageContentBottomText"}) : ""}
                            <h2>EATERY.SE</h2>
                        </div>
                        <LoadImage className="TemplateMenuNewBadge" src={ImgBadge}/>
                    </div>
                    {!Globals.Setting("HidePrint") ? (
                        <div className="TemplateMenuNewTray">
                            <Button
                                color="Black"
                                href={PrintHref}
                                label="Ladda ner som PDF"
                            />
                        </div>
                    ) : ""}
                </Segment>
            </div>
        );
    }
}

TemplateMenuNew.defaultProps =
{
    content: {}
};

export default TemplateMenuNew;