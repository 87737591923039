import Eatery from 'Class/Eatery';
import Globals from 'Class/Globals';
import Parser from 'Class/Parser';
import Button from 'Components/UI/Button';
import React from 'react';
import './announcements.scss';

class Announcements extends React.Component {
    constructor(props) {
        super(props);
        this.state =
            {
                announcement: null,
            };
    }

    componentDidMount() {
        Globals.Listen('announcement', this.OnAnnouncement);
        this.setState({announcement: Eatery.GetAnnouncement()});
    }

    componentWillUnmount() {
        Globals.Remove('announcement', this.OnAnnouncement);
    }

    OnAnnouncement = (announcement) => {
        this.setState({announcement});
    };

    OnClose = (e, hash) => {
        Eatery.CloseAnnouncement(hash);
    };

    render() {
        const {announcement} = this.state;
        if (!announcement) {
            return '';
        }
        const {buttons, hash, message} = announcement;
        const Buttons = [];
        buttons.forEach(({label, page, type, url}, index) => {
            const Label = type === 'internal'
                ? label || Eatery.GetTitle(page)
                : label;
            if (!Label) {
                return;
            }
            Buttons.push(
                <Button
                    hollow={true}
                    href={type === 'external' ? url : ''}
                    key={index}
                    label={Label}
                    small={true}
                    target={type === 'external' ? '_blank' : '_self'}
                    to={type === 'internal' ? page : ''}
                />,
            );
        });
        return (
            <div className="Announcements">
                <div className="Announcement" key={hash}>
                    <div className="AnnouncementButtons">
                        {Buttons}
                        <Button
                            id={hash}
                            label="Stäng"
                            onClick={this.OnClose}
                            small={true}
                        />
                    </div>
                    <div className="AnnouncementMessageWrapper">
                        {Parser.Parse(
                            message,
                            'div',
                            false,
                            {className: 'AnnouncementMessage'},
                        )}
                    </div>
                    <div className="AnnouncementButtonsNarrow">
                        {Buttons}
                        <Button
                            id={hash}
                            label="Stäng"
                            onClick={this.OnClose}
                            small={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Announcements;
