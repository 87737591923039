import React from "react";
import "./checkboxfield.scss";

import Icon from "Components/Layout/Icon";

class CheckboxField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Checked = false;

        this.state =
        {
            checked: false
        };
    }

    componentDidMount()
    {
        const {checked} = this.props;
        this.Checked = checked;
        this.setState({checked});
    }

    componentDidUpdate()
    {
        const {checked} = this.props;

        if (checked !== this.Checked)
        {
            this.Checked = checked;    
            this.setState({checked});
        }
    }

    OnBlur = (e) =>
    {
        const {disabled, id, onBlur} = this.props;
        const {checked} = this.props;

        if (disabled)
        {
            return;
        }

        onBlur(e, checked, id);
        this.setState({focus: false});
    }

    OnClick = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        let {checked} = this.state;

        if (disabled)
        {
            return;
        }

        checked = !checked;
        this.setState({checked});
        onChange(e, checked, id);
    }

    OnFocus = (e) =>
    {
        const {disabled, id, onFocus} = this.props;
        const {checked} = this.props;

        if (disabled)
        {
            return;
        }

        onFocus(e, checked, id);
        this.setState({focus: true});
    }

    OnKeyDown = (e) =>
    {
        switch (e.which)
        {
            case 32:
                this.OnClick(e);
                break;
            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
    }

    Reset = () =>
    {
        const {checked} = this.props;
        this.setState({checked});
    }

    render()
    {
        const {children, className, disabled, error, label, title} = this.props;
        const {checked, focus} = this.state;
        const CA = ["Field", "CheckboxField"];
        
        if (checked)
        {
            CA.push("Checked");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (label)
        {
            CA.push("HasLabel");
        }
        else
        {
            CA.push("NoLabel");
        }
        if (className)
        {
            CA.push(className);
        }

        const Label = label || children;

        return (
            <div className={CA.join(" ")}>
                <div
                    className="Input" onClick={this.OnClick}
                    onBlur={this.OnBlur}
                    onFocus={this.OnFocus}
                    onKeyDown={this.OnKeyDown}
                    onMouseDown={e => e.stopPropagation()}
                    title={title || label}
                    tabIndex="0"
                >
                    <Icon src={checked ? "Checked" : "Square"}/>
                </div>

                {Label ? <div
                    className="CheckboxFieldLabel"
                    onClick={label ? this.OnClick : null}
                >
                    {Label}
                </div> : ""}
            </div>
        );
    }
}

CheckboxField.defaultProps =
{
    checked: false,
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    title: ""
};

export default CheckboxField