import React from "react";
import "./independent.scss";

import Globals from "Class/Globals";
import Modules from "Components/View/Modules";

class TemplateIndependent extends React.Component
{
    componentDidMount()
    {
        Globals.Var("independent", true);
    }

    componentWillUnmount()
    {
        Globals.Var("independent", false);
    }

    render()
    {
        const {content} = this.props;
        const {contents} = content || {};

        return (
            <div className="TemplateIndependent">
                <Modules modules={contents}/>
            </div>
        );
    }
}

TemplateIndependent.defaultProps =
{
    content: {}
};

export default TemplateIndependent;