
/**!
 *  Media gallery.
 * 
 *  @prop string className - Append a class name.
 *  @prop string id - Identifier for this gallery used during callbacks.
 *  @prop integer index - Active item index.
 *  @prop float switchInterval - Number of seconds between auto-navigation. '0' means no auto-navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./gallery.scss";

import {TweenLite} from "gsap";
import {Power1} from "gsap/all";
import DotNavigation from "Components/UI/DotNavigation";

class Gallery extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Block = false;
        this.Gallery = false;
        this.Index = -1;
        this.Items = [];
        this.Mounted = false;
        this.Origin = 0;
        this.Player = false;
        this.SwitchInterval = false;
        this.Touch = false;
        this.Width = 0;
        this.state =
        {
            index: 0,
            numItems: 0
        };
    }

    /*
     * Count the number of items and reset their offset on mount.
     * 
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {children, index} = this.props;
        const NumItems = children ? children.length : 0;

        if (this.Gallery)
        {
            this.Width = this.Gallery.offsetWidth;
        }

        this.Index = index;
        this.setState({index, numItems: NumItems});
        this.IntervalReset();
        setTimeout(() => this.SetOffset(), 0);

        window.addEventListener("resize", this.Adjust);
    }

    /*
     * Update index and recount items when new props are received.
     * 
     * @return void
     */

    componentDidUpdate()
    {
        const {children, index} = this.props;
        const {numItems} = this.state;
        const NumItems = children ? children.length : 0;

        if (NumItems !== numItems)
        {
            this.setState({index, numItems: NumItems});
            setTimeout(() => this.SetOffset(), 0);
        }
        else if (index !== this.Index)
        {
            this.Index = index;
            this.OnNavigation(null, index);
        }
    }

    /*
     * Stop auto-navigation on unmount.
     * 
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        this.IntervalClear();
        window.removeEventListener("resize", this.Adjust);
    }

    AddListeners = (gallery) =>
    {
        if (!gallery)
        {
            return;
        }

        gallery.addEventListener("mousedown", this.OnDragStart);
        gallery.addEventListener("mouseover", this.OnMouseOver);
        gallery.addEventListener("mouseout", this.OnMouseOut);
        gallery.addEventListener("touchstart", this.OnTouchStart);
        gallery.addEventListener("touchmove", this.OnTouchMove, {passive: false});
        gallery.addEventListener("touchend", this.OnTouchEnd);
    }

    /*
     * Adjust the appearance of the gally so that the slides have the correct position and size.
     * 
     * @return void
     */

    Adjust = () =>
    {
        if (!this.Gallery)
        {
            return;
        }

        this.Width = this.Gallery.offsetWidth;
        this.SetOffset(0);
    }

    /*
     * Clear the auto-navigation interval.
     * 
     * @return void
     */

    IntervalClear = () =>
    {
        clearInterval(this.SwitchInterval);
    }

    /*
     * Check if a slide contains a video.
     *
     * @param integer index - Slide index.
     * 
     * @return object|boolean - Video player element or 'false'.
     */

    IsVideo = (index) =>
    {
        const Slide = this.Items[index];

        if (!Slide)
        {
            return false;
        }

        return Slide.getElementsByTagName("video")[0] || false;
    }

    /*
     * Reset the auto-navigation interval.
     *
     * @param float seconds - Optional. Set interval length. Defaults to switchInterval from props.
     * 
     * @return void
     */

    IntervalReset = (seconds) =>
    {
        const {children, switchInterval} = this.props;
        const {index} = this.state;
        const Seconds = seconds || switchInterval;
        const Video = this.IsVideo(index);

        this.IntervalClear();

        if (children.length > 1 && this.Player && this.Player !== Video)
        {
            this.Player.pause();
            this.VideoReset(this.Player);
            this.Player = false;
        }

        if (children.length > 1 && Video && this.Player !== Video)
        {
            this.Player = Video;
            this.VideoReset(this.Player);
            this.Player.addEventListener("ended", this.OnVideoEnd);
            this.Player.play();
        }

        if (this.Player || !Seconds)
        {
            return;
        }

        // IntervalReset is called from within OnNavigation, so there is no need to use an actual interval.
        this.SwitchInterval = setTimeout(() =>
        {
            const {index, numItems} = this.state;
            const NewIndex = index < numItems - 1 ? index + 1 : 0;
            this.OnNavigation(null, NewIndex);
        }, Seconds * 1000);
    }

    /*
     * Output a gallery item.
     *
     * @param JSX content - Item content.
     * @param integer i - Item index.
     * 
     * @return void
     */

    Item = (content, i) =>
    {
        return (
            <div className="GalleryItem" key={i} ref={item => this.Items[i] = item}>
                {content}
            </div>
        );
    }

    /*
     * Callback when drag stops.
     *
     * @param object e - The mouse event.
     * @param boolean touchEvent - Whether this event has been delegated from OnTouchEnd,
     * 
     * @return void
     */

    OnDragEnd = (e, touchEvent) =>
    {
        const DX = e.pageX - this.Origin;

        if (DX)
        {
            const {id, onSwitch} = this.props;
            const {index, numItems} = this.state;
            const L = (numItems - 1) * this.Width;
            const O = DX > L ? L : (DX < -L ? -L : DX);
            const P = -O / this.Width;
            const D = Math.round(P);
            const R = (P - D) * this.Width;
            const NewIndex = (D < 0 && D < -index) ? numItems + D : (index + D) % numItems;

            this.Block = true;

            for (let i = 0; i < numItems; i++)
            {
                const Item = this.Items[i];
                const From = Item.gOffset;
                const To = From + R;

                TweenLite.fromTo(Item, .25, {
                    x: From
                }, {
                    x: To,
                    ease: Power1.easeInOut,
                    onComplete: () =>
                    {
                        Item.gOffset = To;
                        this.Block = false;
                        // Restart the auto-navigation.
                        this.IntervalReset();
                    }
                });
            }   

            onSwitch(NewIndex, id);
            this.setState({index: NewIndex});
        }

        this.Origin = 0;

        if (!touchEvent)
        {
            window.removeEventListener("mousemove", this.OnDragMove);
            window.removeEventListener("mouseup", this.OnDragEnd);
        }
    }

    /*
     * Callback when dragged.
     *
     * @param object e - The mouse event.
     * 
     * @return void
     */

    OnDragMove = (e) =>
    {
        this.SetOffset(e.pageX - this.Origin);
    }

    /*
     * Callback when a drag is initiated.
     *
     * @param object e - The mouse event.
     * @param boolean touchEvent - Whether this event has been delegated from OnTouchStart,
     * 
     * @return void
     */

    OnDragStart = (e, touchEvent) =>
    {
        // Only drag with left mouse button.
        if (this.Block || (e.button !== 0 && !touchEvent) || !this.Gallery)
        {
            return;
        }
        // Halt auto-navigation while dragging.
        this.IntervalClear();
        this.Origin = e.pageX;
        this.Width = this.Gallery.offsetWidth;

        if (!touchEvent)
        {
            window.addEventListener("mousemove", this.OnDragMove);
            window.addEventListener("mouseup", this.OnDragEnd);
        }
    }

    /*
     * Reset the auto-navigation when the cursor leaves the gallery items container.
     * 
     * @return void
     */

    OnMouseOut = () =>
    {
        // Don't reset during user interaction (drag).
        if (this.Origin)
        {
            return;
        }

        this.IntervalReset();
    }

    /*
     * Clear the auto-navigation when the cursor enters the gallery items container.
     * This allows the user to intuitively pause the gallery slideshow.
     * 
     * @return void
     */

    OnMouseOver = () =>
    {
        this.IntervalClear();
    }

    /*
     * Callback when a dot is clicked in the navigation
     *
     * @param object e - The click event.
     * @param integer newIndex - The index.
     * 
     * @return void
     */

    OnNavigation = (e, newIndex) =>
    {
        const {id, onSwitch} = this.props;
        const {index, numItems} = this.state;
        const Delta = (newIndex - index) * this.Width;
        this.IntervalReset();
        this.Block = true;

        for (let n = 0; n < numItems; n++)
        {
            const Index = Delta > 0 ? (index + n) % numItems : (n > index ? numItems + index - n : index - n);
            const From = Delta > 0 ? n * this.Width : n * this.Width * -1;
            const To = From - Delta;
            const Item = this.Items[Index];

            if (!Item)
            {
                continue;
            }

            TweenLite.fromTo(Item, .25, {
                x: From
            }, {
                x: To,
                ease: Power1.easeInOut,
                onComplete: () =>
                {
                    Item.gOffset = To;
                    this.Block = false;
                    this.IntervalReset();
                }
            });
        }

        onSwitch(newIndex, id);
        this.setState({index: newIndex});
    }

    /*
     * Callback when touch drag ends.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchEnd = (e) =>
    {
        if (!this.Touch)
        {
            return;
        }

        this.OnDragEnd(this.Touch, true);
    }

    /*
     * Callback when touch dragged.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchMove = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();

        // Save the latest touch move event in order to get its' position from OnTouchEnd.
        this.Touch = e.touches[0];
        this.OnDragMove(e.touches[0]);
    }

    /*
     * Callback when a touch drag is initiated.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchStart = (e) =>
    {
        this.OnDragStart(e.touches[0], true);
    }

    /*
     * Reset the video player and show next slide when it has ended.
     * 
     * @return void
     */

    OnVideoEnd = (e) =>
    {
        if (!this.Player || !this.Mounted)
        {
            return;
        }

        const NextIndex = (this.state.index + 1) % this.props.children.length;
        this.VideoReset(this.Player);
        this.OnNavigation(e, NextIndex);
    }

    /*
     * Set the horisontal offset of the gallery items.
     *
     * @param integer offset - The horisontal offset.
     * 
     * @return void
     */

    SetOffset = (offset = 0) =>
    {
        const {index, numItems} = this.state;
        const L = (numItems - 1) * this.Width;
        const O = offset > L ? L : (offset < -L ? -L : offset);

        for (let n = 0; n < numItems; n++)
        {
            const Index = O < 0 ? (index + n) % numItems : (n > index ? numItems + index - n : index - n);
            const Offset = O < 0 ? n * this.Width + O : n * this.Width * -1 + O;
            const Item = this.Items[Index];

            if (!Item)
            {
                continue;
            }

            Item.gOffset = Offset;
            Item.style.transform = `translate3d(${Offset}px,0,0)`;
        }
    }

    /*
     * Reset a video player to its' original state.
     * @return void
     */

    VideoReset = (player) =>
    {
        player.currentTime = 0;
        player.loop = false;
        player.removeEventListener("ended", this.OnVideoEnd);
    }

    render()
    {
        const {children, className, showArrows} = this.props;
        const {index, numItems} = this.state;
        const CA = ["Gallery"];

        if (!children || !children.length)
        {
            return "";
        }
        if (className)
        {
            CA.push(className);
        }

        const Items = [];

        children.forEach((child, index) =>
        {
            Items.push(this.Item(child, index));
        });

        return (
            <div className={CA.join(" ")} ref={gallery => this.Gallery = gallery}>
                <div
                    className="GalleryItems"
                    ref={this.AddListeners}
                >
                    {Items}
                </div>

                {Items.length > 1 ? <DotNavigation
                    active={index}
                    className="GalleryNavigation"
                    dots={numItems}
                    onClick={this.OnNavigation}
                    showArrows={showArrows}
                /> : ""}
            </div>
        );
    }
}

Gallery.defaultProps =
{
    className: "",
    id: "",
    index: 0,
    onSwitch: () => {},
    showArrows: true,
    switchInterval: 7
};

export default Gallery;