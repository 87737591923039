import React from "react";
import "./alttop.scss";

import Eatery from "Class/Eatery";
import {UcFirst} from "Functions";
import Button from "Components/UI/Button";
import Full from "Components/Layout/Full";
import MainLogo from "Components/UI/MainLogo";
import Segment from "Components/Layout/Segment";

class ModuleAltTop extends React.Component
{
    render()
    {
        const {
            backgroundColor,
            backgroundImage,
            backgroundPositionH,
            backgroundPositionV,
            backgroundVideo,
            buttons,
            buttonsDirection,
            intro,
            textColor,
            title
        } = this.props;
        const Buttons = [];
        const CA = ["SegmentButtons", "Buttons", `Buttons${UcFirst(buttonsDirection) || "Column"}`];
        buttons.forEach((button, index) =>
        {
            const {action, color, hollow, label, page, type, url} = button;

            switch (type)
            {
                case "action":
                    Buttons.push(
                        <Button
                            color={color}
                            hollow={hollow}
                            key={index}
                            label={label}
                            onClick={() => Eatery.Action(action)}
                        />
                    );
                    break;

                case "link":
                    Buttons.push(
                        <Button
                            color={color}
                            hollow={hollow}
                            href={url}
                            key={index}
                            label={label}
                        />
                    );
                    break;

                default:
                    Buttons.push(
                        <Button
                            color={color}
                            hollow={hollow}
                            key={index}
                            label={label || Eatery.GetTitle(page)}
                            to={page}
                        />
                    );
            }
        });

        return (
            <Segment backgroundColor={backgroundColor} className="Module ModuleAltTop">
                <MainLogo/>
                <Full
                    backgroundColor={backgroundColor}
                    backgroundImage={backgroundImage}
                    backgroundPositionH={backgroundPositionH}
                    backgroundPositionV={backgroundPositionV}
                    backgroundVideo={backgroundVideo}
                    className="Module ModuleAltTop"
                    textColor={textColor}
                >
                    {title ? <h1>{title}</h1> : ""}
                    {intro ? <p>{intro}</p> : ""}
                    {Buttons.length ? <div className={CA.join(" ")}>{Buttons}</div> : ""}
                </Full>
            </Segment>
        );
    }
}

ModuleAltTop.defaultProps =
{
    backgroundColor: "white",
    backgroundImage: "",
    backgroundPositionH: 50,
    backgroundPositionV: 50,
    backgroundVideo: "",
    buttons: [],
    index: 0,
    intro: "",
    textColor: "white",
    title: ""
};

export default ModuleAltTop;