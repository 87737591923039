
import React from "react";
import "./eaterycard.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import CardLogo from "Components/Layout/CardLogo";

class EateryCard extends React.Component
{
    render()
    {
        const RegisterUrl = Eatery.Option("eateryRegisterUrl");
        const DepositUrl = Eatery.Option("eateryDepositUrl");
        return (
            <div className="EateryCard">
                <CardLogo/>
                {Parser.Parse(Eatery.Option("eateryCardInfo"), "div", null, {className: "EateryCardContent"})}
                <div className="DialogButtons">
                    {RegisterUrl ? <Button
                        hollow={true}
                        href={RegisterUrl}
                        label="Registrera kort"
                    /> : ""}
                    {DepositUrl ? <Button
                        color="Black"
                        href={DepositUrl}
                        label="Fyll på kort"
                    /> : ""}
                </div>
            </div>
        );
    }
}

export default EateryCard;