
import React from "react";
import "./form.scss";

import {UcFirst} from "Functions";

import CheckboxField from "Components/UI/Field/CheckboxField";
import DateField from "Components/UI/Field/DateField";
import FoodSelectField from "Components/UI/Field/FoodSelectField";
import Help from "Components/UI/Help";
import RadiosField from "Components/UI/Field/RadiosField";
import RepeatingFields from "Components/UI/Field/RepeatingFields";
import SelectField from "Components/UI/Field/SelectField";
import TextField from "Components/UI/Field/TextField";
import TextareaField from "Components/UI/Field/TextareaField";
import TimeSelectField from "Components/UI/Field/TimeSelectField";

class Form extends React.Component
{
    componentDidMount()
    {
        this.CheckMandatory();
    }

    CheckMandatory = (input, check, callback = true) =>
    {
        const {fields, id, onCheck, values} = this.props;
        const Fields = input || fields;
        const Check = check || {ready: true};
        for (let id in Fields)
        {
            const {mandatory, subFields, type} = Fields[id];
            if (subFields && subFields.length)
            {
                if (!this.CheckMandatory(subFields, Check, false))
                {
                    break;
                }
            }
            else if (!mandatory)
            {
                continue;
            }
            else
            {
                switch (type)
                {
                    case "email":
                        if (!values[id] || !values[id].match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
                        {
                            Check.ready = false;
                        }
                        break;
                    default:
                        if (!values[id])
                        {
                            Check.ready = false;
                        }
                }
            }
            if (!Check.ready)
            {
                break;
            }
        }
        if (callback)
        {
            onCheck(null, Check.ready, id);
        }
        return Check.ready;
    }

    Disabled = (id) =>
    {
        const {disabled} = this.props;
        if (disabled === true)
        {
            return true;
        }
        if (typeof disabled === "object")
        {
            return disabled[id] === undefined ? false : disabled[id];
        }
        return false;
    }

    GetValue = (id, defaultValue = "", fetch = false) =>
    {
        const {id: formId, onValue, values} = this.props;
        if (values && values[id])
        {
            return values[id];
        }
        if (fetch)
        {
            return onValue(id, formId) || defaultValue;
        }
        return defaultValue;
    }

    OnBlur = (e, value, id) =>
    {
        const {id: formId, onBlur} = this.props;
        onBlur(e, formId, id);
    }

    OnField = (e, value, id) =>
    {
        if (this.Disabled(id))
        {
            return;
        }

        const {id: formId, onChange} = this.props;
        onChange(e, value, id, formId);
        this.CheckMandatory();
    }

    OnFocus = (e, value, id) =>
    {
        const {id: formId, onFocus} = this.props;
        onFocus(e, formId, id);
    }

    ParseParameter = (value, defaultValue = "") =>
    {
        if (typeof value === "function")
        {
            return value();
        }
        if (typeof value === "string" && value[0] === "$")
        {
            return this.GetValue(value.substr(1), defaultValue, true);
        }
        return value;
    }

    render()
    {
        const {className, fields} = this.props;
        const CA = ["Form"];
        if (className)
        {
            CA.push(className);
        }
        const Fields = [];
        let Z = Object.keys(fields).length;
        for (let id in fields)
        {
            const {
                alignRight,
                categories,
                defaultPeople,
                fields: subFields,
                half,
                halfNarrow,
                help,
                label,
                limitLower,
                limitUpper,
                mandatory,
                maxRows,
                minRows,
                multiple,
                numRepeats,
                onRenderItem,
                onRenderTag,
                onRenderValue,
                onValue,
                options,
                placeholder,
                redLetters,
                right,
                text,
                type,
                value: defaultValue
            } = fields[id];
            const Type = UcFirst(type);
            const FieldCA = ["FieldWrapper", `FieldWrapper${Type}`];
            const HelpNotice = this.ParseParameter(help);
            let Label = label ? this.ParseParameter(label) + (mandatory ? "*" : "") : "";
            let Field;
            if (halfNarrow)
            {
                FieldCA.push("HalfNarrow");
            }
            else if (half)
            {
                FieldCA.push("Half");
            }
            if (right)
            {
                FieldCA.push("Right");
            }
            if (HelpNotice)
            {
                Label = <span>{Label} <Help>{HelpNotice}</Help></span>;
            }
            switch (type)
            {
                case "checkbox":
                    Field = <CheckboxField
                        checked={this.GetValue(id, defaultValue)}
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                    >{text}</CheckboxField>
                    break;
                case "date":
                    Field = <DateField
                        alignRight={alignRight}
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        limitLower={this.ParseParameter(limitLower)}
                        limitUpper={this.ParseParameter(limitUpper)}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        redLetters={this.ParseParameter(redLetters)}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                case "food":
                    Field = <FoodSelectField
                        categories={this.ParseParameter(categories)}
                        defaultPeople={this.ParseParameter(defaultPeople)}
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        placeholder={this.ParseParameter(placeholder)}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                case "radios":
                    Field = <RadiosField
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        options={this.ParseParameter(options)}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                case "repeating":
                    Field = <RepeatingFields
                        disabled={this.Disabled(id)}
                        fields={this.ParseParameter(subFields)}
                        id={id}
                        label={Label}
                        numRepeats={this.ParseParameter(numRepeats)}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        onRenderTag={onRenderTag}
                        onValue={onValue}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                case "select":
                    Field = <SelectField
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        multiple={this.ParseParameter(multiple)}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        onRenderItem={onRenderItem}
                        onRenderValue={onRenderValue}
                        options={this.ParseParameter(options)}
                        placeholder={this.ParseParameter(placeholder)}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                case "textarea":
                    Field = <TextareaField
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        maxRows={this.ParseParameter(maxRows)}
                        minRows={this.ParseParameter(minRows)}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        onInput={this.OnField}
                        placeholder={this.ParseParameter(placeholder)}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                case "timeselect":
                    Field = <TimeSelectField
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        placeholder={this.ParseParameter(placeholder)}
                        value={this.GetValue(id, defaultValue)}
                    />;
                    break;

                default:
                    Field = <TextField
                        disabled={this.Disabled(id)}
                        id={id}
                        label={Label}
                        onBlur={this.OnBlur}
                        onChange={this.OnField}
                        onFocus={this.OnFocus}
                        onInput={this.OnField}
                        placeholder={this.ParseParameter(placeholder)}
                        type={type}
                        value={this.GetValue(id, defaultValue)}
                    />;
            }
            Fields.push(
                <div className={FieldCA.join(" ")} key={id} style={{zIndex: Math.abs(--Z)}}>
                    {Field}
                </div>
            );
        }
        return (
            <div className={CA.join(" ")}>
                {Fields}
            </div>
        );
    }
}

Form.defaultProps =
{
    disabled: false,
    id: "",
    className: "",
    fields: {},
    onBlur: () => {},
    onChange: () => {},
    onCheck: () => {},
    onFocus: () => {},
    onValue: () => {},
    values: {}
}

export default Form;