import React from "react";
import "./footernavigation.scss";

import Eatery from "Class/Eatery";
import Navigation from "Components/UI/Navigation";

class FooterNavigation extends React.Component
{
    render()
    {
        return (
            <Navigation
                className="FooterNavigation"
                collapsables={true}
                hideCollapsed={false}
                iconCollapse="Plus"
                items={Eatery.Option("footerNavigation", [])}
            />
        );
    }
}

export default FooterNavigation;