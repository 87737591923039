import React from "react";
import "./aboutus.scss";

import Parser from "Class/Parser";

import Icon from "Components/Layout/Icon";
import LoadImage from "Components/Layout/LoadImage";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";
import ThreeColumns from "Components/Layout/ThreeColumns";

class TemplateAboutUs extends React.Component
{
    render()
    {
        const {content, title} = this.props;
        const {
            contents,
            image,
            imagePositionH,
            imagePositionV,
            promiseGroups,
            selectedPromises,
            text
        } = content || {};
        const Promises = [];
        const SelectedPromises = [];

        promiseGroups.forEach((group, groupIndex) =>
        {
            const {promises, title} = group;
            const Items = [];

            promises.forEach((promise, index) =>
            {
                Items.push(<li key={index}><Icon className="ListCheck" src="Check"/>{promise.promise}</li>)
            });

            Promises.push(
                <div className="TemplateAboutUsPromiseGroup" key={groupIndex}>
                    <h3>Vi <Icon src="Heart"/> {title}</h3>
                    <ul>
                        {Items}
                    </ul>
                </div>
            );
        });

        selectedPromises.forEach((promise, index) =>
        {
            SelectedPromises.push(
                <div className="TemplateAboutUsPromisesSelectedItem" key={index}>
                    <h4>No. {index + 1}</h4>
                    <p>{promise.text}</p>
                </div>
            );
        });

        return (
            <div className="TemplateAboutUs">
                <Segment top={true}>
                    <ThreeColumns>
                        <div className="TemplateAboutUsTop">
                            <h5>{title}</h5>
                            {Parser.Parse(text, "div", null, {className: "TemplateAboutUsText"})}
                            <div className="TemplateAboutUsPromisesSelected ColorBeige">
                                <h3>Några av våra löften</h3>
                                {SelectedPromises}
                            </div>
                            {image ? (
                                <div className="TemplateAboutUsImage">
                                    <LoadImage
                                        autoAdjust={true}
                                        src={image}
                                        style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}
                                    />
                                </div>
                            ) : ""}
                        </div>
                    </ThreeColumns>
                </Segment>
                <Segment backgroundColor="black">
                    <div className="TemplateAboutUsPromises">
                        <h3>Alla våra löften</h3>
                        {Promises}
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={2}/>
            </div>
        );
    }
}

TemplateAboutUs.defaultProps =
{
    content: {}
};

export default TemplateAboutUs;