import React from "react";
import "./box.scss";
import {UcFirst} from "Functions";

class Box extends React.Component
{
    render()
    {
        const {children, className, color} = this.props;
        const Color = color ? UcFirst(color) : "Black";
        const CA = ["Box", `Color${Color}`];

        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {children}
            </div>
        );
    }
}

Box.defaultProps =
{
    className: "",
    color: "black"
};

export default Box;