import Booking from 'Class/Booking';
import Eatery from 'Class/Eatery';
import Globals from 'Class/Globals';
import Parser from 'Class/Parser';
import Full from 'Components/Layout/Full';
import Icon from 'Components/Layout/Icon';
import LoadImage from 'Components/Layout/LoadImage';
import LoadVideo from 'Components/Layout/LoadVideo';
import LocalLogo from 'Components/Layout/LocalLogo';
import Segment from 'Components/Layout/Segment';
import ThreeColumns from 'Components/Layout/ThreeColumns';

import Button from 'Components/UI/Button';
import FixedButton from 'Components/UI/FixedButton';
import Gallery from 'Components/UI/Gallery';
import ItemRoom from 'Components/UI/ItemRoom';
import ItemStaff from 'Components/UI/ItemStaff';
import Link from 'Components/UI/Link';
import Links from 'Components/UI/Links';
import Map from 'Components/UI/Map';
import Tab from 'Components/UI/Tab';
import Tag from 'Components/UI/Tag';
import { UcFirst } from 'Functions';
import React from 'react';
import './location.scss';
import { getHash } from '../../../../Utils/Url';

class TemplateLocation extends React.Component {
    constructor(props) {
        super(props);

        const hash = getHash();
        this.state = {
            tab: ['conference', 'event'].indexOf(hash) !== -1
                ? hash
                : 'conference',
        };
    }

    Item = (meetingroom, id) => {
        const {content, tags, title, uri} = meetingroom;

        if (this.state.tab === 'conference') {
            if (tags.indexOf('Konferens') === -1) {
                return null;
            }
        } else if (this.state.tab === 'event') {
            if (tags.indexOf('Fest') === -1 || tags.indexOf('Event') === -1) {
                return null;
            }
        }

        const {
            eatery,
            image,
            image_party,
            imagePositionH,
            imagePositionV,
            peopleMin,
            peopleMax,
        } = content || {};
        const E = Eatery.Eatery(eatery);
        const EateryName = E ? E.title : '';
        const ExternalBooking= E.content.external_booking_url;

        const buttons = [
            {hollow: true, label: 'Läs mer', to: uri},
        ];

        if(!ExternalBooking) {
            buttons.push({
                color: 'Black',
                id,
                label: 'Bokningsförfrågan',
                onClick: this.OnRoom,
            })
        } else {
            buttons.push({
                color: 'Black',
                label: 'Boka',
                href: ExternalBooking,
            })
        }

        return (
            <ItemRoom
                buttons={buttons}
                className="TemplateConferenceItem"
                image={
                    this.state.tab === 'conference'
                        ? image
                        : image_party
                            ? image_party
                            : image
                }
                imagePositionH={imagePositionH}
                imagePositionV={imagePositionV}
                key={id}
                subTitle={EateryName}
                tags={tags}
                title={title}
                top={[
                    <Icon key="icon" src="People" />,
                    peopleMin
                        ? `${peopleMin} – ${peopleMax}`
                        : `Max ${peopleMax}`,
                ]}
            />
        );
    };

    OnRoom = (e, id) => {
        const Active = Booking.GetBookingByType('konferens-fest');
        const Room = Eatery.MeetingRoom(id);
        const Location = Room ? Room.content.eatery : '';
        if (Active) {
            Active.SetParameter('eatery', Location);
            Active.SetParameter('meetingRoom', [id]);
        } else {
            Globals.Var(
                'booking-konferens-fest',
                {
                    meetingRoom: [id],
                    eatery: Location,
                },
            );
        }
        Globals.Trigger('redirect', '/bokning/konferens-fest');
    };

    OnToggleTab = (tab) => {
        window.history.replaceState({}, '', '#' + tab);
        this.setState({tab: tab});
    };

    render() {
        const {content, title, uri} = this.props;
        const {
            external_booking_url,
            address,
            city,
            eventAbout,
            eventBadge,
            eventGallery,
            eventImage,
            eventImagePositionH,
            eventImagePositionV,
            eventPriceList,
            eventPriceListNoVat,
            eventMore,
            eventStaff,
            eventTextColor,
            external,
            partyAbout,
            functions,
            location,
            menues,
        } = content;

        const {lat, lng} = location || {};
        const Address = address.split('\n');
        const AddressItems = [];
        const AllMeetingRooms = Eatery.MeetingRooms();
        const Functions = functions ? Object.keys(functions) : [];
        const HasConference = Functions.indexOf('conference') >= 0;
        const HasEvent = Functions.indexOf('event') >= 0;
        const GalleryItems = [];
        const MeetingRoomItems = [];
        const Menues = Eatery.Menues(uri);
        const PriceList = eventPriceListNoVat || eventPriceList;
        const Staff = (eventStaff && eventStaff.length)
            ? eventStaff
            : Eatery.Option('conferenceStaff', []);
        const StaffItems = [];
        const Tags = [];
        const TagItems = [];
        let MeetingRoomsTitle;
        if (HasEvent && HasConference) {
            MeetingRoomsTitle = `Mötesrum och festlokaler på Eatery ${title}`;
        } else if (HasEvent) {
            MeetingRoomsTitle = `Festlokaler på Eatery ${title}`;
        } else {
            MeetingRoomsTitle = `Mötesrum på Eatery ${title}`;
        }
        Address.forEach((row, index) => {
            if (!location || index) {
                AddressItems.push(<div key={index}>{row}</div>);
            } else {
                AddressItems.push(<Link
                    className="EateryNavigationContactLink"
                    key={index}
                    href={`https://www.google.com/maps/place/?q=place_id:${location.id}`}
                >{row}</Link>);
            }
        });
        const MeetingRooms = Object.values(AllMeetingRooms);
        MeetingRooms.sort((a, b) => {
            const AP = a.content.priority || 0;
            const BP = b.content.priority || 0;
            if ((AP === undefined && BP !== undefined) || AP < BP) {
                return 1;
            }
            if ((BP === undefined && AP !== undefined) || AP > BP) {
                return -1;
            }
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
        MeetingRooms.forEach(room => {
            const {content, id, tags} = room;

            if (content.eatery !== uri) {
                return;
            }
            MeetingRoomItems.push(this.Item(room, id));
            tags.forEach(tag => {
                if (Tags.indexOf(tag) < 0) {
                    Tags.push(tag);
                }
            });
        });
        const MenuButtons = [];
        if (menues) {
            menues.forEach(({menu, label}) => {
                if (Menues[menu] === undefined) {
                    return;
                }
                const {content: menuContent, title: menuTitle} = Menues[menu];
                const {pdf} = menuContent || {};
                MenuButtons.push(pdf ? (
                    <Button
                        color="Beige"
                        href={pdf}
                        key={menu + 'pdf'}
                        label={label || menuTitle}
                    />
                ) : (
                    <Button
                        color="Beige"
                        key={menu}
                        label={label || menuTitle}
                        to={`${uri}/${menu}`}
                    />
                ));
            });
        }
        Tags.forEach((tag, index) => {
            TagItems.push(<Tag key={index} label={tag} />);
        });
        if (eventGallery) {
            const Autoplay = eventGallery.length < 2;
            eventGallery.forEach((media, index) => {
                const {image, imagePositionH, imagePositionV, video} = media;
                if (video) {
                    GalleryItems.push(<LoadVideo
                        autoplay={Autoplay}
                        key={index}
                        loop={Autoplay}
                        src={video}
                    />);
                } else {
                    GalleryItems.push(<LoadImage
                        key={index}
                        src={image}
                        style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}
                    />);
                }
            });
        }
        if (Staff && Staff.length) {
            Staff.forEach(id => {
                StaffItems.push(<ItemStaff id={id} key={id} />);
            });
        }
        return (
            <div className="TemplateLocation">
                <Full
                    backgroundColor="Black"
                    backgroundImage={eventImage}
                    backgroundPositionH={eventImagePositionH}
                    backgroundPositionV={eventImagePositionV}
                    className="TemplateLocationTop"
                    textColor={eventTextColor}
                    top={true}
                >
                    <div className="TemplateLocationLogo notranslate">
                        <LocalLogo
                            name={title}
                            rotate={true}
                            uri={uri}
                        />
                        {eventBadge ? (
                            <div className="TemplateLocationLogoBadge">
                                <LoadImage src={eventBadge} />
                            </div>
                        ) : ''}
                        {PriceList ? (
                            <div className="SegmentButtons Buttons TemplateEateryButtons">
                                <Button
                                    color="black"
                                    label="Prislista"
                                    onClick={() => Globals.DialogPriceList(
                                        PriceList)}
                                />
                            </div>
                        ) : ''}
                    </div>
                </Full>
                {MeetingRoomItems.length ? (
                    <Segment backgroundColor="Beige">
                        <div className="TemplateLocationItemsWrapper">
                            <h2>{MeetingRoomsTitle}</h2>
                            <div className="tabs">
                                <Tab
                                    active={this.state.tab === 'conference'}
                                    title="Konferens"
                                    onClick={() => this.OnToggleTab('conference')}
                                >
                                    Konferens
                                </Tab>
                                <Tab
                                    active={this.state.tab === 'event'}
                                    title="Fest"
                                    onClick={() => this.OnToggleTab('event')}
                                >
                                    Fest
                                </Tab>
                            </div>
                            <div className="TemplateLocationItems">
                                {MeetingRoomItems}
                            </div>
                        </div>
                    </Segment>
                ) : ''}
                <Segment>
                    <ThreeColumns
                        left={external ? (
                            <Links
                                className="TemplateLocationLinksLeft"
                                color="Gold"
                                items={[
                                    {
                                        label: `Läs mer på ${UcFirst(external.replace(
                                            /^https:\/\/|\/$/gi,
                                            '',
                                        ))}`,
                                        type: 'link',
                                        url: external,
                                    },
                                ]}
                                vertical={true}
                            />
                        ) : ''}
                    >
                        <div className="TemplateLocationContent">
                            <h3>{city}</h3>
                            <div className="TemplateLocationTags">
                                {TagItems}
                            </div>
                            {Parser.Parse(
                                this.state.tab === 'event' && partyAbout
                                    ? partyAbout
                                    : eventAbout,
                                'div',
                                null,
                                {className: 'TemplateLocationAbout'},
                            )}
                            {PriceList ? <Button
                                color="beige"
                                label="Prislista"
                                onClick={() => Globals.DialogPriceList(PriceList)}
                            /> : ''}
                            {MenuButtons}
                        </div>
                        {GalleryItems.length
                            ?
                            <Gallery className="TemplateLocationGallery">{GalleryItems}</Gallery>
                            : ''}
                        {eventMore ? Parser.Parse(
                            eventMore,
                            'div',
                            null,
                            {className: 'TemplateLocationAboutMore'},
                        ) : ''}
                        {(lat && lng) ? (
                            <div className="TemplateLocationMap">
                                <Map address={address} lat={lat} lng={lng} />
                            </div>
                        ) : ''}
                        <div className="TemplateLocationAddress">
                            {AddressItems}
                        </div>
                    </ThreeColumns>
                </Segment>
                <Segment>
                    <div className="TemplateLocationStaffItemsWrapper">
                        {external ? (
                            <Links
                                className="TemplateLocationLinksBottom"
                                color="Gold"
                                items={[
                                    {
                                        label: `Läs mer på ${UcFirst(external.replace(
                                            /^https:\/\/|\/$/gi,
                                            '',
                                        ))}`, url: external,
                                    },
                                ]}
                                vertical={true}
                            />
                        ) : ''}
                        <div className="TemplateLocationStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
                {external_booking_url ? (
                    <a
                        className="Link FixedButton ColorGold"
                        href={external_booking_url}
                        title="Till bokning"
                        target="_blank"
                        rel="noreferrer"
                    >Till bokning</a>
                ) : <FixedButton
                    label="Till bokning"
                    to="/bokning/konferens-fest"
                />
                }

            </div>
        );
    }
}

TemplateLocation.defaultProps =
    {
        content: {},
    };

export default TemplateLocation;
