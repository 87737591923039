import Eatery from 'Class/Eatery';
import Parser from 'Class/Parser';

import Full from 'Components/Layout/Full';
import LocalLogo from 'Components/Layout/LocalLogo';
import Segment from 'Components/Layout/Segment';
import ItemLarge from 'Components/UI/ItemLarge';
import ItemStaff from 'Components/UI/ItemStaff';
import Modules from 'Components/View/Modules';
import React from 'react';
import './locations.scss';
import Icon from '../../../Layout/Icon';

class TemplateLocations extends React.Component {
    render() {
        const {content, title} = this.props;
        const {
            about,
            backgroundColor,
            backgroundImage,
            backgroundPositionH,
            backgroundPositionV,
            contents,
        } = content || {};
        const Rooms = Eatery.MeetingRooms();
        const Staff = Eatery.Option('conferenceStaff', []);
        const Locations = {};
        const LocationItems = [];
        const StaffItems = [];


        for (let id in Rooms) {
            const {content, tags} = Rooms[id];
            const {eatery} = content || {};

            if (eatery && Locations[eatery] === undefined) {

                const {
                    content: locationContent,
                    title: locationName,
                } = Eatery.Eatery(eatery);


                if (
                    tags.indexOf('Konferens') === -1 &&
                    tags.indexOf('Digital konferens') === -1 &&
                    tags.indexOf('Digital studio') === -1 &&
                    tags.indexOf('Hybridmöte') === -1 &&
                    tags.indexOf('Samtalsrum') === -1 &&
                    tags.indexOf('Möte') === -1 &&
                    tags.indexOf('Videomöte') === -1
                ) {
                    continue;
                }

                const {
                    eventAbout,
                    eventMax,
                    eventImage,
                    eventImagePositionH,
                    eventImagePositionV,
                    eventPriority,
                    city,
                } = locationContent || {};
                Locations[eatery] =
                    {
                        name: locationName,
                        about: eventAbout,
                        max: eventMax,
                        image: eventImage,
                        imagePositionH: eventImagePositionH,
                        imagePositionV: eventImagePositionV,
                        city: city || 'Stockholm',
                        priority: eventPriority || 0,
                        tags: tags || [],
                        uri: eatery,
                    };
            } else if (Locations[eatery] && tags) {
                tags.forEach(tag => {
                    if (Locations[eatery].tags.indexOf(tag) < 0) {
                        Locations[eatery].tags.push(tag);
                    }
                });
            }
        }
        const Sorted = Object.values(Locations);
        Sorted.sort((a, b) => {
            if (a.priority > b.priority) {
                return -1;
            }
            if (a.priority < b.priority) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
        Sorted.forEach(location => {
            const {
                about,
                city,
                image,
                imagePositionH,
                imagePositionV,
                name,
                uri,
            } = location;
            LocationItems.push(
                <ItemLarge
                    buttons={[
                        {
                            hollow: true,
                            label: 'Läs mer',
                            to: `anlaggningar${uri}`,
                        },
                    ]}
                    className="TemplateLocationsItem"
                    key={name}
                    image={image}
                    imagePositionH={imagePositionH}
                    imagePositionV={imagePositionV}
                >
                    {location.max ? (
                        <div className="ItemRoomTop">
                            <Icon key="icon" src="People"/>
                            Max {location.max}
                        </div>
                    ) : ''}
                    <h3 className="notranslate">{name}</h3>
                    <h5>{city}</h5>
                    {Parser.Parse(
                        about.replace(/<h.*>[^<]*<\/h.*>/gi, ''),
                        'div',
                        null,
                        {className: 'TemplateLocationsItemAbout'},
                    )}
                </ItemLarge>,
            );
        });
        if (Staff && Staff.length) {
            Staff.forEach(id => {
                StaffItems.push(<ItemStaff id={id} key={id} />);
            });
        }
        return (
            <div className="TemplateLocations">
                <Full
                    backgroundColor={backgroundColor}
                    backgroundImage={backgroundImage}
                    backgroundPositionH={backgroundPositionH}
                    backgroundPositionV={backgroundPositionV}
                    className="TemplateLocationsTopNew"
                    top={true}
                >
                    <LocalLogo
                        mainLogo={false}
                        name={title}
                        rotate={true}
                    />
                </Full>
                <Segment backgroundColor="Beige">
                    <div className="TemplateLocationsTop">
                        {Parser.Parse(
                            about,
                            'div',
                            null,
                            {className: 'TemplateLocationsAbout'},
                        )}
                    </div>
                    <div className="TemplateLocationsItemsWrapper">
                        <div className="TemplateLocationsItems">
                            {LocationItems}
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <div className="TemplateConferenceStaffItemsWrapper">
                        <div className="TemplateConferenceStaffItems">
                            {StaffItems}
                        </div>
                    </div>
                </Segment>
                <Modules modules={contents} startIndex={1} />
            </div>
        );
    }
}

TemplateLocations.defaultProps =
    {
        content: {},
    };

export default TemplateLocations;
