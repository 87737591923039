import React from "react";
import "./itemroom.scss";

import Button from "Components/UI/Button";
import LoadImage from "Components/Layout/LoadImage";
import Tag from "Components/UI/Tag";

class ItemRoom extends React.Component
{
    render()
    {
        const {
            buttons,
            className,
            children,
            image,
            imagePositionH,
            imagePositionV,
            subTitle,
            tags,
            title,
            top
        } = this.props;
        const CA = ["ItemRoom"];
        if (className)
        {
            CA.push(className);
        }
        const Buttons = [];
        const Tags = [];
        buttons.forEach((button, index) =>
        {
            Buttons.push(<Button key={index} {...button} small={true}/>);
        });
        tags.forEach((label, index) =>
        {
            Tags.push(<Tag key={index} label={label}/>);
        });
        return (
            <div className={CA.join(" ")}>
                <div className="ItemRoomWrapper">
                    <div className="ItemRoomImage">
                        <LoadImage src={image} style={{backgroundPosition: `${imagePositionH}% ${imagePositionV}%`}}/>
                        {top ? <div className="ItemRoomTop">{top}</div> : ""}
                    </div>
                    {children ? (
                        <div className="ItemRoomContent">
                            {children}
                            <div className="ItemRoomButtons">
                                {Buttons}
                            </div>
                        </div>
                    ) : (
                        <div className="ItemRoomContent">
                            <h3 className="notranslate">{title}</h3>
                            <h5 className="notranslate">{subTitle}</h5>
                            <div className="ItemRoomTags">{Tags}</div>
                            <div className="ItemRoomButtons">
                                {Buttons}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

ItemRoom.defaultProps =
{
    buttons: [],
    className: "",
    image: "",
    imagePositionH: 50,
    imagePositionV: 50,
    subTitle: "",
    tags: [],
    title: "",
    top: ""
};

export default ItemRoom;