import Globals from 'Class/Globals';
import Icon from 'Components/Layout/Icon';

import Delivery from 'Components/View/Delivery';
import Reservation from 'Components/View/Reservation';
import React from 'react';

class Eatery {
    constructor() {
        this.ApiUrl =
            Globals.Setting('ApiUrl', '')
                .replace(/\/?$/, '/wp-json/eatery/v1/');
        this.Content = false;
        this.DefaultEatery = '/gardet';
        this.EventTypes =
            ['Möte/konferens', 'Event/fest företag', 'Event/fest privat'];
        this.Ready = false;
        Globals.Listen('pre-content', this.OnParseTag);
    }

    Action = (slug) => {
        switch (slug) {
            case 'delivery':
                Globals.Dialog({
                    color: 'Beige',
                    content: <Delivery />,
                    id: 'delivery',
                });
                break;
            case 'reservation':
                Globals.Dialog({
                    color: 'Beige',
                    content: <Reservation />,
                    id: 'reservation',
                });
                break;
            default:
        }
    };

    /**
     * Get a activity item by id.
     *
     * @param number id - Activity item id.
     * @return object - Activity item object.
     */

    Activity = (id) => {
        if (!this.Content) {
            return false;
        }
        return this.Content.activities[id] || false;
    };

    /**
     * List activity items.

     * @param string eatery - Optional eatery uri.
     * @return object - List of activity items sorted by category.
     */

    ActivityList = (eatery) => {
        const List = [];
        if (!this.Content) {
            return List;
        }
        for (let id in this.Content.activities) {
            const {content, title} = this.Content.activities[id];
            const {locations} = content;
            const Item = {id, title, content};
            if (eatery &&
                locations &&
                locations.length &&
                locations.indexOf(eatery) <
                0) {
                continue;
            }
            List.push(Item);
        }
        return List;
    };

    CloseAnnouncement = (hash) => {
        Globals.Client(`announcement-${hash}`, 1);
        Globals.Trigger('announcement', this.GetAnnouncement([hash]));
    };

    /**
     * Fetch eateries from the backend content object.
     *
     * @return object|boolean - The eateries object or 'false' when unloaded.
     */

    Eateries = () => {
        if (!this.Content) {
            return false;
        }
        return this.Content.eateries || false;
    };

    EateriesSorted = () => {
        if (!this.Content) {
            return [];
        }
        const Eateries = Object.values(this.Content.eateries);
        Eateries.sort((a, b) => {
            return a.title.localeCompare(b.title, 'sv');
        });
        return Eateries;
    };

    /**
     * Fetch a eatery object from the content.
     *
     * @param string uri - The eatery URI.
     *
     * @return object|boolean - The page object or 'false' when unloaded.
     */

    Eatery = (uri) => {
        if (!this.Content) {
            return false;
        }
        return this.Content.eateries[uri] || false;
    };

    /**
     * Get the name of a Eatery from a URI
     *
     * @param string uri - The eatery URI.
     *
     * @return string|boolean - The eatery name or 'false' when not found.
     */

    EateryName = (uri) => {
        const Location = this.Eatery(uri);
        if (!Location) {
            return false;
        }
        return Location.title;
    };

    /**
     * Get a food/drink item by id.
     *
     * @param number id - Food item id.
     * @return object - Food item object.
     */

    Food = (id) => {
        if (!this.Content) {
            return false;
        }

        return this.Content.food[id] || false;
    };

    FoodCategoryFilter = (filter, categories) => {
        if (!filter || !filter.length) {
            return true;
        }
        if (!categories || !categories.length) {
            return false;
        }
        for (let i in filter) {
            if (categories.indexOf(filter[i]) >= 0) {
                return true;
            }
        }
        return false;
    };

    /**
     * List food items.
     *
     * @param string eatery - Optional eatery uri.
     * @param array filter - Optional category filter.
     * @param int|string event - Optional event type.
     * @return object - List of food items sorted by category.
     */

    FoodList = (eatery, filter) => {
        const List = {};
        if (!this.Content) {
            return List;
        }
        for (let id in this.Content.food) {
            const {categories, content, title} = this.Content.food[id];
            const {locations} = content;
            const Item = {id, title, content};
            if (
                (eatery &&
                    locations &&
                    locations.length &&
                    locations.indexOf(eatery) <
                    0) ||
                !this.FoodCategoryFilter(filter, categories)
            ) {
                continue;
            }
            if (!categories || !categories.length) {
                if (List[''] === undefined) {
                    List[''] = [];
                }
                List[''].push(Item);
            } else {
                for (let i in categories) {
                    if (filter &&
                        filter.length &&
                        filter.indexOf(categories[i]) <
                        0) {
                        continue;
                    }
                    if (List[categories[i]] === undefined) {
                        List[categories[i]] = [];
                    }
                    List[categories[i]].push(Item);
                    break;
                }
            }
        }
        return List;
    };

    /**
     * Parse a object into properly formatted form data.
     *
     * @param object obj
     * @return object
     */

    FormData = (obj) => {
        /**
         * This is a recursive function for parsing a nested object.
         *
         * @param object formData - A FormData instance.
         * @param object obj - Data to be appended into formData.
         * @param string parent - Optional. Parent data key.
         * @return object
         */
        const Append = (formData, obj, parent) => {
            let Item, Key;
            for (let k in obj) {
                Item = obj[k];
                Key = parent ? `${parent}[${k}]` : k;
                if (typeof Item === 'object') {
                    Append(formData, Item, Key);
                } else {
                    formData.append(Key, Item ? Item : '');
                }
            }
            return formData;
        };
        // Create a FormData instance and feed obj into it via append().
        const Data = new FormData();
        Append(Data, obj);
        return Data;
    };

    /**
     * Get announcement.
     *
     * @param array|string exclude - Hash(es) to exclude.
     * @return object|boolean - Announcement.
     */

    GetAnnouncement = (exclude) => {
        const Announcements = this.Option('announcements', []);
        const Exclude = Array.isArray(exclude) ? exclude : [exclude];
        for (let i in Announcements) {
            const Hash = Announcements[i].hash;
            if (Exclude.indexOf(Hash) >=
                0 ||
                Globals.Client(`announcement-${Hash}`)) {
                continue;
            }
            return Announcements[i];
        }
        return false;
    };

    /**
     * Get favourite eatery.
     *
     * @return object - Eatery object.
     */

    GetEatery = () => {
        return this.Eatery(this.SetEatery());
    };

    /**
     * Get a pages' template.
     *
     * @param string uri - The page URI.
     * @return string - The page title.
     */

    GetTemplate = (uri) => {
        if (!uri) {
            return 'default';
        }
        const Page = this.Page(uri);
        if (!Page) {
            return 'default';
        }
        return Page.template || 'default';
    };

    /**
     * Get a pages' title.
     *
     * @param string uri - The page URI.
     * @return string - The page title.
     */

    GetTitle = (uri) => {
        if (!uri) {
            return '';
        }
        const Page = this.Page(uri);
        if (Page) {
            return Page.title;
        }
        const Post = this.Post(uri);
        if (Post) {
            return Post.title;
        }
        return '';
    };

    /**
     * Get a instagram feed.
     *
     * @param string eatery - Optional URI. Get the feed of an eatery.
     * @param string filter - Filter from captions.
     * @return void
     */

    Instagram = (eatery, filter = false, limit = 12) => {
        if (!this.Content) {
            return false;
        }
        if (eatery) {
            const Eatery = this.Eatery(eatery);
            const Username = Eatery ? Eatery.content.instagram.replace(
                /.*\//g,
                '',
            ) : false;
            if (!Username || !this.Content.instagram[Username]) {
                return [];
            }
            if (!filter) {
                return this.Content.instagram[Username].slice(0, limit);
            }
            const Filtered = [];
            this.Content.instagram[Username].forEach(post => {
                if (post.caption && post.caption.includes(filter)) {
                    Filtered.push(post);
                }
            });
            return Filtered.slice(0, limit);
        }
        const Combined = [];
        for (let username in this.Content.instagram) {
            this.Content.instagram[username].forEach(post => {
                if (!filter ||
                    (post.caption && post.caption.includes(filter))) {
                    Combined.push(post);
                }
            });
        }
        Combined.sort((a, b) => {
            if (a.timestamp < b.timestamp) {
                return 1;
            }
            if (a.timestamp > b.timestamp) {
                return -1;
            }
            return 0;
        });
        return Combined.slice(0, limit);
    };

    /**
     * Load initial data from the backend API.
     *
     * @return void
     */

    Load = () => {
        this.Content = false;
        this.Ready = false;
        Globals.Var('error', false);
        Globals.Var('ready', false);
        Globals.Var('loading', true);
        this.Request('load', {}, response => {
            const Success = typeof response ===
                'object' &&
                Object.keys(response).length >
                1;
            this.Content = Globals.Var('content', Success ? response : false);
            Globals.Var('error', !Success);
            Globals.Var('ready', !!Success);
            Globals.Var('loading', false);
        });
    };

    /**
     * List all meeting rooms, or get a specific meeting room.
     *
     * @param {string|number|undefined} uri - Optional meeting room uri or id.
     * @return object - List or meeting room object.
     */

    MeetingRoom = (uri = undefined) => {
        if (!this.Content) {
            return false;
        }

        if (uri === undefined) {
            return this.Content.meetingrooms;
        }

        if (typeof uri === 'number') {
            for (let i in this.Content.meetingrooms) {
                if (this.Content.meetingrooms[i].id === uri) {
                    return this.Content.meetingrooms[i];
                }
            }
        }

        return this.Content.meetingrooms[uri] || false;
    };

    /**
     * Get a list of all meeting rooms.
     *
     * @return array - List of meeting rooms.
     */

    MeetingRooms = () => {
        if (!this.Content) {
            return [];
        }
        return this.Content.meetingrooms;
    };

    /**
     * Get a list of all meeting rooms tags.
     *
     * @return array - List of meeting rooms tags.
     */

    MeetingRoomTags = () => {
        if (!this.Content) {
            return {};
        }
        return this.Content.meetingroomtags;
    };

    /**
     * List all menues, or get a specific menu.
     *
     * @param number|string id - Optional menu id/uri.
     * @return object - List or menu object.
     */

    Menu = (id) => {
        if (!this.Content) {
            return false;
        }
        if (id === undefined) {
            return this.Content.menues;
        }
        if (typeof id === 'string') {
            const [LocationSlug, MenuSlug] = id.substr(1).split('/');
            const Location = this.Eatery('/' + LocationSlug);
            if (!Location || !Location.menues[MenuSlug]) {
                return false;
            }
            return this.Content.menues[Location.menues[MenuSlug]] || false;
        }
        return this.Content.menues[id] || false;
    };

    /**
     * Get a list of menues.
     *
     * @param {string} eatery - Optional Eatery URI.
     * @return array|object - List of menues.
     */
    Menues = (eatery) => {
        if (!this.Content) {
            return [];
        }

        if (!eatery) {
            return this.Content.menues;
        }

        const Menues = {};
        const Location = this.Eatery(eatery);

        if (!Location) {
            return Menues;
        }

        for (let slug in Location.menues) {
            Menues[slug] = this.Content.menues[Location.menues[slug]];
        }

        return Menues;
    };

    News = () => {
        if (!this.Content) {
            return [];
        }

        const news = [];
        for (let slug in this.Content.news) {
            news.push(this.Content.news[slug]);
        }

        return news;
    };

    /**
     * Get a time string thats has the correct timezone.
     *
     * @return string - The time string.
     */

    Now = (insertT = false) => {
        const Now = (new Date()).toLocaleString(
            'sv-SE',
            {timeZone: 'Europe/Stockholm'},
        );
        return insertT ? Now.replace(' ', 'T') : Now;
    };

    /**
     * Callback for when a tag is about to be parsed in Parser().
     *
     * @param object tagObject - The tag.
     * @return void.
     */

    OnParseTag = (element, children, parent) => {
        if (children.length) {
            return;
        }
        const {index, name} = element;
        const {name: parentName} = parent || {};
        const Index = index || 0;
        switch (name) {
            case 'li':
                if (parentName === 'ul') {
                    children.push(<Icon
                        className="ListCheck"
                        key="bullet"
                        src="Check"
                    />);
                } else {
                    children.push(<div
                        className="ListNumber"
                        key="number"
                    >{Index + 1}</div>);
                }
                break;
            default:
        }
    };

    /**
     * Get a option.
     *
     * @param string key - Option key.
     * @param mixed defaultValue - Optional fallback value.
     * @return string - The time string.
     */

    Option = (key, defaultValue = '') => {
        if (!this.Content || this.Content.options[key] === undefined) {
            return defaultValue;
        }
        return this.Content.options[key];
    };

    /**
     * Fetch a page object from the content.
     *
     * @param string uri - The page URI.
     * @return object|boolean - The page object or 'false' when unloaded.
     */

    Page = (uri, return404 = true) => {
        // Remove trailing slash.
        const Uri = uri.replace(/\/$/, '') || '/';
        const Eatery = this.Eatery(Uri);
        if (!this.Content) {
            return false;
        }
        if (Eatery) {
            Eatery.template = 'eatery';
            return Eatery;
        }
        const Menu = this.Menu(Uri);
        if (Menu) {
            Menu.template = 'menu';
            return Menu;
        }
        if (this.Content.pages[Uri]) {
            return this.Content.pages[Uri];
        }
        if (this.Content.posts[Uri]) {
            const Post = this.Content.posts[Uri];
            Post.template = 'post';
            return Post;
        }
        if (this.Content.news[Uri]) {
            const News = this.Content.news[Uri];
            News.template = 'single-news';
            return News;
        }
        if (this.Content.meetingrooms[Uri]) {
            const Post = this.Content.meetingrooms[Uri];
            Post.template = 'meetingroom';
            return Post;
        }
        if (this.Content.studios[Uri]) {
            const Post = this.Content.studios[Uri];
            Post.template = 'studio';
            return Post;
        }
        return return404 ? this.Content.pages['/sidan-hittades-inte'] : false;
    };

    Page404 = () => {
        if (!this.Content) {
            return false;
        }
        return this.Content.pages['/sidan-hittades-inte'];
    };

    /**
     * Get a post by uri.
     *
     * @param string uri - Post uri.
     * @return object - Post object.
     */

    Post = (uri) => {
        if (!this.Content) {
            return false;
        }
        if (uri === undefined) {
            return this.Content.posts;
        }
        return this.Content.posts[uri] || false;
    };

    /**
     * Find a post by id.
     *
     * @param number id - Post id.
     * @return string - Post uri.
     */

    PostSearch = (id) => {
        if (!this.Content) {
            return false;
        }
        const Id = parseInt(id, 10);
        for (let uri in this.Content.posts) {
            if (this.Content.posts[uri].id === Id) {
                return uri;
            }
        }
        return false;
    };

    /**
     * Fetch posts
     *
     * @param {string} eatery - (URI) Filter news from this eatery.
     * @param {number} limit - Limit.
     * @return array - Posts.
     */

    Posts = (eatery, limit = 8) => {
        const Filtered = [];
        for (let id in this.Content.posts) {
            const Post = this.Content.posts[id];
            const {eatery: eaterys, global} = Post.content;
            if ((eatery &&
                    (!Array.isArray(eaterys) ||
                        (Array.isArray(eaterys) && eaterys.indexOf(eatery) < 0))) ||
                (!global && !eatery)) {
                continue;
            }
            Filtered.push(Post);
        }
        Filtered.sort((a, b) => {
            const AS = a.content.sticky;
            const BS = b.content.sticky;
            if (!AS && BS) {
                return 1;
            }
            if (AS && !BS) {
                return -1;
            }
            if (a.date < b.date) {
                return 1;
            }
            if (a.date > b.date) {
                return -1;
            }
            return 0;
        });
        const List = Filtered.slice(0, limit);
        List.sort((a, b) => {
            const AP = a.content.priority || 0;
            const BP = b.content.priority || 0;
            if ((AP === undefined && BP !== undefined) || AP < BP) {
                return 1;
            }
            if ((BP === undefined && AP !== undefined) || AP > BP) {
                return -1;
            }
            if (a.date < b.date) {
                return 1;
            }
            if (a.date > b.date) {
                return -1;
            }
            return 0;
        });
        return List;
    };

    /**
     * List all price lists, or get a specific price list.
     *
     * @param number id - Optional price list id.
     * @return object - List or price list object.
     */

    PriceList = (id) => {
        if (!this.Content) {
            return false;
        }
        if (id === undefined) {
            return this.Content.pricelists;
        }
        return this.Content.pricelists[id] || false;
    };

    /**
     * Get a list of all price list.
     *
     * @return array - List of price list.
     */

    Pricelists = () => {
        if (!this.Content) {
            return [];
        }
        return this.Content.pricelists;
    };

    /**
     * Make a XHR request to the backend server API.
     *
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param function progress - Called when XHR progress updates.
     * @param boolean expectJson - Whether to expect a JSON response.
     * @return object - The XHR object.
     */

    Request = (endpoint, data, callback, progress, expectJson = true) => {
        // Since data is optional, the second parameter may instead
        // be the callback method.
        if (!callback) {
            callback = data;
        }
        if (typeof callback !== 'function') {
            callback = () => {};
        }
        // Check API credentials.
        if (!this.ApiUrl) {
            console.error('No API URL has been configured.');
            callback({
                error: true,
                errorMsg: 'No API URL has been configured.',
            });
            return;
        }
        const Url = this.ApiUrl + endpoint;
        const FormData = this.FormData(data);
        const Xhr = new XMLHttpRequest();
        Xhr.open('POST', Url, true);
        // On complete.
        Xhr.addEventListener('load', e => {
            // Check for 200.
            if (Xhr.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            const Json = Xhr.response.match(/^[{[]/);
            // Check that the response is a proper JSON.
            if (Json) {
                callback(JSON.parse(Xhr.response));
            } else if (expectJson) {
                callback(false);
            } else {
                callback(Xhr.response);
            }
        }, false);
        // On fail.
        Xhr.addEventListener('error', e => callback(false), false);
        // On progress update. If a callback has been supplied.
        if (typeof progress === 'function') {
            Xhr.upload.addEventListener(
                'progress',
                e => progress(e.loaded, e.total),
                false,
            );
        }
        Xhr.send(FormData);
        return Xhr;
    };

    /**
     * Set favourite eatery. Either by param or URI.
     *
     * @param string uri - Optional. Input URI.
     * @return string - Output URI.
     */

    SetEatery = (uri) => {
        const Stored = Globals.Storage('eatery');
        let Uri = uri || window.location.pathname;
        if (!uri && Stored) {
            return Stored;
        }
        if (!Uri || !this.Eatery(Uri)) {
            Uri = this.DefaultEatery;
        }
        Globals.Storage('eatery', Uri);
        Globals.Trigger('eatery', Uri);
        return Uri;
    };

    /**
     * List all staff members, or get a specific staff member.
     *
     * @param number id - Optional staff member id.
     * @return object - List or staff member object.
     */

    Staff = (id) => {
        if (!this.Content) {
            return false;
        }
        if (id === undefined) {
            return this.Content.staff;
        }
        return this.Content.staff[id] || false;
    };

    /**
     * List all studios, or get a specific studio.
     *
     * @param number id - Optional meeting room id.
     * @return object - List or studio object.
     */

    Studio = (id) => {
        if (!this.Content) {
            return false;
        }
        if (id === undefined) {
            return this.Content.studios;
        }
        return this.Content.studios[id] || false;
    };

    /**
     * Get a list of all studios.
     *
     * @return array - List of studios.
     */

    Studios = () => {
        if (!this.Content) {
            return [];
        }
        return this.Content.studios;
    };

    Url = (uri) => {
        return Globals.Setting('ApiUrl', '')
            .replace(/\/?$/, '/' + uri.replace(/^\//, ''));
    };
}

export default new Eatery();
