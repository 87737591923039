import React from 'react';
import Eatery from '../../../../Class/Eatery';
import { DateStamp } from '../../../../Functions';
import Segment from '../../../Layout/Segment';
import './single.scss';
import Button from '../../../UI/Button';
import ItemRoom from '../../../UI/ItemRoom';

function TemplateSingle({id, title, date, content}) {
    const {image, content: text} = content;

    function onShare() {
        navigator.share({
            url: document.location.href,
            title: title,
        });
    }

    const relatedNews = Eatery.News().filter(n => n.id !== id).slice(0, 4);

    return (
        <div className="TemplateSingleNews">
            <Segment backgroundColor="White" top={true}>
                <div className="TemplatePressReleaseTop">
                    <h3>Nyhet</h3>
                    <h4>{DateStamp(new Date(date))}</h4>
                    <h1>{title}</h1>
                </div>
                <div className="TemplatePressReleaseContent">
                    <div className="TemplatePressReleaseIntro"></div>
                    <div className="TemplatePressReleaseBody">
                        <p dangerouslySetInnerHTML={{__html: text}} />
                        {navigator.share &&
                            <button
                                className="share Link Button ColorBlack Big"
                                onClick={onShare}
                            >
                                Dela
                            </button>
                        }
                    </div>
                    <div className="Gallery">
                        <img src={image} alt={title} />
                    </div>
                </div>
            </Segment>
            <Segment backgroundColor="Beige">
                <div className="TemplatePressRelated">
                    <h3>Fler nyheter</h3>
                    <div className="TemplatePressRelatedItems">
                        {relatedNews.map(item => (
                            <ItemRoom
                                key={item.id}
                                buttons={[
                                    {
                                        color: 'Black',
                                        label: 'Läs mer',
                                        to: item.uri,
                                    },
                                ]}
                                className="ItemPress TemplatePressRelatedItem"
                                image={item.content.thumbnail}
                            >
                                <div className="ItemPressContent">
                                    <h3>{item.title}</h3>
                                    <h4>{DateStamp(new Date(item.date))}</h4>
                                    <p>{item.content.excerpt}</p>
                                </div>
                            </ItemRoom>
                        ))}
                    </div>
                    <Button
                        color="Black"
                        label="Alla nyheter"
                        to="/nyheter"
                    />
                </div>
            </Segment>
        </div>
    );
}

export { TemplateSingle };
