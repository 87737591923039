import React from "react";
import "./booking.scss";

import Button from "Components/UI/Button";
import FormNavigation from "Components/UI/FormNavigation";
import Forms from "./forms.js";
import Icon from "Components/Layout/Icon";
import Segment from "Components/Layout/Segment";
import Spinner from "Components/Feedback/Spinner";

class TemplateBooking extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Form = false;
        this.FormNames =
        {
            "konferens-fest": "Konferens & fest",
            "catering": "Catering",
            "studio": "Digitala möten"
        };

        this.state =
        {
            booking: false,
            error: false,
            id: "",
            ready: false,
            sending: false,
            sent: false,
            stage: 0,
            stages: false,
            values: {}
        };
    }

    componentDidMount()
    {
        const {stage} = this.props;
        this.setState({stage: parseInt(stage, 10)});
    }

    OnCheck = (e, ready) =>
    {
        this.setState({ready});
    }

    OnClear = () =>
    {
        this.setState({sent: false});
    }

    OnSending = (sending, approved) =>
    {
        this.setState({
            sending,
            sent: approved
        });
    }

    OnStages = (stages) =>
    {
        this.setState({stages});
    }

    SetStage = (e, stage = 0) =>
    {
        this.setState({stage});
        window.scrollTo(0, 0);
    }

    Submit = () =>
    {
        if (!this.Form)
        {
            return;
        }
        this.Form.Submit();
    }

    render()
    {
        const {type} = this.props;
        const {ready, sending, sent, stage, stages} = this.state;
        const Stage = stages[stage] || stages[0] || {};
        const StageLabels = [];

        if (stages)
        {
            stages.forEach(s => StageLabels.push(s.name));
        }
        
        return (
            <Segment backgroundColor="Beige" top={true}>
                <div className="TemplateBooking">
                    <h1>Bokningsförfrågan | {this.FormNames[type]}</h1>
                    {sent ? "" : <FormNavigation
                        disabled={sending}
                        onClick={(e, index) => this.SetStage(e, index)}
                        step={stage}
                        steps={StageLabels}
                    />}
                    {sent ? "" : <h2>{stage + 1}. {Stage.name}</h2>}
                    <Forms
                        onCheck={this.OnCheck}
                        onClear={this.OnClear}
                        onSending={this.OnSending}
                        onStages={this.OnStages}
                        ref={form => this.Form = form}
                        stage={stage}
                        type={type}
                    />
                    {sent ? "" : (
                        <div className="TemplateBookingTray">
                            <Button
                                className="Left"
                                disabled={sending || stage <= 0}
                                hollow={true}
                                onClick={e => this.SetStage(e, stage - 1)}
                            >
                                Föregående
                                <Icon src="ChevronLeftSmall"/>
                            </Button>
                            {stage < stages.length - 1 ? (
                                <Button
                                    className="Right"
                                    color="black"
                                    disabled={!ready}
                                    onClick={e => this.SetStage(e, stage + 1)}
                                >
                                    Nästa
                                    <Icon src="ChevronRightSmall"/>
                                </Button>
                            ) : (
                                <Button
                                    className="Right"
                                    color="black"
                                    disabled={sending || !ready}
                                    onClick={this.Submit}
                                >
                                    Skicka in
                                    {sending ? <Spinner/> : <Icon src="ChevronRightSmall"/>}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </Segment>
        );
    }
}

TemplateBooking.defaultProps =
{
    stage: 0,
    type: "konferens-fest"
}

export default TemplateBooking;