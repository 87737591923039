
/*!
 *  Load and display an image resource.
 *
 *  @prop boolean autoAdjust - Whether the containing div should auto-adjust to fit the image.
 *  @prop string className - Append a class name.
 *  @prop function onLoad - OnLoad callback. (src, width, height).
 *  @prop function onError - OnError callback. (src).
 *  @prop string src - Image source URL.
 *  @prop string src2x - Double resolution image source URL.
 *  @prop object style - Image inline styles.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./loadimage.scss";

import {ObjectAssign} from "Functions";

class LoadImage extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Mounted = false;
        this.Node = false;
        this.Loading = false;
        this.Src = "";

        this.state =
        {
            height: 0,
            loaded: false,
            loading: false,
            width: 0
        };

    }

    /**
     * Load the image on mount.
     *
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;

        const {src, src2x} = this.props;
        const PR = window.devicePixelRatio;
        const Src = PR > 1 && src2x ? src2x : src;

        this.Load(Src);
    }

    /**
     * Reload the image when its' source changes.
     *
     * @return void
     */

    componentDidUpdate()
    {
        const {src, src2x} = this.props;
        const PR = window.devicePixelRatio;
        const Src = PR > 1 && src2x ? src2x : src;

        if (Src !== this.Src)
        {
            this.Load(Src);
        }
    }

    /**
     * Register unmount.
     *  
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     *   Load an image source.
     * 
     *   @param string src - Image source URL or image name in Media/Photos
     *
     *   @return void
     */

    Load = (src) =>
    {
        if (!src)
        {
            this.Src = src;
            this.setState({loaded: false});
        }
        else 
        {
            const {onError, onLoad} = this.props;
            const Img = new Image();

            Img.onload = () =>
            {
                if (!this.Mounted)
                {
                    return;
                }

                const {width, height} = Img;

                this.setState({
                    height,
                    loaded: true,
                    loading: false,
                    width
                });

                onLoad(src, width, height);
            };

            Img.onerror = () =>
            {
                if (!this.Mounted)
                {
                    return;
                }

                this.setState({
                    error: true,
                    loading: false
                });

                onError( src );
            };

            this.setState({
                error: false,
                loading: true,
                loaded: false
            });

            Img.src = this.Src = src;
        }
    }

    render()
    {
        
        const {autoAdjust, className, style} = this.props;
        const {width, height, error, loaded, loading} = this.state;
        const CA = ["LoadImage"];

        if (error)
        {
            CA.push("Error");
        }
        if (loaded)
        {
            CA.push("Loaded");
        }
        if (loading)
        {
            CA.push("Loading");
        }
        if (className)
        {
            CA.push(className);
        }

        const Style = ObjectAssign({backgroundImage: `url(${this.Src})`}, style);

        if (!this.Src)
        {
            CA.push("Empty");
            return <span className={CA.join(" ")} ref={node => this.Node = node}/>;
        }

        if (autoAdjust)
        {
            Style.paddingTop = height / width * 100 + "%"
        }
        
        return <span className={CA.join(" ")} ref={node => this.Node = node} style={Style}/>;
    }
}

LoadImage.propTypes =
{
    autoAdjust: PropTypes.bool,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    src2x: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    style: PropTypes.object
};

LoadImage.defaultProps =
{
    autoAdjust: false,
    className: "",
    onLoad: () => {},
    onError: () => {},
    src: "",
    src2x: "",
    style: {}
};

export default LoadImage;