
/*!
 *  Textarea form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string icon - Optional. Feather icon src.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop integer maxLength - Maximum number of characters. 0 = unlimited.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string|number value - Field value.
 */

import React from "react";
import "./textareafield.scss";

import {CapFloat, RandomToken, UcFirst} from "Functions";
import Icon from "Components/Layout/Icon";

class TextareaField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.FocusValue = false;
        this.Input = false;
        this.Token = RandomToken();

        this.state =
        {
            focus: false,
            value: ""
        };
    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount()
    {
        const {token, value} = this.props;
        this.SetValue(value);

        if (token)
        {
            this.Token = token;
        }
    }

    /**
     * Update value.
     * 
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1, token} = this.props;
        const {value: v2} = prevProps;

        if (token && token !== this.Token)
        {
            this.Token = token;
            this.forceUpdate();
        }

        if (v1 !== v2)
        {
            this.SetValue(v1);
        }
    }

    /**
     * Set focus on this field.
     * 
     * @return void
     */

    Focus = () =>
    {
        if (this.Input)
        {
            this.Input.focus();
        }
    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) =>
    {
        const {id, onBlur, onChange} = this.props;
        const Value = this.ParseValue(e.currentTarget.value);

        if (this.FocusValue !== Value)
        {
            onChange(e, Value, id);
        }

        onBlur(e, Value, id);
        this.setState({focus: false});
    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const Value = this.ParseValue(e.currentTarget.value);

        this.FocusValue = Value;
        onFocus(e, Value, id);
        this.setState({focus: true});
    }

    /**
     * Callback for when the user inputs a new value into the field.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnInput = (e) =>
    {
        const {disabled, id, onInput} = this.props;

        if (disabled)
        {
            return;
        }

        const Value = this.SetValue(e.currentTarget.value);
        onInput(e, Value, id);
    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) =>
    {
        e.stopPropagation();
    }

    /**
     * Listen for when the Enter key is pressed.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyUp = (e) =>
    {
        const {disabled, id, onEnter} = this.props;

        if (e.which === 13 && !disabled)
        {
            const Value = this.ParseValue(e.currentTarget.value);
            onEnter(e, Value, id);
        }
    }

    /**
     * Parse the field value.
     * 
     * @param string value - The raw value.
     * 
     * @return mixed - The parsed value.
     */

    ParseValue = (value) =>
    {
        const {maxLength} = this.props;
        let Value = String(value);

        if (maxLength)
        {
            Value = Value.substr(0, maxLength);
        }

        return Value;
    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () =>
    {
        const Value = this.ParseValue(this.props.value);
        this.setState({value: Value});
    }

    /**
     * Set field value and adjust its' height.
     * 
     * @param string value - Unparsed value.
     * 
     * @return void
     */

    SetValue = (value) =>
    {
        if (!this.Input)
        {
            return;
        }

        const {maxRows, minRows} = this.props;
        const Styles = window.getComputedStyle(this.Input);
        const Value = this.ParseValue(value);
        const Rows = Value.split(/\n/).length;
        const RowHeight = parseInt(Styles.lineHeight);
        const Height = (maxRows ? CapFloat(Rows, minRows, maxRows) : Math.max(Rows, minRows)) * RowHeight;

        this.setState({
            height: Height,
            value: Value
        });

        return Value;
    }

    /**
     * Get the field value.
     * 
     * @return string - The field value.
     */

    Value = () =>
    {
        return this.ParseValue(this.state.value);
    }

    render()
    {
        const {
            children,
            className,
            color,
            disabled,
            error,
            hollow,
            icon,
            invert,
            label,
            placeholder,
        } = this.props;
        const {
            focus,
            height,
            value
        } = this.state;
        const CA = ["Field", "TextareaField"];
        const Color = UcFirst(color);

        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (invert)
        {
            CA.push("Invert");
        }
        if (value)
        {
            CA.push("HasValue");
        }
        if (className)
        {
            CA.push(className);
        }

        return (
            <div className={CA.join(" ")}>
                {label ? <label htmlFor={this.Token}>{label}</label> : ""}
                <div className="InputWrapper">
                    <textarea
                        className={hollow ? "Input Hollow" : `Input Color${Color}`}
                        disabled={disabled}
                        id={this.Token}
                        onBlur={this.OnBlur}
                        onChange={() => {}}
                        onFocus={this.OnFocus}
                        onInput={this.OnInput}
                        onKeyDown={this.OnKeyDown}
                        onKeyUp={this.OnKeyUp}
                        onMouseDown={e => e.stopPropagation()}
                        placeholder={placeholder}
                        ref={input => this.Input = input}
                        style={{height}}
                        value={value}
                    />
                    {icon ? <Icon className="FieldIcon" feather={icon}/> : ""}
                </div>
                {children}
            </div>
        );
    }
}

TextareaField.defaultProps =
{
    big: false,
    className: "",
    color: "black",
    disabled: false,
    error: false,
    hollow: false,
    icon: "",
    id: "",
    invert: false,
    label: "",
    maxLength: 0,
    maxRows: false,
    minRows: 5,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    token: "",
    value: ""
};

export default TextareaField;