export function getHash() {
    const hash = document.location.hash;
    if (hash) {
        if (hash.indexOf('#') === 0) {
            return hash.slice(1);
        }

        return hash;
    }

    return '';
}
