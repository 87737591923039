import React from "react";
import "./text.scss";

import Parser from "Class/Parser";
import Segment from "Components/Layout/Segment";

class TemplateText extends React.Component
{
    render()
    {
        const {content} = this.props;
        const {text} = content || {};

        return (
            <div className="TemplateText">
                <Segment
                    backgroundColor="Beige"
                    className="TemplateTextPageContainer"
                    top={true}
                >
                    <div className="TemplateTextPage">
                        {Parser.Parse(text, "div", null, {className: "TemplateTextPageContent"})}
                    </div>
                </Segment>
            </div>
        );
    }
}

TemplateText.defaultProps =
{
    content: {}
};

export default TemplateText;