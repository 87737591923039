/**!
 *  Render a map.
 *  @param string address - Map address.
 *  @param string className - Append a class name.
 *  @param float lat - Center latitude.
 *  @param float lng - Center longitude.
 *  @param integer zoom - Zoom level.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./map.scss";
import Globals from "Class/Globals";
import Link from "Components/UI/Link";
import MapObject from "./map.js";

class Map extends React.Component
{
    constructor(props)
    {
        super(props);
        this.ApiKey = Globals.Setting("GoogleMapsKey");
        this.ApiUrl = Globals.Setting("GoogleMapsUrl");
        this.ApiLanguage = Globals.LocaleGet().substr(0, 2);
        if (!this.ApiKey || !this.ApiUrl)
        {
            console.error("Google Maps API credentials have not been set.");
        }
    }

    render()
    {
        if (!this.ApiKey || !this.ApiUrl)
        {
            return "";
        }
        const {address, children, className} = this.props;
        const CA = ["Map"];
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                <MapObject
                    {...this.props}
                    containerElement={<div className="MapContainer"/>}
                    googleMapURL={this.ApiUrl + "?key=" + this.ApiKey + "&language=" + this.ApiLanguage}
                    loadingElement={<div className="MapLoading"/>}
                    mapElement={<div className="MapElement"/>}
                
                />
                <Link className="MapAttribution" href={address ? `https://www.google.com/maps/search/?api=1&query=${address}` : ""}>
                    Kartdata ©{(new Date()).getFullYear()} Google
                </Link>
                {children}
            </div>
        );
    }
}

Map.propTypes =
{
    address: PropTypes.string,
    className: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    locations: PropTypes.array,
    zoom: PropTypes.number
};

Map.defaultProps =
{
    address: "",
    className: "",
    lat: 0,
    lng: 0,
    locations: [],
    zoom: 15
};

export default Map;